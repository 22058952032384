<template>
<div style="text-align: right">
  <!--  单选框类型元素-->
    <a-radio-group   v-model:value="selectRadio" @change="onChange" shape="round"  button-style="solid">
      <a-radio-button :value="item.value" v-for="(item, index) in valueList" :key="index" :checked="item.checked"
               :disabled="(item.disabled ?item.disabled : disabled) || isDisabled" size="small">{{ item.label }}</a-radio-button>
    </a-radio-group>
  <!-- <span ref="tip" style="color: red;font-size: smaller">{{ tips }}</span> -->
</div>
</template>

<script>
import { reactive, toRefs, defineComponent, watch, onBeforeUpdate, onBeforeMount, onMounted} from 'vue'
import {request,} from '../../../../utils/request'
import {getExtendUri,} from '../../../../utils/request_extend'
export default defineComponent({
  name: 'RadioBtnView',
  props: {
    moduleIndex:{
      type: [Number,String],
      default:()=> '0'
    },
    moduleId:{ // 模块ID
      type: [Number,String],
      default:()=> ''
    },
    elementId:{ // 元素ID
      type: [Number,String],
      default:()=> ''
    },
    dictionary: {
      type: String,
      default: () => ''
    },
    title: {
      type:String,
      default: ()=> ''
    },
    dataList: {
      type: [Array, Object],
      default: () => []
    },
    elementCode: {
      type: [Number,String],
      default: () => ''
    },
    isRequired:{
      type: [Number,String],
      default:()=> '0'
    },
    firstValue:{
      type: [Number,String],
      default:()=> ''
    },
    disabledType: {
      type: Boolean,
      default:()=> false
    },
    elementName : {
      type: String,
      default: () => ''
    },
    // 是否默认展示
    defaultRequired:{
      type: String,
      default: () => ''
    },
    detailType:{
      type: String,
      default: () => ''
    },
    isDisabled:{
      type: Boolean,
      default: () => false
    }
  },
  emits:['callback','update:firstValue'],
  setup(props, context) {
    const state = reactive({
      title: props.title,
      tips: '',
      valueList: [],
      selectRadio: '',
      elementCode: props.elementCode,
      disabled: false,
      dictionary: props.dictionary || ''
    })

    onBeforeUpdate(()=>{
      state.disabled = props.disabledType
    })
    const onChange = function () {
      if (props.isDisabled) return
      let arr = state.valueList.filter(item =>{
        return item.value == state.selectRadio
      })
      state.selectRadio = arr[0].value
    }
    watch(props, ()=>{
      if(props.defaultRequired === 'Y' && !props.firstValue){
        handelConsentDetail()
      }
      if(props.firstValue) {
        state.selectRadio = props.firstValue
      }
    })


    const handelConsentDetail = () =>{
      if(!props.dictionary){
        state.valueList = props.dataList.buttonOptionsList
        state.selectRadio = state.valueList[0].value
        let node = {}
        node[state.elementCode] = {
          [state.elementCode]: state.selectRadio,
          elementId: props.elementId,
          moduleType: props.moduleId,
          index:props.moduleIndex,
          required: props.isRequired,
          elementName: props.elementName,
        }
        context.emit('update:firstValue', state.selectRadio)
        context.emit('callback', node)

      }else{
        if(props.defaultRequired === 'Y' && (!props.firstValue || Number(props.firstValue) === 1) || props.firstValue === 'Y'){
          request(getExtendUri('dictionary_query_byType'),
        {data: {ids:[],types:[props.dictionary]}}).then(
          res =>{
            state.valueList = res.result[props.dictionary]
            state.selectRadio = state.valueList[0].value
            let node = {}
            node[state.elementCode] = {
              [state.elementCode]: state.selectRadio,
              elementId: props.elementId,
              moduleType: props.moduleId,
              index:props.moduleIndex,
              required: props.isRequired,
              elementName: props.elementName,
            }
            context.emit('update:firstValue', state.selectRadio)
            context.emit('callback', node)
        })
        }else if(props.firstValue  ){
          request(getExtendUri('dictionary_query_byType'),
          {data: {ids:[],types:[props.dictionary]}}).then(
            res =>{
              state.valueList = res.result[props.dictionary]
              state.selectRadio = state.valueList.filter(item => item.value === props.firstValue)[0].value
              let node = {}
              node[state.elementCode] = {
                [state.elementCode]: state.selectRadio,
                elementId: props.elementId,
                moduleType: props.moduleId,
                index:props.moduleIndex,
                required: props.isRequired,
                elementName: props.elementName,
              }
              context.emit('update:firstValue', state.selectRadio)
              context.emit('callback', node)
          })

        }
      }
    }

    watch(()=>state.selectRadio,(newVal)=>{
        let node = {}
        node[state.elementCode] = {
          [state.elementCode]: newVal,
          elementId: props.elementId,
          moduleType: props.moduleId,
          index:props.moduleIndex,
          required: props.isRequired,
          elementName: props.elementName,
        }
        context.emit('update:firstValue', newVal)
        context.emit('callback', node)
    })


    const getDictionaryDetail = () =>{
      if(!props.dictionary){
        state.valueList = props.dataList.buttonOptionsList
        return
      }
      request(getExtendUri('dictionary_query_byType'),
        {data: {ids:[],types:[props.dictionary]}}).then(
        res =>{
          state.valueList = res.result[props.dictionary]
        })
    }


    onBeforeMount(()=>{
      getDictionaryDetail()

    })
    onMounted(()=>{
      handelConsentDetail()
    })
    const getResult = (data) => {
      context.emit('callback', data)
    }
    return {
      getDictionaryDetail,
      onChange,
      getResult,
      ...toRefs(state)
    }
  }
})
</script>

<style scoped>

:deep(.ant-radio-button-wrapper){
  border-radius: 20px !important;
  background: #ebebeb;
  min-width: 4rem;
  text-align: center;
  border: 0;
}
:deep(.ant-radio-button-wrapper-disabled) {
  /* background: #1890ff !important; */
}
:deep(.ant-radio-button-wrapper:first-child){
  margin-right: 6px;
}
:deep(.ant-radio-button-wrapper::before){
    display: none;
  }
.ant-radio-button-wrapper-checked{
  z-index: 0 !important;
  background: #7ab9ff !important;
}


</style>
