<template>
  <search-form
    :Condition="condition"
    :ColSpan="12"
    @callback="handleSearch"
  ></search-form>
  <div class="btn-bar">
    <a-button type="primary" @click="addUser('add')">
      <template #icon>
        <FileAddOutlined />
      </template>
      新增
    </a-button>
  </div>
  <a-table
    :columns="columns"
    :data-source="dataSource ? dataSource['result'] : []"
    :scroll="{ x: 800 }"
    :row-key="(record) => record.id"
    :pagination="pagination"
    @change="handleTableChange"
    :loading="loading"
  >
    <template #bodyCell="{ column, index, record }">
      <template v-if="column.key === 'index'">
        {{ (pagination.current - 1) * pagination.pageSize + index + 1 }}
      </template>
      <template v-if="column.key === 'operation'">
        <div style="display: flex">
          <a style="color: orange; flex: 1" @click="checkUser(record, 'select')"
            >查看</a
          >
          <a
            style="color: orange; flex: 1"
            @click="modifyUser(record, 'update')"
            >编辑</a
          >
          <a style="color: orange; flex: 1" @click="showModal(record)">删除</a>
        </div>
      </template>
    </template>
  </a-table>
  <shoulderAdd
    ref="userDrawerRef"
    @select="handleSearch"
    :status="status"
    :idd="idd"
    :searchData="searchNow"
    :current="pagination.current"
  ></shoulderAdd>
</template>

<script>
import { computed, defineComponent, reactive, ref, toRefs, onMounted } from 'vue'
import { getRequestURI, request, requestUsePagination } from '../../../utils/request'
import SearchForm from '../../../components/extended/search_form'
import shoulderAdd from './shoulderAdd.vue'
import { message, Modal } from 'ant-design-vue'
export default defineComponent({
  components: {
    SearchForm,
    shoulderAdd,
  },
  setup () {
    const userDrawerRef = ref()
    const state = reactive({
      condition: {
        dutyName: '责任名称',
        dutyCode: '责任编码',
        isInsurance: {
          label: '财寿险标识',
          type: 'select',
          value: '1',
          urlKey: 'queryDictionaryType',
          queryParam: { type: 'riskType' }
        }
      },
      status: '',
      data: {},
      idd: '',
      loading: false,
      query: {
        data: {
          comDutyCode: '',
          comDutyName: '',
          coverageRelationScale: 0,
          coverageWay: 0,
          createUser: '',
          dutyCode: '',
          dutyDuration: 0,
          dutyDurationUnit: '',
          dutyIntroduce: '',
          dutyName: '',
          dutyType: '',
          id: 0,
          insurePremiumList: [
            {
              createTime: '',
              createUser: '',
              dutyId: 0,
              endPremium: 0,
              endPremiumValue: '',
              id: 0,
              coverage: 0,
              coverageValue: '',
              updateTime: '',
              updateUser: ''
            }
          ],
          isDel: 0,
          isInsurance: '',
          isRelationInsure: '',
          isShowStatus: '',
          updateUser: ''
        },
        sorters: [
          {
            field: '',
            order: ''
          }
        ]
      },
      columns: [
        {
          title: '序号',
          key: 'index',
          fixed: 'left',
          width: 60,
          align: 'left'
        },
        {
          title: '责任名称',
          width: 100,
          align: 'center',
          key: 'dutyName',
          dataIndex: 'dutyName'
        },
        {
          title: '责任编码',
          width: 100,
          align: 'center',
          key: 'dutyCode',
          dataIndex: 'dutyCode'
        },
        {
          title: '财寿险标识',
          width: 110,
          align: 'center',
          key: 'isInsuranceName',
          dataIndex: 'isInsuranceName'
        },
        // {
        //   title: '保额方式',
        //   width: 100,
        //   align: 'center',
        //   key: 'coverageWayName',
        //   dataIndex: 'coverageWayName'
        // },
        {
          title: '操作',
          key: 'operation',
          align: 'center',
          fixed: 'right',
          width: 100
        }
      ],
      selectedRowKeys: [],
      searchNow: {}
    })
    const {
      data: dataSource,
      run,
      loading,
      current,
      pageSize,
      total,
    } = requestUsePagination(getRequestURI('duty_queryDuty'))
    const pagination = computed(() => ({
      current: current.value,
      pageSize: pageSize.value,
      total: total.value,
    }))
    const handleTableChange = (pag, filters, sorter) => {
      console.log('handleTableChange', pag, filters, sorter)
      run({
        pagination: {
          current: pag?.current,
          pageSize: pag?.pageSize
        },
        sorter,
        data: state.searchNow,
      })
    }
    //页面挂载调用数据
    onMounted(() => {
      handleSearch({})
    })
    const handleSearch = function (filters, num='') {
      state.searchNow = filters
      state.loading = true
      select(num)
      state.loading = false
    }
    const select = function (num) {
      run({
        pagination: {
          current: num ? num : 1,
          pageSize: 10
        },
        data: state.searchNow,
      })
    }
    const visible = ref(false)
    const selectUser = function () {
      console.log('sss')
    }
    const addUser = function (status) {
      state.status = status

      userDrawerRef.value.showDrawer()
      console.log('ddd')
    }
    const chongzhi = function () {
      console.log('ddd')
    }

    const modifyUser = function (oldData, status) {
      state.idd = oldData.id
      state.status = status
      // riskDuty_checkUpdate1
      request(getRequestURI('riskDuty_checkUpdate1'), { data: { ids: [oldData.id] } }).then(res => {
        if (res.code === 1) {
          const uri = getRequestURI('duty_queryDutyInfoById')
          request(uri, { data: oldData }).then(res => {
            res.result.coverageWay = res.result.coverageWay + ''
            res.result.isInsurance = res.result.isInsurance + ''
            userDrawerRef.value.showDrawer(res.result)
          })
        } else {
          message.warning(res.message)
        }
      })
    }

    const checkUser = function (oldData, status) {
      state.status = status
      const uri = getRequestURI('duty_queryDutyInfoById')
      request(uri, { data: oldData }).then(res => {
        res.result.coverageWay = res.result.coverageWay + ''
        res.result.isInsurance = res.result.isInsurance + ''
        userDrawerRef.value.showDrawer(res.result)
      })
    }
    const deleteUser = function () {
    }
    const showModal = (record) => {
      request(getRequestURI('riskDuty_checkUpdate1'), { data: { ids: [record.id] } }).then(res => {
        if (res.code === 1) {
          // state.delId = record
          console.log(record)
          state.data = record
          Modal.confirm({
            title: '确认删除吗?',
            async onOk () {
              const uri = getRequestURI('duty_removeById')
              request(uri, { data: state.data }).then(res => {
                if (res.code == 1) {
                  console.log(res)
                  message['success'](res.message)
                  select()
                } else {
                  message['error'](res.message)
                }
              })
            },
            onCancel () {
            }
          })
        } else {
          message.warning(res.message)
        }
      })
    }

    return {
      ...toRefs(state),
      handleSearch,
      selectUser,
      addUser,
      userDrawerRef,
      chongzhi,
      dataSource,
      visible,
      run,
      loading,
      current,
      select,
      pageSize,
      total,
      checkUser,
      deleteUser,
      showModal,
      modifyUser,
      pagination,
      handleTableChange
    }
  }
})
</script>

<style scoped>
</style>
