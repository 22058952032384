<template>
  <div style="color: rgb(184, 180, 180);margin-top: 5px" v-if="optionItemList && !optionItemList.length && !isEcho">暂无数据</div>
  <div v-for="(item,index) in isEcho ? otherOptionList : optionItemList"
       :key="index"
       style="margin-bottom:5px; margin-top: 5px">
    <a-row :gutter="isEcho ? 0 : 2">
      <a-col :span="2">
        <p>{{getSerialNumber(index)}}. &nbsp;</p>
      </a-col>
      <a-col :span="16" v-if="item.optionState === '1'">
        <span v-if="item.isOther">{{item.otherText}}</span>
        <p style="display: inline-block; width: 260px"
           v-if=showP(item)>{{item.label}}</p>
        <health-option :is-echo="true"
                       ref="otherOptionRef"
                       v-model:other-option-list="item.otherOptionList"></health-option>
      </a-col>
      <a-col :span="16" v-if="item.optionState === '2'">
        <span v-if="item.isOther">{{item.otherText}}</span>
        <a-input style="width: 348px"  v-model:value="item.label"  @blur="handelCheckSpan"></a-input>
      </a-col>
      <a-col :span="2">
        <a-button size="small"
                  type="primary"
                  @click="onAssociated(item)"
                  v-if="$props.singleOption === 1 && item.optionState === '1' && $props.isIncludeOther && !isEcho">关联</a-button>
      </a-col>
    </a-row>
  </div>
  <div style="margin-top: 15px" v-show="!$props.isEcho">
    <a-button size="small" @click="addOption" >+ 增加</a-button>
    <a-button size="small" style="margin-left: 10px" @click="delOption">- 删除</a-button>
  </div>
  <a-modal destroyOnClose
           v-model:visible="isShowOther"
           :maskClosable="false"
           :keyboard="false"
           title="选项配置"
           @cancel="multipleSelectCancel"
           @ok="multipleSelectConfirm">
    <a-form v-bind="formItemLayout">
      <a-form-item label="选项形式">
        <a-radio-group v-model:value="isMultipleText">
          <a-radio :value="true">纯文本</a-radio>
          <a-radio :value="false">其他____</a-radio>
        </a-radio-group>
      </a-form-item>
      <a-form-item label="其他填充内容" v-if="!isMultipleText">
        <a-radio-group v-model:value="multipleState">
          <a-radio :value='1'>文本</a-radio>
          <a-radio :value='2'>单选</a-radio>
          <a-radio :value='3'>多选</a-radio>
        </a-radio-group>
      </a-form-item>
      <a-form-item label="配置选项"
                   v-show="(multipleState === 2 || multipleState === 3) && !isMultipleText">
        <health-option :is-include-other="false"
                       ref="otherAlertOptionRef"></health-option>
      </a-form-item>
    </a-form>
  </a-modal>
  <Associated ref="associatedRef" @setElementList="setElementList"/>
</template>

<script>
import {reactive, toRefs, watch, ref} from 'vue'
import {message} from 'ant-design-vue'
import Associated from './associated'

/*
optionItemList解构说明
{
  label: 内容文字
  value: 角标

  optionState: '1' 已完成输入    '2' 未完成输入
  isOther: true  是否是other选项
  otherText: '' // other的文字
  optionIndex: 1 角标
  contentText: 'dafdsa' 内容文字
  elementList: []   关联的内容
  otherOptionList: []  other的选项
  otherOptionState:   other类型1文本  2单选  3多选
}
 */
export default {
  name: 'healthOption',
  components: {
    Associated
  },
  props: {
    isEcho: {
      type: Boolean,
      default: () => false
    }, // 是否是二级回显
    isIncludeOther: {
      type: Boolean,
      default: () => true
    },  // 是否包含other
    singleOption: {
      type: Number,
      default() {
        return 1
      },
    },  // 1单选  3多选
    otherOptionList: {
      type: Array,
      default() {
        return []
      },
    }, // other选项
  },
  setup(props, content) {
    const state = reactive({
      optionItemList: [], // 选项数组
    })
    const showData = reactive({
      isShowOther: false, // 多选，是否弹出选择other的弹框
      isMultipleText: true, // 是不是纯文本
      multipleState: 1,  // 1文本  2单选  3多选
    })
    const formItemLayout = {
      labelCol: {
        span: 6,
      },
      wrapperCol: {
        span: 16,
      },
    }

    const otherOptionRef = ref()
    const otherAlertOptionRef = ref()

    // 序号  A  B  C   D  ....
    const getSerialNumber = (index) => {
      return String.fromCharCode(65 + index)
    }
    // 失去焦点触发
    const handelCheckSpan = (event) => {
      if(event.target.value.trim() === ''){
        return message.warning('请输入配置选项内容')
      } else {
        let lastNode = state.optionItemList[state.optionItemList.length - 1]
        lastNode.optionState = '1'
      }
    }

    // 增加选项
    const addOption = () => {
      console.debug('dfsdafsadfsd', state)
      if (state.optionItemList.length > 0) {
        let lastNode = state.optionItemList[state.optionItemList.length - 1]
        if (lastNode.optionState === '2') {
          return message.warning('请先填写配置项')
        }
      }
      console.log(props.singleOption)
      if (props.isIncludeOther && props.singleOption === 3) {
        showData.isShowOther = true
      } else {
        let inNode = {
          optionState: '2',
          isOther: false,
          value: state.optionItemList.length,
          label: undefined,
          elementList: []
        }
        state.optionItemList.push(inNode)
      }
    }
    // 删除选项
    const delOption = () => {
      if (state.optionItemList.length > 0) {
        let lastNode = state.optionItemList[state.optionItemList.length - 1]
        if (lastNode.optionState === '1') {
          state.optionItemList.pop()
          return
        }
      }
      message.warning('请先填写配置项')
    }
    // 取消多选
    const multipleSelectCancel = () => {
      showData.isShowOther = false
      showData.isMultipleText = true
      showData.multipleState = 1
    }
    // 多选确定
    const multipleSelectConfirm = () => {
      if (showData.isMultipleText) {
        let confirmData = {
          isMultipleText: showData.isMultipleText,  // 是不是纯文本
          multipleState: showData.multipleState,  // 1文本  2单选  3多选
          optionItemList: [],   // 选项数组
        }
        alertConfirmOption(confirmData)
        multipleSelectCancel()
        return
      }
      let list = otherAlertOptionRef.value.getOtherAlertList()
      let lastNode = list[list.length - 1]
      if (lastNode && lastNode.optionState === '2') {
        return message.warning('请先填写配置项')
      }
      if (!showData.isMultipleText
        && (showData.multipleState !== 1)
        && (list.length === 0)) {
        return message.warning('请先填写配置项')
      } else {
        let confirmData = {
          isMultipleText: showData.isMultipleText,  // 是不是纯文本
          multipleState: showData.multipleState,  // 1文本  2单选  3多选
          optionItemList: [],   // 选项数组
        }
        console.log(list)
        confirmData.optionItemList = list
        alertConfirmOption(confirmData)
        multipleSelectCancel()
      }
    }

    // 弹框确定之后的回调
    const alertConfirmOption = function (confirmData) {
      if (confirmData.isMultipleText) {
        // 纯文本
        let inNode = {
          optionState: '2',
          isOther: false,
          value: state.optionItemList.length,
          label: undefined,
          elementList: []
        }
        state.optionItemList.push(inNode)
      } else {
        let inNode = {
          optionState: confirmData.multipleState === 1 ? '2' : '1',
          isOther: true,
          otherText: confirmData.multipleState === 1 ? '其他（文字）：' : (confirmData.multipleState === 2 ? '其他（单选）：' : '其他（多选）：'),
          value: state.optionItemList.length,
          label: undefined,
          elementList: [],
          otherOptionList: [],
          otherOptionState: confirmData.multipleState,
        }
        if (confirmData.multipleState !== 1) {
          inNode.otherOptionList = confirmData.optionItemList
        }
        state.optionItemList.push(inNode)
      }
    }

    // other 选项赋值
    const getOtherAlertList = () => {
        return state.optionItemList
    }

    const showP = (item) => {
      if (item.otherOptionState !== 1 && item.otherOptionList) {
        return false
      }
      return true
    }
    watch(() => state.optionItemList, (newValue) => {
      content.emit('update:optionList', newValue)
    })

    const associatedRef = ref()
    // 配置项的关联按钮
    const onAssociated = (config) =>{
      console.log(associatedRef.value)
      associatedRef.value.showDrawer(config)
    }
    // 得到每个选项的关联配置
    const setElementList = (data) =>{
      if(!data.elementList.length){
        return
      }
      // 将得到的关联匹配进行 和选项匹配
      state.optionItemList.forEach(item =>{
        if(item.value === data.value){
          item.elementList = data.elementList
          console.log(item)
        }
      })
    }

    return {
      ...toRefs(state),
      ...toRefs(showData),
      getSerialNumber,
      handelCheckSpan,
      onAssociated,
      addOption,
      delOption,
      multipleSelectCancel,
      multipleSelectConfirm,
      formItemLayout,
      alertConfirmOption,
      otherOptionRef,
      getOtherAlertList,
      otherAlertOptionRef,
      showP,
      setElementList,
      associatedRef,
    }
  }
}
</script>

<style scoped>

</style>
