<template>
  <a-modal v-model:visible="pageStyleShow" title="页面状态" destroyOnClose @cancel="onClose"  @ok="handelOk">
    <a-radio-group v-model:value="pageStyleId">
      <a-radio :value="m.pageStyleId" v-for="m in modalList" :key="m.pageStyleId">{{m.pageStyleName}}</a-radio>
    </a-radio-group>
  </a-modal>
</template>

<script>
import { defineComponent, reactive, toRefs } from 'vue'

export default defineComponent({

  emits:['setPageStyleName'],
  setup(props,context){
    const state = reactive({
      pageStyleShow: false,
      pageStyleId: '',
    })
    const modalList = reactive([
      {pageStyleId: '1', pageStyleName: '弹窗'},
      {pageStyleId: '2', pageStyleName: '页面'},
      // {pageStyleId: '3', pageStyleName: '第三方'},
    ])


    const showDrawer = (id) =>{
      state.pageStyleShow = true
      state.pageStyleId = id

    }

    const handelOk = () =>{
     let arr = modalList.filter(item=>{
        return item.pageStyleId === state.pageStyleId
      })
      context.emit('setPageStyleName', arr[0])
      onClose()
    }
    const onClose = () => {
      state.pageStyleId = ''
      state.pageStyleShow = false
    }



    return {
      ...toRefs(state),
      handelOk,
      onClose,
      showDrawer,
      modalList,

    }

  }
})
</script>

<style lang="less" scoped>

</style>
