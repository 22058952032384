<template>
<!--时间选择-->
<div>
  <!-- 日期节点 -->
  <div v-if="dataType == 5" class="element_wrap">
    <a-input
      class="element-input"
      v-model:value="timeNodeValue"
      :placeholder="'请选择' + `${ replaceTitle(title) }`"
      @click="showChoice"
    />
       <span class="element-suffix" @click="showChoice">
          <!-- {{timeNodeValue}} -->
          <right-outlined/>
        </span>
      <van-popup
        placement="bottom"
        :closable="false"
        position="bottom" :style="{ height: '50%' }"
        v-model:show="pickerVisible"
        @close="onPickerClose"
        height="25rem"
      >
        <van-datetime-picker
            type="date"
            v-model="pickerValue"
            :title="replaceTitle(title)"
            :formatter="formatter"
            :min-date="minDate"
            :max-date="maxDate"
            @confirm="onDatePickerConfirm"
            @cancel="onPickerClose"
        />
      </van-popup>

  </div>
  <!-- 日期节点(可选长期) -->

  <div v-if="dataType == 50" class="element_wrap">
    <a-input
      class="element-input"
      v-model:value="timeNodeValue"
      :placeholder="'请选择' + `${ replaceTitle(title) }`"
      @click="showChoice"
    />
       <span class="element-suffix" @click="showChoice">
          <right-outlined/>
        </span>
      <van-popup
        placement="bottom"
        :closable="false"
        position="bottom" :style="{ height: '50%' }"
        v-model:show="pickerVisible"
        @close="onPickerClose"
        height="25rem"
      >
        <van-datetime-picker
            type="date"
            :formatter="formatter"
            v-model="pickerValue"
            :title="replaceTitle(title)"
            :min-date="minDate"
            @confirm="onDatePickerConfirm"
            @cancel="onPickerClose"
        >
        <template #columns-bottom>
          <div class="dateValid">
            <van-button class="validTitle" type="primary" round @click="handelValidButton" size="small">长期选择请点击这里</van-button>&nbsp;
          </div>
        </template>
        </van-datetime-picker>
      </van-popup>

  </div>
  <!-- 日期范围 -->
    <div v-if=" dataType == 6" class="element_wrap">
      <!-- <a-date-picker :disabledDate="handelDisableMin" @change="handelMinChange" v-model:value="timeMinValue" value-format="YYYY-MM-DD"/>
      <span>&nbsp;&nbsp;&nbsp;至&nbsp;&nbsp;&nbsp;</span>
      <a-date-picker :disabledDate="handelDisableMax" @change="handelMaxChange" v-model:value="timeMaxValue" value-format="YYYY-MM-DD"/> -->
      <a-input
        class="element-input"
        v-model:value="timeDateValue"
        placeholder="请选择日期区间"
        @click="showChoice"
      />
      <span class="element-suffix" @click="showChoice">
        <right-outlined/>
      </span>
        <van-calendar
          v-model:show="pickerVisible"
          :min-date="minDate"
          type="range"
          @confirm="onRangeConfirm"
          @close="onPickerClose"
        />



    </div>
    <!-- 时间节点 -->
    <div v-if="dataType == 3">
      <span class="element-suffix" @click="showChoice">
          {{timeNodeValue}}
          <right-outlined/>
        </span>
      <van-popup
        placement="bottom"
        :closable="false"
        position="bottom" :style="{ height: '50%' }"
        v-model:show="pickerVisible"
        @close="onPickerClose"
        height="25rem"
      >
        <van-datetime-picker
          type="time"
          v-model="pickerValue"
          :title="title"
          @confirm="onDatePickerConfirm"
          @cancel="onPickerClose"
        />
      </van-popup>
    </div>
    <!-- 时间范围 -->
    <div v-if="dataType ==  4">
      <div :class="titlePos === '1' ? 'date_right' : 'date_bottom'">
        <a-time-picker v-model:value="timeMinValue" @change="handeltimeMinValue" :disabledHours="getTimeMinValue"  value-format="HH:mm:ss" />
        <span>&nbsp;&nbsp;&nbsp;至&nbsp;&nbsp;&nbsp;</span>
        <a-time-picker v-model:value="timeMaxValue" @change="handeltimeMaxValue"  value-format="HH:mm:ss" />
      </div>
    </div>
</div>

</template>

<script>
import {DatetimePicker, Button, Calendar, Popup  } from 'vant'
import 'vant/lib/index.css'
import {defineComponent, onBeforeUnmount, onBeforeUpdate, onMounted, reactive, toRefs, watch} from 'vue'
import { message } from 'ant-design-vue'
import store from '../../../../store/index'
import dayjs from 'dayjs'
import {
  changeDataRangeByAgeDiff,
  getDefaultAge,
  isDateBetween,
  showDataMin,
  getNewDateParam,
  showInsurancedateDataMin,
  replaceTitle
} from '../../../../utils/utlis'
export default defineComponent({
  components:{
    [DatetimePicker.name] : DatetimePicker,
    [Button.name] : Button,
    [Calendar.name] : Calendar,
    [Popup.name]: Popup

  },
  props: {
    moduleId:{ // 模块ID
      type: [Number,String],
      default:()=> ''
    },
    title:{
      type: String,
      default:()=> ''
    },
    elementId:{ // 元素ID
      type: [Number,String],
      default:()=> ''
    },
    dataType: {
      type: [Number, String],
      default: ()=> 0
    },
    rangeData: {
      type: Object,
      defaule: ()=> {}
    },
    timeRange:{
      type: String,
      defaule: ()=> ''
    },
    minTime:{
      type:[Date, String],
      default:() => undefined
    },
    maxTime:{
      type:[Date, String],
      default:() => undefined
    },
    elementCode: {
      type: [Number,String],
      default: () => ''
    },
    isRequired:{
      type: [Number,String],
      default:()=> '0'
    },
    moduleIndex:{
      type: [Number,String],
      default:()=> ''
    },
    firstValue:{
      type: [Number,String],
      default:()=> ''
    },
    endValue:{
      type: [Number,String],
      default:()=> ''
    },
    disabledType: {
      type: Boolean,
      default:()=> false
    },
    titlePos:{
      type: [Number,String],
      default:()=> '1'
    },
    elementName: {
      type: String,
      default: () => ''
    },
    isDisabled: {
      type: Boolean,
      default:()=> false
    },
    insuredObj: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  emits:['callback','update:firstValue','update:endValue'],
  setup(props,context) {
    let _f = '-'
    const state = reactive({
      effectDay: '',
      ageDiffValue: '',
      InsuranceNoPlanList: '',
      insurancePlanList: '',
      factorType: '',
      pickerValue:props.firstValue ? new Date(props.firstValue) : new Date(),
      pickerVisible: false,
      title: props.title,
      value1:undefined,
      dataValue: '',
      conversion:'',
      dataType: props.dataType,
      startTime: props.minTime,
      endTime: props.maxTime,
      hackValue: {},
      rangeNodeData: {...props.rangeData.nodeObj,nodeDate: dayjs(props.rangeData.nodeObj.nodeDate)},
      nodeDateType:'date',
      // nodeDate:'',
      timeNodeValue:props.firstValue, // 时间-日期节点
      timeMinValue:props.firstValue,
      timeMaxValue:props.endValue,
      elementCode: props.elementCode,
      // 时间 日期范围的值
      timeRange:Number(props.timeRange),
      disabled: false,
      timeDateValue: props.firstValue,
      minDate:new Date(1900,0,1),
      maxDate: new Date(new Date().setFullYear(new Date().getFullYear() + 100))
    })
    onMounted(()=>{
      if(props.moduleId ==='startToEndTime'){
        state.minDate = new Date(new Date().setDate(new Date().getDate() + 1))
        state.maxDate = new Date(new Date().setFullYear(new Date().getFullYear() + 1))
      }
      // if(!props.firstValue){
        specialAssignment()
      // }
    })
    const specialAssignment = () =>{
      if(props.elementCode ==='cvaliDate'){
        state.pickerValue = new Date(new Date().setDate(new Date().getDate() + 1))
        state.timeNodeValue = `${new Date().getFullYear() +'-0'+ (new Date().getMonth() + 1) +'-'+(new Date().getDate()+1)}`
      }else if(props.elementCode ==='transEndDate'){
        state.pickerValue = new Date(new Date().setFullYear(new Date().getFullYear() + 1))
        state.timeNodeValue = `${(new Date().getFullYear() +1) +'-0'+ (new Date().getMonth() + 1) +'-'+new Date().getDate()}`
      }else if(props.elementCode ==='idTypeStartDate'){
        state.maxDate = new Date()
      }else if(props.elementCode === 'idTypeEndDate'){
          state.minDate = new Date(new Date().getTime() + 24 * 60 * 60 * 1000)
      } else if (props.moduleId !== 'insureds' && (props.elementCode === 'birthday' || props.elementCode === 'appntBirthday')) {
        state.maxDate = new Date()
      } else if (props.moduleId === 'insureds' && props.elementCode === 'birthday') {
        let riskList = JSON.parse(store.getters['insured/getProductDetailData'])?.productInfo?.riskList ?? []
        if (riskList?.length > 0) {
          handelInsurancePlan(riskList)
          const data = getInsurePlan(state.InsuranceNoPlanList?.factorValueList, state.factorType)
          state.minDate = getNewDateParam(data?.[0])
          state.maxDate = getNewDateParam(data?.[1])
        }
      }
    }
    // 获取投保计划要素值
    const handelInsurancePlan = (list) => {
      if (list && list.length) {
        list.forEach(item => {
          state.effectDay = item.premiumTrialCalculationDate==='1'? item.effectDay : 0
          state.ageDiffValue = item.ageDiffValue ? item.ageDiffValue : '0'
          if (item.riskSign === '1' && item.calculateFactor && item.calculateFactor.length) {
            // 获取年龄类型并且在页面显示的
            // state.InsuranceNoPlanList = item.calculateFactor.filter(cal => ['TextAge', 'Insurancedate'].includes(cal.factorType) && cal.isShow === 'Y')[0]
            state.InsuranceNoPlanList = item.calculateFactor.filter(cal => ['TextAge'].includes(cal.factorType) && cal.isShow === 'Y')[0]
            state.insurancePlanList = item.calculateFactor.filter(cal => cal.factorType === 'Plan' && cal.isShow === 'Y')[0]
            state.factorType = state.InsuranceNoPlanList?.factorType || state.insurancePlanList?.factorType
            item.calculateFactor.forEach((type) => {
              if (type.factorType === 'Plan') {
                type.isShow = 'N'
                // item.calculateFactor.splice(index, 1)
              }
            })
          }
        })
      }

    }
     // 获取投保计划的方法
     const getInsurePlan = (list, factorType) => {
      console.log('获取投保计划的方法=======>', list)
      if(list === undefined) return
      const min =  getMinAge(list)
      const max = getMaxAge(list)
      state.effectDay = min.minAge=== '0' && min.mixType==='Y'? 0 : parseInt(state.effectDay)
      store.dispatch('insured/changeInsureJSInfo', {TNUMBER: state.effectDay })
      store.dispatch('insured/changeInsureJSInfo', {AGEDIFF: state.ageDiffValue })
      let tem = getDefaultAge(list,'1988-08-08',state.effectDay)
      const res = factorType === 'Insurancedate' ?showInsurancedateDataMin(min.minAge, min.mixType, max.maxAge, max.maxType, state.effectDay) : showDataMin(min.minAge, min.mixType, max.maxAge, max.maxType, state.effectDay)
      let last = res.latestDate
      let early = res.earliestDate
      const obj = changeDataRangeByAgeDiff(state.ageDiffValue,last,early)
      last = obj.last
      early = obj.early
      // tem = '1988-08-08'
      if (tem && !isDateBetween(tem, early, last)) {
        tem = factorType === 'Insurancedate' ? early : last
      }
      console.debug('early', early)
      console.debug('last', last)
      const  data = [early, last, tem, state.effectDay, state.ageDiffValue]
      return data ? data : null
    }
    const getMinAge = (list) => {
      if(list === undefined) return
      const minList = list.filter(item => (item.value+ '').includes('D'))
      if(minList.length>0) {
        const str = minList[0].value.slice(0, (minList[0].value+'').indexOf('D'))
        return {
          minAge: str,
          mixType: 'D'
        }
      }else {
        const ageData =  list.reduce((x, y) => {
          let a = x.value + ''
          let b = y.value + ''
          a = a.indexOf(_f) > 0 ? Number(a.split(_f)[0]) : Number(a)
          b = b.indexOf(_f) > 0 ? Number(b.split(_f)[0]) : Number(b)
          return a < b ? x : y
        }).value
        return {
          minAge: ageData,
          mixType: 'Y'
        }
      }
    }
    const getMaxAge = (list) => {
      if(!list || !list.length) return
      const ageMaxList = list.filter(item => !(item.value+ '').includes('D'))
      // 表示都是Y
      if (ageMaxList.length > 0) {
        const ageData = ageMaxList.reduce((x, y) => {
          let a = x.value + ''
          let b = y.value + ''
          a = a.indexOf(_f) > 0 ? Number(a.split(_f)[1]) : Number(a)
          b = b.indexOf(_f) > 0 ? Number(b.split(_f)[1]) : Number(b)
          return a > b ? x : y
        }).value
        return {
          maxAge: ageData,
          maxType: 'Y'
        }
      }else{
        // 包括D 也包括有范围的且包含D
        let a = list[list.length-1].value
        let ageData = ''
        let type = 'D'
        if(a.indexOf(_f) > 0){
          const arr = a.split(_f)
          type = arr[1].indexOf('D') > 0 ? 'D' : 'Y'
          ageData = Number(arr[1].replace(type, ''))
        }else{
          ageData = Number(a.replace('D', ''))
        }
        return {
          maxAge: ageData,
          maxType: type
        }

      }
    }
    const getResult = (data) => {
      context.emit('callback',data)
    }
    const back = (data) =>{
        let node = {}
        node[state.elementCode] = {
          [state.elementCode]:  data,
          elementId: props.elementId,
          moduleType: props.moduleId,
          index: props.moduleIndex,
          required: props.isRequired,
         elementName: props.elementName

        }
        getResult(node)
    }
    const formatter = (type, option) => {
      if (type === 'year') {
        option+= '年'
      }
      if (type === 'month') {
        option+= '月'
      }
      if (type === 'day') {
        option+= '日'
      }
      return option
    }
    // 数据回显 不能进行操作的赋值
    watch(()=>props.disabledType, ()=>{
      state.disabled = props.disabledType
    },{immediate:true})
    onBeforeUpdate(()=>{
      if(state.dataType===3){
        state.pickerValue = new Date((props.firstValue)).getHours()+':'+new Date((props.firstValue)).getMinutes()
      }else{
        state.pickerValue = props.firstValue? new Date(props.firstValue) : new Date()
      }
        state.timeNodeValue = props.firstValue
    })
    // watch(() => props.minTime, newVal => {
    //   if (newVal) {
    //     state.minDate = newVal
    //     console.log(state.minDate, 'state.minDate')
    //   }
    // })
    watch(() => props.firstValue, ()=>{
      state.pickerValue = new Date((props.firstValue))
      state.timeNodeValue = props.firstValue
      context.emit('update:firstValue', state.timeNodeValue)
    })
    watch(()=> state.timeNodeValue, (newVal)=>{
      if (!newVal) return
      context.emit('update:firstValue', state.timeNodeValue)
      back(state.timeNodeValue)
    },{immediate: true, deep: true})

    // 点击按钮的弹框
    const showChoice = () =>{
      state.pickerVisible = true
    }
    const onDatePickerConfirm = (value) =>{
      if(state.dataType == 5 || state.dataType == 50){ // 日期节点
         let date = dayjs(`${new Date((value))}`).format('YYYY-MM-DD')
        state.timeNodeValue = date
      } else if(state.dataType == 3){ // 时间节点
        state.timeNodeValue = value
      }
      state.pickerVisible = false

    }
    const onPickerClose = () =>{
      state.pickerVisible = false
    }

    const handelValidButton = () =>{
      state.pickerVisible = false
      state.timeNodeValue = '长期'
      // context.emit('update:firstValue', state.timeNodeValue)
      // back(state.timeNodeValue)
    }

    // 日期范围
    const onRangeConfirm = (e) =>{
      state.timeDateValue = ''
      let arr = []
      e.forEach(item => {
        arr.push(dayjs(`${new Date((item))}`).format('YYYY-MM-DD'))
      })
      state.timeDateValue = arr.join('-')
      state.pickerVisible = false
      context.emit('update:firstValue', state.timeDateValue)
      back(state.timeDateValue)
    }


    const getTimeMinValue = () => {
      return getDisableHours(state.startTime)
    }
    const handeltimeMinValue = () =>{
      // let endTime = dayjs(`${new Date(state.endTime)}`).format('HH:mm:ss')
      // if(time && time > endTime){
      //   state.timeMinValue = await undefined
      //   return message.warning('超出范围值,请重新选择')
      // }
      context.emit('update:firstValue', state.timeMinValue)
      back(state.timeMinValue)
    }

    const handeltimeMaxValue = async (time) =>{
      let handeltimeMinValue = dayjs(handeltimeMinValue).format('HH:mm:ss')
      console.log(time, handeltimeMinValue)
      if(time && time < handeltimeMinValue){
        state.timeMaxValue = await undefined
        return message.warning('超出范围值,请重新选择')
      }
      // context.emit('update:endValue', state.timeMaxValue)
      // back(state.timeMaxValue)

    }
    // 时间范围
    const getDisableHours = value =>{
      let hours = []
      let time = dayjs(`${new Date((value))}`).format('HH:mm:ss')
      let timeArr = time.split(':')
      for (var i = 0; i < parseInt(timeArr[0]); i++) {
        hours.push(i)
      }
      return hours
    }
    // 日期范围
    const handelDisableMin = date =>{
      return date && dayjs(state.startTime) > date
    }
    const handelMinChange = async (date) => {
      if(date && date > dayjs(state.endTime)){
         state.timeMinValue = await undefined
         return message.warning('超出范围值,请重新选择')
      }
    }
    const handelDisableMax = date =>{
      return date && date > dayjs(state.timeMinValue)
    }
    const handelMaxChange = async (date) => {
      if(date && dayjs(state.timeMinValue) > date){
         state.timeMaxValue = await undefined
         return message.warning('超出范围值,请重新选择')
      }
    }
    watch(()=> state.timeMinValue,(newVal)=>{
      if (!newVal) return
        context.emit('update:firstValue', state.timeMinValue)
        back(state.timeMinValue)
      },{immediate: true,deep: true})
    watch(()=> state.timeMaxValue,(newVal)=>{
      if (!newVal) return
      context.emit('update:endValue', state.timeMaxValue)
      back(state.timeMaxValue)
    },{immediate: true,deep: true})



    onBeforeUnmount(()=>{
      state.timeNodeValue = ''
      state.pickerValue = ''
    })





    return {
      formatter,
      handelValidButton,
      handeltimeMaxValue,
      handeltimeMinValue,
      handelMaxChange,
      handelMinChange,
      handelDisableMin,
      handelDisableMax,
      getTimeMinValue,
      ...toRefs(state),
      // onChange,
      getResult,
      onDatePickerConfirm,
      onPickerClose,
      showChoice,
      onRangeConfirm,
      replaceTitle
    }
  }

})
</script>

<style scoped>
:deep(.ant-input){
  padding: 0 !important;
}
.element_wrap {
  width: 100%;
  text-align: right;
  /* margin: .5rem; */
}
.element-suffix {
  float: right;
}
.element-input {
  border: 0;
  outline: none;
  text-align: right;
  width: calc(60% - 1.2rem);
}
.dateValid{
  width: 100%;
  margin-top: 0.3rem;
  font-size: 16px;
  text-align: right;
}
.validTitle{
  margin-top: 10px;
}
.date_right{
  text-align: right;
  float: right;
  display: flex;
  justify-content: flex-end;
}
.date_bottom{
  display: flex;
}
</style>
