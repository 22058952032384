<template>
<div :class="{right_class : titlePos === '1'}">
<!--多选-->
  <a-checkbox-group v-model:value="selectList" :disabled="disabled" :options="options" @change="changeChecked">
    <template #label="{ selectList }">
      <span style="color: red">{{ selectList }}</span>
    </template>
  </a-checkbox-group>
  <span ref="tip" style="color: red;font-size: smaller">{{ tips }}</span>
</div>
</template>

<script>
// import { message } from 'ant-design-vue'
import {defineComponent, reactive, toRefs, onMounted,watch, computed, onBeforeUpdate} from 'vue'
import {request} from '../../../../utils/request'
import {getExtendUri} from '../../../../utils/request_extend'
export default defineComponent({
  props: {
    moduleIndex:{
      type: [Number,String],
      default:()=> '0'
    },
    moduleId:{ // 模块ID
      type: [Number,String],
      default:()=> ''
    },
    elementId:{ // 元素ID
      type: [Number,String],
      default:()=> ''
    },
    dictionary: {
      type: String,
      default: () => ''
    },
    options: {
      type: Object,
      default: () => {}
    },
    elementCode: {
      type: [Number,String],
      default: () => ''
    },
    isRequired:{
      type: [Number,String],
      default:()=> '0'
    },
    firstValue:{
      type: Array,
      default:()=>[]
    },
    disabledType: {
      type: Boolean,
      default:()=> false
    },
    elementName:{
      type: String,
      default:()=>''
    },
    titlePos:{
      type: [Number,String],
      default:()=> '1'
    },
  },
  emits:['callback','update:firstValue'],
  setup(props,context) {
    const state = reactive({
      selectList: props.firstValue,
      tips: '',
      options: computed(()=>{
        if(props.options.buttonOptionsList){
          props.options.buttonOptionsList.forEach(item => {
            item.disabled = false
          })
          return props.options.buttonOptionsList
        }else{
          return []
        }
      }) ,
      elementCode: props.elementCode,
      multipleMaxNum: props.options.multipleMaxNum,
      disabled: false
    })
    onBeforeUpdate(()=>{
      state.disabled = props.disabledType
    })
    const changeChecked = (checkList) => {
      const { options } = state
        let valueList = options.map(item => item.value)
        let disList = []
        valueList.forEach(item => {
            if(!checkList.includes(item)) {
                disList.push(item)
            }
        })
        if(checkList.length == state.multipleMaxNum) {
            for(let i in options) {
                for(let j in disList) {
                    if(options[i].value === disList[j]) {
                        options[i].disabled = true
                    }
                }
            }
        } else {
            for(let i in options) {
                options[i].disabled = false
            }
        }
      state.selectList = checkList
      let node = {}
      node[state.elementCode] = {
        [state.elementCode] : checkList,
        elementId: props.elementId,
        moduleType: props.moduleId,
        index:props.moduleIndex,
        required : props.isRequired,
        elementName: props.elementName

      }
      getResult(node)
      watch(()=> state.selectList,()=>{
        context.emit('update:firstValue', state.selectList)
      },{immediate: true,deep: true})
    }
    const getDictionaryData = () =>{

      if(!props.dictionary){
        return
      }
      request(getExtendUri('dictionary_query_byType'),
      {data: {ids:[],types:[props.dictionary]}}).then(
        res =>{
          res.result[props.dictionary].forEach(item =>{
            item.disabled = false
          })
          Object.assign(state.options,res.result[props.dictionary])
      })
    }
    onMounted(()=>{
      getDictionaryData()
    })
    const getResult = (data) => {
      context.emit('callback',data)
    }
    return {
      getResult,
      changeChecked,
      ...toRefs(state)
    }
  }
})
</script>

<style scoped>
.right_class{
  text-align: right;
}
</style>
