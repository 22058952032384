<template>
  <a-drawer
    title="已关联配置"
    :width="'55%'"
    :visible="visible"
    :body-style="{ paddingBottom: '80px' }"
    :footer-style="{ textAlign: 'right' }"
    @close="onClose"
  >
  <!-- 表格数据 -->
  <a-button type="primary" @click="addToConfigure">
    <template #icon><FileAddOutlined/></template>添加关联配置
  </a-button>
  <a-button type="primary" style="margin-left: 15px" @click="onSetDataSource"><ReloadOutlined/>重置已关联配置</a-button>
  <a-table
    :columns="columns"
    :data-source="dataSource"
    @change="handleTableChange"
    style="margin-top: 10px"
  >
    <!-- record -->
    <template #bodyCell="{ column, index, }">
      <template v-if="column.key === 'index'">
        {{ index + 1 }}
      </template>
    </template>
  </a-table>
  <ElementListConfig ref="elementList" @getElementList="getElementList"/>
  <template #extra>
      <a-space>
        <a-button @click="onClose">
          <template #icon>
            <CloseOutlined/>
          </template>
          关闭
        </a-button>
        <a-button type="primary" @click="addList">
          <template #icon>
            <SaveOutlined/>
          </template>
          提交
        </a-button>
      </a-space>
    </template>
  </a-drawer>
</template>

<script>
import { defineComponent, reactive, toRefs, onMounted, ref } from 'vue'
import ElementListConfig from './elementListConfig'
export default defineComponent({
  components:{
    ElementListConfig
  },
  emits:['setElementList'],
  setup(props, context){
    const state = reactive({
      eleData:{},
      dataSource:[], // 用于接受 关联的配置
      columns: [
        {
          key: 'index',
          title: '序号',
          width: 60,
          align: 'left',
        },
        {
          title: '元素名称',
          width: 150,
          key: 'elementName',
          dataIndex: 'elementName',
        },
        {
          title: '元素类型',
          width: 150,
          key: 'elementType',
          dataIndex: 'elementType',
        },
        {
          title: '元素状态',
          width: 150,
          key: 'elementState',
          dataIndex: 'elementState',
          customRender: (text) => {
            if(text.value === '0') {
              return '暂存'
            }else if(text.value === '1'){
              return '启用'
            }else if(text.value === '2'){
              return '禁用'
            }
          }
        },
        {
          title: '创建时间',
          width: 180,
          key: 'createTime',
          dataIndex: 'createTime',
          customRender:(text) =>{
            let time = new Date(+new Date((text.text)) + 8 * 3600 * 1000).toISOString().replace(/T/g, ' ').replace(/\.[\d]{3}Z/, '')
            return time
          }
        },
      ]
    })

    const showData = reactive({
      visible : false
    })
    const handleTableChange = () =>{

    }


    const showDrawer = (oldData) => {
      state.eleData = oldData
      state.dataSource  = oldData.elementList
      showData.visible = true
    }
    const onClose = () =>{
      state.eleData = {}
      state.dataSource = []
      showData.visible = false
    }
    const elementList = ref()
    // 添加关联配置
    const addToConfigure = () => {
      elementList.value.showDrawer()
    }
    // 提交
    const addList = () =>{
      let node = {
        value: state.eleData.value,
        label: state.eleData.label,
        elementList: state.dataSource
      }
      console.log(node)
      context.emit('setElementList', node)
      onClose()
    }
    // 重置
    const onSetDataSource = () =>{
      state.dataSource = []
    }
    // 得到 关联的配置
    const getElementList = (value) => {
      state.dataSource = value
      console.log(value)
    }


    onMounted(() =>{

    })

    return {
      ...toRefs(state),
      ...toRefs(showData),
      getElementList,
      addList,
      handleTableChange,
      onClose,
      elementList,
      showDrawer,
      addToConfigure,
      onSetDataSource,
    }
  }
})

</script>

<style  scoped>

</style>
