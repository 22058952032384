
export const moduleDetail = function (type) {
  if (type == 'duty') {
    return '责任'
  } else if (type == 'insureds') {
    return '被保人'
  }  else if (type == 'applicant') {
    return '投保人'
  } else if (type == 'beneficiarys') {
    return '受益人'
  } else if (type == 'payment') {
    return '支付  '
  } else if (type == 'healthNotification') {
    return '健康告知'
  } else if (type == 'payMethod') {
    return '缴费方式'
  }
}

