<template>
  <!--  元素列表-->
  <div>
    <SearchForm
      :Condition="condition"
      :ColSpan="8"
      @callback="handleSearch"
    ></SearchForm>
    <div class="btn-bar">
      <a-button type="primary" @click="addElement">
        <template #icon><FileAddOutlined /></template>新增
      </a-button>
      <a-button danger @click="delSomeElement">
        <template #icon><DeleteOutlined /></template>批量删除
      </a-button>
    </div>
    <a-table
      :columns="tabList_title"
      :data-source="dataSource ? dataSource['result'] : []"
      :pagination="pagination"
      @change="tablePaginationChange"
      :loading="loading"
      :row-key="(record) => record.id"
      :row-selection="{
        selectedRowKeys: selectedRowKeys,
        onChange: onSelectChange,
      }"
    >
      <template #bodyCell="{ column, record, index }">
        <template v-if="column.key === 'index'">
          {{ (pagination.current - 1) * pagination.pageSize + index + 1 }}
        </template>
        <template v-if="column.key === 'action'">
          <a-space>
            <a-button type="primary" @click="editElement(record)" size="small">
              <template #icon><FormOutlined /></template>修改
            </a-button>
            <a-button
              type="primary"
              @click="previewElement(record)"
              size="small"
            >
              <template #icon><EyeOutlined /></template>预览
            </a-button>
            <a-button
              type="primary"
              @click="delElement(record)"
              size="small"
              danger
            >
              <template #icon><LinkOutlined /></template>删除
            </a-button>
          </a-space>
        </template>
      </template>
    </a-table>
    <a-modal
      class="previewClass"
      v-model:visible="previewPopShow"
      :bodyStyle="previewModal"
      destroyOnClose
      title="预览元素"
      @ok="handleOk"
    >
      <PreviewPop :isModel="false" :previewData="previewData"></PreviewPop>
    </a-modal>
    <a-modal
      v-model:visible="elementEditShow"
      :title="title"
      :width="650"
      @cancel="onClose"
      @ok="handleEditOk"
    >
      <ElementConfig
        :handleSearch="handleSearch"
        :dictionaryData="condition.dictionaryName.data"
        :elementTypeData="condition.elementMode.data"
        :element-id="selectEId"
        ref="eleConfig"
      ></ElementConfig>
    </a-modal>
    <a-modal
      v-model:visible="elementUpdateShow"
      :title="title"
      :width="650"
      @cancel="onUpdateClose"
      @ok="handleUpdateOk"
    >
      <ElementUpdateConfig
        :handleSearch="handleSearch"
        :dictionaryData="condition.dictionaryName.data"
        :elementTypeData="condition.elementMode.data"
        ref="eleUpdateConfig"
        :editData="editData"
        :current="pagination.current"
        :element-id="selectEId"
        @refreshElementSuccess="refreshElementSuccess"
        :searchfFilters="lastFilter"
      ></ElementUpdateConfig>
    </a-modal>
  </div>
</template>

<script>
import { reactive, toRefs, defineComponent, defineAsyncComponent, computed, ref, nextTick, onMounted } from 'vue'
import { requestUsePagination, getRequestURI, request } from '../../../utils/request'
// import {getDictionaryValue, getExtendUri} from '../../../utils/request_extend'
import { getExtendUri } from '../../../utils/request_extend'
import { message, Modal } from 'ant-design-vue'
import ElementUpdateConfig from './ElementUpdateConfig.vue'
import {
  FileAddOutlined,
  FormOutlined,
  DeleteOutlined,
  EyeOutlined,
  LinkOutlined
} from '@ant-design/icons-vue'
import PreviewPop from  '../../../components/extended/PreviewPop.vue'
export default defineComponent({
  components: {
    ElementUpdateConfig,
    FileAddOutlined,
    FormOutlined,
    DeleteOutlined,
    EyeOutlined,
    LinkOutlined,
    SearchForm: defineAsyncComponent(() => import('../../../components/extended/search_form')),
    ElementConfig: defineAsyncComponent(() => import('./ElementConfig')),
    PreviewPop,
  },
  setup () {
    // customRender
    const state = reactive({
      previewData: {},
      lastFilter: {},
      title: '',
      selectedRowKeys: [],
      condition: {
        elementName: {
          label: '元素名称',
          placeholder: '请输入元素名称关键字',
        },
        elementMode: {
          label: '元素类型',
          type: 'select',
          default: '1',
          data: []
        },
        elementState: {
          label: '元素状态',
          type: 'select',
          urlKey: 'queryDictionaryType',
          queryParam: { type: 'status' }
        },
        elementCode: {
          label: '元素编码',
          placeholder: '请输入关键字',
        },
        dictionaryName: {
          label: '字典表',
          type: 'select',
          default: '0',
          data: []
        }
      },
      tabList_title: [
        {
          key: 'index',
          title: '序号',
          fixed: 'left',
          width: 60,
          align: 'left',
        },
        {
          title: '元素名称',
          width: 170,
          fixed: 'left',
          key: 'elementName',
          dataIndex: 'elementName',
        },
        {
          title: '元素类型',
          width: 170,
          fixed: 'left',
          key: 'elementType',
          dataIndex: 'elementType',
        },
        {
          title: '元素状态',
          width: 200,
          fixed: 'left',
          key: 'elementStateName',
          dataIndex: 'elementStateName',
          // customRender: (text)=> getDictionaryValue('module_status', text.value)
        },
        {
          title: '创建时间',
          width: 200,
          fixed: 'left',
          key: 'createTime',
          dataIndex: 'createTime',
          customRender: (text) => {
            // console.log(text)
            let time = new Date(+new Date((text.text)) + 8 * 3600 * 1000).toISOString().replace(/T/g, ' ').replace(/\.[\d]{3}Z/, '')
            return time
          }
        },
        {
          title: '操作',
          width: 200,
          fixed: 'left',
          key: 'action',
        },
      ],
      previewPopShow: false,
      elementEditShow: false,
      elementUpdateShow: false,
      selectEId: '',
      editData: {},
    })
    // const returnStateText = function (value) {
    //   console.log(value)
    //  let arr = state.condition.elementState.data
    //   for (let i = 0; i <arr.length ; i++) {
    //     if (value == arr[i].value) {
    //       return arr[i].label
    //     }
    //   }
    //   return ''
    // }
    const pagination = computed(() => ({
      current: current.value,
      pageSize: pageSize.value,
      total: total.value,
      pageSizeOptions: [10, 20, 50],
    }))
    const onSelectChange = selectedRowKeys => {
      console.log('selectedRowKeys changed: ', selectedRowKeys)
      state.selectedRowKeys = selectedRowKeys
    }

    const {
      data: dataSource,
      run,
      loading,
      current,
      pageSize,
      total,
    } = requestUsePagination(getRequestURI('element_elementList'))
    //页面挂载调用数据
    onMounted(() => {
      handleSearch({})
    })
    // 存储搜索组件传过来的数据
    const searchfFilters = ref({})
    const handleSearch = function (filters,pag='') {
      if (filters) {
        searchfFilters.value = filters
      }
      state.lastFilter = filters
      run({
        pagination: {
          current: pag ? pag : 1,
          pageSize: 10
        },
        data: filters,
      })
    }
    const tablePaginationChange = (pag, filters, sorter) => {
      console.log('===', filters)
      run({
        pagination: {
          current: pag?.current,
          pageSize: pag?.pageSize
        },
        sorter,
        data: state.lastFilter,
      })
    }
    // const tablePaginationChange = function(pagination) {
    //   this.pagination.current = pagination.current  // 重新设置当前页
    //   this.pagination.pageSize = pagination.pageSize
    // }
    const eleUpdateConfig = ref()
    const editElement = function (ele) {
      state.title = '编辑元素'
      // state.editData = ele
      state.elementUpdateShow = true
      nextTick(() => {
        eleUpdateConfig.value.getUpdateData(ele)
      })
    }
    const addElement = function () {
      state.title = '新增元素'
      state.elementEditShow = true

    }
    // 预览
    const previewElement = function (ele) {
      ele.firstValue = ''
      let arr = []
      arr.push(ele)
      Object.assign(state.previewData, { productElementList: [arr] })
      state.previewPopShow = true
    }



    const delElement = function (ele) {
      if (ele.id === undefined) {
        return message.warning('请选择数据')
      }
      Modal.confirm({
        title: '删除提示',
        content: '是否删除已选择信息？',
        onOk: () => {
          request(getRequestURI('element_deleteElement'), { id: ele.id }).then(res => {
            if (res.code === 1) {
              handleSearch({})
              message.success('删除成功')
              state.selectEId = ''
            } else {
              message.warning(res.message)
            }
          })
        },
        onCancel: () => {
          Modal.destroyAll()
        }
      })

    }
    const delSomeElement = function () {
      if (state.selectedRowKeys.length === 0) {
        return message.warning('请选择数据')
      }
      Modal.confirm({
        title: '删除提示',
        content: '是否删除已选择信息？',
        onOk: () => {
          request(getRequestURI('element_deleteElement'), { ids: [...state.selectedRowKeys] }).then(res => {
            if (res.code === 1) {
              handleSearch({})
              message.success('删除成功')
              state.selectedRowKeys = []
            } else {
              message.warning(res.message)
            }
          })
        },
        onCancel: () => {
          Modal.destroyAll()
        }
      })

    }
    // 预览
    const handleOk = function () {
      state.selectEId = ''
      state.previewPopShow = false
    }


    const eleConfig = ref()
    const handleEditOk = async function () {
      await eleConfig.value.handleOk()
      state.elementEditShow = await false

    }
    const onClose = () => {
      eleConfig.value.onClose()
    }
    const handleUpdateOk = async function () {
      await eleUpdateConfig.value.handleOk()
      state.elementUpdateShow = await false

    }
    // 修改成功后修改数据源
    const refreshElementSuccess = () => {

    }
    const onUpdateClose = () => {
      // state.editData = {}
      eleUpdateConfig.value.onClose()
    }
    // 获取字典表
    const getTypeData = () => {
      request(getExtendUri('dictionary_query_byType'),
        { data: { ids: [], types: ['System'] } }).then(
          res => {
            state.condition.dictionaryName.data = res.result.System
          })
    }
    // 获取元素类型
    const getElementTypeData = () => {
      try {
        request(getExtendUri('dictionary_query_byType'), { data: { ids: [], types: ['element_dict'] } }).then(
          res => {
            console.log('字典数据', res)
            state.condition.elementMode.data = res.result.element_dict
          })
      } catch (error) {
        message.error(error)
      }
    }

    onMounted(() => {
      getTypeData()
      getElementTypeData()
    })
    const previewModal = {
      'max-height': '400px',
      'overflow': 'auto'
    }
    return {
      ...toRefs(state),
      getTypeData,
      handleSearch,
      addElement,
      delElement,
      getRequestURI,
      tablePaginationChange,
      onSelectChange,
      editElement,
      previewElement,
      delSomeElement,
      handleOk,
      handleEditOk,
      handleUpdateOk,
      onClose,
      onUpdateClose,
      getElementTypeData,
      loading,
      dataSource,
      total,
      pagination,
      eleUpdateConfig,
      eleConfig,
      previewModal,
      refreshElementSuccess,
      searchfFilters
    }
  }
})
</script>

<style scoped>
.previewClass {
  height: 500px;
  overflow: auto;
}
</style>
