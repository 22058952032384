<template>
<div style="width: 100%;min-height: 2rem;" class="header">
  <!--  输入框类型元素-->
    <div v-if="!inputConfig.textarea && !inputConfig.isPassword">
      <a-input-number :bordered="inputConfig.bordered" @blur="handelInputBlur" v-if="elementVaule.typeId === 1" :placeholder="inputHint"  v-model:value="valueSatate" :min="getNumLimit(0)" :disabled="disabled"
                      :max="maxLength ? maxLength : 999" class="inputWidth" :readonly="isDisabled"/>
      <a-row v-else-if="elementVaule.typeId !== 7">
        <a-col :span="num" >
<!--          <div style="margin-left: 6rem;">-->
          <div :class="{premium: inputConfig.calculate, disFlex: inputConfig.isCheckPhone}" style="margin-left: 6rem;">
            <a-input v-if="!isReadonly" v-model:value="valueSatate"  class="inputWidth" :disabled="disabled"
                    :placeholder="inputHint" :readonly="inputConfig.readonly || isDisabled" :bordered="inputConfig.bordered" :maxlength="maxLength ? maxLength : 9999" :show-count="inputConfig.showCount" @blur="handelInputBlur"/>
            <a-input v-if="isReadonly" v-model:value="valueSatate"  class="inputWidth"
                    :placeholder="inputHint" :bordered="inputConfig.bordered" :maxlength="maxLength ? maxLength : 9999" :show-count="inputConfig.showCount" @blur="handelInputBlur"/>
<!--            是否需要手机号验证-->
            <CheckCircleFilled v-if="inputConfig.isCheckPhone && isCheck" style="color: #23ff00; font-size: 16px;text-align: center;margin-left: 8px;padding-top: 3px;"/>
            <a-button type="link" v-else-if="inputConfig.isCheckPhone && !isCheck" @click="onVerifyPhone(valueSatate)">验证</a-button>
          </div>
        </a-col>
        <a-col :span="8" v-if="elementVaule.typeId == 11 || elementVaule.typeId == 12">
          <a-select @change="handelSelect" v-model:value="timeState"  :disabled="disabled" :readonly="isDisabled">
            <a-select-option value="天">天</a-select-option>
            <a-select-option value="周">周</a-select-option>
            <a-select-option value="月">月</a-select-option>
            <a-select-option value="年">年</a-select-option>
          </a-select>
        </a-col>
      </a-row>
      <a-auto-complete
        v-else
        :placeholder="inputHint"
        class="compact_class"
        @blur="handelInputBlur"
        v-model:value="valueSatate"
        :options="emailOption"
        :disabled="disabled || isDisabled"
      />
    </div>
    <a-input-password @blur="handelInputBlur"  class="inputWidth" v-else-if="inputConfig.isPassword" v-model:value="valueSatate" :placeholder="inputHint"
                      :disabled="disabled" :readonly="isDisabled" :maxlength="maxLength" :bordered="inputConfig.bordered"/>
    <a-textarea @blur="handelInputBlur" v-else  class="inputWidth" v-model:value="valueSatate" :placeholder="inputHint" :bordered="inputConfig.bordered"
                :disabled="disabled" :readonly="isDisabled" :maxlength="maxLength" :show-count="inputConfig.showCount"/>
  <span v-show="isTips" style="color: red;font-size: smaller">{{ tips }}</span>
  <a-modal style="width: 75%;" centered :closable="false" v-model:visible="isIdentical" @ok="insuranceErrorHandleOk">
      <p style="font-family: PingFangSC-Regular;
        font-size: 1.01rem;
        color: #333333;
        text-align: center;
        margin: 0.45rem 0.25rem;">{{ detailModal }}</p>
      <template #footer>
        <a-button type="link"
                  style="width: 100%;font-size: 20px;text-align: center !important; color: #1677FF;"
                  @click="insuranceErrorHandleOk">确认
        </a-button>
      </template>
  </a-modal>
  <a-input v-if="inputConfig.specialClues" :bordered="false" readonly class="readClass" v-model:value="specialClues" />
  <div v-if="isVisible">
    <verify-phone ref="phoneRef" v-model:isCheck="isCheck" v-if="isVisible"></verify-phone>
  </div>
</div>
</template>

<script>
import { message } from 'ant-design-vue'
import {defineComponent, toRefs, reactive, computed, watch, onBeforeUpdate,nextTick, ref } from 'vue'
import {vTools} from '../../js/verificationTool'
import VerifyPhone from '@/pages/transaction/public/verifyPhone.vue'
import {CheckCircleFilled} from '@ant-design/icons-vue'
import {Dialog} from 'vant'
import store from '@/store'
import router from '@/router'
export default defineComponent({
  name: 'InputView',
  components:{
    VerifyPhone,
    CheckCircleFilled
  },
  props: {
    moduleId:{ // 模块ID
      type: [Number,String],
      default:()=> ''
    },
    elementId:{ // 元素ID
      type: [Number,String],
      default:()=> ''
    },
    elementVaule: {
      type: Object,
      default: () => {
      }
    },
    inputConfig: {
      type: Object,
      default: () => {
      },

    },
    moduleIndex:{
      type:[Number,String],
      },
    maxLength: {
      type: String,
      default: () => ''
    },
    inputTitle: {
      type: String,
      default: () => ''
    },
    inputHint: {
      type: String,
      default: () => ''
    },
    tips: {
      type: String,
      default: () => ''
    },
     elementCode: {
      type: [Number,String],
      default: () => ''
    },
    isRequired:{
      type: [Number,String],
      default:()=> '0'
    },
    moduleName:{
      type: String,
      default: () => ''
    },
    firstValue:{type: [String,Number],default:()=> ''} ,
    endValue:{type: [String,Number],default:()=> ''},
    elementName:{type: String,default:()=> ''},
    disabledType: {
      type: Boolean,
      default:()=> false
    },
    checkType: {
      type: Boolean,
      default:()=> true
    },
    insuredObj:{
      type: Object,
      default: () => {}
    },
    isDisabled: {
      type: Boolean,
      default:()=> false
    }
  },
  emits:['callback','update:endValue','update:firstValue'],
  setup(props,context) {
    const State = reactive({
      isVisible: false,
      detailModal:'',
      isIdentical: false,
      isTips: false,
      disabled:props.inputConfig.disabled || props.disabledType,
      inputConfig: props.inputConfig,
      elementVaule: props.elementVaule,
      inputTitle: props.inputTitle,
      maxLength: props.maxLength * 1,
      inputHint: props.inputHint,
      tips: '',
      specialClues: props.inputConfig.specialClues,
      vToolResult: true,
      timeState:props.endValue ? props.endValue : '',
      valueSatate: props.firstValue,
      elementCode: props.elementCode,
      isReadonly: (props.elementCode === 'idTypeStartDate' || props.elementCode === 'idTypeEndDate') && router.currentRoute.value.query.continuousRenew && router.currentRoute.value.query.continuousRenew === '1',
      isCheck: false,
      num: computed(()=>{
        if(props.typeId !== 11 && props.typeId !== 12){
          return 24
        }else {
          return 16
        }
      })
    })
    const getResult = (data) => {
      context.emit('callback',data)
    }
    onBeforeUpdate(()=>{
      if(State.valueSatate == null || State.valueSatate == ''){
        State.valueSatate = props.firstValue
      }
      State.inputHint = props.inputHint
      State.disabled = props.disabledType
    })
    // type = 0 最小值，1最大值
    const getNumLimit = function (type) {
      // 0-(),1-(],2[),3[]
      let obj = (State.elementVaule && State.elementVaule.rangeList && State.elementVaule.rangeList.length > 0) ? (State.elementVaule.rangeList[0]) : ''
      if (obj !== '') {
        if (obj.containsFlag === 0) {
          return type === 0 ? Number(obj.startValue) + 1 : Number(obj.endValue) - 1
        } else if (obj.containsFlag === 1) {
          return type === 0 ? Number(obj.startValue) + 1 : Number(obj.endValue)
        } else if (obj.containsFlag === 2) {
          return type === 0 ? Number(obj.startValue) : Number(obj.endValue) - 1
        } else {
          return type === 0 ? Number(obj.startValue) : Number(obj.endValue)
        }
      } else {
        // console.warn(`非数字范围无法计算极限值${type}`)
      }
    }
    const handelInputBlur = () =>{
      if (props.isDisabled) return
      try{
        // TODO 逻辑有问题
        let str = props.moduleId === 'applicant' ? 'appntIdType' : 'idType'
        if(State.elementVaule.typeId === 2){
          let arr = numRangeVerification(State.elementVaule)
          if(!(Number(State.valueSatate) >= arr[0] && Number(State.valueSatate) <= arr[arr.length-1])) {
            return message.warning(`请输入${arr[0]}-${arr[arr.length-1]}之间的值`)
          }
        } else if(State.elementVaule.typeId === 3 && props.insuredObj[props.moduleId][props.moduleIndex][str][str] !== '15'){
          State.valueSatate = State.valueSatate.replace(/[^\u4E00-\u9FA5·•]/g,'')
          if(State.valueSatate && State.valueSatate.length < 2){
            State.detailModal = '投保人姓名长度不得少于两个中文字符'
            State.isIdentical = true
            return
          }
        }else if (State.elementVaule.typeId === 3 && props.insuredObj[props.moduleId][props.moduleIndex]?.[str]?.[str] === '15') {
          State.valueSatate = State.valueSatate?.toUpperCase()
        } else if ((props.elementCode === 'idNo' || props.elementCode === 'appntIdNo') && props.insuredObj[props.moduleId][props.moduleIndex]?.[str]?.[str] === '15') {
          State.valueSatate = State.valueSatate?.toUpperCase()
        }else if (State.elementVaule.typeId === 0 && props.elementCode === 'bnfLot') {
            const v = vTools(props.elementCode, State.valueSatate)
            if (v !== true) {
              State.detailModal = v
              State.isIdentical = true
              State.valueSatate = null
              return
            }
            if (State.valueSatate && State.valueSatate.includes('.') && State.valueSatate.split('.')[1].length > 1) {
              State.detailModal = '受益比例最多输入一位小数'
              State.isIdentical = true
              State.valueSatate = null
              return
            }
        } else {
          if (props.insuredObj[props.moduleId]?.[props.moduleIndex]?.[str]?.[str] !== '15') {
            State.valueSatate = State.valueSatate.replace(/\s+/g, '')
          }
        }
        inputContentListener()
      } catch (er) {
        console.debug(er)
      }

    }
    const insuranceErrorHandleOk = () =>{
      State.valueSatate = ''
      State.isIdentical = false
      context.emit('update:firstValue', State.valueSatate)

    }
// console.log(props.moduleIndex)
    // 输入框内容监听，及校验
    const inputContentListener = () => {
      console.log(props.elementCode, 'props.elementCod')
      // 判断是否已经验证
      State.valueSatate = State.valueSatate.replace(/^\s+|\s+$/g, '')
      const types = ['text', 'number', '','','','','','email','tel','phone','pId']
      if(props.elementCode === 'appntIdNo' || props.elementCode === 'idNo'){
        let str = props.moduleId === 'applicant' ? 'appntIdType' : 'idType'
        console.log(str, 'strstr')
        if(props.insuredObj[props.moduleId][props.moduleIndex][str][str] === '01' || props.insuredObj[props.moduleId][props.moduleIndex][str][str] === '05'){
          State.maxLength = 18
          State.vToolResult = vTools('pId', State.valueSatate)
        } else if (props.insuredObj[props.moduleId][props.moduleIndex][str][str] === '07') {
          State.vToolResult = vTools('birthCard', State.valueSatate)
        } else if (props.insuredObj[props.moduleId][props.moduleIndex][str][str] === '15') {
          console.log('112121')
          State.maxLength = 18
          State.vToolResult = vTools('wgrjlz', State.valueSatate)
        } else{
          State.vToolResult = true
        }
      } else if (props.elementCode === 'appntName' || props.elementCode === 'name') {
        let str = props.moduleId === 'applicant' ? 'appntIdType' : 'idType'
        if (props.insuredObj[props.moduleId][props.moduleIndex][str][str] === '15') {
          State.vToolResult = vTools('fName', State.valueSatate)
        }
      } else if (props.elementCode === 'newBankAccNo') {
        State.vToolResult = vTools('bankNo', State.valueSatate)
      } else if (props.elementCode === 'zipCode') {
        State.vToolResult = vTools(props.elementCode, State.valueSatate)
      } else if (props.elementCode === 'salary') {
        State.valueSatate = State.valueSatate.replace(/^(-)*(\d+)\.(\d{0,1}).*$/g, '$1$2.$3')
        if (props.moduleId === 'applicant') {
          console.log(Number(State.valueSatate), 'Number(State.valueSatate)')
          if (Number(State.valueSatate) < 0.1) {
            State.valueSatate = null
            State.vToolResult = '请输入正确的年收入'
          } else {
            State.vToolResult = vTools(props.elementCode, State.valueSatate)
            console.log(State.vToolResult, 'State.vToolResult1111')
          }
        } else {
            State.vToolResult = vTools(props.elementCode, State.valueSatate)
        }
        if (Number(State.valueSatate) >= 1000) {
            Dialog.confirm({
              confirmButtonText:'确定',
              cancelButtonText: '重置',
              message: '请确认年收入填写是否正确',
              confirmButtonColor: '#0078fc'
          }).then(() => {
          }).catch(() => {
            State.valueSatate = null
          })
          }
      } else if (props.elementCode === 'postalAddress' || props.elementCode === 'bnfAddress') {
        State.vToolResult = vTools('address', State.valueSatate)
      } else {
        State.vToolResult = vTools(types[State.elementVaule.typeId], State.valueSatate)
      }
      if (State.vToolResult !== true) {
        State.isTips = true
        State.tips = State.vToolResult
        return
      }else {
        State.isTips = false
        let node = {}
        node[State.elementCode] ={
          [State.elementCode] : [11,12].includes(State.elementVaule.typeId)?{valueSatate: State.valueSatate,timeState: State.timeState,}:State.valueSatate,
          elementId: props.elementId,
          index: props.moduleIndex,
          moduleType: props.moduleId,
          required: props.isRequired,
          elementName: props.elementName,
          moduleName: props.moduleName,
        }
        getResult(node)
      }
      if(State.inputConfig.isCheckPhone) {
        let verifyCodeList = store.getters['insured/getVerifyCode']
        if (verifyCodeList.findIndex(item => item.mobile === State.valueSatate) === -1) {
          State.isCheck = false
        }
      }
    }
    watch(()=>State.timeState,()=>{
      context.emit('update:endValue', State.timeState)
    },{immediate: true,deep:true})
    watch(()=>State.valueSatate,(newValue, oldValue)=>{
      let node = {}
      node[State.elementCode] ={
        [State.elementCode] : newValue,
        elementId: props.elementId,
        index: props.moduleIndex,
        moduleType: props.moduleId,
        required: props.isRequired,
        elementName: props.elementName,
        moduleName: props.moduleName,
      }
      getResult(node)
      context.emit('update:firstValue', State.valueSatate)
      if(State.inputConfig.isCheckPhone && newValue !== oldValue) {
        let verifyCodeList = store.getters['insured/getVerifyCode']
        if (verifyCodeList.findIndex(item => item.mobile === State.valueSatate) !== -1) {
          State.isCheck = true
        }
      }
    },{immediate: true,deep:true})
    watch(()=> props.firstValue, (newValue)=>{
      State.valueSatate = newValue
    })
    watch(() => props.insuredObj, val => {
      if (val) {
        let str = props.moduleId === 'applicant' ? 'appntIdType' : 'idType'
        if (props.elementCode === 'appntIdNo' || props.elementCode === 'idNo') {
          if (val[props.moduleId][props.moduleIndex]?.[str]?.[str] !== '15') {
            State.maxLength = 18
          }
        }
      }
    }, {deep: true})
    const handelSelect = () =>{
      let node = {}
      node[State.elementCode] = {
        [State.elementCode] : {
          valueSatate: State.valueSatate,
          timeState: State.timeState
        },
        elementId: props.elementId,
        index: props.moduleIndex,
        moduleType: props.moduleId,
        required: props.isRequired,
        elementName: props.elementName,
        moduleName: props.moduleName,

      }
      getResult(node)
    }



    // 数字范围校验
    const numRangeVerification = (elementVaule) =>{
        let {rangeList} = elementVaule
        let arrRange = []
        rangeList.forEach(item  => {
          Object.values(item).forEach(num =>{
            arrRange.push(num)
          })
        })
        return arrRange.sort()
    }
    const emailOption = computed(() => {
      let str = (State.valueSatate || '').split('@')[0]
      str = str.replace(/\s+/g, '')
      return [
        {value: str + '@126.com'},
        {value: str + '@163.com'},
        {value: str + '@qq.com'},
        {value: str + '@sina.com'},
        {value: str + '@hotmail.com'}
      ]
    })
    const phoneRef = ref()
    // 验证按钮
    const onVerifyPhone = (value) =>{
      let data = {
        mobile : value,
        attributive: props.moduleId === 'applicant' ? '1' : (props.moduleId === 'insureds' ? '2' : '3' ) ,
        moduleId: props.moduleId
      }
      if(!value) return message.warning('请填写投保人/被保人手机号')
      State.isVisible = true
      nextTick(()=>{
        phoneRef.value.showData(data)
      })

    }

    watch(()=>props.checkType,()=>{
      State.isCheck =  props.checkType
    },{deep:true})

    watch(() => props.disabledType, newValue => {
      State.disabled = newValue
    })
    return {
      insuranceErrorHandleOk,
      onVerifyPhone,
      numRangeVerification,
      handelInputBlur,
      getNumLimit,
      inputContentListener,
      getResult,
      handelSelect,
      emailOption,
      phoneRef,
      ...toRefs(State)
    }
  }

})
</script>

<style scoped>
:global(.header .ant-modal-content) {
  border-radius: 20px ;
}
.ant-input{
  padding: 0 !important;
}
.inputWidth{
  width: 100%;
  text-align: right !important;
  text-overflow: ellipsis;
    white-space: nowrap;
}
:deep(.ant-input-number-input){
  text-align: right !important;
}
:deep(.ant-select:not(.ant-select-disabled):hover .ant-select-selector){
  border: 0;
  border-right-width: 0 !important;
}
:deep(.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector){
  box-shadow: none !important;
}
:deep(.ant-select:not(.ant-select-customize-input) .ant-select-selector){
  padding: 0 !important;
}

.compact_class{
  text-align: right !important;
  width: 100%;
}
:deep(.ant-select:not(.ant-select-customize-input) .ant-select-selector  .ant-select-selection-search-input){
  text-align: right !important;
  width: 70% !important;
}
:deep(.ant-select:not(.ant-select-customize-input) .ant-select-selector ){
  border: 0;
}
.width90{
  width: 89%;
}
.premium{
  display: flex;
  min-height: 1.56rem;
}
.disFlex{
  display: flex;
}
.disFlex .ant-btn{
  margin-left: 10px;
  padding: 0 !important;
}
.readClass{
  text-align: right;
  color: rgba(253, 140, 1, 0.73);
  font-size: 13px;
}
:deep(.ant-input-number-input){
  padding: 0 !important;
}
:deep(.ant-input-number-out-of-range input){
  color: #222 !important;
}

</style>
