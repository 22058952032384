import {request,} from '../../../../utils/request'
import {getExtendUri} from '../../../../utils/request_extend'

export const getDictionaryData =(dictionary) =>{
    if(!dictionary){
      return
    }
    request(getExtendUri('dictionary_query_byType'), 
    {data: {ids:[],types:[dictionary]}}).then(
      res =>{
        console.log(res.result[dictionary])
        return res.result[dictionary]
      })
  }