<template>
  <a-drawer
    title="编辑页面"
    :width="'65%'"
    :visible="visible"
    :body-style="{ paddingBottom: '80px' }"
    :footer-style="{ textAlign: 'right' }"
    @close="onClose"
  >
    <div class="m_header"  ref="dragLayoutRef">
      <div class="m_previewPage">
        <h3 class="center_box">预览界面</h3>
        <div class="continer"  >
          <div v-for="(dragModule,index) in dragModuleList"
               :key="index + new Date()"
               :draggable="true"
               @dragstart.stop="handleDragStart($event, dragModule)"
               @dragover.stop="handleDragOver($event, dragModule)"
               @dragenter.stop="handleDragEnter($event, dragModule)"
               @dragend.stop="handleDragEnd($event, dragModule)"
          >
                                                                          <!-- moduleName -->
            <h3 style="margin:5px 0; text-align:center;font-weight: 700">
              <div class="del-class" @click="handelDelete(index)">
                <close-outlined />
              </div>
              {{dragModuleList[index].moduleName}}
              <!-- {{dragModule[index].labelDescription}} -->
            </h3>
              <PreviewPop :moduleShow="true" :previewData="dragModule" :isModel="false"/>
          </div>
        </div>
      </div>
      <div class="m_moduleList">
        <h3 class="center_box">模块列表</h3>
        <div class="continer">
          <a-input-search
            v-model:value="moduleName"
            placeholder="请输入模块名称"
            @search="onSearch"
            style="margin-bottom: 20px"
          />
          <div
            class="module_detial"
            v-for="mod in moduleList"
            :key="mod.id"
            :draggable="true"
            @dragstart="onDagstart($event, mod)"
          >
            {{mod.moduleName}}
            <span class="module_span">{{mod.labelDescription}}</span>
          </div>
          <div class="tree_drag" ref="dragTree" v-show="isShowDrag"></div>
        </div>
      </div>
    </div>
    <template #extra>
      <a-space>
        <a-button @click="onClose">
          <template #icon>
            <CloseOutlined/>
          </template>
          取消
        </a-button>
        <a-button @click="onReast">
          <template #icon>
            <ReloadOutlined />
          </template>重置
        </a-button>
        <a-button type="primary" @click="handelAdd">
          <template #icon>
            <SaveOutlined/>
          </template>
          保存
        </a-button>
      </a-space>
    </template>

  </a-drawer>
</template>

<script>
import { message } from 'ant-design-vue'
import {defineComponent, reactive, toRefs, ref, nextTick, inject} from 'vue'
import { request,getRequestURI } from '../../../../utils/request'
import {moduleDetail} from './js/moduleType'
// import * as _ from 'lodash'
  export default defineComponent({
    components:{
      // PreviewPop
    },

    setup(){
      const state = reactive({
        visible: false,
        moduleId: null,
        moduleName:'',
        moduleList: [], //得到模块数据
        formState:{},// 详情数据
        dragModuleList:[],
        title:'',
        dragging:{}
      })
      const showData = reactive({
        isShowDrag: false,
      })
      const handleDragStart=(e,items)=>{
        console.log('zxtdhandleDragStart',e,items)
        state.dragging = items//开始拖动时，暂时保存当前拖动的数据。
      }
      const handleDragEnd=(e,items)=>{
        // console.log('zxtdhandleDragEnd',e,items)
        console.debug(e,items)
        state.dragging = null//拖动结束后，清除数据
      }
      const handleDragOver=(e)=> {
        e.dataTransfer.dropEffect = 'move'//在dragenter中针对放置目标来设置!
      }
      const handleDragEnter=(e,items)=>{
        console.log('zxtdhandleDragEnter11',e,items)
        if(items.id&&state.dragging&&state.dragging.id){
          e.dataTransfer.effectAllowed = 'move'//为需要移动的元素设置dragstart事件
          if(items.id == state.dragging.id) return
          let newItems = [...state.dragModuleList]//拷贝一份数据进行交换操作。
          console.log('newItems',newItems)
          let src//获取数组下标
          let dst
          newItems.forEach((item,index)=>{
            // console.log('22222',item[0],index)
            if(item.id===state.dragging.id){
              src=index
              // console.log('lisrc',src)
            }
            if(item.id===items.id){
              dst=index
              // console.log('lidst',dst)
            }

          })
          console.log('src,det',src,dst)
          newItems.splice(dst, 0, ...newItems.splice(src, 1))//交换位置
          nextTick(()=>{

            state.dragModuleList = newItems

          })
        }

      }

      const Debounce= (fn, t)=>
      {
        const delay = t || 500
        let timer
        return function () {
          const args = arguments
          if (timer) {
            clearTimeout(timer)
          }
          timer = setTimeout(() => {
            timer = null
            fn.apply(this, args)
          }, delay)
        }
      }
      const showDrawer = (dragModuleList = []) =>{
        if(dragModuleList && dragModuleList.length){
          state.dragModuleList = JSON.parse(JSON.stringify(dragModuleList))
        }
        state.visible = true
        getModuleList()
      }
      const onSearch = () => {
        getModuleList(state.moduleName)
      }

      // 获取模块列表数据
      const getModuleList = (moduleName) =>{
        // module_moduleList
        request(getRequestURI('module_moduleList'), {
          data:{moduleName:moduleName},
          pagination: {current: 1, pageSize: 99999}
        })
          .then(res=>{
            state.moduleList = res.result
            // console.log('moduleList',state.moduleList)
        })
      }
      // 以下是拖拽
      const onDagstart = (e, mod) =>{
        if((mod.status*1) !== 1) return message.warning('当前模块状态未启用')
        state.moduleId = mod.id
        getMousedown(e)
      }
      const dragTree = ref()
      const dragLayoutRef = ref()
      const dragState = reactive({
        dragTarget: null
      })
      const getMousedown = (e) =>{
      showData.isShowDrag = true
      dragState.dragTarget = e.currentTarget
      dragTree.value.innerHTML= e.target.innerHTML
      dragTree.value.style.left = e.clientX + 'px'
      dragTree.value.style.top = e.clientY + 'px'
      dragState.dragTarget.classList.add('drag')
      nextTick(()=> {
        dragLayoutRef.value.addEventListener('mouseup', stopDrag)
        dragLayoutRef.value.addEventListener('mousemove', drag)
      })
    }
    const drag = (event) => {
      if (event.button === 0 && showData.isShowDrag === true) {
        event.preventDefault() // 阻止默认行为
        dragTree.value.style.left = (event.clientX + 12) + 'px'
        dragTree.value.style.top = (event.clientY + 15) + 'px'
      }
    }
    const stopDrag = () =>{
      dragState.dragTarget.classList.remove('drag')
      dragLayoutRef.value.removeEventListener('mouseup', stopDrag)
      dragLayoutRef.value.removeEventListener('mousemove', drag)
      showData.isShowDrag = false

      getModuleDetail()
    }
    // 拖拽结束后获取模块详情
    const getModuleDetail = () =>{

      request(getRequestURI('module_moduleDetails'),{id:state.moduleId}).then(res =>{
        let arr = []
        res.result.productElementList.forEach((item,index) => {
          arr.push({...item,moduleId:res.result.modularId})
          delete res.result.productElementList[index]
        })
        Object.assign(res.result, {productElementList: [arr]})
        state.dragModuleList.push({...res.result,title: moduleDetail(res.result.modularId)})

      })
    }
    const getNodeEdit = inject('getNodeEdit')
    // 保存
    const handelAdd = () =>{
      console.debug('拖拽结束后获取模块详情',state.dragModuleList)
      getNodeEdit(state.dragModuleList)
      onClose()
    }
    // 删除
    const handelDelete = (index) => {
      state.dragModuleList.splice(index, 1)
    }


    const onReast = () =>{
      state.dragModuleList = []
    }
    const onClose = () =>{
      state.dragModuleList = []
      state.moduleName = ''
      showData.isShowDrag= false
      state.visible = false
    }



      return {
        handelDelete,
        ...toRefs(state),
        ...toRefs(showData),
        onReast,
        showDrawer,
        getModuleList,
        onClose,
        onSearch,
        onDagstart,
        handelAdd,
        getModuleDetail,
        getNodeEdit,
        dragTree,
        dragLayoutRef,
        dragState,
        Debounce,
        handleDragEnter,
        handleDragOver,
        handleDragEnd,
        handleDragStart
      }
    }


  })
</script>

<style scoped>
  .m_header{
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
    margin: 10px 50px;
  }
  .m_previewPage{
    width: 45%;
  }
  .m_moduleList{
    width: 45%;
  }
  .center_box{
    text-align: center;
  }
  .continer{
   /* min-width: 375px; */
   position: relative;
   height: 517px;
   padding: 17px;
   background: #f2f2f2;
   overflow:hidden;
   overflow-y: auto;
 }
  .del-class{
    float: left;
    width: 35px;
    cursor: pointer;
  }
  .module_detial{
   border: 1px solid #999;
   padding: 10px;
   margin-bottom: 10px;
   white-space: nowrap;
   overflow: hidden;
   text-overflow: ellipsis;
  }
  .module_span{
    color: rgb(189, 188, 189);
    font-size: 13px;
    margin-left: 8px;
  }
  .tree_drag{
    position: fixed;
    border: 1px solid #999;
    min-width: 300px;
    height: 30px;
    text-align: center;
    line-height: 30px;
    z-index: 99999;
  }
</style>
