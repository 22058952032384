<template>
  <a-form :model="formState" :label-col="{ span: 4 }" :wrapper-col="{ span: 18 }">
    <a-form-item label="模块名称">
      <a-input v-model:value="formState.moduleName" disabled/>
    </a-form-item>
    <a-form-item label="模块归属">
      <a-input v-model:value="formState.moduleTypeName" disabled/>
    </a-form-item>
    <a-form-item>
      <PreviewPop :previewData="formState" :isModel="false"/>
    </a-form-item>

  </a-form>
</template>

<script>
import {defineComponent,onMounted, reactive,watch} from 'vue'
// import {mod_demo1, mod_demo2} from '../demo'
import { getRequestURI, request} from '../../../../utils/request.js'
// import PreviewPop from '../../element/mod/PreviewPop'
export default defineComponent({
  props:{
    elementId: {
      type: String,
      required: true,
      default: ()=> ''
    }
  },
  components: {
    // PreviewPop,
  },
  setup(props) {
    const formState = reactive({})
    const getData = () =>{
      request(getRequestURI('module_moduleDetails'),{id:props.elementId}).then(res =>{
        console.log(res)
        let arr = []
        res.result.productElementList.forEach((item,index) => {
          arr.push(item)
          delete res.result.productElementList[index]
        })
        Object.assign(res.result, {productElementList: [arr]})
        Object.assign(formState, res.result)
        console.log('详情数据', formState)
      })
    }
    watch(
      () => props.elementId,
      () => {
        getData()
      }
    )
     onMounted(() => {
      getData()
    })
    
    return {
      getData,
      formState

    }
  }
})
</script>

<style scoped>

</style>

