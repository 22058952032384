<template>
  <a-drawer
    title="预览详情"
    :width="'25%'"
    :visible="visible"
    :body-style="{ paddingBottom: '80px' }"
    :footer-style="{ textAlign: 'right' }"
    @close="onClose"
  >
    <div class="steps">
      <a-steps direction="vertical">
        <a-step v-for="(list,index) in positionList" :key="list.code" style="height: 120px">
          <template #title>
            <p style="color: #666">步骤{{index+1}}</p>
          </template>
          <template #icon>
            <check-circle-two-tone />
          </template>
          <template #description>
            <p style="height:50px; line-height: 60px; color: black">{{list.name}}</p>
          </template>
        </a-step>
      </a-steps>
    </div>

  </a-drawer>
</template>

<script>
import {defineComponent, reactive, toRefs, } from 'vue'
import { getRequestURI, request } from '../../../../utils/request'
  export default defineComponent({

    setup(){
      const state = reactive({
        visible: false,
        positionList: []
      })
      // process_processDetails

      const showDrawer = (oldData) =>{
        state.visible = true
        getDetails(oldData.id)
      }
      const getDetails = (id) =>{
        request(getRequestURI('process_processDetails'),{id}).then(
          res =>{
            state.positionList = res.result.processDictList
          }
        )
      }
      const onClose = () =>{
        state.visible = false
        state.positionList= []
      }



      return {
        ...toRefs(state),
        showDrawer,
        onClose,
        getDetails,
      }
    }


  })
</script>

<style scoped>
  .steps{
    width: 100%;
    height: 95%;
    margin-top: 15px;
    overflow-y: auto;
  }
</style>
