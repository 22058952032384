<template>
  <a-drawer
    title="添加调用流程"
    :width="'65%'"
    :visible="visible"
    :body-style="{ paddingBottom: '80px' }"
    :footer-style="{ textAlign: 'right' }"
    @close="onClose"
  >
    <a-table :columns="columns2" :data-source="zhiform"
             :scroll="{ x: 1300 }"
             :pagination="false"
             :row-key="(record) => record.id" :row-selection="{
            selectedRowKeys: selectedRowKeys2,
            onChange: onSelectChanges2}">
      <template #bodyCell="{ column,index,  record }">
        <template v-if="column.key === 'index'">
          {{ index + 1 }}
        </template>
        <template v-if="column.key === 'intrefaceTypeId'">
<!--          <a-input v-model:value="record.intrefaceTypeId" placeholder="请输入">-->
<!--          </a-input>-->
          <a-select
            v-model:value="record.intrefaceTypeId"
            placeholder="请选择"
            show-search
            :options="intrefaceTypeIdList"
            :filter-option="filterOption"
            style="width: 200px"
          ></a-select>
        </template>
        <template v-if="column.key === 'uploadFileType' && haveKey(record.intrefaceTypeId)">
          <a-select
            v-model:value="record.uploadFileType"
            placeholder="请选择"
            :options="uploadFileTypeList"
            style="width: 200px"
          ></a-select>
        </template>
        <template v-if="column.key === 'methodDescription'">
          <div class="description_class">
            <a-popover placement="leftBottom">
              {{getMethodDescription(record.intrefaceTypeId)}}
              <template #content>
                <p style="width: 251px">{{getMethodDescription(record.intrefaceTypeId)}}</p>
              </template>
            </a-popover>
          </div>

        </template>
        <template v-if="column.key === 'isForcedReturn'">
          <!--          <a-input v-model:value="record.isForcedReturn" placeholder="请输入">-->
          <!--          </a-input>-->
          <a-select
            v-model:value="record.isForcedReturn"
            placeholder="请选择"
            :options="options"
            style="width: 100px"
          ></a-select>
        </template>
        <template v-if="column.key === 'interfacePosition'">
<!--          <a-input v-model:value="record.interfacePosition" placeholder="请输入">-->
<!--          </a-input>-->
          <a-select
            v-model:value="record.interfacePosition"
            placeholder="请选择"
            :options="optionsTwo"
            style="width: 100px"
          ></a-select>
        </template>
        <template v-if="column.key === 'sort'">
          <a-input v-model:value="record.sort" placeholder="请输入">
          </a-input>
        </template>

      </template>
    </a-table>
    <a-button @click="add" >增加一行</a-button>&nbsp;
    <a-button @click="del" >删除</a-button>&nbsp;
    <template #extra>
      <a-space>
        <a-button @click="onClose">
          <template #icon>
            <CloseOutlined/>
          </template>
          取消
        </a-button>
        <a-button type="primary" @click="handelAdd">
          <template #icon>
            <SaveOutlined/>
          </template>
          保存
        </a-button>
      </a-space>
    </template>

  </a-drawer>
</template>

<script>
import {message} from 'ant-design-vue'
import {defineComponent, inject, onBeforeMount, reactive, ref, toRefs} from 'vue'
import {getDictionaryData} from '../../../../utils/request_extend'
import {getRequestURI, request} from '../../../../utils/request'
// import {moduleDetail} from './js/moduleType'
// import * as _ from 'lodash'
export default defineComponent({
  components:{
    // PreviewPop
  },

  setup(){
    const state = reactive({
      visible: false,
      moduleId: null,
      moduleName:'',
      nodeTypeCode:'',
      nodeTypeType:'',
      moduleList: [], //得到模块数据
      formState:{},// 详情数据
      dragModuleList:[],
      title:'',
      intrefaceTypeIdList:[],
      selectedRowKeys2: [],
      zhiform:[{id: 0, intrefaceTypeId:null,uploadFileType: null,isForcedReturn:null,interfacePosition:'2',sort:''}],
      columns2: [
        {
          title: '序号',
          width: 80,
          fixed: 'left',
          key: 'index',
          dataIndex: 'index'
        },
        {
          title: '接口类型',
          width: 240,
          fixed: 'left',
          align: 'center',
          key: 'intrefaceTypeId',
          dataIndex: 'intrefaceTypeId'
        },
        {
          title: '影像资料类型',
          width: 230,
          align: 'center',
          key: 'uploadFileType',
          dataIndex: 'uploadFileType'
        },
        {
          title: '接口类型说明',
          width: 340,
          align: 'center',
          key: 'methodDescription',
          dataIndex: 'methodDescription',
          ellipsis: true,
        },
        {
          title: '是否强制退出',
          width: 120,
          align: 'center',
          key: 'isForcedReturn',
          dataIndex: 'isForcedReturn',
        },
        {
          title: '接口位置',
          width: 120,
          align: 'center',
          key: 'interfacePosition',
          dataIndex: 'interfacePosition',
        },
        {
          fixed: 'right',
          title: '接口顺序',
          width: 120,
          align: 'center',
          key: 'sort',
          dataIndex: 'sort',
        },
      ],
    })
    const showData = reactive({
      isShowDrag: false,
    })
    const getNodeProcess = inject('getNodeProcess')
    const handelAdd = () =>{
      const list = state.zhiform.map(item=> item.sort)
      const sortList = new Set(list)
      if(list.length > 1 && list.length !== sortList.size) return message.warning('接口顺序不能重复')
      state.zhiform.forEach(item=>{
        item.nodeTypeCode=state.nodeTypeCode
        item.nodeTypeType=state.nodeTypeType
      })
      getNodeProcess(state.zhiform)
      onClose()
    }
    // state.intrefaceTypeIdList
    const huoqu = () => {
      const uri = getRequestURI('getProductInterface')
      request(uri, {data: {}}).then(res => {
        if (res.code === 1) {
          state.intrefaceTypeIdList=res.result
          state.intrefaceTypeIdList.forEach(item=>{
            item.label=item.name
            item.value=item.id
          })
        }

      })
    }
    huoqu()
    const showDrawer = (nodeData,nodeInterfaceList) =>{
      //从nodeData里面拿取nodeTypeCode":"节点类型code","nodeTypeType":"节点类型type",
      if(nodeData.nodeInterfaceList){
        state.zhiform = JSON.parse(JSON.stringify(nodeData.nodeInterfaceList))
      }
      if(nodeInterfaceList && nodeInterfaceList.length){
        state.zhiform = JSON.parse(JSON.stringify(nodeInterfaceList))
      }
      state.nodeTypeCode=nodeData.value
      state.nodeTypeType=nodeData.type
      state.visible = true
    }
    const options = ref([])
    const optionsTwo = ref([])
    const uploadFileTypeList = ref([])

    onBeforeMount(() => {
      getDictionaryData(options, 'is_forced_return')
      getDictionaryData(optionsTwo, 'interface_position')
      getDictionaryData(uploadFileTypeList, 'upload_file_type')

    })

// 增加一行
    let id = 1
    const add = () => {
      id++
      if (state.zhiform.length === 0) {
        state.zhiform.push({
          id: 0,
          intrefaceTypeId:null,
          uploadFileType: null,
          isForcedReturn:null,
          interfacePosition:'2',
          sort:''
        })
      } else {
        state.zhiform.push({
          id,
          intrefaceTypeId:null,
          uploadFileType: null,
          isForcedReturn:null,
          interfacePosition:'2',
          sort:''
        })
      }
    }
    const onSelectChanges2 = selectedRowKeys => {
      console.log('selectedRowKeys changed: ', selectedRowKeys)
      state.selectedRowKeys2 = selectedRowKeys
    }
    const del = () => {
      if (!state.selectedRowKeys2.length) message.warning('请选择数据')
      state.selectedRowKeys2.forEach((item) => {
        state.zhiform.forEach((item1, index) => {
          if (item1.id == item) {
            state.zhiform.splice(index, 1)
          }
        })
      })
    }


    const onClose = () => {
      state.dragModuleList = []
      state.moduleName = ''
      showData.isShowDrag = false
      state.visible = false
      state.selectedRowKeys2 = []
      state.zhiform = [{
        id: 0,
        intrefaceTypeId: null,
        uploadFileType: null,
        isForcedReturn: null,
        interfacePosition: '2',
        sort: ''
      }]
    }

    const haveKey = (id) => {
      let arr = state.intrefaceTypeIdList.filter(item => item.id === id)
      if (arr && arr.length > 0) {
        return inCodes(arr[0].code)
      }
      return false
    }
    let codes = ['uploadImage', 'getElectronicProposal']
    const inCodes = (code) => {
      for (let i = 0; i < codes.length; i++) {
        if (code === codes[i]) {
          return true
        }
      }
      return false
    }

    const getMethodDescription = (id) => {
      if (id) {
        return state.intrefaceTypeIdList.find(item => item.id === id).methodDescription || ''
      }
      return ''
    }

    const filterOption = (input, option) => {
      return option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
    }

    return {
      haveKey,
      filterOption,
      getMethodDescription,
      ...toRefs(state),
      ...toRefs(showData),
      showDrawer,
      add,
      onSelectChanges2,
      onClose,
      del,
      options,
      optionsTwo,
      uploadFileTypeList,
      huoqu,
      handelAdd,
      getNodeProcess

    }
  }


})
</script>

<style scoped>
.m_header{
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
  margin: 10px 50px;
}
.m_previewPage{
  width: 45%;
}
.m_moduleList{
  width: 45%;
}
.center_box{
  text-align: center;
}
.continer{
  /* min-width: 375px; */
  position: relative;
  height: 517px;
  padding: 17px;
  background: #f2f2f2;
  overflow:hidden;
  overflow-y: auto;
}
.del-class{
  float: left;
  width: 35px;
  cursor: pointer;
}
.module_detial{
  border: 1px solid #999;
  padding: 10px;
  margin-bottom: 10px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.module_span{
  color: rgb(189, 188, 189);
  font-size: 13px;
  margin-left: 8px;
}
.tree_drag{
  position: fixed;
  border: 1px solid #999;
  min-width: 300px;
  height: 30px;
  text-align: center;
  line-height: 30px;
  z-index: 99999;
}
.description_class {
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
}
</style>
