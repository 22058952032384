<template>
  <div>
    <uploadFileToCos class="uploadStyle" :max-num="3"></uploadFileToCos>
    <h3>D表示天，M表示月，Y表示年</h3>
    <h3>60天~16周岁, T+1生效：</h3>
    <div>生效：{{result1.T}}最早可选时间：{{result1.earliestDate}}，最晚可选时间：{{result1.latestDate}}</div>
    <h3>18岁~30周岁，T+5 生效：</h3>
    <div>生效：{{result2.T}}最早可选时间：{{result2.earliestDate}}，最晚可选时间：{{result2.latestDate}}</div>
    <h3>7天~100月，投保当天生效：</h3>
    <div>生效：{{result3.T}}最早可选时间：{{result3.earliestDate}}，最晚可选时间：{{result3.latestDate}}</div>
  </div>

</template>

<script>
import {defineAsyncComponent, onMounted, reactive, toRefs} from 'vue'
import {showDataMin} from '../../utils/utlis'

export default {
  name: 'testPage',
  components: {
    uploadFileToCos: defineAsyncComponent(() => import('../../components/extended/uploadFileToCos.vue')),
  },
  setup() {
    const state = reactive({
      result1: {},
      result2: {},
      result3: {}
    })
    onMounted(()=> {
      state.result1 = showDataMin(60,'D',16,'Y',1)
      state.result2 = showDataMin(18,'Y',30,'Y',5)
      state.result3 = showDataMin(7,'D',100,'M',0)
    })
    return {
      ...toRefs(state)
    }
  }
}
</script>

<style scoped>
.uploadStyle{
  width: 300px;
  height: 300px;
}
</style>
