<template>
  <div class="attach_class">
    <div class="fileUrl_class">
      <a style="padding-right: 10px; color: #00a0e9;" :href="fileUrl">下载模板</a>
      <div  v-if="showPassBack === 'Y'" style=" width: auto; max-width: 80px;">
        <upload-file-button :filetype="'excel'" :showUploadList="false" @fileChange="handleChange"></upload-file-button>
      </div>
    </div>
  </div >

</template>

<script>
  import {defineComponent, onMounted, reactive, toRefs} from 'vue'
  import uploadFileButton from '../../../../components/extended/uploadFileButton.vue'
  import {request} from '../../../../utils/request'
  import {message} from 'ant-design-vue'
  import store from '@/store'
  import {getExtendUri} from '@/utils/request_extend'
  export default defineComponent({
    name: 'AttachView',
    props: {
      firstValue:{
        type: [String,Number],
        default:()=> ''
      } ,
      fileUrl: {
          type: String,
           default: () => ''
      },
      showPassBack: {
        type: String,
        default:()=> ''
      },
      elementCode: {
        type: [Number,String],
        default: () => ''
      },
      moduleId:{ // 模块ID
        type: [Number,String],
        default:()=> ''
      },
      elementName:{
        type: String,
        default:()=> ''
      },
      moduleIndex:{
        type: [Number,String],
        default:()=> '0'
      },
    },
    components: {
      uploadFileButton,
    },
    emits:['callback','update:firstValue'],
    setup(props,{emit}) {
      const state = reactive({
        isShowAble: false,
        isUpload: props.showPassBack === 'Y'? true: false,
        showPassBack: props.showPassBack,
        elementCode: props.elementCode,
        moduleType: props.moduleId,
        elementName: props.elementName
      })
      const data = reactive({
        urlName: '',
        isUpdate: false,
        fileUrl: ''
      })
      const downloadModule = () => {
        console.log('点击下载内容',)
      }
      const handleChange =(info) => {
      // 上传文件
        data.fileUrl = info.url
        getInsuredNum(data.fileUrl)
      }
      const getInsuredNum = (fileUrl) => {
        let riskInfo = JSON.parse(store.getters['insured/getRiskList'])
        let data = {
          productId: riskInfo.productId,
          riskList: riskInfo.riskList,
          excelUrl:fileUrl
        }
        request(getExtendUri('element_fileUpload'), {data}).then(res => {
          if (res.code === 1) {
            let node = {}
            node[state.elementCode] ={
              [state.elementCode] : fileUrl,
              showPassBack: props.showPassBack,
              countInsured: res.result.countInsured,
              countPremium: res.result.countPremium,
              index:props.moduleIndex,
              moduleType: state.moduleType,
              elementName: state.elementName
            }
            emit('callback', node)
            emit('update:firstValue', fileUrl)
          }else {
            message.warning(res.message)
          }
        })


      }
      onMounted(()=>{
        state.showImg = []
        if(props.firstValue){
          getInsuredNum(props.firstValue)
        }
      })
      return {
        ...toRefs(state),
        data,
        downloadModule,
        handleChange,
      }
    }
  })
</script>

<style scoped>
.attach_class{
  height: 100%;
}
.fileUrl_class{
  display: flex;
  justify-content: flex-end;
}

</style>
