<template>
  <a-form v-bind="formItemLayout"  :model="nodeDetail" ref="nodeRef">
    <a-form-item label="节点ID" name="nodeId">
      <p class="marginTop">{{nodeDetail.nodeId}}</p>
    </a-form-item>
    <a-form-item label="节点名称" name="nodeName">
      <p class="marginTop">{{nodeDetail.nodeName}}</p>
    </a-form-item>
    <a-form-item label="所属流程" name="nodeToPro">
      <p class="marginTop">{{nodeDetail.nodeToPro}}</p>
    </a-form-item>
    <a-form-item label="界面样式" name="pageStyleId">
      <a-radio-group v-model:value="nodeDetail.pageStyleId" @change="handelRadio">
        <a-radio value="1">弹窗</a-radio>
        <a-radio value="2">页面</a-radio>
<!--        <a-radio value="3">第三方</a-radio>-->
      </a-radio-group>
    </a-form-item>
    <a-form-item v-if="nodeDetail.nodeId === 'informationEntry'" name="maximumLimit" :rules="[{pattern:/^[0-9]/, message: '支持数字' ,trigger: 'change'}]">
      <template #label>
        <span>被保人最大限制</span>
        <span>
        <a-popover placement="right">
          <template #content>
          <p>
            在多被保人情况下，设置被保人的上限数量
          </p>
          </template>
          <template #title>
          <span>规则说明:</span>
          </template>
          <question-circle-outlined />
        </a-popover>
        </span>
      </template>
      <a-input v-model:value="nodeDetail.maximumLimit" />
    </a-form-item>
    <a-form-item name="bottomTemplateConfig" v-if="(nodeDetail.nodeId === 'healthNotification' && nodeDetail.pageStyleId !=='3') || nodeDetail.nodeId === 'informationEntry'">
      <template #label>
        <span>底部栏模板配置</span>
        <span>
            <a-popover placement="right">
              <template #content>
              <p>
                此配置只对健康告知及告知问卷生效
                <br>1. 当选择“通用”时，健康告知下方是通用的选项配置
                <br>2. 当选择“文本型”时会联动出“底部标题配置”和“底部按钮配置”，底部标题配置用来配置底部的标题，底部按钮配置用来配置标题下面的按钮，当保司要求与通用型不一样时，此时可以使用此项配置来满足保司的要求
              </p>
              </template>
              <template #title>
              <span>规则说明:</span>
              </template>
              <question-circle-outlined />
            </a-popover>
            </span>
      </template>
      <a-radio-group v-model:value="nodeDetail.bottomTemplateConfig">
        <a-radio value="1" @click="handelCurrent">通用</a-radio>
        <a-radio value="2">文本型</a-radio>
      </a-radio-group>
    </a-form-item>
<!--    <a-form-item label="强制阅读" name="isForceRead">-->
<!--      <a-radio-group v-model:value="nodeDetail.isForceRead">-->
<!--        <a-radio :value="1">是</a-radio>-->
<!--        <a-radio :value="0">否</a-radio>-->
<!--      </a-radio-group>-->
<!--    </a-form-item>-->
<!--    <a-form-item label="配置到当前节点" name="isCurrentNode">-->
<!--      <a-radio-group v-model:value="nodeDetail.isCurrentNode">-->
<!--        <a-radio :value="1">是</a-radio>-->
<!--        <a-radio :value="0">否</a-radio>-->
<!--      </a-radio-group>-->
<!--    </a-form-item>-->
    <!-- underwriting -->
<!--    <a-form-item label="是否核保" name="isUnderwriting">-->
<!--      <a-radio-group v-model:value="nodeDetail.isUnderwriting">-->
<!--        <a-radio value="1">是</a-radio>-->
<!--        <a-radio value="2">否</a-radio>-->
<!--      </a-radio-group>-->
<!--    </a-form-item>-->
    <!-- informationEntry -->


    <a-form-item label="条款类型列表" name="clauseTypeList" v-if="false">
      <a-checkbox-group v-model:value="nodeDetail.clauseTypeList" @change="changeChecked">
        <a-checkbox :value="op.value*1" v-for="op in options" :key="op.value">{{op.label}}</a-checkbox>
      </a-checkbox-group>
    </a-form-item>


    <template v-if="nodeDetail.bottomTemplateConfig=== '2'">
      <a-form-item label="底部标题配置" name="bottomTitleConfig">
        <wang-editor :contentHtml="nodeDetail.bottomTitleConfig" v-on:getWangEditorValue="getWangEditorValue"></wang-editor>
      </a-form-item>
      <a-form-item >
        <template #label>
          <span>底部按钮配置</span>
          <span>
            <a-popover placement="right">
              <template #content>
              <p>
                可以自定义按钮名称，按钮的标识从1开始递增。可通过关联选择该按钮的下一步功能
              </p>
              </template>
              <template #title>
              <span>规则说明:</span>
              </template>
              <question-circle-outlined />
            </a-popover>
            </span>
        </template>
        <div style="color: rgb(184, 180, 180);margin-top: 5px" v-if="nodeDetail.buttonConfigList && nodeDetail.buttonConfigList.length==0 ">暂无数据</div>
        <div v-for='(item,index) in nodeDetail.buttonConfigList' :key="index"
             style="margin-bottom:5px; margin-top: 5px">
          <a-row>
            <a-col :span="2">
              <p>{{getSerialNumber(index)}}.</p>
            </a-col>
            <a-col :span="16" v-if="item.optionState === '1'">
              <span >{{item.priInput}}</span>
            </a-col>
            <a-col :span="16" v-if="item.optionState === '2'">
              <a-input v-model:value="item.priInput" @blur="handelCheckSpan"></a-input>
            </a-col>
            <a-col :span="2" >
              <a-button size="small"
                        type="primary"
                        @click="showRelevanceModel(item)"
                        v-if="item.optionState === '1'">关联</a-button>
            </a-col >
            <a-modal v-if="item.optionState === '1'" v-model:visible="item.visible" title="选择关联关系" @ok="handleOk(item)" @cancel="modalClose(item)">
              <a-radio-group v-model:value="item.modelValue">
                <a-radio :value="health.value" v-for="health in healthTypeList" :key="health.value">{{ health.label }}</a-radio>
<!--                <a-radio value="2">智能核保</a-radio>-->
<!--                -->
<!--                <a-radio value="3">校验</a-radio>-->
<!--                <a-radio value="4">校验/智能核保</a-radio>-->
<!--                <a-radio value="99">下一步</a-radio>-->
              </a-radio-group>
              <a-radio-group style="margin-top: 10px" v-model:value="item.mainIdentification" v-if="item.modelValue === '99' || item.modelValue === '3'">
                <div class="tishi">以下选择决定流程下一步和校验时, 告知项是否与选择的一样才能通过</div>
                <a-radio value="0">全部为是</a-radio>
                <a-radio value="1">全部为否</a-radio>
              </a-radio-group>
              <div v-if="item.modelValue === '2'">
                <div class="tishi">以下输入框是弹框提示语</div>
                <a-input v-model:value="item.promptValue" placeholder="请输入"></a-input>
              </div>
            </a-modal>
          </a-row>

        </div>
        <div style="margin-top: 15px">
          <a-button size="small" @click="addOption" >+ 增加</a-button>
          <a-button size="small" style="margin-left: 10px" @click="delOption">- 删除</a-button>
        </div>
      </a-form-item>

    </template>

    <a-form-item style="margin-top:30px">
      <div class="m_button">
        <a-button class="m_box" @click="onClose">取消</a-button>
        <a-button class="m_box" type="primary" style="margin-left: 50px" @click="handelEdit">编辑页面</a-button>
        <a-button class="m_box" type="primary" style="margin-left: 50px" @click="handelProcess">添加调用流程</a-button>
        <a-button class="m_box" type="primary" style="margin-left: 60px" @click="handelAdd">保存</a-button>
      </div>
    </a-form-item>
  </a-form>
  <node-config-edit ref="nodeEditRef"/>
  <association-template ref="associationRef" @templateData="handelRelation"/>
  <process-setting ref="processRef"></process-setting>
</template>

<script>
import {defineComponent, onMounted, reactive, ref, toRefs,provide} from 'vue'
import NodeConfigEdit from './nodeConfigEdit.vue'
import AssociationTemplate from './associationTemplate.vue'
import ProcessSetting from './processSetting.vue'
import { message } from 'ant-design-vue'
import { getRequestURI, request } from '../../../../utils/request'
import WangEditor from '../../../../components/extended/MyEditor.vue'
import {getDictionaryData} from '@/utils/request_extend'
export default defineComponent({
  components:{
    NodeConfigEdit,
    AssociationTemplate,
    WangEditor,
    ProcessSetting
  },
  props:{
    nodeData:{
      type: Object,
      default: () => {}
    },
    proName:{
      type: String,
      default: () => ''
    },
    closeModal:{
      type: Function,
      default: () => {}
    }
  },
  emits:['nodeDetailData'],
  setup(props,context){

    const state = reactive({
      options:[], //
      modelValue: '',
      mainIdentification: '',
      promptValue: '',
    })

    const nodeDetail = reactive({
      nodeId:'',
      nodeName:'',
      nodeToPro: '', // 所属流程
      pageStyleId: '2', // 界面样式
      pageStyleName: '', // 界面样式
      associatedTemplateId:null,  //关联模板ID
      associatedTemplateName: '未关联', // 关联模板
      dragModuleList:[], // 编辑信息的数据
      nodeInterfaceList:[],
      // isForceRead: 1, // 是否强制阅读
      // isCurrentNode: 1, // 是否配置到当前节点
      // isUnderwriting: '2', // 是否核保
      clauseTypeList: [], //条款类型列表
      identification:'',
      bottomTemplateConfig: '1',// 底部模板配置
      buttonConfigList: [],
      bottomTitleConfig: '',// 底部标题配置
      isNotification: 0,
      notificationList:[], // 关联的告知
      selectedRowList:[],
      maximumLimit: '1', // 被保人数量最大限制
    })

    const formItemLayout = {
      labelCol: {
        span: 6,
      },
      wrapperCol: {
        span: 16,
      },
    }

    const nodeRef = ref()

    const onClose = () =>{// 取消
      nodeDetail.clauseTypeList = []
      nodeRef.value.resetFields()
      props.closeModal(false)
    }
    const nodeEditRef = ref()

    // 编辑页面
    const handelEdit = () =>{
      if(nodeDetail.pageStyleId === '3'){
        return message.warning('三方链接仅通过api调用,不支持页面编辑操作')
      }
      nodeEditRef.value.showDrawer(nodeDetail.dragModuleList)
    }
    const processRef=ref()
    const handelProcess = () =>{
      processRef.value.showDrawer(props.nodeData,nodeDetail.nodeInterfaceList)
    }
    const getWangEditorValue = (str) => {
      nodeDetail.bottomTitleConfig = str
    }


    // 页面样式的选择
    const handelRadio =(e) =>{
      if(e.target.value === '1'){
        nodeDetail.pageStyleName = '弹窗'
      }else if(e.target.value === '2'){
        nodeDetail.pageStyleName = '页面'
      }else if(e.target.value === '3'){
        nodeDetail.pageStyleName = '第三方'
      }
    }

    // 保存
    const handelAdd = () =>{
      // 将数据传过去
      if(!nodeDetail.bottomTitleConfig && nodeDetail.bottomTemplateConfig === '0') {
        message.info('请输入底部标题配置')
        return
      }
      console.log(nodeDetail)
      context.emit('nodeDetailData',nodeDetail)

      props.closeModal(false)
    }
    const associationRef = ref()
    const notificationRef = ref()

    // 关联模板
    const handelAssociation = () =>{
      let {associatedTemplateId,associatedTemplateName} = nodeDetail
      associationRef.value.showDrawer(associatedTemplateId,associatedTemplateName)
    }
    // 关联模板的具体信息
    const handelRelation = (value)=>{
      nodeDetail.associatedTemplateId = value.associatedTemplateId ? value.associatedTemplateId : null
      nodeDetail.associatedTemplateName = value.associatedTemplateName ? value.associatedTemplateName : '未关联'
    }

    //获取 编辑页面的数据
    const getNodeEdit = (value) =>{
      value.forEach(item =>{
        item.productElementList.forEach(pro=>{
            pro.forEach(ele =>{
              ele.firstValue = ''
            })
        })
      })
      nodeDetail.dragModuleList = value
    }
    provide('getNodeEdit',getNodeEdit)
    const getNodeProcess = (value) =>{
      nodeDetail.nodeInterfaceList = value
    }
    provide('getNodeProcess',getNodeProcess)
    const setClauseTypeList = (list) =>{
      let arr = []
      if(list.length){
        list.forEach(item =>{
          arr.push(item*1)
        })
      }
      return arr
    }

    const getSerialNumber = (index) => {
      return String.fromCharCode(65 + index)
    }
    // 失去焦点
    const handelCheckSpan = (event) => {
      if(event.target.value.trim() === ''){
        return message.warning('请输入配置选项内容')
      } else {
        let lastNode = nodeDetail.buttonConfigList[nodeDetail.buttonConfigList.length - 1]
        lastNode.optionState = '1'
        // lastNode.visible = false
      }
    }
    // 增加选项
    const addOption = () => {
      console.debug('dfsdafsadfsd', state, nodeDetail.buttonConfigList)
      if (nodeDetail.buttonConfigList.length > 0) {
        let lastNode = nodeDetail.buttonConfigList[nodeDetail.buttonConfigList.length - 1]
        if (lastNode.optionState === '2') {
          return message.warning('请先填写配置项')
        }
      }
      let inNode = {
        optionState: '2',
        priInput: undefined,
        visible:  false ,
        modelValue: '',
        mainIdentification: '',
        promptValue: ''
      }
      nodeDetail.buttonConfigList.push(inNode)

    }
    // 删除选项
    const delOption = () => {
      if (nodeDetail.buttonConfigList.length > 0) {
        let lastNode = nodeDetail.buttonConfigList[nodeDetail.buttonConfigList.length - 1]
        if (lastNode.optionState === '1') {
          nodeDetail.buttonConfigList.pop()
          return
        }
      }
      message.warning('请先填写配置项')
    }
    // 显示关联按钮
    const showRelevanceModel = (item) => {
      item.visible = true
    }
    // 点击显示框保存
    const handleOk = (item) => {
      item.visible = false
    }
    const modalClose = (item) => {
      item.visible = false
    }

    // const nodeDataObj=ref({})
    onMounted(()=>{

      let configList = []
      if(props.nodeData.buttonConfigList){
          configList = props.nodeData.buttonConfigList.map(item => {
          return {
            ...item,
            visible: JSON.parse(item.visible)
          }
        })
      }
      Object.assign(nodeDetail,{
        maximumLimit: props.nodeData.maximumLimit ?props.nodeData.maximumLimit : '1' ,
        id: props.nodeData.id ? props.nodeData.id : '',
        nodeId: props.nodeData.value ? props.nodeData.value:props.nodeData.code,
        nodeName:props.nodeData.label ? props.nodeData.label : props.nodeData.name,
        nodeToPro: props.proName,
        identification: props.nodeData.identification ? props.nodeData.identification: '',
        pageStyleId: props.nodeData.pageStyleId ? props.nodeData.pageStyleId : '2', // 界面样式
        pageStyleName: props.nodeData.pageStyleName ? props.nodeData.pageStyleName: '页面', // 界面样式
        bottomTemplateConfig:  props.nodeData.bottomTemplateConfig?  props.nodeData.bottomTemplateConfig:  props.nodeData.code=== 'healthNotification'? '1' : props.nodeData.bottomTemplateConfig,
        bottomTitleConfig: props.nodeData.bottomTitleConfig,
        buttonConfigList: props.nodeData.buttonConfigList? configList : [] ,
        associatedTemplateId:props.nodeData.associatedTemplateId ? props.nodeData.associatedTemplateId: null,  //关联模板ID
        associatedTemplateName: props.nodeData.associatedTemplateName ? props.nodeData.associatedTemplateName: '未关联', // 关联模板
        dragModuleList:props.nodeData.dragModuleList ? props.nodeData.dragModuleList : (props.nodeData.pageJson ? JSON.parse(props.nodeData.pageJson) : []), // 编辑信息的数据
        nodeInterfaceList:props.nodeData.nodeInterfaceList ? props.nodeData.nodeInterfaceList : (props.nodeData.pageJson ? JSON.parse(props.nodeData.pageJson) : []), // 编辑信息的数据
        // isForceRead: props.nodeData.isForceRead == 0 ? props.nodeData.isForceRead :  1, // 是否强制阅读
        isNotification: props.nodeData.isNotification ? props.nodeData.isNotification :  0,
        // isCurrentNode: props.nodeData.isCurrentNode == 0 ? props.nodeData.isCurrentNode :  1, // 是否配置到当前节点
        // isUnderwriting: props.nodeData.isUnderwriting ? props.nodeData.isUnderwriting :  props.nodeData.relationProcessId ==='acknowledgementOrder'? '1': '2', // 是否核保
        notificationList:props.nodeData.notificationList ? props.nodeData.notificationList : [],
        selectedRowList:props.nodeData.selectedRowList ? props.nodeData.selectedRowList : [],
        // clauseTypeList: props.nodeData.dataList && props.nodeData.dataList.length && props.nodeData.dataList[1].dataType ? setClauseTypeList(props.nodeData.dataList) : props.nodeData.dataList
        clauseTypeList: props.nodeData.clauseTypeList && props.nodeData.clauseTypeList.length ? setClauseTypeList(props.nodeData.clauseTypeList) : []
      })
    })
    const healthTypeList = ref()
    // 获取条款类型
    const getClauseTypeList = () =>{
      request(getRequestURI('productFile_dataTypeList',{})).then(
        res =>{
          // console.debug('获取条款类型',res)
          state.options = res.result && res.result.filter(item => {
            return item.label !== null
          })
        //  state.options = res.result
        }
      )
      getDictionaryData(healthTypeList, 'health-next-type')
    }
    getClauseTypeList()

    // 条款类型列表 选择
    const changeChecked = (list) =>{
      nodeDetail.clauseTypeList = list
    }
    // 通用
    const handelCurrent = () =>{
      nodeDetail.bottomTitleConfig = ''
      nodeDetail.buttonConfigList = []
    }
    return {
      handelCurrent,
      ...toRefs(state),
      onClose,
      handelAdd,
      handelEdit,
      handelAssociation,
      handelRelation,
      handelRadio,
      healthTypeList,
      associationRef,
      notificationRef,
      nodeRef,
      nodeEditRef,
      nodeDetail,
      formItemLayout,
      changeChecked,
      getClauseTypeList,
      getWangEditorValue,
      getSerialNumber,
      handelCheckSpan,
      addOption,
      delOption,
      showRelevanceModel,
      handleOk,
      modalClose,
      handelProcess,
      processRef,
      // nodeDataObj
    }
  }


})
</script>

<style scoped>
  .marginTop {
    margin-top: 5px
  }
  .m_button{
    width: 100%;
    display: flex;
    padding-right: 10px;
    justify-content: space-between;
    /*padding-left: 10px;*/
  }
  .m_button :deep(.ant-btn){
    margin-right: -8px;
  }
  :deep(.ant-checkbox-wrapper){
    width: 100%;
  }
  :deep(.ant-checkbox-group > :first-child) {
    margin-left: 8px;
  }
  .tishi{
    font-size: 12px;
    height: 30px;
    margin-left: 10px;
    margin-top: 10px;
    font-weight: bold;
  }
</style>
