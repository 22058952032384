<template>
  <a-drawer
      :title="title"
      :width="'100%'"
      :visible="showModule"
      :body-style="{ paddingBottom: '80px' }"
      :footer-style="{ textAlign: 'right' }"
      @close="onClose"
    >
    <div class="model-header" ref="dragLayoutRef">
      <div class="left">
        <a-radio-group v-model:value="data.groupValue">
          <a-radio @change="onRadio" :style="radioStyle" :value="group.value" v-for="group in groupingModule" :key="group.value">{{group.label}}</a-radio>
        </a-radio-group>
      </div>
      <div class="center">
          <a-form>
            <a-form-item required>
              <template #label>
                <span>模块名称: </span>
                <span>
                <a-popover placement="right">
                  <template #content>
                  <p>
                    用于模块的标题展示
                      <br>1. 被保人模块名称一般为“被保人信息”；
                      <br>2. 投保人模块名称一般为“投保人信息”；
                      <br>3. 受益人模块名称一般为“受益人信息”；
                      <br>4. 健康告知模块名称一般为“被保人健康告知”；
                      <br>5. 被监护人信息模块名称一般为“被监护人信息”；
                      <br>6. 保险信息模块名称一般为“保险信息”；
                  </p>
                  </template>
                  <template #title>
                  <span>规则说明:</span>
                  </template>
                  <question-circle-outlined />
                </a-popover>
                </span>
              </template>
              <a-input v-model:value="modGroupName" />
            </a-form-item>
          </a-form>
          <div>
              <p style="width: 100%; text-align: center;font-size:20px">{{modularName}}</p>
              <div class="module-detail" style="padding: 20px;" :class="{height437: data.groupValue === 'healthNotification'}">
                  <PreviewPop :isModuleDel="true" @handelDelElement="handelDelElement" draggable :previewData="modList" :isModel="false"></PreviewPop>
              </div>
         </div>
        <div class="association_class" v-if="data.groupValue === 'healthNotification'">
          <a-row>
            <a-col :span="7">健康告知关联类型:</a-col>
            <a-col :span="14">
              <a-checkbox-group v-model:value="associationType">
                <a-row>
                  <a-col :span="8">
                    <a-checkbox value="2">投保人</a-checkbox>
                  </a-col>
                  <a-col :span="8">
                    <a-checkbox value="1">被保人</a-checkbox>
                  </a-col>
                  <a-col :span="8">
                    <a-checkbox value="3">代理人</a-checkbox>
                  </a-col>
                </a-row>
              </a-checkbox-group>
            </a-col>
          </a-row>
        </div>
        <div class="footer-but">
          <a-button @click="onClose">
            <template #icon>
              <CloseOutlined />
            </template>取消
          </a-button>
          <a-button @click="onReact">
            <template #icon>
              <ReloadOutlined />
            </template>重置
          </a-button>
          <a-button type="primary" @click="addElement">
            <template #icon>
              <SaveOutlined/>
            </template>保存
          </a-button>
        </div>
      </div>
      <div class="right">
        <a-form>
            <a-form-item label="标签:">
              <a-input placeholder="当前模块用于某个产品的说明" v-model:value="labelDescription" />
            </a-form-item>
          </a-form>
        <p style="width: 100%; text-align: center;font-size:20px">元素列表</p>
        <div class="module-detail" >
           <a-input-search
              v-model:value="elementName"
              placeholder="请输入搜索名称"
              @search="onSearch"
              style="margin-bottom: 20px"
          />
          <a-modal  v-model:visible="visible" title="添加提示" @ok="handleOk" @cancel="handleOff">
            <div>
              <p>即将添加{{modal_name}}到投保组件,是否确认?</p>
              <a-form :model="data">
                <a-form-item label="是否为必填项">
                  <a-radio-group v-model:value="data.modalValue">
                    <a-radio value="1">必填</a-radio>
                    <a-radio value="0">非必填</a-radio>
                  </a-radio-group>
                </a-form-item>
              </a-form>
            </div>
          </a-modal>
          <a-modal v-model:visible="modVis" title="归属模块变更提示" @ok="handleModOk" @cancel="handleModOff">
            <div>
              <p>切换归属模块，是否需要清空当前模块配置的数据?</p>
            </div>
          </a-modal>
          <div @click="handelStatus(ele)" style="margin: 5px 0" v-for="ele in elementList" :draggable="true"
               @dragstart.stop="onDagstart($event, ele)" :key="ele.id">
            <div>
              <a-card class="cardStyle">
                <p><strong>名称</strong>：{{ ele.elementName }} </p>
                <p><strong>类型</strong>：{{ ele.elementType }} </p>
                <p><strong>标题</strong>：{{ ele.elementTitle }} </p>
                <p><strong>编码</strong>：{{ ele.elementCode }} </p>
              </a-card>
            </div>
          </div>
          <div class="tree_drag" ref="dragTree" v-show="isShowDrag"></div>
        </div>
        <div class="m_pagination">
          <a-pagination v-model:current="pagination.current" :total="pagination.total" show-less-items @change="handelPage"/>
        </div>

      </div>
    </div>
    <template #extra>
      <a-space>
        <a-button @click="onClose">
          <template #icon>
            <CloseOutlined/>
          </template>
          关闭
        </a-button>
      </a-space>
    </template>
  </a-drawer>
</template>

<script>
import {message} from 'ant-design-vue'
import {defineComponent, nextTick, onMounted, reactive, ref, toRefs,} from 'vue'
import {getRequestURI, request} from '../../../utils/request'
import {getExtendUri} from '../../../utils/request_extend'
import PreviewPop from '../../../components/extended/PreviewPop'
import store from '../../../store'

export default defineComponent({
  components: {
    PreviewPop,
  },
  props: {
    handleSearch: {
      type: Function
    },
    searchfFilters: {type: Object},
    current: {type: Number}

  },
  setup(props) {
    const state = reactive({
      previewData: {},
      associationType: [],
      statusType: false,
      labelDescription: '', // 模块说明
      dragIdList: [], ///筛选拖拽的所有ID,
      dictionaryList: [], // 关联字典
      modeInputValue: '',
      addList: [],
      title: '新增模块配置',
      eData: {},
      isShowDrag: false,
      visible: false,
      treeData: [],
      modal_name: '',
      formValue: '1',
      modGroupName: '',
      modularName:'责任模块',
      formState: [],
      fromObj: {},
      parentId:'',
      isShow: false,
      selectedKeys: [],
      id: 0,
      // 元素名称
      elementName: '',
      showModule: false,
      elementList: [], // 元素列表
      isUpdate: false,
      updateId: '',
      modList: {},
      pagination: {
        pageSize: 10,
        current: 1,
        total: 0,
      },
      modVis: false
    })
    // 收集数据
    const data = reactive({
      modalValue:'1', // 弹窗数据
      groupValue: 'insureds'

    })
    const groupingModule = reactive([])

    const radioStyle = reactive({
      display: 'flex',
      height: '50px',
      lineHeight: '30px',
    })
    // 点击元素列表 打开对话框
    const onOpenAdd = () =>{
      // console.log(ele_List)
      // let list = ele_List
      // state.treeData = getTreeData(list)
    }
    // 判断元素状态
    const handelStatus = (ele) =>{
      return ele.elementState == 1
    }
    const onDagstart = (e, eleData) =>{
      const statusType = handelStatus(eleData)
      if(!statusType){
        e.stopPropagation()
        e.stopImmediatePropagation()
        e.preventDefault()
        state.isShowDrag = false
        return message.warning('当前元素状态未启用')
      }else{
        state.fromObj= {}
        getMousedown(e,eleData)
      }
    }

    const dragTree = ref()
    const dragLayoutRef = ref()
    const dragState = reactive({
      dragTarget: null
    })
    const getMousedown = (e, eleData) =>{
      state.fromObj = eleData
      state.isShowDrag = true
      dragState.dragTarget = e.currentTarget
      dragTree.value.innerHTML= e.target.innerHTML
      dragTree.value.style.left = e.clientX + 'px'
      dragTree.value.style.top = e.clientY + 'px'
      dragState.dragTarget.classList.add('drag')
      nextTick(()=> {
        dragLayoutRef.value.addEventListener('mouseup', stopDrag)
        dragLayoutRef.value.addEventListener('mousemove', drag)

      })
    }
    const drag = (event) => {
      if (event.button === 0 && state.isShowDrag === true) {
        event.preventDefault() // 阻止默认行为
        dragTree.value.style.left = (event.clientX + 12) + 'px'
        dragTree.value.style.top = (event.clientY + 15) + 'px'
      }
    }

    const stopDrag = () =>{
      dragState.dragTarget.classList.remove('drag')
      dragLayoutRef.value.removeEventListener('mouseup', stopDrag)
      dragLayoutRef.value.removeEventListener('mousemove', drag)
      state.isShowDrag = false
      state.visible = true

    }
    const screenID = (data) =>{
      if(state.dragIdList.includes(data.id)){
        throw message.warning('已经有这个数据了, 不能重复添加')
      }
      if(data){
        state.dragIdList.push(data.id)
        //元素id唯一不需要进行深层判断
        // if(data.elementOperatingMode.mode_health.conSpanList.length > 0){
        //   data.elementOperatingMode.mode_health.conSpanList.forEach(item =>{
        //     if(item.elementList.length > 0){
        //       item.elementList.forEach(ele =>{
        //         screenID(ele)  // 去找深层的id
        //       })
        //     }
        //   })
        // }
      }
    }




    const productList = []
    const handleOk = () => {
      state.addList = []
      state.visible = false
      try {
        screenID(state.fromObj)
      } catch (error) {
        return
      }
      // 健康告知
      // if(data.groupValue === 'healthNotification'){
      //
      // }
      state.fromObj['required'] = data.modalValue
      state.fromObj['moduleId'] = data.groupValue

      let arr = []
      arr.push(state.fromObj)
      productList.push(arr)
      if(state.modList.productElementList && state.modList.productElementList.length){
        console.log(state.modList.productElementList)
        state.modList.productElementList.push(arr)
      } else{
        Object.assign(state.modList, {productElementList: [...productList]})
      }
      data.modalValue = '1'

    }
    const handleOff = () =>{
      state.isShow = false
      state.id = undefined
      state.selectedKeys = []
    }


    const onSearch = () =>{
      state.pagination.current = 1
      getElementList()
    }
    const onRadio = (e) => {
      data.groupValue = e.target.value
      if (state.modList && state.modList.productElementList) {
        state.modVis = true
      } else {
        handleModOk()
      }
    }
    const showDrawer = (oldData={}, num) => {
      console.log('编辑模块配置',oldData )
      if(num){
        state.title = '编辑模块配置'
        state.isUpdate = true
        state.eData = oldData
        state.updateId = oldData.id
        state.modularName = oldData.moduleTypeName
        data.groupValue = oldData.moduleType
        state.modGroupName= oldData.moduleName
        state.labelDescription = oldData.labelDescription
        getUpdateDetail(oldData.id)
        if(oldData.associationType){
          state.associationType = [...oldData.associationType]
        }
      } else {
        state.title = '新增模块配置'
      }
      state.showModule = true
      getElementList()
      getDictionaryData()
    }
    // 获取字典模块信息
    const getDictionaryData = () =>{
      request(getExtendUri('dictionary_query_byType'),
      {data: {ids:[],types:['module']}}).then(
        res =>{
          Object.assign(groupingModule, [...res.result.module])
      })
    }
    // 修改时 获取对应的详情数据
    const getUpdateDetail = (id) =>{
      request(getRequestURI('module_moduleDetails'),{id:id}).then(res =>{
        res.result.productElementList.forEach((item,index) => {
          let arr = []
          arr.push(item)
          res.result.productElementList.splice(index, 1,arr)
        })
        Object.assign(state.modList, res.result)

        // if(data.groupValue == 'healthNotification'){
        if(state.modList.productElementList && state.modList.productElementList.length){
          state.modList.productElementList.forEach(item =>{
            item.forEach(node =>{
              screenID(node)
            })
          })
        }
      // }
      })
    }
    const onReact = () =>{
      state.modList = {}
      productList.splice(0)
      state.dragIdList = []
    }
    const onClose = () => {
      state.showModule = false
      state.isUpdate = false
      state.labelDescription = ''
      productList.splice(0)
      state.fromObj = {}
      state.modList = {}
      state.dragIdList = []
      state.elementName = ''
      state.modGroupName = ''
      state.modularName = '责任模块'
      state.associationType = []
      Object.assign(data,{
        modalValue: '1',
        groupValue: 'insureds'
      })
    }
    // 获取元素列表
    const getElementList = () =>{
      // element_elementList
      request(getRequestURI('element_elementList'),
      {
        data:{
          elementName:state.elementName,
          elementMode:'',
          elementState:'',
          dictionaryName:''
        },
        pagination:{
          current: state.pagination.current,
          pageSize: state.pagination.pageSize,
        }
      }).then(res => {
        Object.keys(res.pagination).forEach(item=>{
          state.pagination[item] = res.pagination[item]
        })
        state.elementList = res.result
      })
    }
    const handelPage = (current,pageSize) =>{
      state.pagination.current = current
      state.pagination.pageSize = pageSize
      getElementList()
    }
    // watch(()=> state.elementName,()=>{
    //   state.pagination.current = 1
    // })
    // 获取字典表
    const getTypeData = () => {
      request(getExtendUri('dictionary_query_byType'),
      {data: {ids:[],types:['System']}}).then(
        res =>{
          state.dictionaryList = res.result.System
        })
    }
    // 新增 / 修改
    const addElement = () =>{
      if(!state.modGroupName) return message.warning('请填写模块名称')
      if(!state.modList.productElementList || !state.modList.productElementList.length) return message.warning('请选择元素')
      let arr = []
      state.modList.productElementList.forEach(item =>{
        item.forEach(node =>{
          arr.push({elementId: node.id,required: node.required,moduleType: data.groupValue,moduleId:data.groupValue})
        })
      })
      let modulList = groupingModule.filter(item => {
        return data.groupValue === item.value
      })

      let associationType = state.associationType && state.associationType.length ? state.associationType.join(',') : null
      request(getRequestURI('module_addModule'), {
        id: state.isUpdate ? state.updateId : null,
        moduleName: state.modGroupName,
        moduleType: data.groupValue, // 模块ID
        modulTarget:modulList[0],
        associationType,
        status:1,
        createId:store.getters['user/getUserId'],
        elements: arr,
        labelDescription: state.labelDescription
      }).then(res =>{
        if(res.code === 1) {
          message.success('保存成功')
          onClose()
          props.handleSearch(props.searchfFilters, props.current)
        }
      })
    }
    // 删除逻辑
    const handelDelElement = (data) =>{
      for (let k = 0; k < productList.length; k++) {
        for (let l = 0; l < productList[k].length; l++) {
          if(productList[k][l].id === data.id){
            productList.splice(k, 1)
            break
          }
        }
      }
      for (let m = 0; m < state.dragIdList.length; m++) {
        if(state.dragIdList[m] == data.id){
          state.dragIdList.splice(m,1)
          break
        }
      }
      let {productElementList} = state.modList
      for(let i = 0; i < productElementList.length; i++){
        for (let j = 0; j < productElementList[i].length; j++) {
          if(productElementList[i][j].id === data.id){
            if(productElementList[i].length == 1){
              productElementList.splice(i, 1)
              break
            }else if(productElementList[i].length > 1){
              productElementList[i].splice(j ,1)
              break
            }
          }
        }
      }
    }


    onMounted(() => {
      onOpenAdd()
      getTypeData()

    })


    const previewElement = (ele) => {
      let obj = {}
      let arr = []
      arr.push(ele)
      Object.assign(obj, {productElementList: [arr]})
      return obj
    }
    const handleModOk = () => {
      state.modVis = false
      state.modList = {}
      productList.splice(0)
      state.dragIdList = []
      state.fromObj = {}
      groupingModule.forEach(item => {
        if (item.value === data.groupValue) {
          state.modularName = item.label
        }
      })
    }
    const handleModOff = () => {
      groupingModule.forEach(item => {
        if (item.value === data.groupValue) {
          state.modularName = item.label
        }
      })
    }
    return {
      previewElement,
      getTypeData,
      handelStatus,
      handelDelElement,
      getDictionaryData,
      dragLayoutRef,
      getElementList,
      handelPage,
      dragState,
      dragTree,
      stopDrag,
      drag,
      onReact,
      ...toRefs(state),
      onDagstart,
      addElement,
      onSearch,
      onClose,
      handleOff,
      handleOk,
      onOpenAdd,
      onRadio,
      radioStyle,
      groupingModule,
      data,
      showDrawer,
      handleModOk,
      handleModOff
    }
  }

})

</script>

<style  scoped>
:deep(.ant-card-body) {
  height: auto;
}

.cardStyle {
  padding: 10px;
  width: 100%;
  margin: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}

.model-header {
  display: flex;
  justify-content: space-between;
  margin: 10px 50px;
}
  .left{
    width: 15%
  }
  .center{
    width: 35%;
  }
  .right{
    width: 35%;
  }
  .module-detail{
    padding: 15px;
    position: relative;
    border: 1px solid rgb(186, 186, 186);
    border-radius: 10px;
    height: 500px;
    overflow: auto;
  }
  .m_pagination{
    margin-top: 15px;
    display: flex;
    justify-content: flex-end;
  }


  .footer-but{
    width:100%;
    display: flex;
    justify-content: space-around;
    margin-top: 15px;
  }
  .tree_drag{
    position: fixed;
    width: 100px;
    height: 30px;
    text-align: center;
    line-height: 30px;
    z-index: 99999;
  }
  .association_class{
    width: 100%;
    line-height: 65px;
  }
  .height437{
    height: 437px;
  }


</style>
