<template>
  <a-drawer
    title="流程节点配置页"
    :width="'70%'"
    :visible="visible"
    :body-style="{ paddingBottom: '80px' }"
    :footer-style="{ textAlign: 'right' }"
    @close="onClose"
  >
  <div>
    <h2>当前流程名称: &nbsp;&nbsp;{{nodeName}}</h2>
    <p style="font-size: 12px; font-weight:700">tips: &nbsp;&nbsp;编辑节点</p>
  </div>
  
  <a-table
    :columns="columns"
    :data-source="dataSource"
    :row-key="(record) => record.id"
    :scroll="{ x: 1500 }"
    :pagination="false"
  >
  <a-spin :delay="300"/>
    <template #bodyCell="{ column, index, record }">
      <template v-if="column.key === 'index'">
        节点&nbsp;{{index + 1 }}
      </template>
      <template v-if="column.key === 'operation'">
        <div>
          <a style="color: orange" @click="handelDel(record.id)">删除</a>&nbsp;&nbsp;
          <a style="color: orange" @click="handelEdit(record)">编辑</a>&nbsp;&nbsp;
          <!-- <a style="color: orange" @click="handelAssociation(record)">关联模板</a>&nbsp;&nbsp; -->
          <a style="color: orange" @click="handelType(record)">弹窗状态</a>
        </div>
      </template>
    </template>
  </a-table>
  <template #extra>
      <a-space>
        <a-button @click="onClose">
          <template #icon>
            <CloseOutlined/>
          </template>
          关闭
        </a-button>
        <a-button type="primary" @click="onSave">
          <template #icon>
            <SaveOutlined/>
          </template>
          提交
        </a-button>
      </a-space>
    </template>
  </a-drawer>
  <del-modal ref="delModalRef" :processId="id" :nodeName="nodeName" :dataSource="dataSource" :getDetails="getDetails" :handelClose="onClose"/>
  <node-config-edit ref="nodeEdit"/>
  <page-style-modal ref="pageStyleRef" @setPageStyleName="setPageStyleName"/>
  <association-template ref="associationRef" @templateData="getTemplateData"/>
</template>

<script>
import { message } from 'ant-design-vue'
import { defineComponent, reactive, toRefs, ref, provide } from 'vue'
import { request,getRequestURI } from '../../../../utils/request'
import DelModal from './nodeViewModal/delModal'
import NodeConfigEdit from './nodeConfigEdit'
import PageStyleModal from './nodeViewModal/pageStyleModal'
import AssociationTemplate from './associationTemplate'
export default defineComponent({
  components:{
    DelModal,
    NodeConfigEdit,
    PageStyleModal,
    AssociationTemplate
  },
  setup(){
    const state = reactive({
      visible: false,
      id: '',
      nodeName:'',
      nodeId: '',
      dataSource:[],
      columns:[
        {
          title: '序号',
          key: 'index',
          fixed: 'left',
          width: 80,
          align: 'center',
        },
        {
          title: '节点名称',
          key: 'name',
          width: 120,
          align: 'left',
          dataIndex: 'name'
        },
        {
          title: '节点ID',
          key: 'code',
          width: 130,
          align: 'left',
          dataIndex: 'code'
        },
        {
          title: '界面状态',
          key: 'pageStyleName',
          width: 130,
          align: 'left',
          dataIndex: 'pageStyleName'

        },
        {
          title: '关联模板ID',
          key: 'associatedTemplateId',
          width: 130,
          align: 'left',
          dataIndex: 'associatedTemplateId'
        },
        {
          title: '所属流程',
          key: 'processName',
          width: 120,
          align: 'left',
          dataIndex: 'processName'
        },
        {
          title: '操作',
          key: 'operation',
          ellipsis: true,
          align: 'left',
          fixed: 'right',
          width: 150,
          scopedSlots: {customRender: 'operation'}
        },
      ]
      
    })
    const nodeDetailState = reactive({

    })
    const showData = reactive({

    })
    const showDrawer = (oldData) =>{
      console.debug(oldData)
      state.visible = true
      state.nodeId = oldData.id
      state.nodeName = oldData.name
      state.id = oldData.id  // 删除使用的
      getDetails(oldData.id)
    }
    // 获取详情信息
    const getDetails = (id) =>{
      request(getRequestURI('process_processDetails'),{id}).then(
        res =>{
          console.debug(res)
          state.dataSource = res.result ? res.result.processDictList : []
        }
      )
    }
    const delModalRef = ref()
    // 删除
    const handelDel = (id) =>{
      delModalRef.value.showDrawer(id)
    }
    const nodeEdit = ref()
    // 编辑
    const handelEdit = (record) =>{
      state.nodeId = record.id
      if(record.pageStyleId === '3'){
        return message.warning('三方链接仅通过api调用,不支持页面编辑操作')
      }
      
      nodeEdit.value.showDrawer(record.pageJson ? JSON.parse(record.pageJson) : record.pageJson )
    }

    //获取 编辑页面的数据
    const getNodeEdit = (value) =>{
      // console.debug('编辑页面值',value, state.dataSource)
      state.dataSource.forEach(item =>{
        if(item.id == state.nodeId){
          item.pageJson = JSON.stringify(value)
        }
      })
    }
    provide('getNodeEdit',getNodeEdit)

    const pageStyleRef = ref()
    // 弹窗状态
    const handelType = (record) =>{
      state.nodeId = record.id
      pageStyleRef.value.showDrawer(record.pageStyleId)
    }
    // 弹窗状态改变的值
    const setPageStyleName = (value) =>{
      state.dataSource.forEach(item =>{
        if(item.id == state.nodeId){
          item.pageStyleId = value.pageStyleId
          item.pageStyleName = value.pageStyleName
        }
      })
    }
    const associationRef = ref()
    // 关联模板
    const handelAssociation =(record) =>{
      state.nodeId = record.id
      associationRef.value.showDrawer(record.associatedTemplateId)
    }
    // 得到关联模板的值
    const getTemplateData = (value) =>{
      // console.debug('得到关联模板的值', value)
      state.dataSource.forEach(item =>{
        if(item.id == state.nodeId){
          item.associatedTemplateId = value.associatedTemplateId
          item.associatedTemplateName = value.associatedTemplateName
        }
      })
    }


    // 提交
    const onSave = () =>{
      console.debug(state.dataSource)
      state.dataSource.forEach(item =>{
        item.relationProcessId = item.code
        delete item.id
      })
      request(getRequestURI('process_addProcess'), {
          id: state.id,
          name: state.nodeName,
          nodeList:state.dataSource
        }).then(
          res => {
            if(res.code === 1){
              message.success('保存成功')
              onClose()
            }else{
              message.warning(res.message)
            }
            
        })
    }





    const onClose = () =>{
      state.visible = false
      state.nodeName = ''
      state.nodeId = null
      state.id = ''
      state.dataSource = []
    }


    return {
      ...toRefs(state),
      ...toRefs(showData),
      ...toRefs(nodeDetailState),
      showDrawer,
      onSave,
      onClose,
      getDetails,
      handelDel,
      handelEdit,
      handelType,
      setPageStyleName,
      getTemplateData,
      handelAssociation,
      associationRef,
      delModalRef,
      nodeEdit,
      pageStyleRef,
    }
  }
})
  
</script>

<style scoped>
  
</style>