<template>
  <a-modal v-model:visible="delShow" title="删除提示">
    <!-- <p>即将删除当前节点，是否同时更新当前流程？若不更新，将自动创建</p> -->
    <p>是否确认删除当前节点?</p>
    <template #footer>
      <a-button type="primary" danger ghost @click="onClose">取消</a-button>
      <a-button type="primary" ghost @click="handelUpdate(true)">确认</a-button>
      <!-- <a-button type="primary" ghost @click="handelUpdate(false)">创建新流程</a-button> -->
    </template>
  </a-modal>
</template>

<script>
import { message } from 'ant-design-vue'
import { defineComponent, reactive, toRefs, inject } from 'vue'
import { request, getRequestURI } from '../../../../../utils/request'

export default defineComponent({
  props:{
    processId:{
      typeof:[String, Number],
      default: () => ''
    },
    dataSource:{
      type: Array,
      default:() => []
    },
    nodeName:{
      typeof:String,
      default: () => ''
    },
    getDetails:{
      type: Function,
      default:() => {}
    },
    handelClose:{
      type: Function,
      default:() => {}
    }
  },
  setup(props){
    const state = reactive({
      delShow: false,
      delId: '',
      isUpdate: false
    })
    const showDrawer = (id) =>{
      state.delId = id
      state.delShow = true
    }

    // 取消
    const onClose = () =>{
      state.delShow = false
      state.delId = ''
      state.isUpdate = false
    }
    // 更新当前流程  创建新流程
    const handelUpdate = (type) =>{
      state.isUpdate = type
      let arr = props.dataSource.filter(item =>{
        return item.id !== state.delId
      })
      arr.forEach((ele) => {
        ele.relationProcessId = ele.code
        delete ele.id
      })
      addPro(arr)
    }
    const handleSearch = inject('handleSearch')
    const addPro = (arr) =>{
        request(getRequestURI('process_addProcess'), {
          id: state.isUpdate ? props.processId : null,
          name: props.nodeName,
          nodeList:arr
        }).then(
          res => {
            if(res.code === 1){
              // message.success('保存成功')
              message.success('删除成功')
              if(state.isUpdate ){
                props.getDetails(props.processId)
                onClose()
              } else {
                onClose()
                props.handelClose()
                handleSearch({})
              }
              
            }else{
              message.warning(res.message)
            }
            
        })
      
    }


    return {
      ...toRefs(state),
      showDrawer,
      handelUpdate,
      onClose,
      addPro,

    }
  }
})
</script>

<style scoped>
  
</style>