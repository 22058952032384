<template>
  <div style="width: 100%;margin-left:70px">
    <div>
      <h2>问题展示区域: &nbsp;{{ titleStr }}</h2>
    </div>
    <div>
        <!-- 问题配置 -->
      <a-textarea ref="contentArea" v-model:value="titleStr" :placeholder="inputHint" @blur="onBlurListener"/>
      <div style="margin: 15px 0">
        <a-button type="primary" @click="addUnderscore" size="small">添加填空符</a-button>
        <a-button type="primary" @click="setUnderscoreProp" size="small" style="margin:0 10px">设置文本属性</a-button>
        <a-button type="primary" @click="resetTitle" size="small">重置</a-button>
      </div>
    </div>
    <ShowInFront ref="showInF"></ShowInFront>
    <a-modal v-model:visible="propShow" title="设置填空属性" :footer="false" style="width: 800px">
      <InputPropsConfig v-if="propShow === true" ref="propsConfig" @setProps="getInputProps"></InputPropsConfig>
    </a-modal>
  </div>
</template>

<script>
import {defineComponent,reactive, toRefs, ref, nextTick, watch, inject} from 'vue'
import {message} from 'ant-design-vue'
import InputPropsConfig from './InputPropsConfig.vue'
import ShowInFront from './ShowInFront.vue'
export default defineComponent({
  name: 'InputTypeConfig',
  components: {
    // InputPropsConfig: defineAsyncComponent(() => import('./InputPropsConfig')),
    // ShowInFront: defineAsyncComponent(() => import('./ShowInFront')),
    InputPropsConfig,
    ShowInFront,
  },
  props: {
    elementObj: {
      type: Object,
      default: () => {
      }
    },
    title: {
      type: String,
      // default: () => '姓名：___,电话_：___年龄：___岁，地址：___'
      default: () => ''

    }
  },
  setup(props) {
    const contentArea = ref()
    const propsConfig = ref()
    const showInF = ref()
    const value = '___' // 每一个填入符 按 长度3 算
    const state = reactive({
      titleStr: props.title,
      titleF: '',
      inputHint: '请设置问题',
      propShow: false,
      strFrontArray: [],
      strArrayStr: [],
      strArray: [],
      blurIndex: 0,
      modelPropData:{}, // 设置每个属性的类型  字符  日期等
      isUpdate: false, // 修改
      updateTabList: [], // 用于接收修改的数据

    })
    const onBlurListener = (e) => {
      console.log(12333, e)
      state.blurIndex = e.target.selectionStart
      // console.debug(e)
      // console.debug(e.target)
      // console.debug(e.target.selectionStart) //光标所在的位置
    }
    // 在光标的位置加入下划线
    const addUnderscore = () => {
      state.titleStr = state.titleStr.slice(0, state.blurIndex) + value + state.titleStr.slice(state.blurIndex)
    }
    /**
     * 计算属性
     */
    const computedProp = () => {
      // 获取几个填入位置，数组形式，用于给它们设置属性
      if (state.titleStr && state.titleStr.indexOf(value) !== -1) {
        let indexs = getStrPos(state.titleStr, value)
        // console.debug('indexs', indexs)
        // [41, 38, 31, 27, 19, 14, 11, 8, 0]
        let str = state.titleStr
        // for (let i = 0; i < str.length; i++) {
        //   console.debug('元素' + i, str[i])
        // }
        let tkArray = []
        let tkStrArray = []
        let strArray = []
        let strArrayStr = []
        for (let i = 0; i < indexs.length; i++) {
          let item = indexs[i]
          let item2 = indexs[i + 1]
          if (item !== 0 && i === 0) {
            strArrayStr.push({
              type: 'string',
              indexStart: 0,
              indexEnd: item - 1,
              str: str.slice(0, item)
            })
            strArray.push([0, item])
          }
          if (item2 - item === 3) {
            let arr = [item, item2]
            tkArray.push(arr)
            tkStrArray.push(str.slice(item, item2 + 2))
          } else {
            tkArray.push(item)
            tkStrArray.push(str.slice(item, item + 2))
            // strArrayStr.push(str.slice(item+3,item2))
            strArrayStr.push({
              type: 'string',
              indexStart: item + 3,
              indexEnd: item2 - 1,
              str: str.slice(item + 3, item2)
            })
            strArray.push([item + 3, item2])
          }
        }
        // console.debug('元素1【】', tkArray)
        // console.debug('元素1-str【】', tkStrArray)
        // console.debug('元素2【】', strArray)
        // console.debug('元素2-str【】', strArrayStr)
        let array3 = tkArray
        let trueFlag = isHaveSameIndex(array3)
        while (trueFlag) {
          array3 = getNewArray(array3)
          trueFlag = isHaveSameIndex(array3)
        }
        // console.debug('元素3【】', array3)
        strArray = []
        for (let i = 0; i < array3.length; i++) {
          if (Array.isArray(array3[i])) {
            strArray.push({
              length: array3[i][1] + 3 - array3[i][0],
              type: 'input',
              value: '',
              indexStart: array3[i][0],
              indexEnd: array3[i][1] + 2
            })
          } else {
            strArray.push({
              length: 3,
              type: 'input',
              value: '',
              indexStart: array3[i],
              indexEnd: array3[i] + 2
            })
          }
        }
        // console.debug('元素3-str【】', strArray)
        state.strArrayStr = strArrayStr
        state.strArray = strArray
        showInFrontMethod(strArrayStr, strArray)
      } else {
        // console.debug('indexs', '没有要设置的填入符属性')
      }
    }

    const setUnderscoreProp = () => {
      if (state.titleStr && state.titleStr.indexOf(value) !== -1) {
        console.debug('aaaaaaaaaa', state.strArrayStr, state.strArray)
        showInputPropConfig(state.strArrayStr, state.strArray, state.updateTabList)
      } else {
        message.error('没有要设置的填入符属性')
      }
    }
    const isHaveSameIndex = (array) => {
      let newArray = []
      for (let i = 0; i < array.length; i++) {
        let item = array[i]
        if (Array.isArray(item)) {
          newArray.push(item[0], item[1])
        } else {
          newArray.push(item)
        }
      }
      // debugger
      let lengthOld = newArray.length
      let length = Array.from(new Set(newArray)).length
      if (lengthOld !== length) {
        return true
      } else {
        return false
      }
    }
    const getNewArray = (tkArray) => {
      let newArray = []
      let testArray = JSON.parse(JSON.stringify(tkArray))
      for (let i = 0; i < tkArray.length; i++) {
        let item = tkArray[i]
        let item2 = tkArray[i + 1]
        if (Array.isArray(item) && Array.isArray(item2)) {
          if (item[1] === item2[0] || item[0] === item2[0]) {
            testArray[i] = ''
            testArray[i + 1] = [item[0], item2[1]]
            newArray.push([item[0], item2[1]])
          } else {
            newArray.push(item)
            newArray.push(item2)
          }
        } else if (!Array.isArray(item) && Array.isArray(item2)) {
          if (item === item2[0]) {
            testArray[i] = ''
            testArray[i + 1] = item2
            newArray.push(item2)
          } else {
            newArray.push(item)
            newArray.push(item2)
          }
        } else if (Array.isArray(item) && !Array.isArray(item2)) {
          if (item[1] === item2) {
            testArray[i] = ''
            testArray[i + 1] = item
            newArray.push(item)
          } else {
            newArray.push(item)
            newArray.push(item2)
          }
        } else {
          newArray.push(item)
          newArray.push(item2)
        }
      }
      return Array.from(new Set(testArray)).filter(item => item !== '')
      // return  Array.from(new Set(newArray))
    }
    // 获取位置
    const getStrPos = (str, subStr) => {
      var indexs = []
      var string = str
      let index_temp = 0
      const a = true
      while (a) {
        var index = string.indexOf(subStr, index_temp)
        if (index != -1) {
          index_temp = index + 3
          indexs.push(index)
        } else {
          break
        }
      }
      return indexs

    }
    const resetTitle = () => {
      console.debug(state)
      Object.assign(state, {
        titleStr: '',
        titleF: '',
        inputHint: '请设置问题',
        propShow: false,
        strFrontArray: [],
        strArrayStr: [],
        strArray: [],
        blurIndex: 0,
        modelPropData:{},
        isUpdate: false, // 修改
        updateTabList: [], // 用于接收修改的数据
      })
      showInF.value.allData = []
      // state.titleStr = ''
    }
    // if (props.elementObj) {
    //   state.titleStr = props.elementObj.title
    // }
    const showInputPropConfig = (strArray, inputStrArray, updateTabList) => {
      state.propShow = true
      nextTick( () => {
        const myField = propsConfig.value
        myField.initPropsData(state.titleStr, strArray, inputStrArray,updateTabList)
      })
    }
    const getInputProps = (obj) => {
      if(state.isUpdate){
        state.propShow = false
        nextTick(() => {
          const field = showInF.value
          field.initShowFData(state.modelPropData.strArray, state.modelPropData.inputStrArray, state.modelPropData.tabList)
        })
        return
      }
      state.modelPropData = obj
      state.propShow = false
      console.debug(`getInputProps:${JSON.stringify(obj)}`)
      nextTick(() => {
        const field = showInF.value
        field.initShowFData(obj.strArray, obj.inputStrArray, obj.tabList)
      })
    }
    const showInFrontMethod = (d1, d2) => {
      console.debug('问题设置', d1,d2, state)
      if(state.isUpdate){
        return
      }
      nextTick(() => {
        const field = showInF.value
        field.initShowFData(d1, d2)
      })

    }
    watch(
      () => state.titleStr,
      () => {
        nextTick(() => {
          computedProp()
        })
      },
      {
        immediate: true,// 这个属性是重点啦
        deep: true // 深度监听的参数
      }
    )
    const getValue = inject('getQuest')
    const setState = () => {
      getValue(state)
    }
    watch(
      () => state,
      () => {
        nextTick(() => {
          setState()
        })
      },
      {
        immediate: true,// 这个属性是重点啦
        deep: true // 深度监听的参数
      }
    )

    // expose({
    //   state
    // })

    return {
      setState,
      ...toRefs(state),
      contentArea,
      propsConfig,
      showInF,
      addUnderscore,
      resetTitle,
      setUnderscoreProp,
      isHaveSameIndex,
      getNewArray,
      showInputPropConfig,
      getInputProps,
      showInFrontMethod,
      onBlurListener
    }
  }
})
</script>

<style scoped>

</style>
