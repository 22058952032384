import {createApp} from 'vue'
import 'jsoneditor'
import Antd, {message} from 'ant-design-vue'
import App from './app'
import router from './router'
import store from './store'
import {setGlobalOptions} from 'vue-request'
import * as antIcons from '@ant-design/icons-vue'
import ColorPicker from 'colorpicker-v3'
import 'ant-design-vue/dist/antd.css'
import './assets/css/main.css'
import 'colorpicker-v3/dist/style.css'
import PreviewPop from '@/components/extended/PreviewPop'
import ProductDetailInsurePlan from '@/components/extended/product_detail_insure_plan.vue'
import Vconsole from 'vconsole'
import ios_event from './utils/ios_event'
import './pages/zzwTest/js_bridge'

ios_event()
// import {setThemeColor} from './utils/generateColors.js'
//
// setThemeColor('#615faa')
setGlobalOptions({
  // formatResult: res => res,
  manual: true,
  pagination: {
    // currentKey: 'current',
    // pageSizeKey: 'pageSize',
    totalKey: 'pagination.total',
  },
})

const app = createApp(App)
app.config.productionTip = false
app.config.globalProperties.$message = message

app.use(Antd)
Object.keys(antIcons).forEach((key) => {
  app.component(key, antIcons[key])
})
app.use(ColorPicker)

if (process.env.VUE_APP_LOG === 'ON' || localStorage.getItem('developer--debug') === 'ON') {
  const vConsole = new Vconsole()
  app.use(vConsole)
}

// 递归组件全局注册
app.component('PreviewPop', PreviewPop)
app.component('ProductDetailInsurePlan', ProductDetailInsurePlan)
app.use(store)
app.use(router)
router.isReady().then(() => app.mount('#app'))
