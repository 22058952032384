<template>
  <a-drawer
    :title="showTitle"
    :width="'50%'"
    :visible="visible"
    :body-style="{ paddingBottom: '80px' }"
    :footer-style="{ textAlign: 'right' }"
    @close="onClose"
  >
    <a-form
      :model="formData"
      :rules="formRef"
      ref="formRef"
      autocomplete="off"
      :label-col="{ span: 4 }" :wrapper-col="{ span: 16 }"
    >
      <a-form-item name="id" v-if="false">
        <a-input v-model:value="formData.id" />
      </a-form-item>
      <a-form-item label="模板名称" :rules="[{required:true,message:'必填信息',trigger:'change'}]" name="templateName">
        <a-input v-model:value="formData.templateName" placeholder="请输入模板名称"/>
      </a-form-item>
      <a-form-item label="模板编码" name="templateCode" :rules="[{required:true,message:'必填信息',trigger:'change'}]">
        <a-input v-model:value="formData.templateCode" placeholder="请输入模板编码"/>
      </a-form-item>
      <a-form-item label="模板路径" name="route" :rules="[{required:true,message:'必填信息',trigger:'change'}]">
        <a-input v-model:value="formData.route" placeholder="请输入模板路径"/>
      </a-form-item>
      <a-form-item label="模板描述" name="templateDescribe" :rules="[{required:true,message:'必填信息',trigger:'change'}]">
        <a-input v-model:value="formData.templateDescribe" placeholder="请输入模板描述"/>
      </a-form-item>
    </a-form>
    <template #extra>
      <a-space>
        <a-button @click="onClose">
          <template #icon>
            <CloseOutlined/>
          </template>
          关闭
        </a-button>
        <a-button html-type="submit" type="primary" @click="onSave()">
          <template #icon>
            <SaveOutlined/>
          </template>
          提交
        </a-button>
      </a-space>
    </template>
  </a-drawer>
</template>

<script>
import { defineComponent, onBeforeMount, reactive, ref, toRefs} from 'vue'
import {getRequestURI, request} from '../../../utils/request'
import {message} from 'ant-design-vue'

export default defineComponent({
  props: {
    status: {
      type: String,
      require: true,
      default() {
        return 'sd'
      }
    }
  },
  setup(props, {expose, emit}) {
    const formRef = ref()
    const formData = reactive({
      id: null,
      templateCode: '',
      templateName: '',
      route: '',
      templateDescribe: ''
    })
    const onClose = () => {
      formRef.value.resetFields()
      formRef.value.clearValidate()
      Object.assign(formData, {
        id: null,
        templateCode: '',
        templateName: '',
        route: '',
        templateDescribe: ''
      })
      state.visible = false

    }
    onBeforeMount(()=>{
    })
    const state = reactive({
      visible: false,
      status: '',
      showTitle : '新增产品模板'
    })
    const showDrawer = (oldData = {}, status) => {
      state.status = status
      state.visible = true
      state.showTitle = status === 'add' ? '新增产品模板' : '修改产品模板'
      if(status !== 'add'){
        Object.keys(formData).forEach((key) => {
          formData[key] = oldData[key] || formData[key]
        })
      }
    }
    const onSave = async function () {
      await formRef.value.validate()
      request(getRequestURI('productFlowTemplate_add'), {data: formData}).then(res => {
        if (res.code === 1) {
          message['success'](res.message)
          onClose()
          emit('select')
        } else {
          message['error'](res.message)
        }
      })
    }

    expose({
      showDrawer
    })
    return {
      ...toRefs(state),
      onClose,
      onSave,
      formData,
      formRef,
    }
  }
})

</script>

<style scoped>

</style>
