<template>
  <search-form
    :Condition="condition"
    :ColSpan="12"
    @resetAll="resetAll"
    @callback="handleSearch"
    @nameLinked="changes"
  ></search-form>
  <a-table
    :columns="columns"
    :data-source="dataSource ? dataSource['result'] : []"
    :row-selection="{
      selectedRowKeys: selectedRowKeys,
      onChange: onSelectChange,
      type: 'radio',
      hideSelectAll: true,
    }"
    :row-key="(record) => record.id"
    :pagination="pagination"
    @change="handleTableChange"
    :loading="loading"
  >
    <template #bodyCell="{ column, index, record }">
      <template v-if="column.key === 'index'">
        {{ (pagination.current - 1) * pagination.pageSize + index + 1 }}
      </template>
      <template v-if="column.key === 'operation'">
        <div>
          <a style="color: orange" @click="checkUser(record, 'details')">查看</a
          >&nbsp;&nbsp;
          <a style="color: orange" @click="modifyUser(record, 'edit')">编辑</a
          >&nbsp;&nbsp;
          <!--          <a style="color: orange" @click="showModal(record,index)">删除</a>&nbsp;&nbsp;-->
        </div>
      </template>
    </template>
  </a-table>
  <shoulderAdd
    ref="userDrawerRef"
    :status="status"
    :is_insurance="is_insurance"
  ></shoulderAdd>
</template>

<script>
import { computed, defineComponent, onMounted, reactive, ref, toRefs } from 'vue'
import { getRequestURI, request, requestUsePagination } from '../../../utils/request'
import SearchForm from '../../../components/extended/search_form'
import shoulderAdd from './riskDutyAdd.vue'
import { Modal, message } from 'ant-design-vue'
export default defineComponent({
  components: {
    SearchForm,
    shoulderAdd
  },
  setup () {
    onMounted(() => {
      getInsuranceName()
    })
    const userDrawerRef = ref()
    const state = reactive({
      condition: {
        supId: {
          label: '供应商名称',
          type: 'tree-select',
          urlKey: 'querySupplier',
          value: 'id',
          default: '',
          data: []
        },
        riskName: {
          label: '险种名称',
          type: 'select',
          default: '',
          data: []
        },
        riskCode: '险种编码',

      },
      status: '',
      is_insurance: 0,
      result: [],
      query: {
        data: {
          riskCode: '',
          riskLengthSign: '',
          riskName: '',
          riskSign: '',
          riskTypeLargeCode: '',
          supRiskCode: '',
        }
      },
      columns: [
        {
          title: '序号',
          key: 'index',
          fixed: 'left',
          width: 60,
          align: 'left',
        },
        {
          title: '险种编码',
          width: 140,
          align: 'center',
          key: 'riskCode',
          dataIndex: 'riskCode',
        },
        {
          title: '供应商险种编码',
          width: 140,
          align: 'center',
          key: 'supRiskCode',
          dataIndex: 'supRiskCode',
        },
        {
          title: '险种名称',
          width: 140,
          align: 'center',
          key: 'riskName',
          dataIndex: 'riskName',
        },
        {
          title: '供应商名称',
          width: 140,
          align: 'center',
          key: 'supName',
          dataIndex: 'supName',
        },
        {
          title: '操作',
          key: 'operation',
          ellipsis: true,
          align: 'center',
          width: 150,
          scopedSlots: { customRender: 'operation' }
        },
      ],
      selectedRowKeys: [],
      // 删除data
      data: [],
      searchNow: {},
      del: '',
      deleteQuery: {},
    })
    const {
      data: dataSource,
      run,
      loading,
      current,
      pageSize,
      total,
    } = requestUsePagination(getRequestURI('productRisk_selectListForPage'))
    const pagination = computed(() => ({
      current: current.value,
      pageSize: pageSize.value,
      total: total.value,
    }))
    const handleTableChange = (pag, filters, sorter) => {
      console.log('handleTableChange', pag, filters, sorter)
      run({
        pagination: {
          current: pag?.current,
          pageSize: pag?.pageSize
        },
        sorter,
        data: state.lastFilter,
      })
    }
    const changes = function (value) {
      request(getRequestURI('selectRisk'), {
        data: {
          supId: value,
          // riskName:''
        }
      }, true, 'post').then(
        (result) => {
          const arr = []
          const res = result.result
          for (let i = 0; i < res.length; i++) {
            arr.push({ label: res[i].riskName, value: res[i].riskName })
          }
          state.condition.riskName.data = arr
        }
      )
    }
    //获取险种名称数据
    const getInsuranceName = () => {
      request(getRequestURI('selectRisk'), {
        data: {
          riskName: ''
        }
      }, true, 'post').then(
        (result) => {
          const arr = []
          const res = result.result
          for (let i = 0; i < res.length; i++) {
            arr.push({ label: res[i].riskName, value: res[i].riskName })
          }
          state.condition.riskName.data = arr
        }
      )
    }
    const resetAll = () => {
      state.condition.riskName.data = []
      getInsuranceName()
    }

    //多选
    const onSelectChange = (selectedRowKeys) => {
      state.data.splice(0)
      console.log('selectedRowKeys changed: ', selectedRowKeys)
      state.selectedRowKeys = selectedRowKeys
      console.log(state.result, 999999)
      for (let selectedRowKeys of state.selectedRowKeys) {
        for (let result in state.result) {
          if (state.result[result].id == selectedRowKeys && state.data.includes(state.result[result]) == false) {
            state.data.push(state.result[result])
          }
        }
      }
      console.log(state.data)
      console.log(state.result, 999999)
    }
    //页面挂载调用数据
    onMounted(() => {
      handleSearch({})
    })
    const handleSearch = function (filters) {
      console.log(filters, '456')

      state.lastFilter = filters
      run({
        pagination: {
          current: 1,
          pageSize: 10
        },
        data: filters,
      })
    }
    const selectUser = function () {
      console.log('sss')
    }

    //编辑
    const modifyUser = function (record, status) {
      state.is_insurance = record.riskCsSign || 0
      state.status = status
      request(getRequestURI('productRisk_checkUpdate'), { data: { ids: [record.id] } }).then(res => {
        if (res.code === 1) {
          userDrawerRef.value.showDrawer(record, status)
        } else {
          message.warning(res.message)
        }
      })
    }
    //查看
    const checkUser = function (record, status) {
      state.status = status
      userDrawerRef.value.showDrawer(record, status)
    }
    // //批量删除
    // const deleteUser = () => {
    //   Modal.confirm({
    //     title: '确认删除吗?',
    //     async onOk() {
    //       let keys = state.selectedRowKeys
    //       if (keys.length === 0) {
    //         message.warning('请选择数据')
    //         return
    //       }
    //       let uri = getRequestURI('productRisk_delete')
    //       request(uri, {data:state.data}).then(
    //         (result) => {
    //           console.log('批量删除的结果:', result)
    //           // state.selectedRowKeys = []
    //           handleSearch()
    //           // dataSource.value = result.result
    //         }
    //       )
    //     },
    //     onCancel() {
    //     }
    //   })
    // }


    //删除
    const showModal = (record) => {
      state.data.push(record)
      Modal.confirm({
        title: '确认删除吗？',
        async onOk () {
          let uri = getRequestURI('productRisk_delete')
          request(uri, { data: state.data }).then(
            (result) => {
              message.success('删除成功!')
              console.log('批量删除的结果:', result)
              // state.selectedRowKeys = []
              handleSearch()
              // dataSource.value = result.result
            }
          )
        },
        onCancel () {
        }
      })
    }

    return {
      ...toRefs(state),
      handleSearch,
      selectUser,
      resetAll,
      userDrawerRef,
      checkUser,
      showModal,
      modifyUser,
      onSelectChange,
      pagination,
      dataSource,
      loading,
      handleTableChange,
      run,
      // huoqu,
      getInsuranceName,
      changes,
      current,
      pageSize,
      total,
    }
  },
})
</script>

<style scoped>
</style>
