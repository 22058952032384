<template>
  <search-form
    :Condition="condition"
    :ColSpan="12"
    @callback="handleSearch"
  ></search-form>
  <div class="btn-bar">
    <a-button type="primary" @click="addUser('newAdd')">
      <template #icon>
        <FileAddOutlined />
      </template>
      新增
    </a-button>
    <a-button danger @click="deleteUser">
      <template #icon>
        <DeleteOutlined />
      </template>
      批量删除
    </a-button>
    <a-button danger @click="restUserPassword">
      <template #icon>
        <ToTopOutlined />
      </template>
      导出
    </a-button>
  </div>
  <a-table
    :columns="columns"
    :data-source="dataSource ? dataSource['result'] : []"
    :scroll="{ x: 800 }"
    :row-selection="{
      selectedRowKeys: selectedRowKeys,
      onChange: onSelectChange,
    }"
    :row-key="(record) => record.id"
    :pagination="pagination"
    @change="handleTableChange"
    :loading="loading"
  >
    <template #bodyCell="{ column, index, record }">
      <template v-if="column.key === 'index'">
        {{ (pagination.current - 1) * pagination.pageSize + index + 1 }}
      </template>
      <template v-if="column.key === 'operation'">
        <div>
          <a
            style="color: orange; margin-right: 10px"
            @click="checkUser(record, 'details')"
            >查看</a
          >&nbsp;&nbsp;
          <a
            style="color: orange; margin-right: 10px"
            @click="modifyUser(record, 'edit')"
            >编辑</a
          >&nbsp;&nbsp;
          <!--          <a style="color: orange" @click="showModal(record,index)">删除</a>&nbsp;&nbsp;-->
        </div>
      </template>
    </template>
  </a-table>
  <shoulder-add
    ref="userDrawerRef"
    :status="status"
    :handleSearch="handleSearch"
    :searchfFilters="searchfFilters"
    :current="pagination.current"
  ></shoulder-add>
</template>

<script>

import {computed, defineComponent, onMounted, reactive, ref, toRefs} from 'vue'
import {getRequestURI, request, requestUsePagination} from '../../../utils/request'
import {getExtendUri} from '../../../utils/request_extend'
import SearchForm from '../../../components/extended/search_form'
import ShoulderAdd from './insuranceAdd.vue'
import {message, Modal} from 'ant-design-vue'

export default defineComponent({
  components: {
    SearchForm,
    ShoulderAdd
  },

  setup () {
    onMounted(() => {
      getInsuranceName()
      xianZhongType()
    })
    const userDrawerRef = ref()
    const state = reactive({
      condition: {
        supId: {
          label: '供应商名称',
          type: 'tree-select',
          urlKey: 'querySupplier',
          // urlKey: 'supplierQuerySupLevelSt',
          value: 'id',
          default: '',
          data: []
        },
        // supRiskCode:'供应商险种编码',
        riskName: '险种名称',
        riskCode: '险种编码',
        riskTypeLargeCode: {
          label: '险种类型大类',
          type: 'select',
          default: '',
          urlKey: 'queryDictionaryType',
          queryParam: { type: 'risk_type' }
        },
        riskLengthSign: {
          label: '长短险标志',
          type: 'select',
          default: '',
          urlKey: 'queryDictionaryType',
          queryParam: { type: 'riskLSType' }
        },
        riskSign: {
          label: '主附险标志',
          type: 'select',
          default: '',
          urlKey: 'queryDictionaryType',
          queryParam: { type: 'riskMAType' }
        },
      },
      status: '',
      result: [],
      query: {
        data: {
          riskCode: '',
          riskLengthSign: '',
          riskName: '',
          riskSign: '',
          riskTypeLargeCode: '',
          supRiskCode: '',
        }

      },
      columns: [
        {
          title: '序号',
          key: 'index',
          fixed: 'left',
          width: 60,
          align: 'left',
        },
        {
          title: '险种编码',
          width: 90,
          align: 'center',
          key: 'riskCode',
          dataIndex: 'riskCode',
        },
        {
          title: '供应商险种编码',
          width: 90,
          align: 'center',
          key: 'supRiskCode',
          dataIndex: 'supRiskCode',
        },
        {
          title: '险种名称',
          width: 90,
          align: 'center',
          key: 'riskName',
          dataIndex: 'riskName',

        },
        {
          title: '险种类别大类',
          width: 90,
          align: 'center',
          key: 'riskTypeLargeCodeName',
          dataIndex: 'riskTypeLargeCodeName',
        },
        {
          title: '长短险标志',
          width: 90,
          align: 'center',
          key: 'riskLengthSignName',
          dataIndex: 'riskLengthSignName',
        },
        {
          title: '主附险标志',
          width: 90,
          align: 'center',
          key: 'riskSign',
          dataIndex: 'riskSign',
          customRender: (text)=>{
            if (text.value === 1){
              return '主险'
            } else if (text.value === 3) {
              return '即可做主险亦可做附加险'
            } else {
              return  '附险'
            }
          }
        },
        {
          title: '操作',
          key: 'operation',
          ellipsis: true,
          align: 'center',
          fixed: 'right',
          width: 140,
          scopedSlots: { customRender: 'operation' }
        },
      ],
      selectedRowKeys: [],
      lastFilter: {},
      // 删除data
      data: [],
      datas: '',
      searchNow: {},
      del: '',
      deleteQuery: {},
    })
    const {
      data: dataSource,
      run,
      loading,
      current,
      pageSize,
      total,
    } = requestUsePagination(getRequestURI('productRisk_selectListForPage'))
    const pagination = computed(() => ({
      current: current.value,
      pageSize: pageSize.value,
      total: total.value,
    }))
    const handleTableChange = (pag, filters, sorter) => {
      console.log('handleTableChange', pag, filters, sorter)
      run({
        pagination: {
          current: pag?.current,
          pageSize: pag?.pageSize
        },
        sorter,
        data: state.lastFilter,
      })
    }
    //多选
    const onSelectChange = (selectedRowKeys, selectedRow) => {
      state.data.splice(0)
      state.selectedRowKeys = selectedRowKeys
      state.data = selectedRow
      state.datas = selectedRow

    }
    //获取险种名称数据
    const getInsuranceName = () => {
      // request(getRequestURI('selectRisk'), {
      //   data: {
      //     riskName:''
      //   }
      // }, true, 'post').then(
      //   (result) => {
      //     const arr = []
      //     const res = result.result
      //     for (let i = 0; i < res.length; i++) {
      //       arr.push({label: res[i].riskName, value: res[i].riskName})
      //     }
      //     state.condition.riskName.data = arr
      //   }
      // )
    }
    //获取险种类型大全
    const xianZhongType = () => {
      let obj = {
        types: ['protemptype_risk'],
      }
      let uri = getExtendUri('dictionary_query_byType')
      request(uri, { data: obj }).then(result => {
        const arr = []
        const res = result.result.protemptype_risk
        for (let i = 0; i < res.length; i++) {
          arr.push({ label: res[i].label, value: res[i].value })
        }
        state.condition.riskTypeLargeCode.data = arr

      })
    }
    //导出
    const restUserPassword = () => {
      if (!dataSource.value || !dataSource.value['result']) {
        message.warning('暂无数据')
        return
      }
      let data = {
        productRiskFormList: [],
        productRiskForm: state.lastFilter
      }
      state.data.forEach(item => {
        data.productRiskFormList.push({ id: item.id })
      })

      request(getRequestURI('productRisk_export'),
        {
          data
        }, true, 'post').then(
          (result) => {
            message.success(result.message)
            window.location = result.result
            state.selectedRowKeys = []
            state.data = []
          }
        ).catch(e => {
          // console.log(e)
          message.error(e)
        })

    }
    // 存储搜索组件传过来的数据
    const searchfFilters = ref({})
    //页面挂载调用数据
    onMounted(() => {
      handleSearch({})
    })
    const handleSearch = function (filters, num = '') {
      if (filters) {
        searchfFilters.value = filters
      }
      console.log('handleSearch', filters)
      state.query.data.riskCode = state.searchNow.riskCode
      state.query.data.riskSign = state.searchNow.riskSign
      state.query.data.riskLengthSign = state.searchNow.riskLengthSign
      state.lastFilter = filters
      run({
        pagination: {
          current: num ? num : 1,
          pageSize: 10
        },
        data: filters,
      })
    }
    const selectUser = function () {
      console.log('sss')
    }
    //新增
    const addUser = function (status) {
      state.status = status
      userDrawerRef.value.showDrawer(status)
    }
    //编辑
    const modifyUser = function (record, status) {
      // console.log('zx----------', record)
      state.status = status
      userDrawerRef.value.showDrawer({...record}, status)

    }
    //查看
    const checkUser = function (record, status) {
      state.status = status
      userDrawerRef.value.showDrawer({...record}, status)
    }
    //批量删除
    const deleteUser = () => {
      let keys = state.data
      if (keys.length === 0) {
        message.warning('请选择数据')
        return
      }
      let obj = []
      state.data.forEach(item => {
        obj.push(item.id)
      })
      request(getRequestURI('productRisk_checkUpdate'), { data: { ids: obj } }).then(res => {
        if (res.code === 1) {
          Modal.confirm({
            title: '确认删除吗?',
            async onOk () {
              let uri = getRequestURI('productRisk_delete')
              request(uri, { data: state.data }).then(
                (result) => {
                  if (result.code === 1) {
                    message['success']('删除成功')
                    state.data = []
                    state.selectedRowKeys = []
                    handleSearch({})
                  } else {
                    message['warning']('险种被使用')
                  }
                  console.log('批量删除的结果:', result)
                }
              )
            },
            onCancel () {
            }
          })
        } else {
          message.warning(res.message)
        }
      })
    }


    return {
      ...toRefs(state),
      handleSearch,
      selectUser,
      addUser,
      userDrawerRef,
      checkUser,
      deleteUser,
      // showModal,
      modifyUser,
      onSelectChange,
      pagination,
      dataSource,
      loading,
      handleTableChange,
      run,
      getInsuranceName,
      current,
      pageSize,
      total,
      xianZhongType,
      restUserPassword,
      searchfFilters
    }
  },
})
</script>

<style scoped>
</style>
