<template>
  <div class="switch_class">
    <a-input v-model:value="inputValue" readonly placeholder="待填写" @click="handelInputClick"/>
    <span @click="handelInputClick">
      <right-outlined/>
    </span>
  </div>
</template>

<script>
import { inject, onBeforeUpdate, reactive, toRefs, watch } from 'vue'

export default {
  props: {
    moduleIndex:{
      type: [Number,String],
      default:()=> '0'
    },
    moduleId:{ // 模块ID
      type: [Number,String],
      default:()=> ''
    },
    elementId:{ // 元素ID
      type: [Number,String],
      default:()=> ''
    },
    dictionary: {
      type: String,
      default: () => ''
    },
    title: {
      type:String,
      default: ()=> ''
    },
    dataList: {
      type: [Array, Object],
      default: () => []
    },
    elementCode: {
      type: [Number,String],
      default: () => ''
    },
    isRequired:{
      type: [Number,String],
      default:()=> '0'
    },
    firstValue:{
      type: [Number,String, Boolean],
      default:()=> ''
    },
    disabledType: {
      type: Boolean,
      default:()=> false
    },
    isDisabled: {
      type: Boolean,
      default:()=> false
    },
    elementName : {
      type: String,
      default: () => ''
    }
  },
  emits:['callback','update:firstValue', 'handelNodeHealth'],
  setup(props, context) {
    const state = reactive({
      notifyValue: '',
      inputValue: '待填写'
    })
    let handelNodeHealth = inject('handelNodeHealth')
    // 点击的时候 跳到告知关联的页面
    const handelInputClick = () =>{
      if (props.isDisabled) return
      handelNodeHealth(props)
    }
    watch(()=> props.firstValue,(newValue)=>{
      state.notifyValue = props.firstValue
      if(newValue === 'Y'){
        state.inputValue = '已填写'
      }else{
        state.inputValue = '待填写'
      }
      let node = {}
      node[props.elementCode] = {
        [props.elementCode]: state.notifyValue,
        elementId: props.elementId,
        moduleType: props.moduleId,
        index:props.moduleIndex,
        required: props.isRequired,
        elementName: props.elementName,
      }
      context.emit('update:firstValue', state.notifyValue)
      context.emit('callback', node)
    },{immediate:true})

    onBeforeUpdate(()=>{
    })
    return{
      ...toRefs(state),
      handelInputClick

    }
  }
}
</script>

  <style scoped>
  .switch_class{
    display: flex;
    justify-content: space-around;
    cursor: pointer;
  }
  .ant-input{
    text-align: right;
    border: 0;
    cursor: pointer;
  }
  :deep(.ant-input:focus, .ant-input-focused) {
    border-color: #f1f1f1 !important;
    box-shadow: none !important;
    border-right-width: 0px !important;
    outline: 0;
  }

  </style>
