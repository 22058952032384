<template>
  <a-drawer
    placement="bottom"
    :closable="false"
    :visible="occVisible"
    @close="onPickerClose"
    height="100%"
  >
    <div style="background-color: #f6f5fa;margin: -24px;">
      <!-- 头部搜索 -->
      <div class="header_nav">
        <van-nav-bar @click-left="onPickerClose"  left-text="返回" left-arrow>
          <template #title>
            <van-search background="#f6f5fa" shape="round" @click-left-icon="handelSelect"  v-model="searchValue" placeholder="请输入搜索关键词" />
          </template>
          <template #right>
            <div @click="handelSelect">搜索</div>
          </template>
        </van-nav-bar>
      </div>
      <div>
        <van-cell @click="handelClickCell(item)" style="text-align: left;" v-for="item in occupationList" :key="item.id" :title="item.name" />
      </div>
    </div>

  </a-drawer>
</template>

<script>
import {reactive, toRefs, defineComponent, onMounted, watch} from 'vue'
import { Search, NavBar,Cell  } from 'vant'
import { request } from '../../../../utils/request'
import { getExtendUri } from '../../../../utils/request_extend'
import store from '../../../../store'
export default defineComponent({
  components:{
    [Search.name]: Search,
    [NavBar.name]: NavBar,
    [Cell.name]: Cell
  },
  props:{
    moduleId: {
      type: String
    },
    isInsureds:{
      type: Boolean,
      default:()=> false
    }
  },
  emits: ['handelClickOk'],
  setup (props, {emit}) {
    const state = reactive({
      occVisible: false,
      flag : '',
      list: [],
      searchValue: '',
      occupationList: '',
      productInfo: {}
    })
    // 职业搜索：/product/productProfessionCommon/selectprofessionByName
    const handelSelect = () =>{
      let data = {
        productCode: state.productInfo.productCode,
        supplierCode: state.productInfo.supplierCode,
        name:state.searchValue
        // productCode:'100000000850',
        // supplierCode: '1043',
        // name:state.searchValue
      }
      request(getExtendUri('selectprofessionByName'), {data}).then(res => {
      if(res.code == 1){
        state.occupationList = res.result
      }
      })
    }
    const showDrawer = () =>{
      state.occVisible = true
      state.flag = 'ON'
      window.history.pushState(null, null, document.URL)
      window.addEventListener('popstate', onCloseDrawer, false)
    }
    // 返回上一级
    const onPickerClose = () =>{
      state.searchValue = ''
      state.occVisible = false
      state.flag = 'OFF'
    }
    const handelClickCell = (data) =>{
      emit('handelClickOk',data)
      state.occVisible = false
    }
    const onCloseDrawer = () =>{
      console.debug('用户尝试回退')
        state.searchValue = ''
        state.occVisible = false
        state.flag = 'OFF'
    }

    onMounted(()=>{
      console.log('职业弹窗', state.occVisible)
      if(typeof(store.getters['insured/getProductDetailData']) === 'string'){
        state.productInfo = JSON.parse(store.getters['insured/getProductDetailData']).productInfo || {}
      }
    })
    watch(() => state.flag, (newVal)=>{
      if (newVal === 'OFF') {
        window.removeEventListener('popstate', onCloseDrawer, false)
      }
    }, {deep: true})
    return {
      handelClickCell,
      ...toRefs(state),
      handelSelect,
      onPickerClose,
      showDrawer
    }
  }
})
</script>

<style scoped>
.header_nav{
  background-color: #f6f5fa;
  height: 50px;
  border-bottom: 1px solid #bbb;
}
:deep(.ant-drawer-body){
  padding: 0 !important;
}
:deep(.van-nav-bar__text){
  font-family: '微软雅黑';
  color: #000;
}
:deep(.van-search){
  padding: 0;
}
:deep(.van-nav-bar__content){
  background: rgb(246, 245, 250);
}
:deep(.van-search__content--round){
  border: 1px solid #299fa5;
  border-radius: 8px;
}
:deep(.van-nav-bar__title){
  max-width: 100% !important;
  width: 59%;
}
:deep(.van-nav-bar__right,.van-nav-bar__left){
  right: 10px;
  color: #666;
  font-size: 1rem;
  background: rgb(246, 245, 250);
}
:deep(.van-nav-bar__left){
  background: rgb(246, 245, 250);
}
</style>
