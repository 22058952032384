
// 一个入口去调用

export const vTools = function (type, param) {
  if (type === 'pId') {
    return rules_idCardNum(param)
  } else if (type === 'name') {
    return rules_name(param)
  } else if (type === 'phone') {
    return rules_phone(param)
  }  else if (type === 'phone2') {
    return rules_phone2(param)
  } else if (type === 'tel') {
    return rules_tel(param)
  } else if (type === 'email') {
    return rules_email(param)
  } else if (type === 'text') {
    return rules_text(param)
  } else if (type === 'number') {
    return rules_number(param)
  } else if (type === 'age') {
    return rules_age(param)
  } else if (type === 'bankNo') {
    return rules_bankNo(param)
  } else if (type === 'zipCode') {
    return rules_zipCode(param)
  } else if (type === 'salary') {
    return rules_salary(param)
  } else if (type === 'bnfLot') {
    return rules_bnfLot(param)
  } else if (type === 'birthCard') {
    return rules_birth(param)
  } else if (type === 'address') {
    return rules_address(param)
  } else if (type === 'wgrjlz') {
    return testIdCard(param)
  } else if (type === 'fName') {
    return rules_f_name(param)
  }
}
/**
 * 外国人姓名
 * @param text
 */
const rules_f_name = function (text) {
  if (!text) {
    return '请输入姓名'
  }
  let pattern = new RegExp(/^[A-Za-z, ]+$/)
  // 检查输入中是否有不在空格到波浪号~之间的字符
  if(!pattern.test(text) || text.length < 4 || text.length > 40) {
    return '请输入正确的姓名'
  }
  let str = text.trim()
  if (str.charAt(0) === ',' || str.charAt(str.length - 1) === ',') {
    return '请输入正确的姓名'
  }
  return true
}
/**
 * 受益比例
 * @param text
 */
const rules_bnfLot = function (text) {
  if (!text) {
    return '请输入受益比例'
  }
  let pattern = new RegExp(/^[1-9]\d*$/)
  if (rules_number(text) === true) {
    if (Number(text) <= 0 || Number(text) > 100) {
      return '请输入正确的受益比例'
    }
    if (!pattern.test(text)) {
      return '请输入整数'
    }
  } else {
    return '请输入正确的受益比例'
  }
  return true
}
/**
 * 出生证校验
 * @param text
 */
const rules_birth = function (text) {
  let pattern = new RegExp(/^[A-Z]\d{9}$/)
  if (!text) {
    return '请输入证件号'
  }
  if (!pattern.test(text)) {
    return '请输入正确的证件号码'
  }
  return true
}
function countDispersedChineseCharacters(str) {  
  let count = 0
  for (let i = 0; i < str.length; i++) {  
    const charCode = str.charCodeAt(i) 
    // 判断字符是否在汉字范围内  
    if (charCode >= 0x4e00 && charCode <= 0x9fff) {
      // 如果上一个字符不是汉字，则增加计数 
      count++
    }
    // 当计数达到5时，可以立即返回true，因为已经满足条件  
    if (count >= 5) {  
      return true
    }  
  }  
  // 如果遍历完整个字符串都没有达到5个分散的汉字，则返回false  
  return count >= 5
}  
/**
 * 详细地址
 * @param text
 */
const rules_address = function (text) {
  // let pattern = new RegExp(/[\u4e00-\u9fff]{5,}/)
  if (!text) {
    return '请输入详细地址'
  }
  console.log(countDispersedChineseCharacters(text), 'countDispersedChineseCharacters(text)')
  if (!countDispersedChineseCharacters(text)) {
    return '请输入正确的详细地址'
  }
  return true
}
/**
 * 文本校验
 * @param text
 */
const rules_text = function (text) {
  if (!text) {
    return '请输入文本'
  }
  return true
}
/**
 * 年龄校验
 * @param text
 */
const rules_age= function (text) {
  if (!text) {
    return '请输入年龄'
  }
  return true
}

/**
 * 数字校验
 * @param number
 */
 const rules_number = function (number) {
  if (typeof Number(number) !== 'number' || isNaN(number)) {
    return '请输入数字'
  }
  return true
}

/**
 * 姓名的校验，
 * 仅校验不包含特殊字符（现在的名字可能会有字母，数字，或超长字符串）
 */
const rules_name = function (name) {
  // debugger
  // let rule = /^[u4e00-u9fa5]{2,4}$/ //2-4个中文字符正则，貌似有很长的名字，所以不限制字数
  let pattern = new RegExp(
    '[`~!@#$^&*()=|{}\':;\',\\[\\].<>《》/?~！@#￥……&*（）——|{}【】‘；：”“\'。，、？1234567890QWERTYUIOPASDFGHJKLZXCVBNMqwertyuiopasdfghjklzxcvbnm]'
  )
  if (name) {
    if (pattern.test(name)) {
      return '姓名不能包含特殊字符'
    }
  } else {
    return '请输入姓名'
  }

  return true
}

/**
 * 手机校验
 * @param phone
 */
const rules_phone = function (phone) {
  let pattern = new RegExp(
    /^(13[0-9]|14[0-9]|15[0-9]|16[6]|17[0-9]|18[0-9]|19[13589])\d{8}$/
    // /^(13[0-9]|14[01456879]|15[0-3,5-9]|16[2567]|17[0-8]|18[0-9]|19[0-3,5-9])\d{8}$/
  //  汇彬的正则
  // /^[1][0-9][0-9]{9}$/

)
  if (!pattern.test(phone)) {
    return '请输入正确的手机号'
  }
  return true
}
const rules_phone2 = function (phone) {
  let pattern = new RegExp(
    /^(13[0-9]|14[0-9]|15[0-9]|16[6]|17[0-9]|18[0-9]|19[13589])\d{8}$/
    // /^(13[0-9]|14[01456879]|15[0-3,5-9]|16[2567]|17[0-8]|18[0-9]|19[0-3,5-9])\d{8}$/
    //  汇彬的正则
    // /^[1][0-9][0-9]{9}$/
  )
  if (!pattern.test(phone)) {
    return '请输入正确的手机号'
  }
  return true
}
/**
 * 外国人永久拘留证
 * @param idNo
 */
const testIdCard = (id) => {
  // 1 "验证通过!", 0 //校验不通过 // id为身份证号码
  
  // // 新 增加新版永居证号码 以9开头再加受理区域
  // var format = /^((([9](([1][1-5])|([2][1-3])|([3][1-7])|([4][1-6])|([5][0-4])|([6][1-5])|([7][1])|([8][1-2])))\d{3}))(([1][9]\d{2})|([2]\d{3}))(([0][1-9])|([1][0-2]))(([0][1-9])|([1-2][0-9])|([3][0-1]))\d{3}[0-9xX]$/
  // // var format = /^(((([1][1-5])|([2][1-3])|([3][1-7])|([4][1-6])|([5][0-4])|([6][1-5])|([7][1])|([8][1-2]))\d{4})|(([9](([1][1-5])|([2][1-3])|([3][1-7])|([4][1-6])|([5][0-4])|([6][1-5])|([7][1])|([8][1-2])))\d{3}))(([1][9]\d{2})|([2]\d{3}))(([0][1-9])|([1][0-2]))(([0][1-9])|([1-2][0-9])|([3][0-1]))\d{3}[0-9xX]$/
  // var format15Foreign = /^[a-zA-Z]{3}[0-9]{4}[0-9]{2}((01|03|05|07|08|10|12)(0[1-9]|[1-2][0-9]|3[0-1])|(04|06|09|11)(0[1-9]|[1-2][0-9]|30)|02(0[1-9]|[1-2][0-9]))[0-9]{2}$/

  // //号码规则校验
  // if (!(format.test(id) || format15Foreign.test(id))) {
  //   return '请输入正确的外国人永久居留身份证号码'
  // }
  // //区位码校验
  // if (id.length == 18) {
  //   //出生年月日校验  前正则限制起始年份为1900;
  //   var year = id.substr(6, 4),//身份证年
  //     month = id.substr(10, 2),//身份证月
  //     date = id.substr(12, 2),//身份证日
  //     time = Date.parse(month + '-' + date + '-' + year),//身份证日期时间戳date
  //     now_time = Date.parse(new Date()),//当前时间戳
  //     dates = (new Date(year, month, 0)).getDate()//身份证当月天数
  //   if (time > now_time || date > dates) {
  //     return '请输入正确的外国人永久居留身份证号码'
  //   }

  //   //校验码判断
  //   var c = new Array(7, 9, 10, 5, 8, 4, 2, 1, 6, 3, 7, 9, 10, 5, 8, 4, 2)  //系数
  //   var b = new Array('1', '0', 'X', '9', '8', '7', '6', '5', '4', '3', '2') //校验码对照表
  //   var id_array = id.split('')
  //   var sum = 0
  //   for (var k = 0; k < 17; k++) {
  //     sum += parseInt(id_array[k]) * parseInt(c[k])
  //   }
  //   if (id_array[17].toUpperCase() != b[sum % 11].toUpperCase()) {
  //     return '请输入正确的外国人永久居留身份证号码'
  //   }
  // }
  // if (id.length === 15) {
  //   let yearNum = id.substr(7, 2)
  //   let year = (Number(yearNum) >= 0 && Number(yearNum) <= 17) ? ('20' + yearNum) : ('19' + yearNum)
  //   let month = id.substr(9, 2)
  //   let date = id.substr(11, 2)
  //   time = Date.parse(month + '-' + date + '-' + year),//身份证日期时间戳date
  //   now_time = Date.parse(new Date()),//当前时间戳
  //   dates = (new Date(year, month, 0)).getDate()//身份证当月天数
  //   if (time > now_time || date > dates) {
  //     return '请输入正确的外国人永久居留身份证号码'
  //   }
  // }
  if (!id) {
    return '请输入证件号码'
  }
  let pattern = new RegExp(/^([a-zA-Z0-9]{15}|[a-zA-Z0-9]{18})$/)
  if (!pattern.test(id)) {
    return '请输入正确的证件号码'
  }
  return true
}

/**
 * 座机校验
 * @param tel
 */
const rules_tel = function (tel) {
  let pattern = new RegExp(
    /^0\d{2,3}-?\d{7,8}$/
  )
  if (!pattern.test(tel)) {
    return '请输入正确的座机号'
  }

  return true
}

/**
 * 邮箱校验
 * @param email
 */
const rules_email = function (email) {
  let pattern = new RegExp(
    /^[a-zA-Z0-9_.-]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/
      // /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/
 // /^([a-zA-Z0-9_-])+@([a-zA-Z0-9_-])+(\.[a-zA-Z0-9_-])+/
  // /^[0-9a-zA-Z]+([.-_]*[0-9a-zA-Z]+)*@([0-9a-zA-Z]+[-_]*[0-9a-zA-Z]+.)+[0-9a-zA-Z]{2,6}$/
  )

  if (!pattern.test(email)) {
    return '请输入正确的邮箱'
  } else {
    let pattern2 = new RegExp(  /^[^\s@]+(?!\w+\.[a-zA-Z]{2,4})$/)
    if (!pattern2.test(email.split('@')[0])) {
      return '输入邮箱有误'
    }
  }
  return true
}

/**
 * 银行卡号校验
 * @param bankN
 */
const rules_bankNo = function (email) {
  let pattern = new RegExp(/^\d{16,19}$/)

  if (!pattern.test(email)) {
    return '请输入正确的银行卡号'
  }
  return true
}
/**
 * 邮编校验
 * @param bankN
 */
const rules_zipCode = function (email) {
  let pattern = new RegExp(/^[1-9]\d{5}$/)

  if (!pattern.test(email)) {
    return '请输入正确的邮编'
  }
  return true
}
function isNumber(value) {
  return !isNaN(parseFloat(value)) && isFinite(value)
}
/**
 * 年收入校验
 * @param bankN
 */
const rules_salary = function (email) {
  if (Number(email) === 0) {
    return true
  }
  console.log(email, 'email111')
  console.log(isNumber((email)), 'isNaN(email)' )
  // let pattern = new RegExp(/^(?![+-]?0(\.\d+)?$)[+-]?(\d+(\.\d*)?|\.\d+)$/)
  if (!isNumber(email) || Number(email) < 0) {
    return '请输入正确的年收入'
  }
  return true
}

/**
 * @desc 校验身份证号是否合法
 * @param {String} idCardNum 18 位身份证号
 * @return {Boolean}
 */
const rules_idCardNum = function (idCardNum) {
  // 非法字符串
  if (typeof idCardNum !== 'string') return '请输入正确的身份证号'
  // 所有身份证前两位代表的是地区
  const city = { 11: '北京', 12: '天津', 13: '河北', 14: '山西', 15: '内蒙古', 21: '辽宁', 22: '吉林', 23: '黑龙江 ', 31: '上海', 32: '江苏', 33: '浙江', 34: '安徽', 35: '福建', 36: '江西', 37: '山东', 41: '河南', 42: '湖北 ', 43: '湖南', 44: '广东', 45: '广西', 46: '海南', 50: '重庆', 51: '四川', 52: '贵州', 53: '云南', 54: '西藏 ', 61: '陕西', 62: '甘肃', 63: '青海', 64: '宁夏', 65: '新疆', 71: '台湾', 81: '香港', 82: '澳门', 91: '国外' }
  const birthday = idCardNum.substr(6, 4) + '/' + Number(idCardNum.substr(10, 2)) + '/' + Number(idCardNum.substr(12, 2))
  const d = new Date((birthday))
  const newBirthday = d.getFullYear() + '/' + Number(d.getMonth() + 1) + '/' + Number(d.getDate())
  const currentTime = new Date().getTime()
  const time = d.getTime()
  // 身份证系数列表
  const arrInt = [7, 9, 10, 5, 8, 4, 2, 1, 6, 3, 7, 9, 10, 5, 8, 4, 2]
  // 设定身份证通过相加运算得到值对应的身份证最后一位的列表
  const arrCh = ['1', '0', 'X', '9', '8', '7', '6', '5', '4', '3', '2']
  let sum = 0
  // 非法身份证
  // if (!/^\d{17}(\d|x)$/i.test(idCardNum)) return false
  if (!/^\d{17}(\d|x)$/i.test(idCardNum)) return '请输入正确的身份证号'
  // 非法地区
  // if (city[idCardNum.substr(0, 2)] === undefined) return false
  if (city[idCardNum.substr(0, 2)] === undefined) return '请输入正确的身份证号'
  // 非法生日
  // if (time >= currentTime || birthday !== newBirthday) return false
  if (time >= currentTime || birthday !== newBirthday) return '请输入正确的身份证号'

  // 计算当前身份证最后一位的值
  for (let i = 0; i < 17; i++) {
    sum += idCardNum.substr(i, 1) * arrInt[i]
  }

  const residue = arrCh[sum % 11]
  // 非法身份证呀
  // if (residue !== idCardNum.substr(17, 1)) return false
  if (residue !== idCardNum.substr(17, 1)) return '请输入正确的身份证号'

  return true
}


