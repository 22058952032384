<style scoped>
.djsc {
  width: 128px;
  height: 128px;
}
</style>

<template>
  <a-drawer
    :title="inNew"
    :width="'70%'"
    :visible="visible"
    :body-style="{ paddingBottom: '80px' }"
    :footer-style="{ textAlign: 'right' }"
    @close="onClose"
  >
    <a-form
      :model="formData"
      :rules="formRules"
      layout="vertical"
      ref="formRef"
      autocomplete="off"
    >
      <a-input v-model="formData.id" v-show="false"/>
      <a-row :gutter="10">
        <a-col span="12">
          <a-form-item
            :rules="[{ required: true, message: '必填信息' ,trigger: 'change'}]"
            label="责任名称:" name="dutyName">
            <a-input v-model:value="formData.dutyName" :disabled="isDisable" placeholder="请输入责任名称"></a-input>
          </a-form-item>
        </a-col>
        <a-col span="12">
          <a-form-item
            :rules="[{ required: true, message: '必填信息' ,trigger: 'change'}, { pattern:/^[0-9a-zA-Z]+$/, message: '支持数字和字母格式' ,trigger: 'change'}]"
            label="责任编码:" name="dutyCode">
            <a-input v-model:value="formData.dutyCode" :disabled="isDisable" placeholder="请输入责任编码"/>
          </a-form-item>
        </a-col>
        <a-col span="12">
          <a-form-item
            :rules="[{ required: true, message: '必填信息' ,trigger: 'change'}]"
            label="财寿险标识:" name="isInsurance">
            <a-select v-model:value="formData.isInsurance" placeholder="请选择财寿险标识" :options="caishouxianbiaoshi"
                      :disabled="isDisable" ></a-select>
          </a-form-item>
        </a-col>
        <a-col span="12">
          <a-form-item
            :rules="[{ required: true, message: '必填信息' ,trigger: 'change'}]"
            label="责任类型:" name="dutyType">
            <a-select v-model:value="formData.dutyType" :options="zerenleixing" placeholder="请选择责任类型"
                      :disabled="isDisable"  show-search :filter-option="filterOption"></a-select>
          </a-form-item>
        </a-col>
        <a-col span="12">
          <a-form-item
            label="是否界面显示:" name="isShowStatus" :rules="[{ required: true, message: '必填信息' ,trigger: 'change'}]">
            <a-select v-model:value="formData.isShowStatus" :options="shifouyemianzhanshi"  placeholder="请选择是否界面显示"
                      :disabled="isDisable"></a-select>
          </a-form-item>
        </a-col>
        <!-- <a-col span="12">
          <a-form-item
            :rules="[{ required: true, message: '必填信息' ,trigger: 'change'}]"
            label="保额选择方式:" name="coverageWay"
          >
            <a-select v-model:value="formData.coverageWay" :options="baoefangshi"
                      :disabled="isDisable"></a-select>
          </a-form-item>
        </a-col> -->
      <!--  <a-col span="12">
          <a-form-item label="是否关联保费计算:" name="isRelationInsure">
            <a-select v-model:value="formData.isRelationInsure" :options="shifouyemianzhanshi"
                      :disabled="isDisable"></a-select>
          </a-form-item>
        </a-col>-->
        <!-- <a-col span="12">
          <a-form-item label="关联保额比例(%):" name="coverageRelationScale"
           :rules="[{ pattern:/^([1-9][0-9]{0,1}|100)$/, message: '支持正整数格式且小于100' ,trigger: 'change'}]">
            <a-input v-model:value="formData.coverageRelationScale" :disabled="isDisable"></a-input>
          </a-form-item>
        </a-col> -->
        <a-col span="24">
          <a-form-item label="责任描述:" name="note">
            <a-textarea v-model:value="formData.dutyIntroduce" :disabled="isDisable" :rows="4"/>
          </a-form-item>
        </a-col>
      </a-row>
      <div style="position: relative;" v-if="formData.coverageWay == 2">
        <div>投保保额列表</div>
        <a-table
          :columns="columns"
          :data-source="formData.insurePremiumList"
          :scroll="{ x: 300 }"
          :pagination="false"
          style="width: 450px;"
          :row-key="(record) => record.id"
          :row-selection="{
			selectedRowKeys: selectedRowKeys,
			onChange: onSelectChange,
		}">
          <template #bodyCell="{ column, index, record}">
            <template v-if="column.key === 'index'">
              {{ index + 1 }}
            </template>
            <template v-if="column.key === 'coverage'">
              <div>
                <a-input v-model:value="record.coverage" :disabled="isDisable"/>
              </div>
            </template>
            <template v-if="column.key === 'coverageValue'">
              <div>
                <a-input v-model:value="record.coverageValue" :disabled="isDisable"/>
              </div>
            </template>
          </template>
        </a-table>
        <a-button @click="add" v-if="!isDisable">增加一行</a-button>&nbsp;
        <a-button @click="del" v-if="!isDisable">删除</a-button>&nbsp;
        <a-button @click="kuisushengcheng" v-if="!isDisable">快速录入数据</a-button>
      </div>
      <div style="position: relative;" v-if="formData.coverageWay == 3">
        <div>投保保额范围列表</div>
        <a-table
          :columns="columnss"
          :data-source="formData.customInsurePremiumList"
          :scroll="{ x: 300 }"
          :pagination="false"
          :row-key="(record) => record.id"
          style="width: 700px"
          :row-selection="{
			selectedRowKeys: selectedRowKeys,
			onChange: onSelectChange,
		}">
          <template #bodyCell="{ column, index, record}">
            <template v-if="column.key === 'index'">
              {{ index + 1 }}
            </template>
            <template v-if="column.key === 'coverage'">
              <div>
                <a-input-number :min="1" v-model:value="record.coverage" :disabled="isDisable"/>
              </div>
            </template>
            <template v-if="column.key === 'coverageValue'">
              <div>
                <a-input v-model:value="record.coverageValue" :disabled="isDisable"/>
              </div>
            </template>
            <template v-if="column.key === 'endCoverage'">
              <div>
                <a-input-number :min="1"  v-model:value="record.endCoverage" :disabled="isDisable"/>
              </div>
            </template>
            <template v-if="column.key === 'endCoverageValue'">
              <div>
                <a-input v-model:value="record.endCoverageValue" :disabled="isDisable"/>
              </div>
            </template>
          </template>
        </a-table>
        <a-button @click="add1" v-if="!isDisable">增加一行</a-button>&nbsp;
        <a-button @click="del1" v-if="!isDisable">删除</a-button>&nbsp;
      </div>
      <quickEnter ref="quickEnterRef" @tableChange="tableChange"></quickEnter>
    </a-form>
    <template #extra>
      <a-space>
        <a-button @click="onClose">
          <template #icon>
            <CloseOutlined/>
          </template>
          关闭
        </a-button>
        <a-button html-type="submit" type="primary" @click="onSave()" v-if="!isDisable">
          <template #icon>
            <SaveOutlined/>
          </template>
          提交
        </a-button>
      </a-space>
    </template>
  </a-drawer>
</template>

<script>
import {SaveOutlined} from '@ant-design/icons-vue'
import {defineComponent, reactive, toRefs, ref, computed, onBeforeMount} from 'vue'
import {message} from 'ant-design-vue'
import {getRequestURI, request} from '../../../utils/request'
import {getExtendUri} from '../../../utils/request_extend'
import quickEnter from '../../../components/extended/quickEnter'
import {getDictionaryData} from '../../../utils/request_extend'
export default defineComponent({
  components: {
    SaveOutlined, quickEnter
  },
  props: {
    status: {
      type: String,
      required: true,
      default() {
        return '123'
      }
    },
    idd: {
      type: [Number, String],
      default: () => ''
    },
    searchData: {
      type: Object,
      default: () => {}
    },
    current: {
      type: Number,
      default: () => 1
    }

  },
  emits: ['select'],
  setup(props, {expose, emit}) {
    const formRef = ref()
    const quickEnterRef = ref()
    const formData = reactive({
      comDutyCode: '',
      comDutyName: '',
      coverageRelationScale: '',
      coverageWay: '1',
      createUser: '',
      dutyCode: '',
      dutyDuration: 0,
      dutyDurationUnit: '',
      dutyIntroduce: '',
      dutyName: '',
      dutyType: undefined,
      id: '0',
      customInsurePremiumList: [
        // {
        //   createTime: '',
        //   createUser: '',
        //   id: 0,
        //   dutyId: 0,
        //   endPremium: 0,
        //   endPremiumValue: '',
        //   premium: 0,
        //   premiumValue: '',
        //   updateTime: '',
        //   updateUser: ''
        // }
      ],
      insurePremiumList: [
        // {
        //   createTime: '',
        //   createUser: '',
        //   id: 0,
        //   dutyId: 0,
        //   endPremium: 0,
        //   endPremiumValue: '',
        //   premium: 0,
        //   premiumValue: '',
        //   updateTime: '',
        //   updateUser: ''
        // }
      ],
      isDel: 0,
      isInsurance: null,
     /* isRelationInsure: 'Y',*/
      isShowStatus: undefined,
      updateUser: ''
    })
    const kuisushengcheng = function() {
      quickEnterRef.value.show()
    }
    const shifouyemianzhanshi = ref([])
    const baoefangshi = ref([])
    const caishouxianbiaoshi = ref([])
    const zerenleixing = ref([])
    onBeforeMount(()=>{
      getDictionaryData(shifouyemianzhanshi, 'yes_no')
      getDictionaryData(baoefangshi, 'amntChoiceWay')
      getDictionaryData(caishouxianbiaoshi, 'riskType')
      getDictionaryData(zerenleixing, 'duty_type')
    })
    const filterOption = (input, option) => {
      return option.label.indexOf(input) > -1
    }
    const listData = reactive({
      options: [],

      zerenbaoxianqijian: [
        {
          label: '请选择',
          value: '00'
        },
        {
          label: '年',
          value: 'Y'
        },
        {
          label: '月',
          value: 'M'
        },
        {
          label: '日',
          value: 'D'
        },
        {
          label: '年龄',
          value: 'A'
        }
      ],
      shifoutoubaoguanlian: [
        {
          label: '是',
          value: 'Y'
        },
        {
          label: '否',
          value: 'N'
        }

      ],
    })
    const formRules = {
      realName: [{required: true}],
      phoneNo: [{required: true}],
      email: [{required: true, type: 'email'}],
      userStatus: [
        {
          required: true,
          type: 'number'
        }
      ],
      startDate: [
        {
          required: true,
          type: 'date'
        }
      ]
    }
    const state = reactive({
      visible: false,
      isNew: true,
      options: [],
      columns: [
        {
          title: '序号',
          key: 'index',
          fixed: 'left',
          width: 60,
          align: 'left'
        },
        {
          title: '保额',
          key: 'coverage',
          fixed: 'right',
          width: 100
        },
        {
          title: '保额显示值',
          key: 'coverageValue',
          fixed: 'right',
          width: 100
        }
      ],
      columnss: [
        {
          title: '序号',
          key: 'index',
          fixed: 'left',
          width: 60,
          align: 'left'
        },
        {
          title: '起始保额',
          key: 'coverage',
          fixed: 'right',
          width: 100
        },
        {
          title: '保额显示值',
          key: 'coverageValue',
          fixed: 'right',
          width: 100
        },
        {
          title: '终止保额',
          key: 'endCoverage',
          fixed: 'right',
          width: 100
        },
        {
          title: '终止保额显示值',
          key: 'endCoverageValue',
          fixed: 'right',
          width: 100
        }
      ],
      selectedRowKeys: [],
      transferStyle: {width: '50%'},
      roleTitles: ['未添加授权角色列表', '已添加角色列表'],
      roleData: []
    })

    const showDrawer = (oldData = {}) => {
      state.visible = true
      state.isNew = !(typeof oldData.id === 'string' && oldData.id.length > 0)
      console.log('editNewUser', state.isNew, oldData)
      console.log(formData.coverageWay)
      Object.keys(formData).forEach((key) => {
        formData[key] = oldData[key] || formData[key]
      })
      if (oldData.coverageWay == 2) {
        formData.insurePremiumList = oldData.insurePremiumList
        // 如果是自定义
      } else if (oldData.coverageWay == 3) {
        formData.customInsurePremiumList = oldData.customInsurePremiumList
      }
    }

    const onClose = () => {
      state.visible = false
      formRef.value.clearValidate()
        formRef.value.resetFields()
        formData.comDutyCode = ''
        formData.comDutyName = ''
        formData.coverageRelationScale = ''
        formData.coverageWay = '1'
        formData.createUser = ''
        formData.dutyCode = ''
        formData.dutyDuration = 0
        formData.dutyDurationUnit = ''
        formData.dutyIntroduce = ''
        formData.dutyName = ''
        formData.dutyType = undefined
        formData.id = 0
        formData.insurePremiumList = []
        formData.isDel = 0
        formData.isInsurance = undefined
       /* formData.isRelationInsure = 'Y',*/
        formData.isShowStatus = undefined
        formData.updateUser = ''
    }
    const onFormClean = () => {
      formRef.value.resetFields()
      state.targetKeys = []
      state.visible = false
    }
    const onSave = async () => {
      await formRef.value.validate()
      let reg1 = /^[0-9a-zA-Z]+$/
      let vive1 = reg1.test(formData.dutyCode)
      if (formData.dutyCode === '') {
        message['warning']('责任编码不能为空')
        return
      }
      if (!vive1) {
        message['warning']('责任编码只支持数字和字母格式')
        return
      }

      if (props.status == 'update') {
        if (formData.coverageWay == 1) {
          formData.insurePremiumList = []
          formData.customInsurePremiumList = []
        } else if (formData.coverageWay == 2) {
          for (let item of formData.insurePremiumList) {
            let test = /^[0-9]+([.][0-9]{1,})?$/
            let testOne = test.test(item.coverage)
            if (!testOne) {
              message.warning('保额只能是数字格式')
              return
            }
          }
          formData.insurePremiumList.forEach(item => {
            if (item.dutyId == '') {
              item.dutyId = props.idd
              delete item.id
            }
          })
          formData.customInsurePremiumList = []
        } else if (formData.coverageWay == 3) {
          for (let item of formData.customInsurePremiumList) {
            let test = /^[0-9]+([.][0-9]{1,})?$/
            let testOne = test.test(item.coverage)
            let testTwo = test.test(item.endCoverage)
            if (!testOne || !testTwo) {
              message.warning('保额只能是数字格式')
              return
            }
          }
          formData.customInsurePremiumList.forEach(item => {
            if (item.dutyId == '') {
              item.dutyId = props.idd
              delete item.id
            }
          })
          formData.insurePremiumList = []
        }


        let obj = {
          data: formData
        }
        const uri = getRequestURI('duty_update')
        request(uri, obj).then(res => {
          if (res.code == 1) {
            message['success'](res.message)
            emit('select', props.searchData,props.current)
            onClose()
          } else {
            message['error'](res.message)
          }
        })
      } else {
        if (formData.coverageWay == 1) {
          formData.insurePremiumList = []
          formData.customInsurePremiumList = []
        } else if (formData.coverageWay == 2) {
            for (let item of formData.insurePremiumList) {
              let test = /^[0-9]+([.][0-9]{1,})?$/
              let testOne = test.test(item.coverage)
              if (!testOne) {
                message.warning('保额只能是数字格式')
                return
              }
            }
          formData.insurePremiumList.forEach(item => {
            if (item.dutyId == '') {
              item.dutyId = props.idd
              delete item.id
            }
          })
          formData.customInsurePremiumList = []
        } else if (formData.coverageWay == 3) {
          for (let item of formData.customInsurePremiumList) {
            let test = /^[0-9]+([.][0-9]{1,})?$/
            let testOne = test.test(item.coverage)
            let testTwo = test.test(item.endCoverage)
            if (!testOne || !testTwo) {
              message.warning('保额只能是数字格式')
              return
            }
          }
          formData.customInsurePremiumList.forEach(item => {
            if (item.dutyId == '') {
              item.dutyId = props.idd
              delete item.id
            }
          })
          formData.insurePremiumList = []
        }
        formData.insurePremiumList.forEach(item => {
          delete item.id
        })
        let obj = {
          data: formData
        }
        const uri = getRequestURI('duty_add')
        request(uri, obj).then(res => {
          if (res.code == 1) {
            emit('select')
            message['success'](res.message)
            console.log(res)
            onClose()
          } else {
            message['error'](res.message)
          }
        })
      }
    }
    const del = function () {
      if (state.selectedRowKeys.length === 0) {
        message.warning('请选择数据')
        return
      }
      state.selectedRowKeys.forEach((item) => {
        formData.insurePremiumList.forEach((item1, index) => {
          if (item1.id == item) {
            formData.insurePremiumList.splice(index, 1)
          }
        })
      })
    }
    // 快速生成
    const tableChange = function(list, status) {
      if (status === true) {
        list.forEach(item => {
         item.coverageValue = item.calculatedValue
         item.coverage = item.calculated
          item.dutyId = ''
        })
        console.log(list)
        formData.insurePremiumList.push(...list)
      } else {
        formData.insurePremiumList = []
        list.forEach(item => {
          item.coverageValue = item.calculatedValue
          item.coverage = item.calculated
          item.dutyId = ''
        })
        console.log(list)
        formData.insurePremiumList.push(...list)
      }
    }

    const handleChange = info => {
      if (info.file.status !== 'uploading') {
        console.log(info.file, info.fileList)
      }

      if (info.file.status === 'done') {
        message.success(`${info.file.name} file uploaded successfully`)
      } else if (info.file.status === 'error') {
        message.error(`${info.file.name} file upload failed.`)
      }
    }
    const isDisable = computed(() => {
      if (props.status == 'select') {
        return true
      } else {
        return false
      }
    })
    const onSelectChange = (selectedRowKeys) => {
      console.log('selectedRowKeys changed: ', selectedRowKeys)
      if (props.status === 'select') {
        console.log('sss')
      } else {
        state.selectedRowKeys = selectedRowKeys
      }

    }
    let id = 1
    const add = function () {
      id++
      formData.insurePremiumList.push({
        createTime: '',
        createUser: '',
        dutyId: 0,
        id: id,
        endCoverage: 1,
        endCoverageValue: '',
        coverage: 1,
        coverageValue: '',
        updateTime: '',
        updateUser: ''
      })
    }
    let idds = 1
    const add1 = function() {
      idds++
      formData.customInsurePremiumList.push({
        createTime: '',
        createUser: '',
        dutyId: 0,
        id: idds,
        endCoverage: 1,
        endCoverageValue: '',
        coverage: 1,
        coverageValue: '',
        updateTime: '',
        updateUser: ''
      })
    }
    const del1 = function() {
      if (state.selectedRowKeys.length === 0) {
        message.warning('请选择数据')
        return
      }
      state.selectedRowKeys.forEach((item) => {
        formData.customInsurePremiumList.forEach((item1, index) => {
          if (item1.id == item) {
            formData.customInsurePremiumList.splice(index, 1)
          }
        })
      })
    }
    const inNew = computed(() => {
      if (props.status == 'select') {
        return '责任库查看'
      } else if (props.status == 'add') {
        return '责任库增加'
      } else {
        return '责任库修改'
      }
    })
    const init = function () {
      const uri = getRequestURI('duty_selectList')
      request(uri, {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        }
      }, true, 'post').then(res => {
        console.log(res.result)
        // listData.options = res.result
        // console.log(listData.options)
        res.result.forEach(item => {
          let obj = {}
          obj.value = item.dutyName
          state.options.push(obj)
        })
      })
      request(getExtendUri('dictionary_query_byType'), {data: {types: ['duty_type']}}).then(res => {
        console.log(res)
        res.result.duty_type.forEach(item => {
          let obj = {}
          obj.value = item.value
          obj.label = item.label
          // listData.zerenleixing.push(obj)
        })
        // console.log(listData.zerenleixing)
      })
    }
    init()
    const fileList = ref([])

    expose({
      showDrawer
    })
    return {
      zerenleixing,
      shifouyemianzhanshi,
      baoefangshi,
      caishouxianbiaoshi,
      formRef,
      formData,
      formRules,
      listData,
      isDisable,
      add,
      del,
      add1,
      del1,
      onSelectChange,
      inNew,
      ...toRefs(state),
      onClose,
      onSave,
      onFormClean,
      fileList,
      quickEnterRef,
      kuisushengcheng,
      headers: {
        authorization: 'authorization-text'
      },
      handleChange,
      tableChange,
      filterOption
    }
  }
})
</script>
