<template>
  <!-- 是否需要对应的输入框值 -->
   <div class="element_wrap">
    <van-field
      class="element-input"
      v-model="selectValue"
      is-link
      readonly
      :disabled="disabledType"
    :placeholder="'请选择' + `${ elementTitle }`"
      @click="showChoice"
    />
     <template v-if="pickerVisible">
       <van-popup v-model:show="pickerVisible" round position="bottom"  :lazy-render='true' >
         <van-picker
           :columns="filterGetNewOption(option)"
           :columns-field-names="pickerField"
           @confirm="onPickerConfirm"
           @cancel="onPickerClose"

           ref="pickerRef"
         />
       </van-popup>
     </template>

  </div>
</template>

<script>
import {Picker, Popup, Field} from 'vant'
import 'vant/lib/index.css'
import {defineComponent, onBeforeMount, onBeforeUpdate, onMounted, reactive, toRefs, watch,ref, inject, nextTick} from 'vue'
import {useRouter} from 'vue-router'
// import {getDictionaryData} from './publicMethod'
import {request} from '../../../../utils/request'
import {getExtendUri} from '../../../../utils/request_extend'
import store from '@/store'
import {getNewOption} from '@/utils/relational_mapping_helper.js'
export default defineComponent({
  components:{
    [Picker.name]: Picker,
    [Popup.name]: Popup,
    [Field.name]: Field
  },
  name: 'SelectView',
  props: {
    moduleIndex:{
      type: [Number,String],
      default:()=> '0'
    },
     moduleId:{ // 模块ID
      type: [Number,String],
      default:()=> ''
    },
    elementId:{ // 元素ID
      type: [Number,String],
      default:()=> ''
    },
    dictionary: {
      type: String,
      default: () => ''
    },
    title: {
      type: String,
      default: () => ''
    },
    elementTitle:{
      type: String,
      default: () => ''
    },
    options: {
      type: [Array,Object],
      default: () => []
    },
    elementCode: {
      type: [Number,String],
      default: () => ''
    },
    isRequired:{
      type: [Number,String],
      default:()=> '0'
    },
    firstValue:{
      type: [Number,String],
      default:()=> null
    },
    disabledType: {
      type: Boolean,
      default:()=> false
    },
    // 是否默认展示
    defaultRequired:{
      type: String,
      default: () => ''
    },
    detailType:{
      type: String,
      default: () => ''
    },
    insuredsType:{
      type: String,
      default: () => ''
    },
    insuredsIndex:{
      type: [Number,String],
      default: () => ''
    },
    isShow:{
      type: Boolean,
      default: () => false
    },
    isDisabled:{
      type: Boolean,
      default: () => false
    },
    insuredObj:{
      type: Object,
      default: () => {}
    },
  },
  emits:['callback','update:firstValue', 'handelToAppnt'],
  setup(props, context) {
    const router = useRouter()
    const State = reactive({
      dictionary: props.dictionary,
      title: props.title,
      option: [],
      tips: '',
      selectValue: undefined,
      selectId: undefined,
      elementCode:props.elementCode,
      pickerVisible: false,
      pickerField: {text: 'label',values:'value'},
    })
    const pickerRef = ref(null)

    const handelToAppnt = inject('handelToAppnt')

    // 点击按钮的弹框
    const showChoice = async () => {
      if(props.disabledType || props.isDisabled) return
      if(props.moduleId == 'insureds' && props.elementCode === 'relationToAppnt'){
          let data = handelToAppnt() || {}
          if(data.status === 'Y' && data.index !== null ){
            if(props.moduleIndex == data.index){
              pickerRef.value?.setColumnValues(0, State.option)
              State.pickerVisible = true
              return
            }
            for (let i = 0; i < State.option.length; i++) {
              if(State.option[i].value === '01'){
                State.option.splice(i,1)
              }
            }
            pickerRef.value?.setColumnValues(0, State.option)
          }else{
            getDictionaryValue()
          }
        }
      State.pickerVisible = true


    }


    const onPickerConfirm = (data) =>{
      State.pickerVisible = false
      State.selectValue = data[0].label
      State.selectId = data[0].value
      getData(State.selectId)

    }


    // 先找到 必填标识
    watch(() => props.defaultRequired, (newVal)=>{
      if(newVal.defaultRequired === 'Y' && !props.isShow  && props.firstValue !== '新增'){
        handelConsentDetail()
      }
    }, {deep: true, immediate: true})
    watch(() => props.firstValue, () => {
      if ((props.firstValue || props.firstValue === 0) && !props.isShow  && props.firstValue !== '新增') {
        handelConsentDetail()
      }
    })
    //初始默认值

    const  getData = (selectId) =>{
      let node = {}
      node[State.elementCode] = {
        [State.elementCode] : selectId,
        elementId: props.elementId,
        moduleType: props.moduleId,
        index:props.moduleIndex,
        required: props.isRequired,
        elementName: props.title,
        label: State.selectValue
      }
      context.emit('update:firstValue', selectId)
      context.emit('callback',node)
    }


    const onPickerClose = () =>{
      State.pickerVisible = false
    }


    // 监视是否是关联字典的 和 初始默认值
    const handelConsentDetail = () =>{
      const continuousRenew = router.currentRoute.value.query?.continuousRenew
      if(!props.dictionary){
        State.option = JSON.parse(JSON.stringify(props.options.dropDownConfiguration))
        console.log(State.option, '111112')
        if (State.elementCode === 'relationToAppnt') {
          // console.error('relationToAppnt', State.option)
          State.option = getNewOption(State.option)
        }
        if(State.option.length){
          if(props.defaultRequired === 'Y' && !props.firstValue){
            State.selectValue =  State.option[0].label
            State.selectId = State.option[0].value
          }else if(props.firstValue){
            let list = State.option.filter(item => item.value == props.firstValue)
            if(list.length){
              State.selectValue = list[0].label
              if (props.firstValue !== State.selectId && props.firstValue !== '新增' && continuousRenew){
                getData(State.selectId)
              }
              State.selectId = list[0].value
            }
          }
        }
      } else if(props.dictionary) {
        if(props.defaultRequired === 'Y' && (!props.firstValue || props.firstValue === '01')){
          let data = store.getters['dictionary/getData'](props.dictionary)
          if (data) {
            State.option = data
            if (State.elementCode === 'relationToAppnt') {
              // console.error('relationToAppnt', State.option)
              State.option = getNewOption(State.option)
            }
            State.selectValue =  State.option?.[0]?.label ?? null
            State.selectId = State.option?.[0]?.value ?? null
            console.log(State.option, State.selectId, 'ttyyyy')
          } else {
            request(getExtendUri('dictionary_query_byType'), {data: {types: [props.dictionary]}}).then(result => {
              if (result.code === 1) {
                let d = result.result[props.dictionary]
                State.option =  result.result[props.dictionary]
                State.selectValue =  State.option?.[0]?.label ?? null
                State.selectId = State.option?.[0]?.value ?? null
                console.log(State.option, State.selectId, 'ttyuuu')
                if (props.firstValue !== State.selectId && props.firstValue !== '新增'){
                  getData(State.selectId)
                }
                store.commit('dictionary/setCache', {type: props.dictionary, data: d})
              }
            })
          }
        }else if(props.firstValue && props.firstValue !== State.selectId){
          let data = store.getters['dictionary/getData'](props.dictionary)
          if (data) {
            State.option = data
          } else {
            request(getExtendUri('dictionary_query_byType'), {data: {types: [props.dictionary]}}).then(result => {
              if (result.code === 1) {
                let d = result.result[props.dictionary]
                State.option =  result.result[props.dictionary]
                store.commit('dictionary/setCache', {type: props.dictionary, data: d})
                State.selectValue =  State.option.filter(item => item.value === props.firstValue)?.[0]?.label ?? null
                State.selectId = State.option.filter(item => item.value === props.firstValue)?.[0]?.value ?? null
                if (props.firstValue !== State.selectId && props.firstValue !== '新增' && continuousRenew){
                  getData(State.selectId)
                }
              }
            })
          }
          State.selectValue =  State.option.filter(item => item.value === props.firstValue)[0]?.label
          if (props.firstValue !== State.selectId && props.firstValue !== '新增' && continuousRenew){
            getData(State.selectId)
          }
          State.selectId = State.option.filter(item => item.value === props.firstValue)[0]?.value
        } else if(!props.firstValue && props.firstValue !== State.selectId) {
          State.selectId = null
          State.selectValue = null
        }
      }

      if (props.firstValue !== State.selectId && props.firstValue !== '新增'){
        getData(State.selectId)
      }

    }


    // 初始化 下拉框数据
    const getDictionaryValue = () =>{
      if(!props.dictionary){
        State.option = JSON.parse(JSON.stringify(props.options.dropDownConfiguration))
        // console.error(' State.option = JSON.parse(JSON.stringify(props.options.dropDownConfiguration))', State.option)
        return
      }
      request(getExtendUri('dictionary_query_byType'),
          {data: {ids:[],types:[props.dictionary]}}).then(
            res =>{
              if(res.code == 1){
                State.option =  res.result[props.dictionary]
                // console.error('State.option =  res.result[props.dictionary]', State.option)
                nextTick(()=>{
                  pickerRef?.value?.setColumnValues(0, State.option)
                })
              }
            })
    }

    onMounted(()=>{
        handelConsentDetail()
    })
    onBeforeMount(()=>{
      getDictionaryValue()
    })
    onBeforeUpdate(()=>{
    })
    const filterGetNewOption = (options) => {
      let str = props.moduleId === 'applicant' ? 'appntIdType' : 'idType'
      console.log(props.insuredObj, 'props.insuredObj')
      let idType = props.insuredObj?.[props.moduleId]?.[props.moduleIndex]?.[str]?.[str] ?? ''
      console.log(idType, props.elementCode, '11112222')
      if (props.elementCode === 'nativePlace' && idType === '15') {
        return getNewOption(options).filter(item => {
          return item.value !== 'CHN'
        })
      } else if (props.elementCode === 'nativePlace' && idType === '01'){
        return getNewOption(options).filter(item => {
          return item.value === 'CHN'
        })
      }
      else {
        return getNewOption(options)
      }
    }
    return {
      pickerRef,
      handelToAppnt,
      ...toRefs(State),
      showChoice,
      onPickerConfirm,
      onPickerClose,
      handelConsentDetail,
      getDictionaryValue,
      getNewOption,
      filterGetNewOption
    }
  }

})
</script>

<style scoped>
:deep(.ant-input){
  padding: 0 !important;
}
.element_wrap {
  width: 100%;
  text-align: right;
}
.element-input {
  outline: none;
  text-align: right;
}
:deep(.van-picker__columns) {
  display: block !important;
}
:deep(.van-picker-column) {
  height: 12rem !important;
}
:deep(.van-popup) {
  overflow-y: hidden !important;
}
.element-input /deep/ .van-field__body {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
:deep(.van-field__control) {
  text-align: right;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 60%;
}
.van-cell.van-cell--clickable.van-field.element-input {
  padding: 0.5rem 0 !important;
}
.van-cell:after{
  border-bottom: 0;
}
:deep(.van-picker__toolbar){
  height: 23px;
}

:root :root{
  --van-picker-toolbar-height: 0px;
}
</style>
