<template>
  <!-- <div class="header">
		<div class="health-config"> -->
			<a-form v-bind="formItemLayout">
				<a-form-item>
          <template #label>
            <span>是否校验</span>
            <span>
                <a-popover placement="right">
                  <template #content>
                  <p>
                    会在执行下一页的时候校验是否已填
                  </p>
                  </template>
                  <template #title>
                  <span>规则说明:</span>
                  </template>
                  <question-circle-outlined />
                </a-popover>
                </span>
          </template>
					<a-radio-group v-model:value="heaData.isCheck">
						<a-radio :value="true">是</a-radio>
						<a-radio :value="false">否</a-radio>
					</a-radio-group>
				</a-form-item>
				<a-form-item label="匹配词">
					<a-tag closable @close="handelDelLog(index)" v-for="(list,index) in heaData.keywords" :key="index">{{list.label}}</a-tag>
					<a-button type="primary" size="small" @click="addKeywords">添加</a-button>
				</a-form-item>
        <a-form-item>
          <template #label>
            <span>表达式</span>
            <span>
                <a-popover placement="right">
                  <template #content>
                  <p>
                    通过该表达式，决定该健康告知问题是否在前端显示，目前支持对投被保人性别，年龄进行校验，格式为“对象类型:判断表达式”。
                    <br>1. 如投保人为男且年龄大于2，则可录入“applicant: age>=2 && sex==='男'”
                    <br>2. 如被保人为女性且年龄大于15，则可录入“insureds: age>=15 && sex==='女'”
                    <br>3. 当既有投保人也有被保人时，支持&&或||组合判断，如"(applicant: age>=2 && sex==='男') && (insureds: age>=15 && sex==='女')"
                  </p>
                  </template>
                  <template #title>
                  <span>规则说明:</span>
                  </template>
                  <question-circle-outlined />
                </a-popover>
                </span>
          </template>
					<a-input placeholder="并且：&&、或者：||、等于：===" v-model:value="heaData.expressionValue" />
				</a-form-item>
				<a-form-item>
          <template #label>
            <span>选择方式</span>
            <span>
                <a-popover placement="right">
                  <template #content>
                  <p>
                    选择方式指的是健康告知选项如何展现
                    <br>1. 单选：在前端展示为单选按钮，需要配置选项，默认选项值从1开始递增
                    <br>2. 输入：在前端展示为录入框，会联动出问题展示区域字段和添加空字符、设置文本属性、重置功能按钮
                    <br>&emsp;&emsp;a). 问题展示区域会在前端展示
                    <br>&emsp;&emsp;b). 添加填空符会在问题展示区域出现下划线表示需要填写框，若添加了填空符则需要“设置文本属性”配置填空符的属性、范围、和key值，当有多个填空符时，key值不能相同且不能与元素编码相同，一般为元素编码后面添加点和序号
                    <br>3. 多选：在前端展示为多选按钮，需要配置选项，默认选项值从1开始递增
                  </p>
                  </template>
                  <template #title>
                  <span>规则说明:</span>
                  </template>
                  <question-circle-outlined />
                </a-popover>
                </span>
          </template>
					<a-select v-model:value="heaData.way" @change="handleChangeWay" placeholder="请选择方式">
						<a-select-option :value="1">单选</a-select-option>
						<a-select-option :value="2">输入</a-select-option>
						<a-select-option :value="3">多选</a-select-option>
					</a-select>
				</a-form-item>
        <!-- label="元素题目" -->
				<a-form-item v-show="isImport">
					<!-- <a-input placeholder="元素题目" v-model:value="heaData.eleTopic"></a-input> -->
          <InputTypeConfig ref="inputConfig"/>
				</a-form-item>
        <a-form-item label="配置选项" v-show="!isImport">
          <health-option :single-option="heaData.way"
                         ref="healthOptionRef"></health-option>
         </a-form-item>

			</a-form>
		<!-- </div> -->
		<a-modal destroyOnClose v-model:visible="visible" :maskClosable="false" :keyboard="false" title="添加匹配词" @cancel="onClose" @ok="handleOk">
      <a-form :model="modelData" v-bind="formItemLayout">
				<a-form-item label="匹配词类型">
					<a-select v-model:value="modelData.modelWay" @change="handleType" placeholder="请选择方式">
						<a-select-option :value="way.value" v-for="way in modelData.modelWayList" :key="way.label">{{way.label}}</a-select-option>
					</a-select>
				</a-form-item>
				<!-- 当匹配类型是 范围值时展示 -->
				<a-form v-bind="formItemLayout" v-if="isRangeShow || isNodeShow">
					<a-form-item :label="modelData.typeData" v-if="isRangeShow">
						<div v-for="(range,index) in modelData.rangeList" :key="index">
							<div v-if="modelData.modelWay === 5" style="margin-bottom:5px">
								<a-select v-model:value="range.type" style="width:200px;margin:10px 0 10px 35px;display: block;  ">
									<!-- <a-select-option value="time">Time</a-select-option> -->
									<a-select-option value="date">Date</a-select-option>
									<a-select-option value="week">Week</a-select-option>
									<a-select-option value="month">Month</a-select-option>
									<a-select-option value="year">Year</a-select-option>
								</a-select>
								起始:
								<a-space direction="vertical" :size="12">
									<a-date-picker @blur="onBlur" @change="onChange(index)"  v-model:value="range.startData"  :picker="range.type" style="width: 200px"/>
								</a-space><br/>
								<div style="margin: 5px 0"></div>
								<span>结束: </span>
								<a-space direction="vertical"  :size="12">
									<a-date-picker :disabled-date="disabledDate" v-model:value="range.endData"  :picker="range.type" style="width: 200px"/>
								</a-space>
							</div>
							<div v-if="modelData.modelWay === 7" style="margin-bottom: 10px">
								起始:&nbsp;
								<a-select style="width:100px" v-model:value="range.startData">
									<a-select-option :value="num" @blur="onBlur" @change="onChange(index)" v-for="num in 24" :key="num">{{num}}</a-select-option>
								</a-select>
								<a-select style="width:100px;margin-left: 1px;" v-model:value="range.type">
									<a-select-option value="小时">小时</a-select-option>
									<a-select-option value="分钟">分钟</a-select-option>
									<a-select-option value="秒">秒</a-select-option>
								</a-select><br/>
								<span style="margin-top: 10px; display: inline-block;">结束: &nbsp;</span>
								<a-select style="width:100px" v-model:value="range.endData">
									<a-select-option :value="num" @blur="endDataBlur($event,index)" v-for="num in 24" :key="num">{{num}}</a-select-option>
								</a-select>
								<a-select style="width:100px;margin-left: 1px;" v-model:value="range.type">
									<a-select-option value="小时">小时</a-select-option>
									<a-select-option value="分钟">分钟</a-select-option>
									<a-select-option value="秒">秒</a-select-option>
								</a-select>
							</div>
							<div v-if="modelData.modelWay === 3" style="margin-bottom: 10px">
								起始:
								<a-input style="width:100px" @blur="onBlur" @change="onChange(index)" v-model:value="range.startData"/>
								<span style="margin-left: 20px">结束: </span>
								<a-input style="width:100px" @blur="endDataBlur($event,index)" v-model:value="range.endData"/>
							</div>
						</div>
					</a-form-item>
					<a-form-item :wrapper-col="{ span: 14, offset: 13 }" v-if="isRangeShow">
						<a-button @click="addRange" size="small">+ 增加</a-button>
						<a-button style="margin-left: 10px" size="small" @click="delRange">- 删除</a-button>
					</a-form-item>
					<!-- 节点 展示  需要验证 -->
					<a-form-item label="录入关键字" v-if="isNodeShow">
						<div v-if="isInputNode">
							<a-input v-model:value="modelData.modelValue"/>
						</div>
						<div v-if="modelData.modelWay === 6">
							<a-select v-model:value="modelData.modelValue" style="width:60px" >
									<a-select-option :value="num" v-for="num in 24" :key="num">{{num}}</a-select-option>
								</a-select>
								<a-select v-model:value="modelData.nodeDataValue" style="width:70px;margin-left: 1px;">
									<a-select-option value="小时">小时</a-select-option>
									<a-select-option value="分钟">分钟</a-select-option>
									<a-select-option value="秒">秒</a-select-option>
								</a-select>
						</div>
						<div v-if="modelData.modelWay === 4">
							<a-select v-model:value="modelData.nodeDateType" style="width:100px;margin-bottom:10px;display: block;  ">
									<a-select-option value="date">Date</a-select-option>
									<a-select-option value="week">Week</a-select-option>
									<a-select-option value="month">Month</a-select-option>
									<a-select-option value="year">Year</a-select-option>
								</a-select>
								<a-space direction="vertical" :size="12">
									<a-date-picker v-model:value="modelData.nodeDataValue"  :picker="modelData.nodeDateType" style="width: 120px"/>
								</a-space>
						</div>
					</a-form-item>
				</a-form>
			</a-form>
    </a-modal>
	<!-- </div> -->
</template>

<script>
import { message } from 'ant-design-vue'
import { defineComponent, reactive, toRefs, ref, provide, defineAsyncComponent } from 'vue'
import {vTools} from '../js/verificationTool'
import {getDateValue} from './dateJS/date'
import dayjs from 'dayjs'
import InputTypeConfig from '../element/mod/healthodConfig/InputTypeConfig'

export default defineComponent({
  components:{
    healthOption: defineAsyncComponent(() => import('../healthNotificationConfiguration/healthOption')),

    InputTypeConfig
  },
	// setup(peops,{expose}) {
	setup() {
		const state = reactive({
      inputValue: '',
      conversion: '',
      inputTypeData: {},
			heaData:{  // 列表数据
				isCheck: true,
				keywords: [],
				way: 1,
        conSpanList:[], // 配置选项列表
        eleTopic: '', // 元素题目
        expressionValue:'', // 表达式
      },
			modelData:{ // 匹配关键词
				modelWay: undefined,
				modelWayList:[
					{value: 1, label: '字符串',name: 'text', isCheck: true},
					{value: 2, label: '数字', name: 'number', isCheck: true},
					{value: 3, label: '数字范围'},
					{value: 4, label: '日期'},
					{value: 5, label: '日期范围'},
					{value: 6, label: '时间'},
					{value: 7, label: '时间范围'},
				],
				typeData:'',
				rangeList:[],
				modelValue:'', // 文本 数字的输入
        nodeDateType: '', // 日期类型
        nodeDataValue: '',  // 日期数据
			}
		})
    const getQuest = (value) =>{
      state.inputTypeData = value
    }
    provide('getQuest', getQuest)
		const showData = reactive({
			visible:false,
      // 根据选择不同的属性,展示的多种状态s
      isRangeShow: false,  // 范围
      isNodeShow: false,  // 节点
      isInputNode: false,
      isSpan : false,
      isInput: false,
      otherIsSpan: false,
      otherIsInput: false,
      isImport: false,
      isShowOther: false, // 多选中，是否弹出选择other的弹框
    })
		const formItemLayout = {
			labelCol: {
				span: 6,
			},
			wrapperCol: {
				span: 16,
			},
		}
    // 添加 关键词
		const addKeywords = () =>{
			showData.visible = true

		}


    // 匹配类型选择
		const handleType = (value,{key}) =>{
      state.modelData.modelValue = undefined
			state.modelData.typeData = key
      state.modelData.rangeList = []
      showData.isRangeShow = false
      showData.isNodeShow = false
      showData.isInputNode = false
      if(value === 3 || value === 5 || value === 7){
        showData.isRangeShow = true
        state.modelData.rangeList.push({type: undefined,startData: undefined, endData: undefined})
      } else {
        showData.isNodeShow = true
        if(value !== 4 && value !== 6){
          showData.isInputNode = true
        }
      }
		}
    // 匹配词的新增 范围
		const addRange = () =>{
      state.conversion = ''
      state.modelData.rangeList.push({type: undefined,startData: undefined, endData: undefined})
		}
    // 匹配词的删除 范围
		const delRange = () =>{
			if(state.modelData.rangeList.length <= 1) {
        return message.warning('手下留情, 再点就没啦')
      }
      state.modelData.rangeList.pop()
		}

    // 匹配词的确认
    const handleOk = () => {
      setModelData(state.modelData.modelWay)

    }

    // 根据不同的ID 返回 匹配词
    const setModelData =  (modelWay) => {
      if(modelWay === 1 || modelWay === 2){
        elementValueVerification(state.modelData.modelValue)
      } else if(modelWay === 4) {
        if (!state.modelData.nodeDataValue || !state.modelData.nodeDateType) return message.warning('请选择日期')
          let dateValue = getDateValue(state.modelData.nodeDateType, state.modelData.nodeDataValue.$d)
          let node = {
            id: state.modelData.modelWayList.length ? state.modelData.modelWayList.length + 1 : 1,
            label: dateValue
          }
          state.heaData.keywords.push(node)
          onClose()
      } else if(modelWay === 6) {
          if(!state.modelData.modelValue || !state.modelData.nodeDataValue) return message.warning('请补充完整')
          let node = {
            id: state.modelData.modelWayList.length ? state.modelData.modelWayList.length + 1 : 1,
            label: state.modelData.modelValue + state.modelData.nodeDataValue
          }
          state.heaData.keywords.push(node)
          onClose()
      } else if(modelWay === 5) {
        state.modelData.rangeList.forEach(item => {
          // console.log(item)
          if(item.type && item.startData && item.endData) {
            let startData = getDateValue(item.type,item.startData)
            let endData =getDateValue(item.type,item.endData)
            // console.log(startData,endData)
            let node = {
            id: state.modelData.modelWayList.length ? state.modelData.modelWayList.length + 1 : 1,
            label: startData + '至' + endData
          }
          state.heaData.keywords.push(node)
          onClose()
          }else{
            showData.visible = true
            message.warning('请将数据补充完整')
            throw '出错啦'
          }
        })
        showData.visible = false
      } else if(modelWay === 3) { // 数字范围
        let list = state.modelData.rangeList.filter(item =>{
          return item.startData !=='' && item.endData !==''
        })
        console.log(list)
        if(!list.length || !list[0].startData || !list[0].endData){
          return message.warning('请补全范围值')
        }else{
          let node = {
              id: state.modelData.modelWayList.length ? state.modelData.modelWayList.length + 1 : 1,
              label: list[0].startData + '-' + list[0].endData
            }
            state.heaData.keywords.push(node)
            onClose()
        }
      } else if(modelWay === 7) {
        state.modelData.rangeList.forEach(item => {
          if(item.type && item.startData && item.endData) {
            let node = {
              id: state.modelData.modelWayList.length ? state.modelData.modelWayList.length + 1 : 1,
              label: item.startData + item.type + '-' + item.endData + item.type
            }
            state.heaData.keywords.push(node)
            onClose()
          }else{
            message.warning('请将范围值补充完整')
            throw '出错啦'
          }
        })
      }
    }

    // 用于校验 匹配词的输入框
    const elementValueVerification =  async (inputValue) => {
      console.log(inputValue)
      if(!inputValue){
        return message.warning('请输入类型值')
      }
      let {modelWayList} = state.modelData
      let list = modelWayList.filter(item =>{
        return item.value== state.modelData.modelWay && item.isCheck == true
      })
      let check = await vTools(list[0].name, inputValue)
      console.log(check)
      if(check == true){
        let node = {
            id: modelWayList.length ? modelWayList.length + 1 : 1,
            label: state.modelData.modelValue
          }
            console.log(state.heaData)

          state.heaData.keywords.push(node)
        onClose()
      }else{
        state.modelData.modelValue = ''
      }

    }

    // 关键词删除
    const handelDelLog = (index) =>{
      state.heaData.keywords.splice(index,1)
    }

    // 弹框取消
    const onClose = () =>{
      showData.visible = false
      showData.isRangeShow = false  // 范围
      showData.isNodeShow = false  // 节点
      showData.isInputNode = false
      state.conversion = ''
      Object.assign(state.modelData, { // 匹配关键词
				modelWay: undefined,
				modelWayList:[
					{value: 1, label: '字符串',name: 'text', isCheck: true},
					{value: 2, label: '数字', name: 'number', isCheck: true},
					{value: 3, label: '数字范围'},
					{value: 4, label: '日期'},
					{value: 5, label: '日期范围'},
					{value: 6, label: '时间'},
					{value: 7, label: '时间范围'},
				],
				typeData:'',
				rangeList:[],
				modelValue:'', // 文本 数字的输入
        nodeDateType: '', // 日期类型
        nodeDataValue: '',  // 日期数据

			})
    }
    // 日期范围  起始时间失去焦点
    const onBlur = e => {
      state.conversion = e.target.value
    }
    // 日期 禁用
    const disabledDate = (current) => {
      return current && current < dayjs(state.conversion)
    }
    const onChange = (index) => {
      state.modelData.rangeList[index].endData = undefined
    }
    // 数字范围 / 时间范围
    const endDataBlur = (e,index) =>{
      if(e.target.value < state.conversion) {
        onChange(index)
        return message.warning('请选择正确的范围')
      }
    }
    const inputConfig = ref()

    // 选择方式 发生变化
    const handleChangeWay = (value) =>{
      if(value === 2) {
        showData.isImport = true
      } else {
        showData.isImport = false
      }
    }
    const healthOptionRef = ref()
    const getHealthOptionList = () => {
      return healthOptionRef.value.optionItemList
    }


		return {
      getHealthOptionList,
      healthOptionRef,
      getQuest,
			...toRefs(state),
			...toRefs(showData),
      inputConfig,
      handleChangeWay,
      endDataBlur,
      onChange,
      onBlur,
      disabledDate,
			handleType,
			addKeywords,
			addRange,
			delRange,
      onClose,
      handelDelLog,
      handleOk,
			formItemLayout,
		}
	}
})
</script>

<style scoped>
/* .header{
	background-color: rgb(223, 218, 218);
	padding: 30px;


}
.health-config{
	overflow: hidden;
	background: white;
	width: 800px;
	margin: 0 auto;
	padding: 30px;
} */
</style>
