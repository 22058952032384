import dayjs from 'dayjs'
export const getDateValue = (type, param) =>{
    if(type === 'week') {
        return getWeek(param)
    } else if(type === 'year') {
        return getYear(param)
    } else if (type === 'month') {
        return getMonth(param)
    } else if (type === 'date') {
        return getDay(param)
    }
}

/**
 * 天
 * @param param
 */
 const getDay = function (param) {
    let dateValue = dayjs(param).format('YYYY-MM-DD')
    console.log(dateValue)
    return dateValue
}


/**
 * 周
 * @param param
 */
 const getWeek = function (param) {
    let dateValue = dayjs(param).format('YYYY-MM-DD HH:mm:ss')
    let d1 = new Date((dateValue))
    let d2 = new Date(dateValue)
    d2.setMonth(0)
    d2.setDate(3)  //3 周一为本周第一天；2 周日为本周第一天；1 周六为本周第一天
    let rq = d1 - d2
    let days = Math.ceil(rq / (24 * 60 * 60 * 1000))
    let num = Math.ceil(days / 7)
    return num + 1 +'周'
}

/**
 * 月
 * @param param
 */
 const getMonth = function (param) {
    let  year = new Date((param)).getFullYear()
    let month = new Date(param).getMonth() + 1
    console.log(year + '-' + month)
    return year + '-' + month + '月'
}

/**
 * 年
 * @param param
 */
 const getYear = function (param) {
    let  year = new Date(param).getFullYear()
    console.log(year)
    return year + '年'
}
