<template>
<!--  级联，目前支持单选-->
  <a-cascader
    v-if="leavel === 1"
    v-model:value="selectObj"
    style="width: 100%"
    :options="options"
    placeholder="请选择"
    @change="onChange"
  ></a-cascader>
<!--  多选会采用标签样式，先注掉-->
<!--  <a-cascader-->
<!--    v-if="leavel === 0"-->
<!--    v-model:value="selectObj"-->
<!--    style="width: 100%"-->
<!--    multiple-->
<!--    max-tag-count="responsive"-->
<!--    :options="options"-->
<!--    placeholder="请选择"-->
<!--  ></a-cascader>-->

<!--  下方是可选择任意层级-->
  <a-cascader
    v-else
    :show-search="{ filter }"
    :options="options"
    :display-render="displayRender"
    expand-trigger="hover"
    placeholder="选择"
    changeOnSelect @change="onChange"/>
</template>

<script>
import {reactive, toRefs} from 'vue'

export default {
  name: 'CascaderView',
  props: {
    isDictionary: {
      type: Boolean,
      default: () => false
    },
    leavel: {
      type: Number,
      default: () => 0
    },
    options: {
      type: Array,
      default: () => []
    }
  },
  setup(props) {
    const state = reactive({
      options: props.options,
      selectObj: ''
    })
    const onChange = (value) => {
      console.log(value)
    }
    const filter = (inputValue, path) => {
      return path.some(option => option.label.toLowerCase().indexOf(inputValue.toLowerCase()) > -1)
    }
    const displayRender = ({labels, selectedOptions}) => {
      console.log(`入参：：${JSON.stringify({labels, selectedOptions})}`)
      let queryParam = {}
      // 初始化时selectedOptions为空数组，当选择某一项关闭弹窗的时候会触发这个方法，
      // lables，selectedOptions返回为多级数组，如果是想要数据里面的id或者其他参数，可以使用selectedOptions
      // 给对象增加一个属性去区分某些情况例如organizationType ，针对部门成员这样的组织架构，可以用来区分选择的是成员还是组织
      if (selectedOptions.length === 0) {
        // 初始化和点击清除按钮触发
        queryParam.pId = ''
        queryParam.cId = ''
      } else {
        let item = selectedOptions[selectedOptions.length - 1]
        console.log(`计算0：：${JSON.stringify(item)}`)
        if (item.organizationType === 1) { // 父
          queryParam.pId = item.value
          queryParam.cId = ''
        } else { // 子
          queryParam.cId = item.value
          queryParam.pId = ''
        }
      }
      console.log(`计算1：：${JSON.stringify(queryParam)}`)
      console.log(`计算：：${labels[labels.length - 1]}`)
      return labels[labels.length - 1]
    }
    return {
      // func
      displayRender,
      filter,
      onChange,
      //ref
      ...toRefs(state)
    }
  }
}
</script>

<style scoped>

</style>
