<template>
  <!-- 是否需要对应的输入框值 -->
  <div class="element_wrap">
    <van-field
      class="element-input"
      v-model="selectValue"
      is-link
      readonly
      :placeholder="`请选择${elementTitle}`"
      @click="showChoice"
    />
    <template v-if="pickerVisible">
      <van-popup v-model:show="pickerVisible" round position="bottom" :style="{ height: '30%' }"  :lazy-render='false' >
        <van-picker
          :columns="option"
          :columns-field-names="fieldNames"
          @confirm="onPickerConfirm"
          @cancel="onPickerClose"
          ref="pickerRef"
        />
      </van-popup>
    </template>
  </div>
</template>

<script>
import {Picker, Popup, Field} from 'vant'
import 'vant/lib/index.css'
import {defineComponent, onBeforeMount, onMounted, reactive, toRefs, watch,ref, nextTick} from 'vue'
import {request} from '../../../../utils/request'
import {getExtendUri} from '../../../../utils/request_extend'
export default defineComponent({
  components:{
    [Picker.name]: Picker,
    [Popup.name]: Popup,
    [Field.name]: Field
  },
  name: 'CertificateTypeView',
  props: {
    moduleIndex:{
      type: [Number,String],
      default:()=> '0'
    },
    moduleId:{ // 模块ID
      type: [Number,String],
      default:()=> ''
    },
    elementId:{ // 元素ID
      type: [Number,String],
      default:()=> ''
    },
    dictionary: {
      type: String,
      default: () => ''
    },
    title: {
      type: String,
      default: () => ''
    },
    elementTitle:{
      type: String,
      default: () => ''
    },
    options: {
      type: [Array,Object],
      default: () => []
    },
    elementCode: {
      type: [Number,String],
      default: () => ''
    },
    isRequired:{
      type: [Number,String],
      default:()=> '0'
    },
    firstValue:{
      type: [Number,String],
      default:()=> null
    },
    disabledType: {
      type: Boolean,
      default:()=> false
    },
    isDisabled: {
      type: Boolean,
      default:()=> false
    },
    // 是否默认展示
    defaultRequired:{
      type: String,
      default: () => ''
    },
    detailType:{
      type: String,
      default: () => ''
    },
    insuredsType:{
      type: String,
      default: () => ''
    },
    insuredsIndex:{
      type: [Number,String],
      default: () => ''
    },
    isShow:{
      type: Boolean,
      default: () => false
    },
    certifiOptions: {
      type: [Array,Object],
      default: () => []
    }
  },
  emits:['callback','update:firstValue'],
  setup(props, context) {
    const State = reactive({
      dictionary: props.dictionary,
      title: props.title,
      option: [],
      tips: '',
      selectValue: undefined,
      selectId: undefined,
      elementCode:props.elementCode,
      pickerVisible: false,
      fieldNames: {text: 'label',values:'value'}
    })
    const pickerRef = ref(null)
    // 点击按钮的弹框
    const showChoice = async () => {
      if(props.disabledType || props.isDisabled) {
        return
      }
      getDictionaryValue()
      State.pickerVisible = true
    }
    const onPickerConfirm = (data) =>{
      State.pickerVisible = false
      State.selectValue = data[0].label
      State.selectId = data[0].value
    }
    // 先找到 必填标识
    watch(props, (newVal)=>{
      if(newVal.defaultRequired === 'Y' && !props.isShow  && props.firstValue !== '新增'){
        handelConsentDetail()
      }
    })

    // 初始默认值

    watch(()=>State.selectId, ()=>{
      let node = {}
      node[State.elementCode] = {
        [State.elementCode] : State.selectId,
        elementId: props.elementId,
        moduleType: props.moduleId,
        index:props.moduleIndex,
        required: props.isRequired,
        elementName: props.title,
      }
      context.emit('update:firstValue', State.selectId)
      context.emit('callback',node)
    })



    const onPickerClose = () =>{
      State.pickerVisible = false
    }


    // 监视是否是关联字典的 和 初始默认值
    const handelConsentDetail = () =>{
      if(!props.dictionary){
        State.option = getCertifiOption()
        if(State.option.length){
          if(props.defaultRequired === 'Y' && (!props.firstValue || Number(props.firstValue) === 1)){
            State.selectValue =  State.option[0].label
            State.selectId = State.option[0].value
          }else if(props.firstValue){
            let list = State.option.filter(item => item.value === props.firstValue)
            if(list.length){
              State.selectId = list[0].value
              State.selectValue = list[0].label
            }
          }
          let node = {}
          node[State.elementCode] = {
            [State.elementCode] : State.selectId,
            elementId: props.elementId,
            moduleType: props.moduleId,
            index:props.moduleIndex,
            required: props.isRequired,
            elementName: props.title,
          }
          context.emit('update:firstValue', State.selectId)
          context.emit('callback',node)
        }
      } else if(props.dictionary) {
        if(props.defaultRequired === 'Y' && (!props.firstValue || Number(props.firstValue) === 1)){
          request(getExtendUri('dictionary_query_byType'), {data: {ids:[],types:[props.dictionary]}}).then(res =>{
              State.option =  res.result[props.dictionary]
              State.selectValue =  State.option[0].label
              State.selectId = State.option[0].value
              let node = {}
              node[State.elementCode] = {
                [State.elementCode] : State.selectId,
                elementId: props.elementId,
                moduleType: props.moduleId,
                index:props.moduleIndex,
                required: props.isRequired,
                elementName: props.title,
              }
              context.emit('update:firstValue', State.selectId)
              context.emit('callback',node)
            })
        }else if(props.firstValue){
          request(getExtendUri('dictionary_query_byType'), {data: {ids:[],types:[props.dictionary]}}).then(res =>{
              State.option =  res.result[props.dictionary]
              State.selectValue =  State.option.filter(item => item.value === props.firstValue)[0].label
              State.selectId = State.option.filter(item => item.value === props.firstValue)[0].value
              let node = {}
              node[State.elementCode] = {
                [State.elementCode] : State.selectId,
                elementId: props.elementId,
                moduleType: props.moduleId,
                index:props.moduleIndex,
                required: props.isRequired,
                elementName: props.title,
              }
              context.emit('update:firstValue', State.selectId)
              context.emit('callback',node)
            })
        }
      }
    }

    // 初始化 下拉框数据
    const getDictionaryValue = () =>{
      if(!props.dictionary){
        State.option = getCertifiOption()
        return
      }
      request(getExtendUri('dictionary_query_byType'), {data: {ids:[],types:[props.dictionary]}}).then(res =>{
        if(res.code == 1){
          State.option =  res.result[props.dictionary]
          nextTick(()=>{
            pickerRef?.value?.setColumnValues(0, State.option)
          })
        }
      })
    }
    const getCertifiOption = () => {
      if(props.options.length > 0 && props.certifiOptions.length > 0){
        const arr = []
        props.options.forEach(item => {
          props.certifiOptions.forEach(child =>{
            if(item.value === child) {
              arr.push({
                label: item.label,
                value: item.value,
                children: []
              })
            }
          })
        })
        return arr
      }
      return []
    }
    onMounted(()=>{
      if( !State.selectId && State.selectId !== 0 && !State.selectValue && !props.isShow && props.firstValue !== '新增'){
       handelConsentDetail()
      }
    })
    onBeforeMount(()=>{
      getDictionaryValue()
    })

    return {
      pickerRef,
      ...toRefs(State),
      showChoice,
      onPickerConfirm,
      onPickerClose,
      handelConsentDetail,
      getDictionaryValue
    }
  }

})
</script>

<style scoped>
:deep(.ant-input){
  padding: 0 !important;
}
.element_wrap {
  width: 100%;
  text-align: right;
}
.element-input {
  outline: none;
  text-align: right;
  width: 100%;
}
:deep(.van-picker__columns) {
  display: block !important;
}
:deep(.van-picker-column) {
  height: 12rem !important;
}
:deep(.van-field__control) {
  text-align: right;
}
.van-cell.van-cell--clickable.van-field.element-input {
  padding: 0.5rem 0 !important;
}
.van-cell:after{
  border-bottom: 0;
}
:deep(.van-picker__toolbar){
  height: 23px;
}

:root :root{
  --van-picker-toolbar-height: 0px;
}
</style>
