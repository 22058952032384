<template>
<div>

<!-- 特殊上传组件 -->
  <div v-if="mode_id == 101" >
    <span @click="showChoice" class="uploadClass">
      <div class="right_icon">
        <span>{{upTypeName}}</span>
        <img :src="rightIcon" alt="扫描" style="width: 25px;height: 25px;margin: 0 10px;">
        <span style="color: #0078fc;">扫描</span>
      </div>
    </span>
<!--    <a-modal v-model:visible="typeShow"  destroyOnClose title="上传证件" @ok="handleOk"  @cancel="onClose">-->
    <a-drawer
      :visible="typeShow"
      placement="bottom"
      height="85%"
      @close="onClose"
      destroyOnClose
      class="style101"
      :closable="false"
    >
      <template #title>
        <div class="drawer_title_class">{{titleType}}</div>
      </template>
      <template #extra>
        <a-space>
          <close-outlined  @click="onClose"/>
          <!-- <a-button style="border: #ebedf0">关闭</a-button> -->
        </a-space>
      </template>
      <div class="m_teshu">
        <div class="field_class">
          <div class="span-field">
            <span style="display: inline-block; width: 70px;line-height: 40px;">证件类型</span>
            <van-field
              class="element-input"
              v-model="selectValue"
              is-link
              readonly
              placeholder="请选择"
              @click="handelShowChoice"
              :border="false"
            />
              <van-popup v-model:show="pickerVisible" round position="bottom" height="15rem" :lazy-render='false' >
                <van-picker
                    :columns="option"
                    :columns-field-names="pickerField"
                    @confirm="onConfirm"
                    @cancel="() => pickerVisible = false"
                    height="15rem"
                    :default-index="2"
                    ref="pickerRef"
                />
              </van-popup>

          </div>
        </div>
        <div class="m_content displayType">
          <div >
            <div class="displayType" v-for="(image,index) in imgNumList" :key="image.value">
              <upload-file-new :titleType="titleType" :select-list="['image/*']" :isSpecial="index+1" @click="getUploadIndex(image,index)" :uploadNameType="selectId" ref="positiveRef"  @getFileList="handelDelCard"  listType="picture-card" filetype="img" :num="1" @fileChange="uploadFileUrl" :key="time"/>
            </div>
          </div>
          <div class="add_class" v-if="imgNumList.length < 4 && selectId !== '01' && selectId !== '1' && selectId !== '05' && selectId !== '07' && selectId !== '15' && selectId">
            <div class="add_class_img">
              <img @click="handelSaveProve" src="../../../../../public/anysign/image/add.png" alt="没了, 重新上传哇">
            </div>
            <div>新增</div>
          </div>
        </div>
      </div>
      <div class="drawer_footer">
        <div class="footer_class">
          <div class="m_footer">
            扫描完成后将自动填充证件信息,请仔细核对,如不一致可重新扫描或在信息填写页面直接修改。
          </div>
          <div style="color: #333;
            font-weight: 600;
            font-size: .95rem;
            padding-top: 0.2rem;">
            证件照片拍摄示例及说明
          </div>
          <div class="imgTable_footer" >
            <div class="imgTable" v-for="item in imgLabelList" :key="item.value">
              <img :src="item.type" alt="type"/>
              <div style="width: 100%; margin: 0 auto;">
                <img :src="item.icon" alt="icon"/>
              </div>
              <div>{{ item.label }}</div>
            </div>
          </div>
          <div class="img_button_class">
            <a-button :style="{background:  !isShow ? '#0078fc' : '#92c3fb',color: '#f3f5f5'}" :disabled="isShow"  @click="handleOk">确定</a-button>
          </div>
        </div>
      </div>
    </a-drawer>
<!--    </a-modal>-->
  </div>
  <!-- 上传组件 -->
  <div v-if="mode_id == 100">
    <!-- 限制 -->
    <div v-if="isRestrict">

      <span @click="showChoiceShow" class="uploadClass">
        <span style="display: inline-block; padding-right:25px">{{upTypeName}}</span>
        <right-outlined style="margin-top: 3px"/>
      </span>
      <a-modal v-model:visible="typeUPShow"  destroyOnClose title="上传数据" @ok="handleSave"  @cancel="onClose">
        <upload-file-to-cos
          ref="uploadRef"
          :listType="uploadType"
          :filetype="uploadType"
          :maxNum="maximumRestrict"
          @fileChange="uploadFileType"
          :selectList="selectList"
          :echoList="echoList"
          :maxBig="moduleBig"
        />
      </a-modal>
    </div>
    <!-- 不限制 -->
    <div v-if="!isRestrict">
      <span @click="showChoiceShow" class="uploadClass">
        <span style="display: inline-block; padding-right:25px">{{upTypeName}}</span>
        <right-outlined style="margin-top: 3px"/>
      </span>
      <a-modal v-model:visible="typeUPShow"  destroyOnClose title="上传数据" @ok="handleSave"  @cancel="onClose">
        <upload-file-to-cos
          :listType="uploadType"
          :filetype="uploadType"
          :maxNum="maximumRestrict"
          @fileChange="uploadFileType"
          :echoList="echoList"
          :maxBig="moduleBig"
        />
      </a-modal>
    </div>

  </div>
</div>
</template>

<script>
import { message } from 'ant-design-vue'
import {
  defineComponent,
  inject,
  onMounted,
  reactive,
  toRefs,
  ref,
  watch,
  onUnmounted,
  onBeforeUpdate,
  nextTick
} from 'vue'
import { useParent } from '@vant/use'
import { RELATION_KEY } from '../../../../utils/utlis'
import UploadFileNew from '../../../../components/extended/hr_uploadFileNew'
import UploadFileToCos from '../../../../components/extended/uploadFileToCos'
import BrightLight from '../../../../assets/img/brightLight.png'
import Defect from '../../../../assets/img/defect.png'
import Standard from '../../../../assets/img/standard.png'
import Fuzzy from '../../../../assets/img/fuzzy.png'
import Check from '../../../../assets/img/check.png'
import Close from '../../../../assets/img/close.png'
import Scan from '../../../../assets/img/scan.png'
import { request } from '../../../../utils/request'
import {Picker, Popup, Field} from 'vant'
import 'vant/lib/index.css'
import {getExtendUri} from '@/utils/request_extend'
  export default defineComponent({
    components:{
      UploadFileNew,
      UploadFileToCos,
      [Picker.name]: Picker,
    [Popup.name]: Popup,
    [Field.name]: Field
    },
    props:{
      moduleIndex:{
        type: [Number,String],
        default:()=> 0
      },
        moduleId:{ // 模块ID
        type: [Number,String],
        default:()=> ''
        },
        elementId:{
          type: [Number,String],
          default:()=> ''
        },
        mode_id:{
          type: [Number, String],
          default: ()=> 0
        },
        maximumRestrict:{
          type:[Number, String],
          default: () => 0
        },
        uploadType:{// 限制时选中的类型名
          type: String,
          default: () => ''
        },
        selectType:{  // 限制类型
          type: Number,
          default: () => 0
        },
        moduleBig:{  // 限制类型
          // type: [Number, String],
          type: Number,
          default: () => 2
        },
        isRestrict:{ // 是否限制
          type: Boolean,
          default:()=>false
        },
        selectList:{ // 限制类型
          type: Array,
          default:()=> []
        },
        elementCode: {
        type: [Number,String],
        default: () => ''
      },
      isRequired:{
        type: [Number,String],
        default:()=> '0'
      },
      specialUpload: {// 特殊上传
        type: Object,
        default:()=> {}
      },
      disabledType: {
        type: Boolean,
        default:()=> false
      },
      firstValue:{
        type: [Number,String,Object,Array],
        default:()=> ''
      },
      elementData:{
        type: Array,
        default:()=> []
      },
      isDisabled: {
        type: Boolean,
        default:()=> false
      }

    },
    emits:['callback', 'setUserDetail', 'update:firstValue'],
    setup(props,context){
      const { parent } = useParent(RELATION_KEY)
      if (parent) {
        console.log(parent, 'parent111')
      }
      const state = reactive({
        time:new Date().getTime(),
        imgNumList: [{value: new Date().getTime(), label: ''},{value: new Date().getTime()+1, label: ''}],
        selectId:'',
        temId: '',
        selectValue: props.elementCode === 'newBankCardUrl' ? '银行卡' : '身份证',
        pickerField: {text: 'label',value:'value'},
        option: [],
        pickerVisible: false,
        titleType: '',
        positiveImg:[],
        otherSideImg:[],
        imgLabelList:[
          {value: 1, label:'标准', type:Standard, icon: Check},
          {value: 2, label:'缺损', type:Defect, icon: Close },
          {value: 3, label:'模糊', type:Fuzzy, icon: Close},
          {value: 4, label:'强光', type:BrightLight, icon:Close},
        ],
        uploadData:{},
        uploadClickData:{},
        uploadIndex: '',
        mode_id: props.mode_id,
        isRestrict: props.isRestrict,
        moduleBig: props.moduleBig,
        uploadType: props.uploadType,
        selectList: props.selectList,
        maximumRestrict: props.maximumRestrict*1,
        positiveUrl:'', // 正面
        theOtherSideUrl: '', // 反面
        enclosureName: [],
        echoList: [],
        rightIcon: Scan,
        elementCode: props.elementCode,
        typeUPShow: false,
        typeShow: false,
        upTypeName: '待上传',
        uploadDetailType: props.firstValue,
        positiveUrlShow: '',
        theOtherSideUrlShow: '',
        isShow: true,
        fileList: [],
        upFileName: props.specialUpload.uploadName == 1 ? '身份证' : (props.specialUpload.uploadName == 2 ? '银行卡' : '')
      })
    const pickerRef = ref(null)
      watch(() => state.pickerVisible, (newVal) => {
      if (newVal) {
        pickerRef.value.setIndexes([0])
      }
    })
      const positiveRef = ref()
      const otherSideRef = ref()

      onMounted(()=>{})
      const getImgNumList = () =>{
        console.log(state.selectId, 'state.selectId')
        if(state.selectId === '1' || state.selectId === '03' || state.selectId === '07' || !state.selectId){
          state.imgNumList = [{value: new Date().getTime(), label: ''}]
        }else{
          state.imgNumList = [{value: new Date().getTime(), label: ''},{value: new Date().getTime()+1, label: ''}]
        }
      }
      const getUploadIndex = (data,index) =>{
        state.uploadIndex = index
        state.uploadClickData = data
      }

        // 点击图标 展开数据
      const showChoice = () =>{
        if(props.disabledType || props.isDisabled) return
        state.imgNumList = [{value: new Date().getTime(), label: ''},{value: new Date().getTime()+1, label: ''}]
        state.typeShow = true
        state.isShow = true
        const idTypeList = props.elementData.filter(item => item.elementCode === 'idType' || item.elementCode === 'appntIdType' || item.elementCode === 'newAccountType')
        if(idTypeList.length){
          nextTick(()=>{
            getIdType(idTypeList[0])
          })
        }else{
          getImgNumList()
        }
      }
      // 获取证件类型
      const getIdType = (data) =>{
        if(data.dictionaryName && data.isDictionary === 'true'){
          getDictionaryValue(data)
        }else{
          state.option = data.elementOperatingMode.mode_select.dropDownConfiguration
          nextTick(()=>{
            const obj = state.option.filter(item => item.value === data.firstValue)[0] ?? state.option[0]
            state.selectValue = obj.label
            state.selectId = obj.value
            state.temId = obj.value
            getImgNumList()
            positiveRef.value.forEach(item =>{
              item.handelSpecialImg(state.selectId)
            })
          })
        }
      }
      const getDictionaryValue = (data) =>{
        request(getExtendUri('dictionary_query_byType'),
          {data: {ids:[],types:[data.dictionaryName]}}).then(
          res =>{
            if(res.code == 1){
              state.option =  res.result[data.dictionary]
              const obj = state.option.filter(item => item.value === data.firstValue)[0]
              state.selectValue = obj.label
              state.selectId = obj.value
              state.temId = obj.value
              getImgNumList()
              positiveRef.value.forEach(item =>{
                item.handelSpecialImg(state.selectId)
              })
            }
          })
      }
      const onConfirm = (e) =>{
        state.pickerVisible = false
        if(state.selectValue !== e[0].label){
          positiveRef.value.forEach(item =>{
            item.fileList = []
          })
        }
        state.selectValue = e[0].label
        state.selectId = e[0].value
        getImgNumList()
        state.echoList = []
        positiveRef.value.forEach(item =>{
          item.handelSpecialImg(state.selectId)
        })
      }
      // watch(()=>state.typeShow,()=>{
      //   if(state.typeShow){
      //     const url = new URL(window.location)
      //     window.history.pushState({}, '', url)
      //   }
      // })
      const showChoiceShow = () =>{
        if(props.disabledType || props.isDisabled) return
        state.echoList = []
        if(state.enclosureName.length){
          state.echoList = state.enclosureName
        }
        state.typeUPShow = true
      }
      const setUserDetail = inject('setUserDetail')
      const setIdType = inject('setIdType')
      console.log(setIdType, 'setIdType')
      const uploadRef = ref()
      const handleOk = () =>{
        state.isShow = true // 禁用按钮
        setTimeout(() => {
          state.isShow = false // 3秒后启用按钮
        }, 3000)
        const list = state.imgNumList.filter(item => !item.label)
        if(list.length) return message.warning(`请上传${state.selectValue}证件照片`)
        state.upTypeName = '已上传'
        if(state.selectId === '01' || state.selectId === '1' || !state.selectId){
          getOCRDetail()
        }else{
          const data = {
            [props.elementCode]:{
              [state.elementCode] : state.imgNumList,
              detailData:{
                selectId: state.selectId
              },
              elementId: props.elementId,
              moduleType: props.moduleId,
              index: props.moduleIndex,
              selectId: state.selectId,
              required: props.isRequired,
              cosUrls: state.imgNumList,
              elementName: props.elementName,
              isDisabled: props.isDisabled
            }
          }
          context.emit('update:firstValue', state.imgNumList)
          if (state.selectId !== '05' && state.selectId !== '07' && state.selectId !== '15') {
            setUserDetail(data, 'ocr')
          }
          setIdType({
            isClear: state.temId !== state.selectId,
            idType:{
              elementId: '5eeaf3e5e9f8463baafc96c0weeef87',
              elementName: '证件类型',
              index: props.moduleIndex,
              moduleType: props.moduleId,
              required: props.isRequired,
              idType: state.selectId
            }
          })
          state.typeShow = false

        }
      }
      const back = (data, imgUrls) =>{
        let node = {}
        let val = data
        if (val.bankCardNo) {
          val.bankCardNo = val.bankCardNo?.replace(/\s+/g, '')
        }
        node[state.elementCode] = {
          [state.elementCode] : state.selectId === '1' ? imgUrls[0].label : imgUrls,
          detailData: val,
          elementId: props.elementId,
          moduleType: props.moduleId,
          index:props.moduleIndex,
          required: props.isRequired,
          cosUrls: imgUrls,
          selectId: state.selectId,
          elementName: props.elementName,
          isDisabled: props.isDisabled
        }
        state.uploadData = {...node}
        getResult(node)
        context.emit('update:firstValue', imgUrls)
      }
      const handelSaveProve = () =>{
        for (let i = 0; i < state.imgNumList.length; i++) {
          if (!state.imgNumList[i].label){
            return message.warning('请先上传')
          }
        }
        state.imgNumList.push({value: new Date().getTime(), label: ''})
      }

      // 获取正面上传的地址
      const uploadFileUrl = (file) =>{
        state.fileList.push({...file[0]})
        state.imgNumList[state.uploadIndex].label = file[0].playUrl
        state.imgNumList[state.uploadIndex].cardType = Number(state.uploadIndex) + 1
      }
      const getOCRDetail = () =>{
         // 1  身份证  2 是银行卡
        let data = {}
        let url = null
        if(props.specialUpload.uploadName == 1){
           data = {
            idCardFrontImg: state.imgNumList[0].label, // 正面图片地址
            idCardReverseImg: state.imgNumList[1].label, // 反面图片地址
          }
          url = '/bee-common-service/common/ocr/api/idCard'
        } else if(props.specialUpload.uploadName == 2){
          data = {
            bankCardImg: state.imgNumList[0].label
          }
          url = '/bee-common-service/common/ocr/api/bankCard'
        }

        request(url,{
          data:{ ...data},
        }).then(res =>{
          if(res.code === 1){
            res.result['selectId'] = state.selectId
            back(res.result, state.imgNumList)
            state.uploadDetailType = state.imgNumList
            state.typeShow = false
            setUserDetail(state.uploadData, 'ocr')
            state.positiveImg = []
            state.otherSideImg = []
          }else{
            getImgNumList()
            state.positiveUrl = ''
            state.theOtherSideUrl = ''
            state.upTypeName = '待上传'
            state.uploadDetailType = undefined
            message.error(res.message)
          }
        })
      }
      const onClose = () =>{
        if(props.specialUpload.uploadName == 1){
          if((!state.positiveImg.length || !state.otherSideImg.length) || state.upTypeName=='待上传'){
            state.positiveUrl = ''
            state.theOtherSideUrl = ''
            state.upTypeName = '待上传'
            state.uploadDetailType = undefined
          }
        }else if(props.specialUpload.uploadName == 2) {
          if(!state.positiveImg.length || state.upTypeName=='待上传'){
            state.positiveUrl = ''
            state.upTypeName = '待上传'
            state.uploadDetailType = undefined
          }
        }
        state.typeShow = false
      }
      // 删除身份证 or 银行卡
      const handelDelCard = (file) =>{
        for (let i = 0; i < state.imgNumList.length; i++) {
          if(state.imgNumList[i].label === file.playUrl){
            if(state.selectId === '1' || state.selectId === '01' || state.imgNumList.length<3){
              state.imgNumList[i].label = ''
            }else{
              state.imgNumList.splice(i, 1)
            }
            break
          }
        }
      }

      // 不限制
      const uploadFileType = (file) =>{
        state.enclosureName = file
        back(file)
      }
      const handleSave =() =>{
        state.enclosureName.length ? state.upTypeName = '已上传' : state.upTypeName = '待上传'
        state.enclosureName.length ? state.uploadDetailType = 'Y' : state.uploadDetailType = 'N'
        state.typeUPShow = false
      }
      const getResult = (data) => {
        context.emit('callback',data, 'ocr')
      }
      window.addEventListener('popstate', onClose, false)
      onUnmounted(() => {
        window.removeEventListener('popstate', onClose, false)
      })
      onBeforeUpdate(()=>{
        if(props.firstValue && !state.uploadDetailType){
          state.uploadDetailType = props.firstValue
        }
      })
      watch(()=> props.moduleId,()=>{
        if(props.moduleId === 'applicant') state.titleType = '投保人证件'
        if(props.moduleId === 'insureds') state.titleType = '被保人证件'
        if(props.moduleId === 'beneficiarys') state.titleType = '受益人证件'
        if(props.moduleId === 'payMethod' || props.moduleId ==='payment') state.titleType = '银行卡'
      },{immediate: true})
      // 点击按钮的弹框
    const handelShowChoice = () => {
      if(props.disabledType || props.isDisabled) return
      state.pickerVisible = true
    }
    watch(()=>props.firstValue, ()=>{
      state.uploadDetailType = props.firstValue
    },{immediate:true})
    watch(()=> state.uploadDetailType,(newValue) =>{
      if(newValue !== 'N' && newValue && newValue?.length !== 0){
        state.upTypeName = '已上传'
        back({}, newValue)
      }else{
        state.upTypeName = '待上传'
      }
    },{immediate:true})
    watch(()=>state.imgNumList, ()=>{
      if (state.imgNumList.length){
        state.isShow = false
        state.imgNumList.forEach(item => {
          if(!item.label) state.isShow = true
        })
      }

    },{deep: true})
  const closePop = () => {
    state.typeShow = false

  }
      return {
        closePop,
        handelSaveProve,
        handelDelCard,
        handelShowChoice,
        getOCRDetail,
        uploadRef,
        showChoiceShow,
        onClose,
        setUserDetail,
        positiveRef,
        otherSideRef,
        handleSave,
        showChoice,
        getResult,
        ...toRefs(state),
        uploadFileUrl,
        uploadFileType,
        handleOk,
        pickerRef,
        getIdType,
        getDictionaryValue,
        onConfirm,
        getUploadIndex
      }
    }
  })
</script>

<style scoped>
  .m_teshu{
    width: 100%;
    min-height: 20rem;
  }
  /*.m_teshu::-webkit-scrollbar {*/
  /*  width: 0;*/
  /*  height: 0;*/
  /*}*/
  .text-center{
    text-align: center;
  }
  .m_header{
    text-align: center;
    font-size: 18px;
    font-weight: 600;
  }
  .displayType{
    /* display: flex;
    flex-direction: column;
    align-items: center; */
    text-align: center;
  }
  .m_content{
    width: 100%;
    margin: 25px 0;


  }

  .uploadClass{
    cursor: pointer;
    display: flex;
    justify-content: flex-end;

  }
  .style101{
    overflow-x: hidden;
    overflow-y: auto;
  }
  .style101::-webkit-scrollbar
  {
    display: none;
  }
  :deep(.style101 .ant-drawer-header){
    height: 100%;
    border-bottom: 1px solid #dedede !important;
  }
  :deep(.style101 > .ant-drawer-header-title > .ant-drawer-title){
    color: #333;
    font-size: 1.28rem;
    font-family: '微软雅黑';
    font-weight: 600;
  }
  .ant-drawer-top.ant-drawer-open, .ant-drawer-bottom.ant-drawer-open{
    height: 100vh;
  }
  .drawer_footer{
    background: rgb(245, 246, 250);
    padding-top: 10px;
    margin: 0 -24px;
  }
  .footer_class{
    background: white;
    padding: 0 24px;

  }
  .m_footer{
    font-size: 14px;
    padding-top: 10px;
    color: #0078fc;
  }
  .imgTable_footer{
    display: flex;
    justify-content: space-between;
    padding-bottom: 50px;
  }
  .imgTable{
    position: relative;
    margin-top: 8px;
    display: flex;
    flex-direction: column;
    width: 60px;
    text-align: center;
    line-height: 1.6rem;
  }
  .imgTable img{
    width: 4rem;
    height: 3rem;
  }
  .imgTable >div > img{
    width: 1.26rem;
    height: 1.26rem;
  }
  .img_button_class{
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
  }
  .img_button_class > .ant-btn{
    width: 100%;
    height: 50px;
  }
  .ant-btn-primary{
    font-size: 1.18rem;
  }
  .drawer_title_class{
    font-size: 1.16rem;
    font-weight: 600;
  }
  .right_icon{
    height: 100%;
    display: flex;
    margin-right: 5px;
  }

.element-input {
  outline: none;
  text-align: right;
  width: 100%;
}
:deep(.van-picker__columns) {
  display: block !important;
}
:deep(.van-picker-column) {
  height: 12rem !important;
}
:deep(.van-field__control) {
  text-align: right;
}
.van-cell.van-cell--clickable.van-field.element-input {
  padding: 0.5rem 0 !important;
}
.span-field{
  display: flex;
  padding-right: 24px;
}
.field_class{
  min-height: 50px;
  border-bottom: 1px solid #f0f0f0;
  margin-right: -24px;
}
.add_class{
  /*width: 8rem;*/
  height: 5.7rem;
  padding-top: .5rem;
}
.add_class_img img{
  width: 2.5rem;
  height: 2.5rem;
  margin-bottom: 5px;
}
.add_class div{
  text-align: center;
}

</style>
