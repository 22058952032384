<template>
<!--  这里就是单个元素-->
<div>
<!--  标题-->
  <p><span>{{serialNumber}} </span>{{htObj.title}}</p>

  <!--  操作类型0 - 单选 1-多选 2-输入-->
  <div v-if="htObj.modeType === 2">
<!--    <p><span>{{serialNumber}} </span>{{htObj.title}}</p>-->

    <InputView :max-length="htObj.inputConfig.maxLength" :input-config="htObj.inputConfig" @callback="result"/>
  </div>
  <!--  答案列标-->
  <div  v-else v-for="(answerItem, ansIndex) in htObj.answerList" :key="ansIndex">
    <div v-if="htObj.modeType === 0">
      <RadioBtnView></RadioBtnView>
    </div>
    <div v-else>
      <CheckBoxView></CheckBoxView>
    </div>
  </div>


</div>
</template>

<script>
import {reactive, toRefs, defineAsyncComponent} from 'vue'

export default {
  props: {
    obj: {
      type: Object,
      default: ()=> {}
    },
    serialNumber: {
      type: String,
      default: ()=> '0'
    }
  },
  components: {
    InputView: defineAsyncComponent(() => import('../InputView')),
    RadioBtnView: defineAsyncComponent(() => import('../RadioBtnView')),
    CheckBoxView: defineAsyncComponent(() => import('../CheckBoxView'))
  },
  name: 'HealthToldItem',
  setup(props){
    const state = reactive({
      htObj: props.obj
    })
    const result = (e) => {
      console.log(`获取结果:${e}`)
    }
    return {
      // func
      result,
      // ref
      ...toRefs(state)
    }
  }
}
</script>

<style scoped>

</style>
