<template>
<div style="width: 100%" class="element_wrap">
  <input
      class="element-input"
      v-model="inputVal"
      :placeholder="'请选择' + `${ title }`"
      :readonly="true"
      :style="{color: disabledType ? '#d9d9d9' : ''}"
      @click="showChoice">
  <span class="element-suffix" @click="showChoice">
      <right-outlined/>
  </span>
  <van-popup
  style="padding:30px;"
  lazy-render
    position="bottom"
    :closable="false"
    v-model:show="pickerVisible"
    @close="onPickerClose"
  >
    <van-cascader
        v-model="dictionarieValue"
        :options="dictionarieList"
        :field-names="cascaderFieldNames"
        @finish="onCascaderConfirm"
        @change="onCascaderChange"
        :closeable="false"
    >
    <template #title>
      <div class="cascader_calss">
        <div @click="onPickerClose" style="padding-top: 5px;">取消</div>
        <div v-if="elementDetail.elementMode==='5'" @click="handelSelectOccupation">
          <van-icon style="margin: 0.1rem 0.2rem 0;" name="search" size="1.3rem" color="#d2d2d2"/>
          <span>职业查询</span>
        </div>
      </div>
    </template>
    </van-cascader>
  </van-popup>
</div>
<occupation-select :isInsureds="isInsureds" :moduleId="moduleId" ref="occupationRef" @handelClickOk="handelClickOk"></occupation-select>
</template>

<script>
import {message} from 'ant-design-vue'
import {Cascader, Icon, Popup} from 'vant'
import 'vant/lib/index.css'
import {defineComponent, onBeforeUnmount, onBeforeUpdate, onMounted, reactive, ref, toRefs, watch} from 'vue'
import {request} from '../../../../utils/request'
import {getExtendUri} from '../../../../utils/request_extend'
import OccupationSelect from './OccupationSelect'
import store from '../../../../store'

export default defineComponent({
  components: {
    [Cascader.name]: Cascader,
    [Icon.name]: Icon,
    OccupationSelect,
    [Popup.name]: Popup,
  },
  props: {
    title: {
      type: String,
      default: () => ''
    },
    occupationCity: {
      type: Object,
      default: () => {
      }
    },
    moduleIndex: {
      type: [Number, String],
      default: () => ''
    },
    firstValue: {
      type:[String,Number],
      default: () => ''
    },
    moduleId:{
      type:[Number,String],
      default: () => ''
    },
    occupationCityObj:{
      type:Object,
      default: () => {}
    },
    disabledType: {
      type: Boolean,
      default:()=> false
    },
    moduleShow:{
      type: Boolean,
      default:()=> false
    },
    isInsureds:{
      type: Boolean,
      default:()=> false
    },
    isDisabled:{
      type: Boolean,
      default:()=> false
    },
  },
  emits:['callback','update:firstValue'],
  setup(props, {emit}){
    const state = reactive({
      isSelect: false,
      codeDetail:'',
      occupationLevel: '',
      inputVal:'',
      dictionarieList:[],
      elementDetail: props.occupationCity,
      dictionarieValue:props.firstValue,
      pickerVisible: false,
      disabled: false,
      cascaderFieldNames: {
        text: 'name',
        value: 'id',
        children: 'children',
      },
    })

    const insideVal = reactive({
      value: undefined,
      name: undefined,
    })
    watch(()=>props.disabledType, ()=>{
      state.disabled = props.disabledType
    },{immediate:true})
    watch(insideVal, (newVal)=>{
      state.inputVal = insideVal.name
      let node = {}
      node[state.elementDetail.elementCode] = {
        [state.elementDetail.elementCode] :insideVal.value,
        index: props.moduleIndex,
        elementId: state.elementDetail.id,
        moduleType: props.occupationCityObj.moduleType,
        required: state.elementDetail.required,
        occupationLevel: state.occupationLevel,
        occupationName: insideVal.name,
        elementName: props.title,
      }
      emit('update:firstValue', newVal.value)
      emit('callback', node)
    })

    watch(()=>props.firstValue, ()=> {
      if (props.firstValue) {
        insideVal.value = props.firstValue
        insideVal.name = props.occupationCity?.show_tagName || props.occupationCity?.tagName
        state.occupationLevel = props.occupationCity?.show_Level
        state.inputVal = insideVal.name
      }else if(!props.firstValue){
        state.inputVal = ''
      }
    },{immediate: true})

    const onRest = () =>{
      insideVal.value = undefined
      insideVal.name = undefined
    }
    const occupationRef = ref()
    // 职业查询
    const handelSelectOccupation = () =>{
      occupationRef.value.showDrawer()
      state.pickerVisible = false
    }
    // 当职业查询中的弹窗点击确定的时候
    const handelClickOk = (data) =>{
      insideVal.name = data.name || undefined
      insideVal.value = data.id || undefined
      state.occupationLevel = data.occupationLevel
      state.dictionarieValue = ''
      state.pickerVisible = false
    }

    const showChoice = () =>{
      console.log(props.disabledType,'props.disabledType')
      console.log(props.firstValue,'props.firstValue')
      if(props.disabledType || props.isDisabled) return
      if(props.firstValue !== insideVal.value || !props.firstValue ){
        state.dictionarieList = []
        insideVal.value = undefined
        insideVal.name = undefined
        getCityAndOccOptions('0', state.dictionarieList)
      }
      state.pickerVisible = true
    }


    // 改变的方法
    const onCascaderChange = ({value, tabIndex, selectedOptions})=>{
      if(state.elementDetail.elementMode == '4' && tabIndex < 2 && selectedOptions[tabIndex]['children'] === undefined){
        // 将数据传递 获取下一级数据
        if(state.elementDetail.elementOperatingMode?.mode_city?.urbanAreaLevel && (tabIndex+1) == state.elementDetail.elementOperatingMode.mode_city.urbanAreaLevel){
          console.log(1111, selectedOptions)
          return
        }
        selectedOptions[tabIndex]['children'] = []
        getCityAndOccOptions(value, selectedOptions[tabIndex])
      }else if (state.elementDetail.elementMode == '5' && selectedOptions[tabIndex]['childrenCount'] > 0) {
        selectedOptions[tabIndex]['children'] = []
        getCityAndOccOptions(value, selectedOptions[tabIndex])
    }
    }
    // 选择之后触发这个方法
    const onCascaderConfirm = ({selectedOptions}) =>{
      state.codeDetail = selectedOptions[selectedOptions.length-1].code
      if(state.elementDetail.elementMode === '4'){
        insideVal.value = selectedOptions[selectedOptions.length-1].id + '' || undefined
        insideVal.name = selectedOptions.map((option) => option.name).join('') || undefined
      }else if(state.elementDetail.elementMode === '5'){
        insideVal.value = selectedOptions[selectedOptions.length-1].code || undefined
        insideVal.name = selectedOptions[selectedOptions.length-1].name || undefined
        state.occupationLevel = selectedOptions[selectedOptions.length-1].occupationLevel
      }
      state.pickerVisible = false
    }
    const onPickerClose = () =>{
      state.pickerVisible = false
    }
    // 获取地区数据
    const getCityAndOccOptions = (pId, node) =>{
      if(props.moduleShow) return
      // 之后需要判断 是城市 还是 职业
      if(state.elementDetail.elementMode == '4'){  // 城市
        request(getExtendUri('queryArea'), {
          data:{
            parentId: pId,
            productId: store.getters['insured/getProductId'] || '',
            agentId: JSON.parse(store.getters['insured/getProductDetailData'])?.agentInfo?.cardCode,
            urbanAreaLevel: state.elementDetail.elementOperatingMode?.mode_city?.urbanAreaLevel,
            isSameSalesRegion: state.elementDetail.elementOperatingMode?.mode_city?.isSameSalesRegion
          }
        }).then(res =>{
          if(res.code === 1){
            if (pId === '0' && res.result.length > 0) {
              state.dictionarieList = res.result
            }else if(res.result.length > 0){
              node['children'] = res.result
            }
          }
        })
      }else if(state.elementDetail.elementMode == '5'){ // 职业
        let url = getExtendUri('queryOccupation')
        if (pId !== '0') {
          url = getExtendUri('querySubOccupation')
        }
        if(typeof(store.getters['insured/getProductDetailData']) !== 'string' || store.getters['insured/getProductDetailData'] === '{}' || store.getters['insured/getProductDetailData'] === '[]') return
        //sessionStorage
        const mainRiskInfo =  JSON.parse(store.getters['insured/getProductDetailData']).productInfo.riskList.filter(risk => risk.riskSign === '1')[0]
        request(url, {
          data: {
            id: pId,
            riskId: mainRiskInfo.riskId
          }
        }).then(res => {
          if (res.code === 1 && res.result) {
            if (pId === '0') {
              state.dictionarieList = res.result
            } else {
              node['children'] = res.result
            }
          } else {
            message['error'](res.message)
          }
        })
      }

    }

    onMounted(()=>{
      getCityAndOccOptions('0', state.dictionarieList)
    })
    onBeforeUpdate(()=>{

    })
    onBeforeUnmount(()=>{
      insideVal.value = undefined
      insideVal.name = undefined
      state.inputVal = ''
    })

    return{
      occupationRef,
      handelClickOk,
      handelSelectOccupation,
      ...toRefs(state),
      insideVal,
      onRest,
      showChoice,
      onPickerClose,
      onCascaderConfirm,
      onCascaderChange,
      getCityAndOccOptions,
    }
  }
})
</script>

<style  scoped>
.a-drawer {
  z-index: 9999 !important;
}
.van-popup--bottom {
  padding: 10px !important;
}
  .element_wrap {
    text-align: right;
    /* margin: .5rem; */
  }
  input::-webkit-input-placeholder{
        color: #c2c2c2;
    }
.ant-drawer-content-wrapper::-webkit-scrollbar {display: none}
:deep(.van-tabs__content) ::-webkit-scrollbar{
  width: 0px ;
  height: 0px ;
}
.element-suffix {
  float: right;
}
:deep(.van-picker__columns) {
  display: block !important;
}
:deep(.van-picker-column) {
  height: 12rem !important;
}
.element-input {
  border: 0;
  outline: none;
  text-overflow: ellipsis;
  text-align: right;
  white-space: nowrap;
  overflow: hidden;
  width: calc(95% - 4rem);
}
:deep(.van-cascader__title){
  width: 100%;
}
:deep(.ant-drawer-body){
  /* padding: 10px !important; */
  flex-grow: 1;
  padding: 10px;
  overflow: auto;
  font-size: 14px;
  line-height: 1.5715;
  word-wrap: break-word;
}
:deep(.van-tabs){
  margin:0 -15px !important;
}
:deep(.van-cascader__options){
  margin-right:-1% !important;

}
:deep(.van-tabs__track .van-swipe__track){
  width: 100% !important;
  padding: -14px !important;

}
:deep(.van-cascader__header){
  padding: 0 !important;
  height: auto;
}
/* :deep(.van-cascader){
} */
.cascader_calss{
  display: flex;
  justify-content: space-between;
  margin-top: -10px;
}

</style>
