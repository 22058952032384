<style scoped>

</style>

<template>
  <a-drawer
    title="资料文件详情"
    :width="'63%'"
    :visible="visible"
    :maskClosable="false"
    destroyOnClose
    :body-style="{ paddingBottom: '80px' }"
    :footer-style="{ textAlign: 'right' }"
    @close="onClose"
  >
    <a-form
      :model="formDataOne"
      layout="vertical"
      ref="formRef2"
      autocomplete="off"
    >
      <a-row :gutter="10">
        <a-col span="24">
          <a-form-item label="文件资料类型:" name="fileType"
                       :rules="[{required:true,message:'必填信息',trigger:'change'}]"
          >
            <a-select v-model:value="formDataOne.fileType" :disabled="status === 'bianJi'"  placeholder="请选择文件资料类型"
                      :options="fileTypeList"   @change="getFileType">
            </a-select>
          </a-form-item>
        </a-col>
        <a-col span="24">
          <a-form-item label="文件资料类型展示顺序:" name="displayOrder"  :rules="[{required:true,message:'必填信息',trigger:'change'}]">
            <a-input v-model:value="formDataOne.displayOrder"   placeholder="请输入文件资料展示顺序"/>
          </a-form-item>
        </a-col>
      </a-row>
    </a-form>
    <div class="btn-bar">
      <a-button danger @click="handelDelInsurance">
        <template #icon>
          <DeleteOutlined />
        </template>
        批量删除
      </a-button>
      <a-button type="primary" @click="handelSaveInsurance">
        <template #icon>
          <FileAddOutlined />
        </template>
        添加
      </a-button>
    </div>
    <a-table
      :columns="columns"
      :data-source="dataSource ? dataSource['result'] : []"
      :scroll="{ x: 800 }"
      :row-selection="{
      selectedRowKeys: selectedRowKeys,
      onChange: onSelectChange,
    }"
      :pagination="false"
      :row-key="(record) => record.fileOrder"
      :loading="loading"
    >
      <template #bodyCell="{ column, index, record }">
        <template v-if="column.key === 'index'">
          {{ index + 1 }}
        </template>
        <template v-if="column.key === 'uploadFileName'" >
          <a style="color: #1890ff;cursor: pointer;" @click="handelDownload(record)">{{record.uploadFileName}}</a>
        </template>
        <template v-if="column.key === 'operation'">
          <div>
            <a
              style="color: orange; margin-right: 10px"
              @click="handelEditInsurance(record)"
            >编辑</a>&nbsp;&nbsp;
          </div>
        </template>
      </template>
    </a-table>
    <template #extra>
      <a-space>
        <a-button @click="onClose">
          <template #icon>
            <CloseOutlined/>
          </template>
          关闭
        </a-button>
        <a-button html-type="submit" type="primary" @click="onSave" >
          <template #icon>
            <SaveOutlined/>
          </template>
          保存
        </a-button>
      </a-space>
    </template>
    <shoulderAdd ref="shoulderAddRef" @getUploadFilesData="getUploadFilesData" @getEditUploadFilesData="getEditUploadFilesData" :status="status"></shoulderAdd>
  </a-drawer>
</template>
<script>
import {SaveOutlined, CloseOutlined, DeleteOutlined, FileAddOutlined} from '@ant-design/icons-vue'
import {defineComponent, reactive, toRefs, ref, computed, onBeforeMount} from 'vue'
import {getRequestURI, request, requestUsePagination} from '../../../utils/request'
import {message, Modal} from 'ant-design-vue'
import {getDictionaryData, getDictionaryValue} from '../../../utils/request_extend'
import shoulderAdd from './insuranceAddOne'
export default defineComponent({
  components: {
    SaveOutlined,
    CloseOutlined,
    DeleteOutlined,
    FileAddOutlined,
    shoulderAdd,
  },
  emits:['handleSearchs'],
  setup(props, {emit,expose}) {
    const formRef = ref()
    const formDataOne = reactive({
      fileType: undefined,//文件资料类型
      riskId: '',//匹配的id
      riskCode:'',//险种编码
      id:'',
      displayOrder:undefined,//文件显示位置
      fileList: []
    })
    const state = reactive({
      newFilter:{},
      selectedRowKeys: [],
      selectedRowData: [],
      showImg:[],
      status:'',
      time:null,
      visible: false,
      columns: [
        {
          title: '序号',
          key: 'index',
          fixed: 'left',
          width: 60,
          align: 'left',
        },
        {
          title: '文件名称',
          width: 90,
          align: 'center',
          key: 'uploadFileName',
          dataIndex: 'uploadFileName',
        },
        {
          title: '文件展示位置',
          width: 90,
          align: 'center',
          key: 'mustReadOrder',
          dataIndex: 'mustReadOrder',
          customRender: (text) => getDictionaryValue('mustReadOrderType', text.value+'')
        },
        {
          title: '文件顺序',
          width: 90,
          align: 'center',
          key: 'fileOrder',
          dataIndex: 'fileOrder',
        },
        {
          title: '操作',
          key: 'operation',
          ellipsis: true,
          align: 'center',
          fixed: 'right',
          width: 140,
          scopedSlots: { customRender: 'operation' }
        }
      ]
    })
    const shoulderAddRef = ref()
    const fileTypeList = ref([])
    onBeforeMount(()=>{
      getDictionaryData(fileTypeList,'risk_file_type')
    })

    const showDrawer = (oldData={}, type='') => {
      if(oldData.fileType){
        oldData.fileType += ''
      }
      Object.keys(formDataOne).forEach(key =>{
        formDataOne[key] = oldData[key] || formDataOne[key]
      })
      formDataOne.riskId = oldData.id ? oldData.id : oldData.riskId

      if(type === 'bianJi'){
        let data = {
          fileType: formDataOne.fileType,
          riskId: formDataOne.riskId
        }
        handleSearch(data)
      }

      state.visible = true
      state.status = type
    }
    const getFileType = () =>{
      let data = {
        fileType: formDataOne.fileType,
        riskId: formDataOne.riskId
      }
      handleSearch(data)
    }
    const {
      data: dataSource,
      run,
      loading,
      current,
      pageSize,
      total,
    } = requestUsePagination(getRequestURI('productFile_detail'))
    const pagination = computed(() => ({
      current: current.value,
      pageSize: pageSize.value,
      total: total.value,
    }))
    const handleSearch = function (filters) {
      // console.log('搜索参数==', filters)
      state.newFilter = filters
      run({
        data: filters
      })
    }
    const handleTableChange = (pag, filters, sorter) => {
      console.log('handleTableChange', pag, filters, sorter)
      run({
        pagination: {
          current: pag?.current,
          pageSize: pag?.pageSize
        },
        sorter,
        data: state.newFilter,
      })
    }
    const onSelectChange = (selectedRowKeys, data) => {
      state.selectedRowKeys = selectedRowKeys
      state.selectedRowData = data
    }

    // 编辑
    const handelEditInsurance = (data) =>{
      let status ='bianJi'
      shoulderAddRef.value.showDrawerOne(data, status)
    }
    // 新增
    const handelSaveInsurance = () =>{
      if(!formDataOne.fileType) return message.warning('请选择文件资料类型')
      let status ='newAdds'
      shoulderAddRef.value.showDrawerOne(formDataOne, status)
    }
    const  getUploadFilesData = (data) =>{
      if(dataSource.value && dataSource.value.result && dataSource.value.result.length > 0){
        dataSource.value.result.push(data)
      }else{
        dataSource.value = {
          result: []
        }
        dataSource.value.result.push(data)
      }
    }
    // 资料编辑后的数据
    const getEditUploadFilesData = (data) =>{
      dataSource.value.result.forEach(item =>{
        if(item.id === data.id){
          Object.assign(item, data)
        }
      })
    }
    // 点击下载
    const handelDownload = (data) =>{
      window.location.href = data.uploadFile
    }
    // 删除
    const handelDelInsurance = () => {
      if (state.selectedRowKeys.length === 0) {
        message.warning('请选择数据')
        return
      }
        Modal.confirm({
          title: '确认删除吗?',
          async onOk () {
            state.selectedRowData.forEach(sel=>{
              dataSource.value.result.forEach((item,index) =>{
                if(item.id === sel.id){
                  dataSource.value.result.splice(index,1)
                }
              })
            })
            message.success('删除成功, 请点击右上角保存按钮进行保存')
          },
          onCancel () {
          }
        })



    }


    //关闭
    const onClose = () => {
      state.visible = false
      state.showImg.splice(0)
      state.time = new Date().getTime()
      formDataOne.fileType= undefined //文件资料类型
        formDataOne.uploadFile= ''//上传文件
        formDataOne.fileName=''//文件显示名称
        formDataOne.riskId= ''//匹配的id
        formDataOne.riskCode=''//险种编码
        formDataOne.uploadFileName=''//上传文件名称
        formDataOne.displayOrder=undefined //文件显示位置
        formDataOne.fileOrder=1//必读文件显示顺序
        formDataOne.fileList = []
        formDataOne.id=''
        state.status=''
      state.selectedRowData = []
      state.selectedRowKeys = []
      if(dataSource.value){
        dataSource.value = {}
      }
      emit('handleSearchs')
    }

    //保存
    const onSave = () => {
      formDataOne.fileList= []
      if(dataSource.value && dataSource.value['result'].length > 0){
        dataSource.value['result'].forEach(item =>{
          formDataOne.fileList.push({
            uploadFile: item.uploadFile,
            uploadFileName: item.uploadFileName,
            fileOrder: item.fileOrder,
            mustReadOrder: item.mustReadOrder,
            displayOrder: item.mustReadOrderType
          })
        })
      }
      let obj = {
        data: formDataOne
      }
      let uri = 'productFile_update'
      if( state.status==='newAdds'){
        formDataOne.id=''
        uri = 'productFile_insert'
      }
      request(getRequestURI(uri), obj).then(res => {
        if (res.code === 1) {
          state.time = new Date().getTime()
          if(state.status==='bianJi'){
            message['success']('资料编辑成功')
          }
          if(state.status==='newAdds'){
            message['success']('资料上传成功')
          }

          onClose()
          handleSearch({})
        }else{
          message['warning'](res.message)
        }
      })


    }
    expose({
      showDrawer
    })
    return {
      getFileType,
      getUploadFilesData,
      handleSearch,
      ...toRefs(state),
      dataSource,
      run,
      loading,
      pagination,
      fileTypeList,
      formRef,
      formDataOne,
      shoulderAddRef,
      onSelectChange,
      handleTableChange,
      onClose,
      onSave,
      showDrawer,
      handelEditInsurance,
      handelSaveInsurance,
      handelDelInsurance,
      getEditUploadFilesData,
      handelDownload,
    }
  },

})
</script>
