<template>
  <!--  元素列表-->
  <div>
    <SearchForm
      :Condition="condition"
      :ColSpan="8"
      @callback="handleSearch"
    ></SearchForm>
    <div class="btn-bar">
      <a-button type="primary" @click="addElement">
        <template #icon><FileAddOutlined /></template>新增
      </a-button>
      <a-button danger @click="delElement">
        <template #icon><DeleteOutlined /></template>批量删除
      </a-button>
    </div>
    <a-table
      :columns="tabList_title"
      :data-source="dataSource ? dataSource['result'] : []"
      :pagination="pagination"
      @change="tablePaginationChange"
      :loading="loading"
      :row-key="(record) => record.id"
      :row-selection="{
        selectedRowKeys: selectedRowKeys,
        onChange: onSelectChange,
      }"
    >
      <template #bodyCell="{ column, index, record }">
        <template v-if="column.key === 'index'">
          {{ (pagination.current - 1) * pagination.pageSize + index + 1 }}
        </template>
        <template v-if="column.key === 'action'">
          <a-space>
            <a-button
              type="primary"
              @click="editElement(record, 1)"
              size="small"
            >
              <template #icon><FormOutlined /></template>修改
            </a-button>
            <a-button
              type="primary"
              @click="previewElement(record)"
              size="small"
            >
              <template #icon><EyeOutlined /></template>预览
            </a-button>
            <a-button
              type="primary"
              @click="handelUpdateStatus(record)"
              size="small"
            >
              <template #icon><setting-two-tone /></template>更新状态
            </a-button>
            <!-- <a-button type="primary" @click="delElement(record)" size="small" danger>
              <template #icon><LinkOutlined/></template>删除
            </a-button> -->
          </a-space>
        </template>
      </template>
    </a-table>
    <a-modal
      v-model:visible="previewPopShow"
      destroyOnClose
      title="预览模块"
      @ok="handleOk"
    >
      <!-- <previewModule :element-id="selectEId" previewData></previewModule> -->
      <previewModule
        :element-id="selectEId"
        :previewData="previewData"
      ></previewModule>
    </a-modal>
    <a-modal
      v-model:visible="isUpstatus"
      destroyOnClose
      title="更新状态"
      @ok="handleUpdateOk"
    >
      <a-select
        v-model:value="status"
        :options="statusTypeList"
        style="width: 300px"
      >
      </a-select>
    </a-modal>
  </div>
  <ModuleDetails
    v-model:labelDescription="labelDescription"
    :handleSearch="handleSearch"
    ref="showDetails"
    :searchfFilters="searchfFilters"
    :current="pagination.current"
  ></ModuleDetails>
</template>

<script>
// import {reactive, toRefs, defineComponent, defineAsyncComponent, computed, h} from 'vue'
import { reactive, ref, toRefs, defineComponent, defineAsyncComponent, computed, onMounted, provide} from 'vue'
// import {tabList} from './demo.js'
import { requestUsePagination, getRequestURI, request } from '../../../utils/request.js'
// import {getDictionaryValue, getExtendUri} from '../../../utils/request_extend'
import { getDictionaryData, getExtendUri } from '../../../utils/request_extend'
import {
  FileAddOutlined,
  FormOutlined,
  DeleteOutlined,
  EyeOutlined,
} from '@ant-design/icons-vue'
import { message, Modal } from 'ant-design-vue'

export default defineComponent({
  // name: 'ElementList',
  components: {
    FileAddOutlined,
    FormOutlined,
    DeleteOutlined,
    EyeOutlined,
    SearchForm: defineAsyncComponent(() => import('../../../components/extended/search_form')),
    previewModule: defineAsyncComponent(() => import('./preMod/previewModule.vue')),
    ModuleDetails: defineAsyncComponent(() => import('./moduleDetails.vue')),

  },
  setup () {
    // customRender
    const state = reactive({
      labelDescription: '',
      previewData: {},
      lastFilter: {},
      selectedRowKeys: [],
      condition: {
        moduleName: {
          label: '模块名称',
          placeholder: '请输入模块名称关键字',
        },
        status: {
          label: '组件状态',
          type: 'select',
          default: '1',
          urlKey: 'queryDictionaryType',
          queryParam: { type: 'status' }
        },
        moduleType: {
          label: '所属模块',
          type: 'select',
          default: '0',
          data: [],
        },
        labelName: {
          label: '标签',
          placeholder: '请输入标签',
        },
      },
      tabList_title: [
        {
          key: 'index',
          title: '序号',
          align: 'center',
          width: 60,
        },
        {
          title: '模块名称',
          width: 200,
          align: 'left',
          key: 'moduleName',
          dataIndex: 'moduleName',
        },
        {
          title: '模块状态',
          width: 150,
          align: 'left',
          key: 'statusName',
          dataIndex: 'statusName',
          // customRender: (text)=> getDictionaryValue('module_status', text.value)
        },
        {
          title: '模块归属',
          width: 180,
          align: 'left',
          key: 'moduleTypeName',
          dataIndex: 'moduleTypeName',
        },
        {
          title: '标签',
          width: 140,
          align: 'left',
          key: 'labelDescription',
          dataIndex: 'labelDescription',
        },
        {
          title: '创建时间',
          width: 200,
          align: 'left',
          key: 'createTime',
          dataIndex: 'createTime',
          customRender: (text) => {
            let time = new Date(+new Date((text.text)) + 8 * 3600 * 1000).toISOString().replace(/T/g, ' ').replace(/\.[\d]{3}Z/, '')
            return time
          }
        },
        {
          title: '操作',
          width: 200,
          align: 'left',
          key: 'action'
        },
      ],
      // tabList: [...tabList],
      previewPopShow: false,
      elementEditShow: false,
      selectEId: '',
      isUpstatus: false,
      status: null
    })
    // const returnStateText = function (value) {
    //  let arr = state.condition.status.data
    //   for (let i = 0; i <arr.length ; i++) {
    //     if (value === arr[i].value) {
    //       return arr[i].label
    //     }
    //   }
    //   return ''
    // }

    const {
      data: dataSource,
      run,
      loading,
      current,
      pageSize,
      total,
    } = requestUsePagination(getRequestURI('module_moduleList'))
    //页面挂载调用数据
    onMounted(() => {
      handleSearch({})
    })
    // 存储搜索组件传过来的数据
    const searchfFilters = ref({})
    const handleSearch = function (filters, num = '') {
      if (filters) {
        searchfFilters.value = filters
      }
      state.lastFilter = filters
      run({
        pagination: {
          current: num ? num : 1,
          pageSize: 10
        },
        data: filters,
      })
    }
    const pagination = computed(() => ({
      current: current.value,
      pageSize: pageSize.value,
      total: total.value,
      pageSizeOptions: [10, 20, 50],
    }))
    const onSelectChange = selectedRowKeys => {
      console.log('selectedRowKeys changed: ', selectedRowKeys)
      state.selectedRowKeys = selectedRowKeys
    }

    const tablePaginationChange = function (pag, filters, sorter) {
      // console.log('handleTableChange', pag, filters, sorter)
      run({
        pagination: {
          current: pag?.current,
          pageSize: pag?.pageSize
        },
        sorter,
        data: state.lastFilter,
      })

    }
    const showDetails = ref()
    // 编辑
    const editElement = function (ele, num) {
      showDetails.value.showDrawer(ele, num)
    }
    // 新增元素
    const addElement = function () {
      showDetails.value.showDrawer()
      // state.title = '新增元素'
      // state.elementEditShow = true
    }
    // 预览
    const previewElement = function (ele) {
      let arr = []
      arr.push(ele)
      Object.assign(state.previewData, { productElementList: [arr] })
      state.selectEId = ele.id
      state.previewPopShow = true

    }
    const handelToAppnt = () =>{
    }
    const setUserDetail = () =>{
    }
    provide('handelToAppnt', handelToAppnt)
    provide('setUserDetail', setUserDetail)
    const delElement = function () {
      if (state.selectedRowKeys.length === 0) {
        return message.warning('请选择数据')
      }
      Modal.confirm({
        title: '删除提示',
        content: '是否删除已选择信息？',
        onOk: () => {
          request(getRequestURI('module_delete'), { ids: state.selectedRowKeys }).then(res => {
            if (res.code === 1) {
              handleSearch({})
              message.success('删除成功')
              state.selectedRowKeys = []
            } else {
              message.warning(res.message)
            }
          })
        },
        onCancel: () => {
          Modal.destroyAll()
        }
      })
    }
    const handleOk = function () {
      state.selectEId = ''
      state.previewPopShow = false
    }
    const handleEditOk = function () {
      state.elementEditShow = false
    }
    const getModularId = () => {
      request(getExtendUri('dictionary_query_byType'), { data: { types: ['module'] } }).then(
        res => {
          state.condition.moduleType.data = res.result.module
        })
    }
    // 更新状态
    const handelUpdateStatus = (record) => {
      console.log(record)
      state.isUpstatus = true
      state.status = record.status
      state.statusId = record.id

    }
    const handleUpdateOk = () => {
      request(getRequestURI('module_updateStatus'), {
        id: state.statusId,
        status: state.status
      }).then(
        res => {
          if (res.code === 1) {
            state.isUpstatus = false
            message.success('更新成功')
            handleSearch(searchfFilters.value)
          } else {
            message.warning(res.message)
          }

        })
    }
    const statusTypeList = ref([])
    onMounted(() => {
      getDictionaryData(statusTypeList, 'status')
      getModularId()
    })

    return {
      ...toRefs(state),
      statusTypeList,
      handelUpdateStatus,
      handleUpdateOk,
      getModularId,
      handleSearch,
      addElement,
      delElement,
      getRequestURI,
      loading,
      dataSource,
      showDetails,
      total,
      pagination,
      tablePaginationChange,
      onSelectChange,
      editElement,
      previewElement,
      handleOk,
      handleEditOk,
      searchfFilters
    }
  }
})
</script>

<style scoped>
</style>
