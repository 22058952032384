<template>
  <a-drawer
    title="关联模板"
    :width="'35%'"
    :visible="visible"
    :body-style="{ paddingBottom: '80px' }"
    :footer-style="{ textAlign: 'right' }"
    @close="onClose"
  >
  <div >
    <div class="m_temp" v-for=" temp  in tempList" :key="temp.templateId">
      <div class="m_radio">
        <a-radio-group v-model:value="templateId" @change="handelCheck(temp.templateName)">
          <a-radio :value="temp.templateId"></a-radio>
        </a-radio-group>
      </div>
      <div class="m_form">
        <p class="marginTop">模板ID&nbsp;:&nbsp;&nbsp;&nbsp;{{temp.templateId}}</p>
        <p class="marginTop">模板名称&nbsp;:&nbsp;&nbsp;&nbsp;{{temp.templateName}}</p>
      </div>
        
      
    </div>
  </div>
  <template #extra>
      <a-space>
        <a-button @click="onClose">
          <template #icon>
            <CloseOutlined/>
          </template>
          取消
        </a-button>
        <a-button @click="handelUnrelated">
          <template #icon>
            <ReloadOutlined />
          </template>不关联模板
        </a-button>
        <a-button type="primary" @click="handelRelation">
          <template #icon>
            <SaveOutlined/>
          </template>
          确定
        </a-button>
      </a-space>
    </template>
  </a-drawer>
</template>

<script>
import { defineComponent, reactive, toRefs } from 'vue'
import { getRequestURI, request } from '../../../../utils/request'

export default defineComponent({
  emits:['templateData'],
  setup(props,context){
    const state = reactive({
      visible: false,
      tempList: [], // 模板数据
      templateId:null,
      templateName:'',
    })

    const showDrawer = (templateId) =>{
      state.visible = true
      state.templateId = templateId
      getTemplateList()
      
    }
    // 获取模板列表 process_getTemplateList
    const getTemplateList = () =>{
      request(getRequestURI('process_getTemplateList'),{data:{},pagination:{current:1,pageSize:99999}}).then(
        res =>{
          state.tempList = res.result
        })
    }

    const onClose = () =>{
      state.visible = false
      handelUnrelated()
    }
    // 不关联模板
    const handelUnrelated = () =>{
      state.templateId = null
      state.templateName = ''
    }
    
    // 点击单选 获取模板名称
    const handelCheck = (templateName) =>{
      state.templateName = templateName
    }
    // 确定
    const handelRelation = () =>{
      let node = {
        associatedTemplateId: state.templateId,
        associatedTemplateName: state.templateName
      }
      context.emit('templateData',node)
      onClose()
    }



    return{
      ...toRefs(state),
      showDrawer,
      onClose,
      handelCheck,
      handelUnrelated,
      handelRelation,
      getTemplateList,
    }
  }
})
</script>

<style  scoped>
  .marginTop{
    margin-top: 5px;
  }
  .m_temp{
    width: 80%;
    border: 1px solid #999;
    display: flex;
    justify-content: flex-start;
    margin-bottom: 20px;
    margin-left: 20px;
    padding:0 30px;
  }
  .m_radio{
    display: flex;
    justify-items: center;
    align-items: center;
    margin-right: 30px;
  }
  .m_form{
    /* height: 100px; */
  }
</style>