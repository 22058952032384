<style scoped>
.bg {
  margin-bottom: 20px
}
.insurance_conditions_info{
  width: 100%;
}
</style>

<template>
  <a-drawer
      :title=panDuan
      :width="'70%'"
      :visible="visible"
      :body-style="{ paddingBottom: '80px' }"
      :footer-style="{ textAlign: 'right' }"
      @close="onClose"
  >

    <a-tabs v-model:activeKey="activeKey" @change="onChangeActiveKey">
      <a-tab-pane key="1" tab="险种基础信息">
        <a-form
            :model="formData"
            layout="vertical"
            ref="formRef"
            autocomplete="off"
        >
          <a-row :gutter="10">
            <a-col span="12">
              <a-form-item label="险种编码(仅支持数字字母):" name="riskCode"
                           :rules="[{required:true,message:'必填信息',trigger:'change'},
                            { pattern:/^[0-9a-zA-Z]+$/, message: '支持数字和字母格式' ,trigger: 'change'},
                            {max: 20, message: '输入长度不能超过20' ,trigger: 'change'}]"
              >
                <a-input v-model:value="formData.riskCode" :disabled="zhuangTai1=='details'"
                         placeholder="请输入险种编码"/>
              </a-form-item>
            </a-col>
            <a-col span="12">
              <!--              {pattern:/^[a-zA-Z0-9_]*$/, message: '只支持字母、数字、下划线格式' ,trigger: 'change'}-->
              <a-form-item label="供应商险种编码:" name="supRiskCode"
                           :rules="[{required:true, message: '请输入供应商险种编码' ,trigger: 'change'}]"
              >
                <a-input v-model:value="formData.supRiskCode" :disabled="zhuangTai1=='details'"
                         placeholder="请输入供应商险种编码"/>
              </a-form-item>
            </a-col>
            <a-col span="12">
              <a-form-item label="险种名称:" name="riskName"
                           :rules="[{required:true,max: 100, message: '输入长度不能超过100' ,trigger: 'blur'}]"
              >
                <a-input v-model:value="formData.riskName" :disabled="zhuangTai1=='details'"
                         placeholder="请输入险种名称"/>
              </a-form-item>
            </a-col>
            <a-col span="12">
              <a-form-item label="供应商名称:" name="supId"
                           :rules="[{ required: true, message: '必填信息' ,trigger: 'change'}]">
                <a-tree-select :filterTreeNode="filterTreeOption" show-search tree-default-expand-all
                               v-model:value="formData.supId" :disabled="zhuangTai1=='details'"
                               placeholder="请选择供应商名称"
                               @change="changSup" :tree-data="listData.gongyingshangmingcheng"
                               :fieldNames="{children:'children', label:'supName', key:'id', value:'id'}">
                </a-tree-select>
              </a-form-item>
            </a-col>
            <a-col span="12">
              <a-form-item label="财寿标志:" name="riskCsSign"
                           :rules="[{required:true,message:'必填信息',trigger:'change'}]"
              >
                <a-select :options="riskCsSignList" v-model:value="formData.riskCsSign"
                          :disabled="zhuangTai1=='details'" placeholder="请选择财寿标志" clearable>
                  <!-- <a-select-option :value= '1'>财产险</a-select-option>
                  <a-select-option :value= '2'>人身险</a-select-option> -->
                </a-select>
              </a-form-item>
            </a-col>
            <a-col span="12">
              <a-form-item label="团个险标志:" name="riskGroupSign"
                           :rules="[{required:true,message:'必填信息',trigger:'change'}]"
              >
                <a-select :options="riskGroupSignList" v-model:value="formData.riskGroupSign"
                          placeholder="请选择团个险标志"
                          :disabled="zhuangTai1=='details'" clearable>
                  <!-- <a-select-option :value='1'>个险</a-select-option>
                  <a-select-option :value='2'>团险</a-select-option> -->
                </a-select>
              </a-form-item>
            </a-col>
            <a-col span="12">
              <a-form-item label="长短险标志:" name="riskLengthSign"
                           :rules="[{required:true,message:'必填信息',trigger:'change'}]"
              >
                <a-select :options="riskLengthSignList" v-model:value="formData.riskLengthSign"
                          placeholder="请选择长短险标志"
                          :disabled="zhuangTai1=='details'" clearable>
                  <!-- <a-select-option :value='1'>长险</a-select-option>
                  <a-select-option :value='2'>短险</a-select-option> -->
                </a-select>
              </a-form-item>
            </a-col>
            <a-col span="12">
              <a-form-item label="主附险标志:" name="riskSign"
                           :rules="[{required:true,message:'必填信息',trigger:'change'}]"
              >
                <a-select :options="riskSignList" v-model:value="formData.riskSign" placeholder="请选择主附险标志"
                          :disabled="zhuangTai1=='details'" clearable>
                  <!-- <a-select-option :value='1'>主险</a-select-option>
                  <a-select-option :value='2'>附险</a-select-option> -->
                </a-select>
              </a-form-item>
            </a-col>
            <a-col span="12">
              <a-form-item label="双主险标志:" name="doubleMainRiskFlag"
                           :rules="[{required:true,message:'必填信息',trigger:'change'}]"
              >
                <a-select :options="SZXList" v-model:value="formData.doubleMainRiskFlag" placeholder="请选择双主险标志"
                          :disabled="zhuangTai1=='details'" clearable>
                </a-select>
              </a-form-item>
            </a-col>
            <a-col span="12">
              <a-form-item label="险种类别大类:" name="riskTypeLargeCode"
                           :rules="[{required:true,message:'必填信息',trigger:'change'}]"
              >
                <a-select v-model:value="formData.riskTypeLargeCode"
                          :options="riskTypeLargeCodeData"
                          placeholder="请选择险种类别大类" :disabled="zhuangTai1=='details'"
                          @change="changeDaLei1" clearable>
                </a-select>
              </a-form-item>
            </a-col>
            <a-col span="12" v-if="false">
              <a-form-item label="险种类别细类:" name="riskTypeFineCode"
                           :rules="[{required:true,message:'必填信息',trigger:'change'}]"
              >
                <a-select v-model:value="formData.riskTypeFineCode"
                          :options="riskTypeSmallCodeData"
                          placeholder="请选择险种类别细类" :disabled="zhuangTai1=='details'" clearable>
                </a-select>
              </a-form-item>
            </a-col>
            <a-col span="12">
              <a-form-item label="保监险种类别:" name="insuranceTypeCode"
                           :rules="[{required:true,message:'必填信息',trigger:'change'}]"
              >
                <a-select v-model:value="formData.insuranceTypeCode"
                          :options="riskTypeLargeCodeData2"
                          placeholder="请选择保监险种类别" :disabled="zhuangTai1=='details'"
                          clearable @change="changeDaLei2">
                </a-select>
              </a-form-item>
            </a-col>
            <a-col span="12" v-if="false">
              <a-form-item label="保监险种细分类:" name="insuranceFineCode"
                           :rules="[{required:true,message:'必填信息',trigger:'change'}]"
              >
                <a-select v-model:value="formData.insuranceFineCode" placeholder="请选择保监险种细分类"
                          :options="riskTypeSmallCodeData2"
                          :disabled="zhuangTai1==='details'" clearable></a-select>
              </a-form-item>
            </a-col>
            <a-col span="12">
              <a-form-item label="是否赠险:" name="giftRisk"
                           :rules="[{required:true,message:'必填信息',trigger:'change'}]"
              >
                <a-select :options="giftRiskShow" v-model:value="formData.giftRisk" @change="zengXian"
                          placeholder="请选择是否赠险"
                          :disabled="zhuangTai1=='details'" clearable>
                  <!-- <a-select-option :value="'Y'">是</a-select-option>
                  <a-select-option :value="'N'">否</a-select-option> -->
                </a-select>
              </a-form-item>
            </a-col>
            <a-col span="12" v-if="formData.giftRisk == 'Y'">
              <a-form-item v-if="formData.giftRisk == 'Y'" label="赠险类型:" name="giftRiskType"
                           :rules="[{required:true,message:'必填信息',trigger:'change'}]"
              >
                <a-select :options="giftRiskTypeList" v-model:value="formData.giftRiskType" placeholder="请选择赠险类型"
                          :disabled="zhuangTai1=='details' ? true : (ziDuan ? true: false)" clearable>
                  <!-- <a-select-option :value='1'>中介公司赠险</a-select-option>
                  <a-select-option :value='2'>供应商赠险</a-select-option> -->
                </a-select>
              </a-form-item>
            </a-col>
            <a-col span="12">
              <a-form-item name="exemptRisk" :rules="[{required:true,message:'必填信息',trigger:'change'}]">
                <template #label>
                  <span>是否豁免险: </span>&nbsp;
                  <span>
                <a-popover placement="right">
                  <template #content>
                    <p>当设置为豁免险时，保额计算方式为主险+长险的保费和</p>
                  </template>
                  <question-circle-outlined />
                </a-popover>
              </span>
                </template>
                <a-select :disabled="zhuangTai1=='details'" :options="exemptRiskList"
                          v-model:value="formData.exemptRisk" placeholder="请选择" clearable>
                </a-select>
              </a-form-item>
            </a-col>
            <a-col span="12">
              <a-form-item label="限购份数:" name="riskNumber"
                           :rules="[{ pattern:/^[0-9]*[1-9][0-9]*$/,message: '支持大于0整数' , trigger: 'blur'},
                             {max: 5, message: '输入长度不能超过5' ,trigger: 'change'}]"
              >
                <a-input v-model:value="formData.riskNumber" :disabled="zhuangTai1=='details'"
                         placeholder="请输入限购份数"></a-input>
              </a-form-item>
            </a-col>
            <a-col span="12">
              <a-form-item name="effectDay" :rules="[{max: 5, message: '输入长度不能超过5' ,trigger: 'change'},
                            { pattern:/^[+]{0,1}(\d+)$/, message: '支持大于等于0整数' ,trigger: 'change'},
                           {required:true,message:'必填信息',trigger:'change'}]">
                <template #label>
                  <span>生效天数(天): </span>&nbsp;
                  <span>
                    <a-popover placement="right">
                      <template #content>
                        <p>用于差n天而未满足投保年龄的用户进行投保</p>
                      </template>
                      <question-circle-outlined />
                    </a-popover>
                  </span>
                </template>
                <a-input v-model:value="formData.effectDay" :disabled="zhuangTai1=='details'"
                         placeholder="请输入生效天数(天)"></a-input>
              </a-form-item>
            </a-col>
            <a-col span="12">
              <a-form-item label="等待期(天):" name="waitDate"
                           :rules="[{max: 5, message: '输入长度不能超过5' ,trigger: 'change'},
                            { pattern:/^([0-9]+[\d]*(.[0-9]{1,2})?)$/, message: '支持小数最长两位或大于等于0的整数' ,trigger: 'change'},
                           {required:true,message:'必填信息',trigger:'change'}]"
              >
                <a-input v-model:value="formData.waitDate" :disabled="zhuangTai1=='details'"
                         placeholder="请输入等待期(天)"></a-input>
              </a-form-item>
            </a-col>
            <a-col span="12">
              <a-form-item label="犹豫期(天):" name="wideDate"
                           :rules="[
                           {required:true, message:'必填信息',trigger:'change'},
                           {max:5, message:'输入长度不能超过5',trigger:'change'},
                           { pattern:/^([0-9]+[\d]*(.[0-9]{1,2})?)$/, message:'支持小数最长两位或大于等于0的整数' ,trigger:'change'},
                           ]"
              >
                <a-input v-model:value="formData.wideDate" :disabled="zhuangTai1=='details'"
                         placeholder="请输入犹豫期(天)"></a-input>
              </a-form-item>
            </a-col>
            <a-col span="12">
              <a-form-item name="countCopiesWay"
                           :rules="[{required:true,message:'必填信息',trigger:'change'}]"
              >
                <template #label>
                  <span>份数计算方式: </span>&nbsp;
                  <span>
                    <a-popover placement="right">
                      <template #content>
                        <p>1.	直接录入: 表示客户在页面录入后，直接获取。配置投保要素时需要配置份数投保要素
                           <br>2.	险种保额/基础保额：最通用的计算方式，配置投保要素时需要配置基础保额投保要素，并有且仅有一个值，还要配置一个保额投保要素。例如一般的费率表都是以1000保额为基数给出保费，这里的1000即为基础保额，险种保额一般由客户在前端选择。如险种保额为10万，基础保额为1000，份数即为100份，但是此时的100并不能真实的表达份数，他只是一个计算倍数，所以保费计算结果中给出的份数默认为1份。
                           <br>3.	主险保费*缴费年期/基础保额：比较特殊的计算方式，实际份数为1。配置投保要素时需要配置基础保额投保要素，并有且仅有一个值，还要配置一个缴费年期投保要素。
                           <br>4.	长险保费和/基础保费：使用于豁免险的计算方式，实际份数为1。配置投保要素时需要配置基础保额投保要素，并有且仅有一个值，还要同时向后台传递该单中所有的险种信息。
                        </p>
                      </template>
                      <template #title>
                        <span>规则说明:</span>
                      </template>
                      <question-circle-outlined />
                    </a-popover>
                  </span>
                </template>
                <a-select v-model:value="formData.countCopiesWay" :disabled="zhuangTai1=='details'"
                          :options="fenshujisuanfangshi" placeholder="请选择保费计算方式" clearable>
                </a-select>
                <!--                <a-input v-model:value="formData.baseCoverage" :disabled="zhuangTai1=='details'"  placeholder="请输入基础保额"></a-input>-->
              </a-form-item>
            </a-col>
            <a-col span="12">
              <a-form-item name="premiumCalculation"
                           :rules="[{required:true,message:'必填信息',trigger:'change'}]"
              >
                <template #label>
                  <span>保费计算方式: </span>&nbsp;
                  <span>
                    <a-popover placement="right">
                      <template #content>
                        <p>1.	保额算保费：最常用的计算方式
                            <br>2. 保费算保额：根据保费计算保额
                            <br>3. 保司计算费率：选择该计算方式时，无需系统计算保费，同样也不会显示以下配置内容
                        </p>
                      </template>
                      <template #title>
                        <span>规则说明:</span>
                      </template>
                      <question-circle-outlined />
                    </a-popover>
                  </span>
                </template>
                <a-select :options="premiumList" v-model:value="formData.premiumCalculation"
                          :disabled="zhuangTai1=='details'" placeholder="请选择保费计算方式" clearable>
                </a-select>
              </a-form-item>
            </a-col>
            <a-col span="12" v-if="formData.premiumCalculation === '1' || formData.premiumCalculation === '2'">
              <!-- 去字典上取 -->
              <a-form-item name="premiumCalculationMethod"
                           :rules="[{required:true,message:'必填信息'}]"
              >
                <template #label>
                  <span>保费计算公式: </span>&nbsp;
                  <span>
                    <a-popover placement="right">
                      <template #content>
                        <p>1.	保费=固定金额，配置投保要素时需要配置一个固定金额投保要素，有且仅有一个值。适用于单份产品为固定值的场景，如车票，
                            <br>2. 保费=保额*固定费率，按照保额的固定费率收取保费，配置投保要素时需要配置一个固定费率投保要素，有且仅有一个值。还需要配置保额投保要素。如保额为10万，按照5%收取，保费即为5000
                            <br>3. 保费=费率表枚举，最常用的保费计算公式，根据保司提供的费率表录入，没有必须的投保要素
                            <br>4. 保费=阶梯费率表枚举，根据保额不同的阶段收取保费，如0-1万收1%，1-5万收2%，该计算方式需要在费率表中的保费列录入费率
                            <br>5. 保费=阶梯费率表枚举-扣除数：根据保额不同的阶段先按照比例收取保费，再减去该阶段固定的扣除数，所以必须配置一个扣除数投保要素，如0-1万收1%，扣除数为0，1-5万收2%，扣除数为100，该计算方式最常见于交税。需要在投保要素中配置一个扣除数投保要素，在费率表中的保费列录入费率
                            <br>6. 华安装修保计算公式：针对于华安装修保保额*固定费率+阶梯固定数值方式
                            <br>7. 德华安顾的保费计算方式：适用于德华安顾的，在费率表基础上对首单，1年期医疗险打折的情况
                        </p>
                      </template>
                      <template #title>
                        <span>规则说明:</span>
                      </template>
                      <question-circle-outlined />
                    </a-popover>
                  </span>
                </template>
                <a-select :options="premiumCalculationList" v-model:value="formData.premiumCalculationMethod"
                          placeholder="请选择保费计算公式" :disabled="zhuangTai1=='details'" clearable>
                </a-select>
              </a-form-item>
            </a-col>
            <a-col span="12" v-if="formData.premiumCalculation === '1' || formData.premiumCalculation === '2'">
              <a-form-item label="保费是否需要精度计算:" name="premiumCountSwitch"
                           :rules="[{required:true,message:'必填信息',trigger:'change'}]"
              >
                <a-select :options="premiumCountSwitchList" v-model:value="formData.premiumCountSwitch"
                          :disabled="zhuangTai1=='details'" clearable placeholder="请选择">
                </a-select>
              </a-form-item>
            </a-col>
            <a-col span="12"
                   v-if="formData.premiumCountSwitch === 'Y' && (formData.premiumCalculation === '1' || formData.premiumCalculation === '2')">
              <a-form-item label="保费保留位数:" name="premiumCountDigit"
                           :rules="[{required:true,message:'必填信息',trigger:'change'}]"
              >
                <a-select :options="premiumCountDigitList" v-model:value="formData.premiumCountDigit"
                          placeholder="请选择"
                          :disabled="zhuangTai1=='details'" clearable>
                </a-select>
              </a-form-item>
            </a-col>
            <a-col span="12"
                   v-if="formData.premiumCountSwitch === 'Y' && (formData.premiumCalculation === '1' || formData.premiumCalculation === '2')">
              <a-form-item label="保费精度计算方式:" name="premiumCountPrecision"
                           :rules="[{required:true,message:'必填信息',trigger:'change'}]"
              >
                <a-select :options="premiumCountPrecisionList" v-model:value="formData.premiumCountPrecision"
                          placeholder="请选择" :disabled="zhuangTai1=='details'" clearable>
                </a-select>
              </a-form-item>
            </a-col>
            <a-col span="12">
              <a-form-item
                  :rules="[{ required: true, message: '必填信息' ,trigger: 'change'}]" name="riskType">
                <template #label>
                  <span>险种类别: </span>&nbsp;
                  <span>
                    <a-popover placement="right">
                      <template #content>
                        <p>1. 经代产品：会根据代理人可售地区来限制售卖区域
                            <br>2. 网销产品：不会限制售卖区域
                        </p>
                      </template>
                      <template #title>
                        <span>规则说明:</span>
                      </template>
                      <question-circle-outlined />
                    </a-popover>
                  </span>
                </template>

                <a-select :options="riskTypeList" v-model:value="formData.riskType" allow-clear
                          placeholder="请选择险种类别" :disabled="zhuangTai1=='details'"
                          :getPopupContainer="(triggerNode)=>triggerNode.parentNode">
                </a-select>
              </a-form-item>
            </a-col>
            <a-col span="12">
              <a-form-item label="保单试算日期选择:" name="premiumTrialCalculationDate"
                           :rules="[{required:true,message:'必填信息',trigger:'change'}]"
              >
                <a-select v-model:value="formData.premiumTrialCalculationDate" :disabled="zhuangTai1=='details'"
                          :options="calculationDateList" placeholder="请选择保单试算日期选择" clearable>
                </a-select>
                <!--                <a-input v-model:value="formData.baseCoverage" :disabled="zhuangTai1=='details'"  placeholder="请输入基础保额"></a-input>-->
              </a-form-item>
            </a-col>
            <a-col span="12">
              <a-form-item label="职业类别:" name="occupationType">
                <a-select v-model:value="formData.occupationType" :disabled="zhuangTai1=='details'"
                          @change="getoccupationLevelList"
                          :options="occupationTypeList" placeholder="请选择职业类别" clearable>
                </a-select>
                <!--                <a-input v-model:value="formData.baseCoverage" :disabled="zhuangTai1=='details'"  placeholder="请输入基础保额"></a-input>-->
              </a-form-item>
            </a-col>

            <a-col span="12" v-if="formData.supId&&formData.occupationType">
              <a-form-item name="occupationLevel"
                           :rules="[{ required: false, message: '必填信息' ,trigger: 'change'}]">
                <template #label>
                  <span>职业等级: </span>&nbsp;
                  <span>
                    <a-popover placement="right">
                      <template #content>
                        <p>
                          当选择“供应商名称”和“险种类别”后，会联动出“此字段；不同供应商会有不同的职业等级划分；相同保司，不同险种类别也会有不同的职业等级；此处配置职业等级后，在产品详情页及信息录入页被保人职业选择时，不符合等级的职业会不显示；
                        </p>
                      </template>
                      <template #title>
                        <span>规则说明:</span>
                      </template>
                      <question-circle-outlined />
                    </a-popover>
                  </span>
                </template>
                <a-select
                    allow-clear
                    :disabled="zhuangTai1=='details'"
                    show-search
                    mode="multiple"
                    :options="occupationLevelList"
                    v-model:value="formData.occupationLevel" placeholder="请选择">
                </a-select>
              </a-form-item>
            </a-col>
          </a-row>
          <a-row>
            <a-col span="12">
              <a-form-item name="ageDiffValue" :rules="[
                           { pattern: /^-?\d+$|^-?\d+Y$|^-?\d+Y:-?\d+$/, message:'请输入符合格式的值，可以是整数，或者整数Y，或者整数Y:整数三种格式之一' ,trigger:'change'},
                           ]">
                <template #label>
                  <span>年龄计算规则: </span>&nbsp;
                  <span>
                <a-popover placement="right">
                  <template #content>
                    <p>输入天数值，格式为整数，整数Y，整数Y:整数三种格式之一，其中Y表示年,前端将以天数差去处理;
                      <br>eg:假设17岁过18岁生日:
                      <br>1. 生日当天不算年龄18而是明天才算18，则输入1;
                      <br>2. 如果生日当天即为18，则输入0;
                      <br>3. 如果生日前一天就可按18算，则为-1;
                      <br>eg:若以间隔年去处理，则带上单位Y，年差与天数差以冒号:符号分开，若只传1Y则默认为:0
                      <br>1. 如果生日按虚岁算且18岁当天生日就是19岁，则1Y或1Y:0
                      <br>2. 如果生日按虚岁算且18岁的下一天才算是19岁，则1Y:1
                      <br>3. 如果生日按虚岁算且18岁的前一天就算是19岁，则1Y:-1
                    </p>
                  </template>
                  <question-circle-outlined/>
                </a-popover>
              </span>
                </template>
                <a-input v-model:value="formData.ageDiffValue"
                         placeholder="请输入天数差值" :disabled="zhuangTai1=='details'"></a-input>
              </a-form-item>
            </a-col>
          </a-row>
        </a-form>
      </a-tab-pane>
      <a-tab-pane key="2" tab="资料上传" v-if="!zhuangTai3">
        <search-form
            :Condition="condition"
            :ColSpan="12"
            @callback="handleSearchs"
            v-if="look"
        ></search-form>
        <a-button danger @click="delMultiple" v-if="!zhuangTai2">
          <template #icon>
            <DeleteOutlined/>
          </template>
          批量删除
        </a-button>
        <a-button type="primary" v-if="!zhuangTai2" style="margin-left: 20px" @click="addUsers(a,'newAdds')">
          <template #icon>
            <FileAddOutlined/>
          </template>
          新增
        </a-button>
        <div v-if="!zhuangTai2">
          <a-table
              class="bg"
              :key="time"
              :columns="columns"
              :data-source="dataSourc"
              :row-selection="{
			selectedRowKeys: selectedRowKeys,
			onChange: onSelectChange,
		}"
              :row-key="(record) => record.fileType"
              @change="handleTableChange"
              :loading="loading"
              :pagination="pagination"
          >
            <!--            :pagination="false"-->

            <template #bodyCell="{ column, index,record }">
              <template v-if="column.key === 'uploadFileName'">
                <a @click="downloadCodeImg(record)" download>{{ record.uploadFileName }}
                </a>
              </template>
              <template v-if="column.key === 'index'">
                {{ (pagination.current - 1) * pagination.pageSize + index + 1 }}
              </template>
              <template v-if="column.key === 'operation'">
                <div v-if="zhuangTai1!='details'">&nbsp;&nbsp;
                  <a style="color: orange" @click="modifyUser(record,'bianJi')">编辑</a>&nbsp;&nbsp;
                </div>
              </template>
            </template>
          </a-table>
        </div>
        <div v-if="zhuangTai2">
          <a-table
              :columns="columns"
              :data-source="dataSourc"
              :key="time"
              :row-key="record => record.fileType"
              :pagination="pagination"
              @change="handleTableChange"
              :loading="loading"
              :scroll="{ x: 800 }"
          >
            <template #bodyCell="{ column, index,record}">
              <template v-if="column.key === 'uploadFileName'">
                <a @click="downloadCodeImg(record)" download>{{ record.uploadFileName }}
                </a>
              </template>
              <template v-if="column.key === 'index'">
                {{ (pagination.current - 1) * pagination.pageSize + index + 1 }}
              </template>
            </template>
          </a-table>
        </div>
      </a-tab-pane>
      <a-tab-pane key="3" tab="险种条款信息" v-if="!zhuangTai3">
        <a-spin :spinning="spinning" :delay="50" size="large">
          <div class="insurance_conditions_info">
            <div v-for="(item, index) in sourceData" :key="item.id">
              <a-form
                  :model="item"
                  ref="sourceDataRef"
                  autocomplete="off"
                  :label-col="{ span: 4 }" :wrapper-col="{ span: 16 }"
              >
                <a-row :gutter="8">
                  <a-col :span="20">
                    <a-form-item label="主标题" name="mainTitle" :required="index !== 0">
                      <a-input :readonly="index === 0" :disabled="zhuangTai1==='details'" v-model:value="item.mainTitle"
                               placeholder="请输入主标题"></a-input>
                    </a-form-item>
                  </a-col>
                  <a-col :span="1" v-if="zhuangTai1!=='details'">
                    <a-button v-if="index !== 0" danger @click="onDeleteSourceData(index)">
                      <template #icon>
                        <DeleteOutlined/>
                      </template>
                      删除
                    </a-button>
                  </a-col>
                  <a-col :span="20">
                    <a-form-item label="所属文件" name="articleBelongs">
                      <a-input :disabled="zhuangTai1==='details'" v-model:value="item.articleBelongs"
                               placeholder="请输入所属文件名"></a-input>
                    </a-form-item>
                  </a-col>
                  <a-col :span="20">
                    <a-form-item label="展示顺序" name="sort" :required="index !== 0">
                      <a-input :readonly="index === 0" :disabled="zhuangTai1==='details'" v-model:value="item.sort"
                               placeholder="请输入展示顺序"></a-input>
                    </a-form-item>
                  </a-col>
                  <a-col :span="20" v-if="activeKey === '3'">
                    <a-form-item label="内容模板" name="text" :required="index !== 0">
                      <wang-editor
                          :ref="(el) => setElementRef(el, 'editorRef-'+item.id)"
                          :readOnly="zhuangTai1==='details'" :contentHtml="item.text" v-model:text="item.text"
                          v-on:getWangEditorValue="getWangEditorValue"></wang-editor>
                    </a-form-item>
                  </a-col>
                </a-row>
              </a-form>
            </div>
            <div>
              <a-row v-if="zhuangTai1!=='details'">
                <a-col :span="4" :offset="4">
                  <a-button type="primary" @click="onAddSourceData">
                    <template #icon>
                      <FileAddOutlined/>
                    </template>
                    添加
                  </a-button>
                </a-col>

                <a-col :span="6">
                  <a-button type="primary" @click="onSaveSourceData">
                    <template #icon>
                      <SaveOutlined/>
                    </template>
                    提交
                  </a-button>
                </a-col>
              </a-row>

            </div>

          </div>
        </a-spin>
      </a-tab-pane>
    </a-tabs>
    <template #extra>
      <a-space>
        <a-button @click="onFormClean" v-if="activeKey === '1'">
          <template #icon>
            <CloseOutlined/>
          </template>
          关闭
        </a-button>
        <a-button html-type="submit" type="primary" @click="onSave" v-if="activeKey === '1' && zhuangTai1!=='details'">
          <template #icon>
            <SaveOutlined/>
          </template>
          提交
        </a-button>
        <a-button @click="onFormClean" v-if="activeKey !== '1'">
          <template #icon>
            <CloseOutlined/>
          </template>
          关闭
        </a-button>
      </a-space>
    </template>
  </a-drawer>
  <insuranceInformationType ref="insuranceTypeRef" :status="status"
                            @handleSearchs="handleSearchs(formDataOne, pagination)"></insuranceInformationType>
</template>

<script>
import {DeleteOutlined, FileAddOutlined, SaveOutlined} from '@ant-design/icons-vue'
import {computed, defineComponent, nextTick, onBeforeMount, onMounted, reactive, ref, toRefs} from 'vue'
import {message, Modal} from 'ant-design-vue'
import {getRequestURI, request, requestUsePagination} from '../../../utils/request'
import {
  getDictionaryData,
  getDictionaryDataInApi,
  getDictionaryValue,
  getExtendUri
} from '../../../utils/request_extend'
import SearchForm from '../../../components/extended/search_form.vue'
import insuranceInformationType from './insuranceInformationType'
import WangEditor from '@/components/extended/Editor'
import {getUUID} from 'ant-design-vue/es/vc-dialog/util'
// 前端转图片功能注释掉~
// import {getSourceDataWithImageData} from '../../../utils/rich_to_image.js'

export default defineComponent({
  components: {
    SaveOutlined,
    SearchForm,
    insuranceInformationType,
    WangEditor,
    FileAddOutlined,
    DeleteOutlined

  },
  props: {
    status: {
      type: String,
      required: true,
      default() {
        return '123'
      },
    },
    handleSearch: {type: Function},
    searchfFilters: { type: Object },
    current: {type: Number}
  },

  setup(props, {expose}) {
    const riskTypeLargeCodeData = ref([])
    const riskTypeSmallCodeData = ref([])
    const riskTypeLargeCodeData2 = ref([])
    const riskTypeSmallCodeData2 = ref([])
    const formRef = ref()
    const editorRef = ref()
    const editorRl = {}
    const getHtmlHeaderStr = (name) => {
      return `<h2><span style="max-width: 5px; height: 25px; color:#4CAF50; background-color: #4CAF50; margin-right: 10px;">i</span>${name}</h2>\n`
    }

    const setElementRef = (el, flag) => {
      if (el) {
        editorRl[flag] = el
      }
    }
    const  insuranceTypeRef = ref()
    const listData = reactive({
      gongyingshangmingcheng: [],
    })
    const premiumCalculationList = reactive([]) // 保费计算公式存储
    const formData = reactive({
      countCopiesWay: 'direct_input',
      occupationType: undefined,
      premiumTrialCalculationDate: undefined, //保单试算日期选择
      occupationLevel:[],//职业等级
      supName: '',//供应商名称
      supId: undefined,//供应商Id
      riskName: '',//险种名称
      supRiskCode: '',//供应商险种编码
      riskCode: '',//险种编码
      riskCsSign: undefined,//财寿标志
      riskGroupSign: undefined,//团个险标志
      riskLengthSign: undefined,//长短险标志
      riskSign: undefined,//主附险标志
      doubleMainRiskFlag: undefined,//双主线标志
      riskTypeLargeCode: undefined,//险种类别大类
      riskTypeFineCode: undefined,//险种类别细类
      insuranceTypeCode: undefined,//保监险种类别
      insuranceFineCode: undefined,//保监险种细分类
      giftRisk: 'N',//是否赠险
      exemptRisk: 'N', // 是否豁免险
      giftRiskType: undefined,//赠险类型
      riskNumber: undefined,//限购份数
      effectDay: undefined,//生效天数
      waitDate: undefined,//等待期
      wideDate: undefined,//犹豫期
      premiumCalculation: undefined,//保费计算方式
      baseCoverage: undefined, // 基础保额
      premiumCalculationMethod: undefined,  // 保费计算公式
      premiumCountSwitch: undefined, //保费是否需要特殊计算
      premiumCountDigit: undefined,  // 保费计算保留位数
      premiumCountPrecision: undefined, // 保费计算精度计算方式
      id: '',
      isDel: '',
      updateUser: '',
      createUser: '',
      riskType:undefined,
      ageDiffValue: 0
    })
    const formDataOne = reactive({
      fileType: '',//文件资料类型
      displayOrder: '',//显示顺序
      uploadFile: '',//上传文件
      fileName: '',//文件显示名称
      riskId: '',//匹配的id
      riskCode: '',//险种编码
      uploadFileName: '',//上传文件名称
      id: '',
      fileOrder:'',
      mustReadOrder:''

    })
    const state = reactive({
      activeKey: '1',
      occupationLevelList:[],
      chuan: '',
      panDuan: '',
      time: null,
      look: false,
      ziDuan: true,
      exemptRiskState: true,
      supNameList: [],//供应商名称
      status: '',
      selectedRowKeys: [],//多选反选
      data: [],//批量删除
      checkids: [],
      dataSourc: [],
      zhuangTai1: null,
      zhuangTai2: false,
      zhuangTai3: false,
      zhuangTai4: true,
      supGradeList: [
        {
          label: '总公司',
          value: 1
        },
        {
          label: '分公司',
          value: 2
        },
        {
          label: '中心支公司',
          value: 3
        },
        {
          label: '支公司',
          value: 4
        }
      ],
      //保监险种类别
      insuranceTypeCodeList: [],
      //保监险种细分类
      insuranceFineCodeList: [],
      code: '',
      condition: {
        fileType: {
          label: '资料类型',
          type: 'select',
          default: '',
          urlKey: 'queryDictionaryType',
          queryParam: {type: 'risk_file_type'}
        }
      },
      //表格表头
      columns: [
        {
          title: '序号',
          key: 'index',
          fixed: 'left',
          width: 60,
          align: 'center',
        },
        {
          title: '文件资料类型',
          width: 100,
          align: 'center',
          key: 'fileType',
          dataIndex: 'fileType',
          customRender: (text) => getDictionaryValue('risk_file_type', text.value)
        },
        {
          title: '文件资料展示顺序',
          width: 100,
          align: 'center',
          key: 'displayOrder',
          dataIndex: 'displayOrder',
        },
      ],
      visible: false,
      isNew: true,
      exemptionData: {
        id: '',
        mainTitle: '责任免除',
        articleBelongs: '',
        sort: '0',
        text: '',
      }
    })
    const userDrawerRefOne = ref()
    const addUsers = function (record, status) {
      record = state.chuan
      // record.displayOrder = 0
      insuranceTypeRef.value.showDrawer(record, status)
    }
    //赠险判断
    const zengXian = (val) => {
      // console.log(val,'赠险')
      if (val == 'Y') {
        state.ziDuan = false
      } else if (val == 'N') {
        state.ziDuan = true
      }
    }
    const downloadCodeImg = (content) => {
      let link = document.createElement('a')
      let url = content.uploadFile
      fetch(url).then(res => res.blob()).then(blob => { // 将链接地址字符内容转变成blob地址
        link.href = URL.createObjectURL(blob)
        link.download = content.uploadFileName
        document.body.appendChild(link)
        link.click()
      })
    }
    const filterTreeOption = function (input, option) {
      return option.supName.includes(input)
    }
    const isVisible = ref(false)
    //表格多选反选
    const onSelectChange = (selectedRowKeys, selectedRows) => {
      state.data.splice(0)
      // console.log('selectedRowKeys changed: ', selectedRowKeys)
      state.selectedRowKeys = selectedRowKeys
      state.data = selectedRows
    }
    const setType = (type) => {
      if (type) {
        state.checkids = []
      }
    }
    const baofeijisuanfangshi = function () {

    }
    const onSelectChanges = (selectedRowKeys, record) => {
      // console.log('selectedRowKeys changed: ', selectedRowKeys)
      state.checkids = selectedRowKeys
      userDrawerRefOne.value.showDrawerOne(record[0], 'chakan')
      // console.log(record[0],'chalna')
    }

    const fenshu = function () {
      request(getExtendUri('dictionary_query_byType'), {data: {types: ['count_copies_way']}}).then(res => {
        console.log(res)
        res.result.count_copies_way.forEach(item => {
          let obj = {}
          obj.label = item.label
          obj.value = item.value
          // listData.fenshujisuanfangshi.push(obj)
        })
      })
    }
    //获取供应商名称
    const huoqu = () => {
      const uri = getRequestURI('supplierQuerySupLevelSt')
      request(uri, {data: {}}).then(res => {
        console.log(res)
        if (res.code == 1) {
          listData.gongyingshangmingcheng = res.result
        }
      })
    }
    huoqu()
    //改变供应商名称
    const changSup = (val, val1) => {
      formData.supName = val1[0]
      getoccupationLevelList()
    }

    // }
    const changeDaLei1 = (val, opt) => {
      console.log('changeDaLei1', opt)
      if(opt) {
        getDictionaryData(riskTypeSmallCodeData, opt['subType'])
      }
    }
    const changeDaLei2 = (val, opt) => {
      // console.debug('changeDaLei2', opt)
      if(opt) {
        getDictionaryData(riskTypeSmallCodeData2, opt['subType'])
      }
    }

    //获取险种类别大全
    const riskTyperiskType = () => {
      let obj = {
        types: ['protemptype_risk'],
      }
      let uri = getExtendUri('dictionary_query_byType')
      request(uri, {data: obj}).then(res => {
        state.riskTypeLargeCodeList = res.result.protemptype_risk
        state.insuranceTypeCodeList = res.result.protemptype_risk
      })
    }
    //获取险种细类
    const xiLei = () => {
      let obj = {
        types: [state.code],
      }
      let uri = getExtendUri('dictionary_query_byType')
      request(uri, {
        data: obj, pagination: {
          current: 1,
          pageSize: 1000,
          total: 0
        },
        sorters: [
          {
            field: '',
            order: ''
          }
        ]
      }).then(res => {
        state.riskTypeFineCodeList = res.result[state.code]
        state.insuranceFineCodeList = res.result[state.code]
      })
    }
    //  批量删除
    const delMultiple = () => {
      let keys = state.data
      if (keys.length === 0) {
        message.warning('请选择数据')
        return
      }

      Modal.confirm({
        title: '确认删除吗?',
        async onOk() {
          // visible.value = true
          let uri = getRequestURI('productFile_delete')
          request(uri, {data: state.data}).then(
            (result) => {
              handleSearchs({})
              state.data = []
              if (result.code == 1) {
                message['success']('删除成功')
              }else{
                message['warning'](result.message)
              }
            }
          )
        },
        onCancel() {
        }
      })
    }

    //编辑
    const modifyUser = function (record, status) {
      insuranceTypeRef.value.showDrawer(record, status)
    }
    //文件上传
    const uploadCosFileChange = (data) => {
      formDataOne.uploadFile = data.url
      formDataOne.uploadFileName = data.enclosureName
    }

    const {
      data: dataSource,
      run,
      loading,
      current,
      pageSize,
      total,
    } = requestUsePagination(getRequestURI('productFile_selectListForPage'))
//查询
    const handleSearchs = function (filters, page='') {
      formDataOne.fileType = filters.fileType
      // 参数没有险种编码也就没有查询接口的必要了
      if (formDataOne.riskCode && formDataOne.riskCode !== '') {
        run({
          pagination: {
            current: page ? page.current : 1,
            pageSize: 10
          },
          data: formDataOne,
        }).then(res => {
          state.dataSourc = res.result
        })
      } else {
        state.dataSourc = []
      }

    }
    const pagination = computed(() => ({
      current: current.value,
      pageSize: pageSize.value,
      total: total.value,
    }))
    const handleTableChange = (pag, filters, sorter) => {
      console.log('===', filters)
      run({
        pagination: {
          current: pag?.current,
          pageSize: pag?.pageSize
        },

        sorter,
        data: formDataOne,
      }).then(res => {
        state.dataSourc = res.result
      })
    }

    //组件传值
    const showDrawer = (oldData = {}, newData = {}) => {
      zidianData()
      nextTick(() => {

        getoccupationLevelList()
      })
      state.chuan = oldData
      state.look = true
      state.zhuangTai1 = newData
      state.visible = true
      state.isNew = !(typeof oldData.id === 'string' && oldData.id.length > 0)
      if (oldData.occupationLevel) {
        oldData.occupationLevel = getol(oldData.occupationLevel)
      }
      Object.keys(formData).forEach((key) => {
        if (typeof (oldData[key]) == 'number') {
          oldData[key] = oldData[key] + ''
        }
        formData[key] = oldData[key] || formData[key]
      })
      // if (oldData.effectDay == 0) {
      //   formData.effectDay = 0
      // }
      // if (oldData.wideDate == 0) {
      //   formData.wideDate = 0
      // }

      // getPremiumCalculationMethod()
      // if (formData.waitDate === undefined) {
      //   formData.waitDate = 0
      // }
      if (formData.riskTypeLargeCode !== '' && formData.riskTypeLargeCode !== undefined && formData.riskTypeLargeCode !== null) {
        console.log(state.riskTypeLargeCodeList)
        riskTyperiskType()
        state.riskTypeLargeCodeList.forEach(item => {
            if (item.value === formData.riskTypeLargeCode) {
              state.code = item.subType
              xiLei()
            }
          }
        )
      }
      formDataOne.riskId = oldData.id
      formDataOne.riskCode = oldData.riskCode
      if (oldData.giftRisk == 'Y') {
        state.ziDuan = false
      } else {
        state.ziDuan = true
      }

      if (oldData == 'newAdd') {
        state.zhuangTai3 = true
      } else {
        state.zhuangTai3 = false
      }


      state.activeKey = '1'
      if (newData == 'details') {
        state.zhuangTai2 = true
        state.panDuan = '险种信息查看'
        const isOptional =  state.columns.find(item => item.key=== 'operation')
        if (isOptional){
          state.columns.pop()
        }
        let editArrChangeDaLei1 = riskTypeLargeCodeData.value.filter(item => {
          return item.value == oldData.riskTypeLargeCode
        })
        let editArrChangeDaLei2 = riskTypeLargeCodeData2.value.filter(item => {
          return item.value == oldData.insuranceTypeCode
        })
        changeDaLei1(oldData.riskTypeLargeCode, editArrChangeDaLei1[0])
        changeDaLei2(oldData.insuranceTypeCode, editArrChangeDaLei2[0])
      } else if (newData == 'edit') {
        state.panDuan = '险种信息编辑'
        let editArrChangeDaLei1 = riskTypeLargeCodeData.value.filter(item => {
          return item.value == oldData.riskTypeLargeCode
        })
        let editArrChangeDaLei2 = riskTypeLargeCodeData2.value.filter(item => {
          return item.value == oldData.insuranceTypeCode
        })
        changeDaLei1(oldData.riskTypeLargeCode, editArrChangeDaLei1[0])
        changeDaLei2(oldData.insuranceTypeCode, editArrChangeDaLei2[0])

        state.zhuangTai2 = false
        const isOptional =  state.columns.find(item => item.key=== 'operation')
        if(!isOptional) {
          state.columns.push(
            {
              title: '操作',
              key: 'operation',
              ellipsis: true,
              align: 'center',
              fixed: 'right',
              width: 100,
            }
          )
        }
      } else {
        state.panDuan = '险种信息新增'
      }


      huoqu()
      riskTyperiskType()
    }
    const onClose = () => {
      // state.visible = false
      state.dataSourc = []
      pagination.value.total = 0
      state.time = new Date().getTime()
      onFormClean()
    }
    //关闭按钮
    const onFormClean = () => {
      state.dataSourc = []
      formRef.value.resetFields()
      formRef.value.clearValidate()
      state.targetKeys = []
      state.look = false
      state.visible = false
      Object.assign(formData, {
        countCopiesWay: 'direct_input',
        occupationType: undefined,
        supName: '',//供应商名称
        supId: undefined,//供应商Id
        occupationLevel:[],
        riskName: '',//险种名称
        supRiskCode: '',//供应商险种编码
        riskCode: '',//险种编码
        riskCsSign: undefined,//财寿标志
        riskGroupSign: undefined,//团个险标志
        riskLengthSign: undefined,//长短险标志
        riskSign: undefined,//主附险标志
        doubleMainRiskFlag: undefined,//双主线标志
        riskTypeLargeCode: undefined,//险种类别大类
        riskTypeFineCode: undefined,//险种类别细类
        insuranceTypeCode: undefined,//保监险种类别
        insuranceFineCode: undefined,//保监险种细分类
        giftRisk: 'N',//是否赠险
        giftRiskType: undefined,//赠险类型
        riskNumber: undefined,//限购份数
        effectDay: undefined,//生效天数
        waitDate: undefined,//等待期
        wideDate: undefined,//犹豫期
        premiumCalculation: undefined,//保费计算方式
        baseCoverage: undefined, // 基础保额
        premiumCalculationMethod: undefined,  // 保费计算公式
        premiumCountSwitch: undefined, //保费是否需要特殊计算
        premiumCountDigit: undefined,  // 保费计算保留位数
        premiumCountPrecision: undefined, // 保费计算精度计算方式
        id: '',
        isDel: '',
        updateUser: '',
        createUser: '',
      })
      state.dataSource = []
      state.checkids = []
      state.selectedRowKeys = []
      state.time = new Date().getTime()
    }
    //资料上传提交按钮
    const onSave = async () => {
      await formRef.value.validate()
      // 险种编码校验
      let reg1 = /^[0-9a-zA-Z]+$/
      let reg1Array = formData.riskCode
      let vive1 = reg1.test(formData.riskCode)
      if (formData.riskCode === '') {
        formData.riskCode = ''
        message['warning']('险种编码不能为空')
        return
      }
      if (!vive1) {
        message['warning']('险种编码支持数字和字母格式')
        formData.riskCode = ''
        return
      }
      if (reg1Array.length > 20) {
        formData.riskCode = ''
        message['warning']('险种编码输入长度不能超过20')
        return
      }
      //供应商险种编码校验
      // let reg2Array = formData.supRiskCode.split('')
      if (formData.supRiskCode === '') {
        formData.supRiskCode = ''
        message['warning']('供应商险种编码不能为空')
        return
      }

      if (formData.riskName === '' || formData.riskName === undefined) {
        message['warning']('险种名称不能为空')
        return
      }
      if (formData.supId === '' || formData.supId === undefined) {
        message['warning']('供应商名称不能为空')
        return
      }

      //财寿标志校验
      if (formData.riskCsSign === ''|| formData.riskCsSign === undefined) {
        message['warning']('财寿标志不能为空')
        return
      }
      //团个险标志校验
      if (formData.riskGroupSign === '' || formData.riskGroupSign === undefined) {
        message['warning']('团个险标志不能为空')
        return
      }
      //长短险标志校验
      if (formData.riskLengthSign === '' || formData.riskLengthSign === undefined) {
        message['warning']('长短险标志不能为空')
        return
      }
      //主附险标志校验
      if (formData.riskSign === ''|| formData.riskSign === undefined) {
        message['warning']('主附险标志不能为空')
        return
      }
      //双主险标志校验
      if (formData.doubleMainRiskFlag === '' || formData.doubleMainRiskFlag === undefined) {
        message['warning']('双主险标志不能为空')
        return
      }
      //险种类别大类校验
      if (formData.riskTypeLargeCode === ''|| formData.riskTypeLargeCode === undefined) {
        message['warning']('险种类别大类不能为空')
        return
      }
      //保监险种类别大类校验
      if (formData.insuranceTypeCode === ''|| formData.insuranceTypeCode === undefined) {
        message['warning']('保监险种类别不能为空')
        return
      }
      //是否赠险校验
      if (formData.giftRisk === ''|| formData.giftRisk === undefined) {
        message['warning']('是否赠险不能为空')
        return
      }
      if (formData.giftRisk === 'Y') {
        //赠险类型校验
        if (formData.giftRiskType === ''|| formData.giftRiskType === undefined) {
          message['warning']('赠险类型不能为空')
          return
        }
      }

      //生效天数校验
      let reg4 = /^[+]{0,1}(\d+)$/
      let reg4Array = formData.effectDay
      let vive4 = reg4.test(formData.effectDay)
      if (formData.effectDay === '' || formData.effectDay === undefined) {
        message['warning']('生效天数不能为空')
        return
      }
      if (!vive4) {
        formData.effectDay = ''
        message['warning']('生效天数支持整数字')
        return
      }
      if (reg4Array.length > 5) {
        formData.effectDay = ''
        message['warning']('生效天数输入长度不能超过5')
        return
      }


      //等待期校验
      let reg5 = /^([0-9]+[\d]*(.[0-9]{1,2})?)$/
      let reg5Array = formData.waitDate
      let vive5 = reg5.test(formData.waitDate)
      if (formData.waitDate === '' || formData.waitDate === undefined) {
        message['warning']('等待期不能为空')
        return
      }
      if (!vive5) {
        formData.waitDate = ''
        message['warning']('等待期支持整数或小数格式，最长两位小数')
        return
      }
      if (reg5Array.length > 5) {
        formData.waitDate = ''
        message['warning']('等待期输入长度不能超过5')
        return
      }
      //保费计算方式校验
      if (formData.premiumCalculation === '' || formData.premiumCalculation === undefined || formData.premiumCalculation === null) {
        message['warning']('保费计算方式不能为空')
        return
      }
      if (formData.premiumCalculation === '1' || formData.premiumCalculation === 'Y') {
        // if (formData.baseCoverage === '' || formData.baseCoverage === undefined) {
        //   message['warning']('基础保额不能为空')
        //   return
        // }
        if (formData.premiumCountSwitch === undefined || formData.premiumCountSwitch === '') {
          message['warning']('保费是否需要特殊计算不能为空')
          return
        }
        if (formData.premiumCountSwitch === '1') {
          if (formData.premiumCountPrecision === undefined || formData.premiumCountPrecision === '') {
            message['warning']('保费计算精度计算方式不能为空')
            return
          }
          if (formData.premiumCountDigit === undefined || formData.premiumCountDigit === '') {
            message['warning']('保费计算保留位数不能为空')
            return
          }
          if (formData.premiumCalculationMethod === '' || formData.premiumCalculationMethod === undefined) {
            message['warning']('保费计算公式不能为空')
            return
          }
        }


      }
      // if (formData.occupationType === '' || formData.occupationType === undefined || formData.occupationType === null) {
      //   message['warning']('职业类别不能为空')
      //   return
      // }

      if (state.panDuan === '险种信息新增') {
        if (state.activeKey === '1') {
          let objOne = {
            data: formData
          }
          const uri = getRequestURI('productRisk_insert')
          request(uri, objOne).then(res => {
            if (res.code === 1) {
              onClose()
              state.visible = false
              message['success']('新增成功')
              props.handleSearch({})
            } else {
              message['warning'](res.message)
              handleSearchs({})
            }
          })
        }
      } else if (state.panDuan === '险种信息编辑') {
        if (state.activeKey === '1') {
          let objOne = {
            data: formData
          }
          const uri = getRequestURI('productRisk_update')
          request(uri, objOne).then(res => {
            if (res.code == 1) {
              onFormClean()
              props.handleSearch(props.searchfFilters, props.current)
              message['success']('修改成功')
            } else {
              handleSearchs({})
              message.warning(res.message)
            }
          })
        }
      }
    }

    const handleChange = info => {
      if (info.file.status !== 'uploading') {
        console.log(info.file, info.fileList)
      }

      if (info.file.status === 'done') {
        message.success(`${info.file.name} file uploaded successfully`)
      } else if (info.file.status === 'error') {
        message.error(`${info.file.name} file upload failed.`)
      }
    }
    // 获取保费计算公式
    const getPremiumCalculationMethod = () => {
      request(getExtendUri('dictionary_query_byType'), {data: {types: ['calculation_type']}}).then(
        res => {
          // console.log('测试', res)
          Object.assign(premiumCalculationList, [...res.result.calculation_type])
        })
    }
    const getoccupationLevelList=()=>{
      request(getRequestURI('selOccupationLevel'), {data: {supId:formData.supId,occupationType:formData.occupationType}}).then(
        res => {
          // console.log('测试', res)
          // console.log('zx11111',res)
          state.occupationLevelList=[]
          if(res.result){
            // state.occupationLevelList=
            res.result.forEach(item=>{
              // console.log(item)
              state.occupationLevelList.push({label:item,value:item})
            })
            // console.log('2222221',state.occupationLevelList)
          }


        })
    }
    const riskCsSignList = ref([])
    const riskGroupSignList = ref([])
    const riskLengthSignList = ref([])
    const riskSignList = ref([])
    const SZXList = ref([{value: 1, label: '是'}, {value: 0, label: '否'}])
    const giftRiskShow = ref([])
    const premiumList = ref([])
    const giftRiskTypeList = ref([])
    const fenshujisuanfangshi = ref([])
    const premiumCountSwitchList = ref([])
    const premiumCountDigitList = ref([])
    const premiumCountPrecisionList = ref([])
    const exemptRiskList = ref([])
    const occupationTypeList = ref([])
    const calculationDateList = ref([])
    const riskTypeList = ref([])


    const zidianData = () => {
      getDictionaryData(riskCsSignList, 'riskType')
      getDictionaryData(exemptRiskList, 'yes_no')
      getDictionaryData(riskGroupSignList, 'riskGSType')
      getDictionaryData(riskLengthSignList, 'riskLSType')
      getDictionaryData(riskSignList, 'riskMAType')
      getDictionaryData(giftRiskShow, 'yes_no')
      getDictionaryData(premiumList, 'premCalculateWay')
      getDictionaryData(giftRiskTypeList, 'giftRiskType')
      getDictionaryData(fenshujisuanfangshi, 'count_copies_way')
      getDictionaryData(premiumCountSwitchList, 'yes_no')
      getDictionaryData(premiumCountDigitList, 'premPrecision')
      getDictionaryData(premiumCountPrecisionList, 'premPrecisionFunc')
      getDictionaryData(occupationTypeList, 'occupationType')
      getDictionaryData(calculationDateList, 'premiumTrialCalculationDate')
      getDictionaryData(riskTypeList, 'ProductType')
      getPremiumCalculationMethod()
    }

    onBeforeMount(() => {
      getDictionaryDataInApi(riskTypeLargeCodeData, 'risk_type')
      getDictionaryData(riskTypeLargeCodeData2, 'protemptype_risk')
    })
    onMounted(() => {
    })
    const fileList = ref([])
    const getol = (ol) => {
      if (Array.isArray(ol)) {
        // myObj是一个数组
        return ol.filter((elem) => {
          return elem
        })
      }
      return ol
    }
    riskTyperiskType()
    fenshu()
    const sourceData = reactive([
      {
        id: '',
        mainTitle: '责任免除',
        articleBelongs: '',
        sort: '0',
        text: '',
      }
    ])
    const spinning = ref(false)
    const menusList = ref([
      'head',
      'bold', //字体加粗
      'fontSize',//字号
      'fontName',//字体
      'underline',//下划线
      'strikeThrough',//删除线
      'indent',
      'image',
      'undo',//撤销
      'redo',//恢复
    ])
    const getWangEditorValue = (str) => {
      console.log('执行getWangEditorValue------<>', str)
    }
    const onChangeActiveKey = (key) =>{
      if(key === '3'){
        spinning.value = true
        sourceData.splice(0, sourceData.length)
        getSourceData()
      }
    }
    // 获取条款信息
    const getSourceData = () =>{
      // 获取详情
      request(getRequestURI('riskTermDetail'), {data: {code: formData.riskCode}}).then(res =>{
        if(res.code === 1){
          if(res.result.length){
            sourceData.splice(0)
            Object.assign(sourceData, res.result)
          }else{
            const id= getUUID()
            Object.assign(sourceData, [{
              id,
              mainTitle: '责任免除',
              articleBelongs: '',
              sort: 0,
              text: '',
            }])
          }
          spinning.value = false
        }else{
          message.warning(res.message)
          spinning.value = false
        }
      })
    }
    // 新增条款信息
    const onAddSourceData = () =>{
      if (isReadSave(sourceData)) {
        const id = getUUID()
        sourceData.push({id, mainTitle: '', articleBelongs: '', sort: '', text: '',})
      } else {
        message.warning('内容未填写，请检查！')
      }

    }
    // 删除条款信息
    const onDeleteSourceData = (index) =>{
      // if(sourceData.length < 2){
      //   message.warning('条款信息不能少于一条')
      //   return
      // }
      spinning.value = true

      Modal.confirm({
        title: '删除提示',
        content: '确定删除此数据吗?',
        onOk: () => {
          message.success('删除成功')
          sourceData.splice(index, 1)
          spinning.value = false
        },
        onCancel: () => {
          spinning.value = false
          Modal.destroyAll()   // 清除弹出框
        }
      })
    }

    // 判断每条是否都填写了mainTitle和text，sort
    const isReadSave = (list) => {
      // 判断list里的每条数据是否都存在了mainTitle和text，sort
      const arr = list.filter(item => item.sort !== 0)
      return arr.every(item => {
        return item.mainTitle && item.text && item.sort && item.articleBelongs
      })
    }

    // const getRefList = (list) => {
    //   // 检查 list 是否是一个数组，并且 editorRl 是否存在
    //   if (!Array.isArray(list) || !editorRl) {
    //     console.error('Invalid input for getRefList')
    //     return []
    //   }
    //
    //   // 使用 map 方法来简化循环
    //   return list.map(item => {
    //     // 检查 item 是否有 id 属性
    //     if (!item.id) {
    //       return null // 或者返回 undefined，或者跳过这个元素，具体取决于你的需求
    //     }
    //     // 直接从 editorRl 中获取对应的引用
    //     return editorRl['editorRef-' + item.id]
    //   })
    // }
    // 提交条款信息
    const onSaveSourceData = async () => {
      if (!isReadSave(sourceData)) {
        message.warning('还有内容未填写完，请检查！')
        return
      }
      // let refList = getRefList(sourceData)
      spinning.value = true
      // let param = await getSourceDataWithImageData(sourceData, refList)
      let param = sourceData
      console.log(888, param, state.exemptionData)
      if (param) {
        console.debug('zhixingzhixing')
        request(getRequestURI('riskTermUpdate'),
            // {data: {riskCode: formData.riskCode, riskTerms: param}}).then(res => {
            {data: {riskCode: formData.riskCode, riskTerms: param}}).then(res => {
          if (res.code === 1) {
            message.success('保存成功')
            getSourceData()
          } else {
            message.warning(res.message)
            spinning.value = false
          }
        })
      }
    }





    expose({
      showDrawer
    })
    return {
      getSourceData,
      onAddSourceData,
      onDeleteSourceData,
      menusList,
      sourceData,
      spinning,
      onChangeActiveKey,
      getWangEditorValue,
      onSaveSourceData,
      occupationTypeList,
      calculationDateList,
      exemptRiskList,
      premiumCountSwitchList,
      premiumCountDigitList,
      premiumCountPrecisionList,
      fenshujisuanfangshi,
      premiumList,
      giftRiskTypeList,
      giftRiskShow,
      zidianData,
      riskTypeLargeCodeData,
      riskTypeSmallCodeData,
      riskTypeLargeCodeData2,
      riskTypeSmallCodeData2,
      premiumCalculationList,
      getPremiumCalculationMethod,
      formRef,
      editorRef,
      formData,
      formDataOne,
      ...toRefs(state),
      setType,
      onClose,
      onSave,
      onFormClean,
      fileList,
      headers: {
        authorization: 'authorization-text'
      },
      handleChange,
      delMultiple,
      isVisible,
      handleSearchs,
      uploadCosFileChange,
      onSelectChange,
      onSelectChanges,
      pagination,
      handleTableChange,
      modifyUser,
      changSup,
      zengXian,
      riskTyperiskType,
      xiLei,
      changeDaLei1,
      changeDaLei2,
      loading,
      dataSource,
      run,
      listData,
      downloadCodeImg,
      userDrawerRefOne,
      addUsers,
      baofeijisuanfangshi,
      fenshu,
      insuranceTypeRef,
      filterTreeOption,
      riskCsSignList,
      riskGroupSignList,
      riskLengthSignList,
      riskSignList,
      SZXList,
      riskTypeList,
      getoccupationLevelList,
      getol,
      setElementRef,
      getHtmlHeaderStr
    }
  },
})
</script>
