
<style scoped>
.bg {
  margin-bottom: 20px
}
</style>

<template>
  <a-drawer
    :title="panDuan"
    :width="'70%'"
    :visible="visible"
    :body-style="{ paddingBottom: '80px' }"
    :footer-style="{ textAlign: 'right' }"
    @close="onClose"
  >

    <search-form
      :Condition="condition"
      :ColSpan="12"
      @callback="handleSearch"
      v-if="look"
    ></search-form>
    <a-button danger @click="delMultiple"  v-if="!zhuangTai2">
      <template #icon>
        <DeleteOutlined/>
      </template>
      批量删除
    </a-button>
    <a-button type="primary"  v-if="!zhuangTai2" style="margin-left: 20px" @click="addUsers(a,'newAdds')">
      <template #icon>
        <FileAddOutlined/>
      </template>
      新增
    </a-button>
    <div  v-if="!zhuangTai2">
      <a-table
        class="bg"
        :columns="columns"
        :key="time"
        :data-source="dataSourc"
        :scroll="{ x: 1700 }"

        :row-selection="{
			selectedRowKeys: selectedRowKeys,
			onChange: onSelectChange,
		}"
        :row-key="(record) => record.id"
        :pagination="false"
        @change="handleTableChange"
        :loading="loading"
      >
        <!--        :pagination="pagination"-->

        <template #bodyCell="{ column, index,record }">
          <template v-if="column.key === 'index'">
            {{ (pagination.current - 1) * pagination.pageSize + index + 1 }}
          </template>
          <template v-if="column.key === 'operation'">
            <div v-if="zhuangTai1!='details'">&nbsp;&nbsp;
              <a style="color: orange" @click="modifyUser(record,'bianJi')" >编辑</a>&nbsp;&nbsp;
<!--              <a style="color: orange" @click="showModal(record,index)"  >查看</a>&nbsp;&nbsp;-->
            </div>
          </template>
        </template>
      </a-table>
    </div>
    <div  v-if="zhuangTai2">
      <a-table :columns="columns" :data-source="dataSourc"
               :key="time"
               :row-selection="{ selectedRowKeys: checkids,
            onChange: onSelectChanges,  type:'radio',hideSelectAll:true
            }"
               :row-key="record => record.id"
               :pagination="false"
               @change="handleTableChange"
               :loading="loading"
               :scroll="{ x: 1700 }"
      >
        <template #bodyCell="{ column, index}">
          <template v-if="column.key === 'index'">
            {{ (pagination.current - 1) * pagination.pageSize + index + 1 }}
          </template>
        </template>
      </a-table>

    </div>
    <template #extra>
      <a-space>
        <a-button @click="onFormClean">
          <template #icon>
            <CloseOutlined/>
          </template>
          关闭
        </a-button>
      </a-space>
    </template>
  </a-drawer>
  <shoulderAddOne ref="userDrawerRefOne" @setShow="setShow" :status="status" :is_insurance="isInsurance"></shoulderAddOne>
  <!-- @setShow="setShowTwo" -->
  <shoulderAddTwo ref="userDrawerRefTwo" @resetValue="resetValue" :handleSearch="handleSearch" :searchData="newFilters" :current="pagination.current" :status="status" :is_insurance="isInsurance"></shoulderAddTwo>
</template>

<script>
// import {SaveOutlined} from '@ant-design/icons-vue'
import {computed, defineComponent, reactive, ref, toRefs} from 'vue'
import {message, Modal} from 'ant-design-vue'
import {getRequestURI, request, requestUsePagination} from '../../../utils/request'
import SearchForm from '../../../components/extended/search_form'
import shoulderAddOne from './riskDutyAddOne.vue'
import shoulderAddTwo from './riskDutyAddTwo.vue'
import {getDictionaryValue} from '../../../utils/request_extend'

export default defineComponent({
  components: {
    // SaveOutlined,
    SearchForm,
    shoulderAddOne,
    shoulderAddTwo


  },
  props: {
    status: {
      type: String,
      required: true,
      default() {
        return '123'
      },
    },
    is_insurance: {
      type: Number,
      default() {
        return 0
      }
    }
  },

  setup(props, {expose}) {
    const formRef = ref()
    const formData = reactive({
      dutyCode:'',//责任编码
      comDutyCode:'',//供应商责任编码
      dutyName:'',//责任名称
      dutyType: undefined,//责任类型
      dutyTypeName:'',//责任类型名称
      isShowStatus:'N',//页面是否显示
      coverageWay:'1',//保额选择方式
      sort: '',//显示顺序
      isRisk:'N',//是否可选责任
      mainFlag:undefined,//主附险标志
      deductibleExcess:'',//免赔额
      dutyIntroduce:'',//责任描述
      id: '0',
      riskId:'',//险种id
      riskDutyPremiumList:[
        {
          premium:'0',//	保额/起止保额
          premiumValue:'',//保额/起止保额显示值
          id:'',
          riskDutyId:''//险种责任id
        }
      ],
      riskDutyPremiumScopeList:[
        {
          endPremium:'0',//终止保额
          endPremiumValue:'',//终止保额显示值
          id:'',
          premium:'0',//保额/起止保额
          premiumValue:'',//保额/起止保额显示值
          riskDutyId:'',//险种责任id

        }
      ],

    })
    const formDataOne = reactive({
      dutyCode:'',//责任编码
      dutyName:'',//责任名称
      id:''
    })
    const state = reactive({
      newFilters: {},
      isInsurance: props.is_insurance,
      time:null,
      dataSourc: [],
      look:false,
      dutyTypeOne:[],//责任类型
      status: '',
      selectedRowKeys: [],//多选反选
      selectedRowKeysOne:[],//保额单选
      data: [],//批量删除
      checkids: [],
      dataSource: [],
      bianji:[],
      zhuangTai1: null,
      zhuangTai2: false,
      zhuangTai3: false,
      zerenma:[],
      condition: {
        dutyCode:'责任编码',
        dutyName:'责任名称'
      },
      //表格表头
      columns: [
        {
          title: '序号',
          key: 'index',
          fixed: 'left',
          width: 60,
          align: 'left',
        },
        {
          title: '责任编码',
          width: 100,
          align: 'center',
          key: 'dutyCode',
          dataIndex: 'dutyCode',
        },
        {
          title: '供应商责任编码',
          width: 100,
          align: 'center',
          key: 'comDutyCode',
          dataIndex: 'comDutyCode',
        },
        {
          title: '责任名称',
          width: 100,
          align: 'center',
          key: 'dutyName',
          dataIndex: 'dutyName',
        },
        {
          title: '责任类型',
          width: 100,
          align: 'center',
          key: 'dutyType',
          dataIndex: 'dutyType',
          customRender:(text)=> getDictionaryValue('duty_type',text.value)
        },
        {
          title: '互斥关键字',
          width: 100,
          align: 'center',
          key: 'repulsionKey',
          dataIndex: 'repulsionKey',
        },
        {
          title: '显示顺序',
          width: 100,
          align: 'center',
          key: 'sort',
          dataIndex: 'sort',
        },
        {
          title: '界面是否显示',
          width: 100,
          align: 'center',
          key: 'isShowStatus',
          dataIndex: 'isShowStatus',
          customRender: (text)=> getDictionaryValue('yes_no', text.value)
        },
      ],

      visible: false,
      isNew: true,
      chuan:null
    })
    // const setShowTwo = function() {
    //   handleSearch({})
    // }
    //title判断状态
    // eslint-disable-next-line vue/return-in-computed-property
    const panDuan = computed(() => {
      if (props.status == 'newAdd') {
        return '险种责任增加'
      } else if (props.status == 'details') {
        return '险种责任查看'
      } else {
        return '险种责任编辑'
      }
    })
    const setShow = function (aa) {
      state.status = 'edit'
      console.log(aa)
      formData.dutyName=aa[0].dutyName+''
      formData.comDutyCode=aa[0].comDutyCode+''
      formData.dutyCode=aa[0].dutyCode+''
      formData.dutyType=aa[0].dutyType+''
      formData.isShowStatus=aa[0].isShowStatus+''
      formData.coverageWay=aa[0].coverageWay+''
      formData.sort=aa[0].sort+''
      formData.isRisk=aa[0].isRisk+''
      formData.mainFlag=aa[0].mainFlag+''
      formData.deductibleExcess=aa[0].deductibleExcess+''
      formData.dutyIntroduce=aa[0].dutyIntroduce+''

    }

    //表格多选反选
    const onSelectChange = (selectedRowKeys) => {
      state.data.splice(0)
      state.selectedRowKeys = selectedRowKeys
      state.data=selectedRowKeys
      for (let selectedRowKeys of state.selectedRowKeys) {
        for (let result in state.dataSource) {
          if (state.dataSource[result].id == selectedRowKeys && state.data.includes(state.dataSource[result]) == false) {
            state.data.push(state.dataSource[result])
          }
        }
      }
    }
    const onSelectChanges = (selectedRowKeys, selectedRows) => {
      state.data=selectedRowKeys
      userDrawerRefTwo.value.showDrawerTwo(selectedRows[0],'chakan')
      state.checkids = selectedRowKeys
    }
    const resetValue=()=>{
      state.checkids=[]
    }
    const onSelectChangess = (selectedRowKeys) => {
      console.log('selectedRowKeys changed: ', selectedRowKeys)
      state.selectedRowKeysOne = selectedRowKeys
    }

    //  批量删除
    const delMultiple = () => {
      let keys = state.data
      if (keys.length === 0) {
        message.warning('请选择数据')
        return
      }
      Modal.confirm({
        title: '确认删除吗?',
        async onOk() {
          // visible.value = true
          let uri = getRequestURI('riskDuty_deleteRiskDuty')
          request(uri, {data:{ids:state.data}}).then(
            (result) => {
              state.data = []
              if (result.code == 1) {
                message['success']('删除成功')
                handleSearch({})
              }else{
                message['warning'](result.message)

              }
              handleSearch({})

            }
          )
        },
        onCancel() {
        }
      })
    }
    // //获取责任类型
    // const shuJuType = () => {
    //   let obj = {
    //     types:['duty_type'],
    //     ids:[]
    //   }
    //   let uri = getRequestURI('dictionary_query_byType')
    //   request(uri, {data: obj}).then(res => {
    //     state.dutyTypeOne = res.result.duty_type
    //     // console.log( state.dutyTypeOne)
    //     //
    //     // console.log(state.dutyTypeOne)
    //     // console.log(res.result.duty_type,'1')
    //     // console.log(res.result['duty_type'],'2')
    //   })
    // }

    //编辑
    const modifyUser = function (record, status) {
      state.bianji=record
      state.status = status
      record.sort = record.sort + ''
      if (record.coverageWay === '2') {
        record.riskDutyPremiumList.forEach(item => {
          item.premium=item.premium+''
          item.premiumValue=item.premiumValue+''
        })
        record.riskDutyPremiumScopeList = []
      } else if (record.coverageWay === '3') {
        record.riskDutyPremiumScopeList.forEach(item => {
          item.premium=item.premium+''
          item.premiumValue=item.premiumValue+''
          item.endPremium=item.endPremium+''
          item.endPremiumValue=item.endPremiumValue+''
        })
        record.riskDutyPremiumList = []
      }
      record.dutyCode= record.dutyCode+''
      record.coverageWay =  record.coverageWay + ''
      record.isInsurance = state.isInsurance
      userDrawerRefTwo.value.showDrawerTwo(record,status)
      handleSearch({})
      // record.dutyCode=''
      // record.riskDutyPremiumScopeList.forEach(item => {
      //   item.premium=''
      //   item.premiumValue=''
      //   item.endPremium=''
      //   item.endPremiumValue=''
      // })
      // record.riskDutyPremiumList.forEach(item => {
      //   item.premium=''
      //   item.premiumValue=''
      // })
      // record.sort =''

    }

    //搜索
    const {
      data:dataSource,
      run,
      loading,
      current,
      pageSize,
      total,
    } = requestUsePagination(getRequestURI('selectRisk_dutyList'))


    const pagination = computed(() => ({
      current: current.value,
      pageSize: pageSize.value,
      total: total.value,
    }))
    const handleTableChange = (pag, filters, sorter) => {
      console.log('handleTableChange', pag, filters, sorter)
      run({
        pagination: {
          current: pag?.current,
          pageSize: pag?.pageSize
        },
        sorter,
        data:formData,
      }).then(res => {
        state.dataSourc = res.result
        console.log(state.dataSourc)
      })
    }

    const handleSearch = function (filters, pag='') {
      formData.dutyCode=filters.dutyCode
      formData.dutyName=filters.dutyName
      state.newFilters = filters
      run({
        pagination: {
          current: pag ?pag:1,
          pageSize: 10
        },
        data: formData,
      }).then(res => {
        state.dataSourc = res.result
        console.log(state.dataSourc)
      })

    }
    const userDrawerRefOne = ref()
    const search = function (status) {
      console.log(status)
      state.status = status
      userDrawerRefOne.value.showDrawer(status)
    }
    const userDrawerRefTwo = ref()
    const addUsers = function (record,status){
      record=state.chuan
      userDrawerRefTwo.value.showDrawerTwo(record,status)
      handleSearch({})
    }

    const activeKey = ref('1')
    //组件传值
    const showDrawer = (oldData = {}, newData = {}) => {
      state.isInsurance = oldData.riskCsSign
      state.chuan=oldData
      // handleSearch({})
      state.zhuangTai1 = newData
      state.look=true
      state.visible = true
      state.status=newData
      state.isNew = !(typeof oldData.id === 'string' && oldData.id.length > 0)

      Object.keys(formData).forEach((key) => {
        formData[key] = oldData[key] || formData[key]
      })
      formData.riskId=oldData.id

      activeKey.value = '1'
      if (newData == 'details') {
        state.zhuangTai2 = true
        const isOptional =  state.columns.find(item => item.key=== 'operation')
        if (isOptional){
          state.columns.pop()
        }
      } else {
        state.zhuangTai2 = false
       const isOptional =  state.columns.find(item => item.key=== 'operation')
        if(!isOptional) {
          state.columns.push(
            {
              title: '操作',
              key: 'operation',
              ellipsis: true,
              align: 'center',
              fixed: 'right',
              width: 100,
            }
          )
        }
      }
    }




        const onClose = () => {
          state.dataSourc=[]
          pagination.value.total = 0
          // state.selectedRowKeys=[]
          state.checkids=[]
          state.time = new Date().getTime()
            state.look=false
            state.visible = false
            formData.dutyCode='',//责任编码
            formData.comDutyCode='',//供应商责任编码
            formData.dutyName='',//责任名称
            formData.dutyType=undefined,//责任类型
            formData.isShowStatus='N',//页面是否显示
            formData.coverageWay='1',//保额选择方式
            formData.sort='',//显示顺序
            formData.isRisk='N',//是否可选责任
            formData.mainFlag=undefined,//主附险标志
            formData.deductibleExcess='',//免赔额
            formData.dutyIntroduce='',//责任描述
            formData.id='0',
            formData.riskId='',//险种id
              formData.riskDutyPremiumList=[
                {
                  premium:'0',//	保额/起止保额
                  premiumValue:'',//保额/起止保额显示值
                  id:'',
                  riskDutyId:''//险种责任id
                }
              ],
              formData.riskDutyPremiumScopeList=[
                {
                  endPremium:'0',//终止保额
                  endPremiumValue:'',//终止保额显示值
                  id:'',
                  premium:'0',//保额/起止保额
                  premiumValue:'',//保额/起止保额显示值
                  riskDutyId:'',//险种责任id
                }
              ]
          state.dataSource=[]
          state.selectedRowKeys=[]
          state.selectedRowKeysOne=[]
          state.data=[]

    }
    //关闭按钮
    const onFormClean = () => {
      state.visible = false
      state.dataSource = []
      onClose()

    }



        const fileList = ref([])

        expose({
          showDrawer
        })
        return {
          formRef,
          formData,
          ...toRefs(state),
          onClose,
          onFormClean,
          fileList,
          headers: {
            authorization: 'authorization-text'
          },
          delMultiple,
          formDataOne,
          panDuan,
          handleSearch,
          setShow,
          onSelectChange,
          onSelectChanges,
          onSelectChangess,
          pagination,
          handleTableChange,

          modifyUser,
          resetValue,
          search,
          activeKey,
          userDrawerRefOne,
          userDrawerRefTwo,
          run,
          loading,
          current,
          pageSize,
          total,
          dataSource,
          // shuJuType,
          addUsers,
        }

      }
})
</script>
