<template>
  <div>
    <div class="switch_class">
      <a-input  @click="handelInputClick" readonly/>
      <span @click="handelInputClick">
        <right-outlined/>
      </span>
    </div>
    <a-drawer
    :visible="typeShow"
    placement="bottom"
    height="100%"
    @close="onClose"
    destroyOnClose
    :closable="false"
    >
    <div style="margin-top: -24px;margin-bottom: 30px;">
      <ImageDetailsAttachments ref="imageDetailsRef" :confirmation-obj="confirmationObj" :isConfirmation="isConfirmation" :insuranceReminder="insuranceReminder" :relationship="relationship"  v-model:relationshipList="relationshipList" v-model:reminderList="reminderList"/>
    </div>
    <div class="img_button_class">
        <a-button type="primary" @click="handelOffDrawer">确定</a-button>
      </div>
    </a-drawer>
    <a-modal style="width: 80%;" centered :closable="false" v-model:visible="isIdentical"
             @ok="insuranceErrorHandleOk">
      <p style="font-size: 1.01rem;color: #333333;text-align: center;margin: 0.45rem 0.25rem;">{{ detailModal }}</p>
      <template #footer>
        <a-button type="link"
                  style="width: 100%;font-size: 20px;text-align: center !important; color: #1677FF;"
                  @click="insuranceErrorHandleOk">确认
        </a-button>
      </template>
    </a-modal>
  </div>
</template>

<script>
import {onUnmounted, reactive, toRefs, ref, nextTick, onMounted} from 'vue'
import ImageDetailsAttachments from '../../../transaction/image_details'
export default {
  components:{
    ImageDetailsAttachments
  },
  props:{
    isDisabled:{
      type: Boolean,
      detault: ()=> false
    },
    insuredObj:{
      type: Object,
      default: () => {}
    },
    elementCode: {
      type: [Number,String],
      default: () => ''
    },
    isRequired:{
      type: [Number,String],
      default:()=> '0'
    },
    firstValue:{
      type: [Number,String],
      default:()=> null
    },
    moduleIndex:{
      type: [Number,String],
      default:()=> '0'
    },
    moduleId:{ // 模块ID
      type: [Number,String],
      default:()=> ''
    },
    elementId:{ // 元素ID
      type: [Number,String],
      default:()=> ''
    },
    title: {
      type: String,
      default: () => ''
    },
    insuranceReminder: {
      type: String,
      default: () => ''
    },
    relationship: {
      type: String,
      default: () => ''
    },
    imgDetailList: {
      type: Array,
      default: () => []
    },
    position: {
      type: String,
      default: () => ''
    },
    confirmationObj:{
      type: Object,
      default: () => {}
    },
    isConfirmation:{
      type: String,
      default: () => ''
    }
  },
  emits:['callback','update:firstValue', 'getImageParameters'],
setup(props, context){
  const state = reactive({
    typeShow: false,
    relationshipList: [],
    reminderList: [],
    insuranceReminder: props.insuranceReminder || '',
    relationship: props.relationship || '',
    isIdentical: false,
    detailModal: ''
  })
  const imageDetailsRef = ref()

  // 点击查看详情数据
  const handelInputClick = () =>{
    if(props.isDisabled) return
    state.typeShow = true
    nextTick(()=>{
      if(props.position === 'confirm'){
        imageDetailsRef.value.getDetailImgList(props.imgDetailList)
      }else{
        imageDetailsRef.value.getImgList(props.insuredObj)
      }
    })
  }
  const handelOffDrawer = () =>{
    let type = ''
    if(state.relationship === '1'){
      if (!state.relationshipList.length){
        state.isIdentical = true
        state.detailModal = '请上传关系证明影像文件'
        return
      }else{
        type = getCheck(state.relationshipList)
        if(!type) return
      }
    }
    if(state.insuranceReminder === '1'){
      if (!state.reminderList.length){
        state.isIdentical = true
        state.detailModal = '请上传签署投保提示书照片'
        return
      }else{
        type = getCheck(state.reminderList)
        if(!type) return
      }
    }
    let data = {
      imageList : []
    }
    state.relationshipList.forEach(item =>{
      data.imageList.push({
        imageTYpe: '1',
        imageUrl: item.label
      })
    })
    state.reminderList.forEach(item =>{
      data.imageList.push({
        imageTYpe: '2',
        imageUrl: item.label
      })
    })
    context.emit('getImageParameters', data)
    state.typeShow = false
  }
  const getCheck = (list) =>{
    for (let i = 0; i < list.length; i++) {
      if(!list[i].label && list[i].placeholder){
        state.isIdentical = true
        state.detailModal = '请上传'+ list[i].placeholder
        return false
      }else if(!list[i].label && !list[i].placeholder){
        state.isIdentical = true
        state.detailModal = '请上传关系证明影像文件'
        return false
      }
    }
    return true
  }
  const getData = () =>{
    let node = {}
    node[props.elementCode] = {
      [props.elementCode] : 'Y',
      elementId: props.elementId,
      moduleType: props.moduleId,
      index:props.moduleIndex,
      required: '0',
      elementName: props.title,
    }
    context.emit('update:firstValue', 'Y')
    context.emit('callback',node)
  }
  // 关闭
  const onClose = () =>{
    state.typeShow = false
  }
  const insuranceErrorHandleOk = () =>{
    state.isIdentical = false
  }
  // watch(()=>state.typeShow,()=>{
  //   if(state.typeShow){
  //     const url = new URL(window.location)
  //     window.history.pushState({}, '', url)
  //   }
  // })
  window.addEventListener('popstate', onClose, false)
  onMounted(()=>{
    getData()
  })
  onUnmounted (() => {
    window.removeEventListener('popstate', onClose, false)
  })
  return{
    ...toRefs(state),
    onClose,
    handelInputClick,
    imageDetailsRef,
    getData,
    handelOffDrawer,
    insuranceErrorHandleOk
  }
}
}
</script>

<style scoped>
  .switch_class{
    display: flex;
    justify-content: space-around;
    cursor: pointer;
  }
  .ant-input{
    text-align: right;
    border: 0;
    cursor: pointer;
  }
  .img_button_class{
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
  }
  .img_button_class > .ant-btn{
    width: 100%;
    height: 50px;
  }

</style>
