<template>
  <!-- 特殊上传组件 -->
  <div v-if="mode_id == 101">
    <a-form
      ref="formRef"
      :model="specialUpload"
      v-bind="formItemLayout"
    >
      <a-form-item name="uploadName">
        <template #label>
          <span>上传类型</span>
          <span>
        <a-popover placement="right">
          <template #content>
          <p>
            1. 用于身份证、银行卡的上传。
            <br>2. 身份证类型要求上传身份证正反面
            <br>3. 银行卡类型要求上传银行卡正面照片
          </p>
          </template>
          <template #title>
          <span>规则说明:</span>
          </template>
          <question-circle-outlined />
        </a-popover>
        </span>
        </template>
        <a-select v-model:value="specialUpload.uploadName" placeholder="请选择方式">
          <a-select-option :value="1">身份证</a-select-option>
          <a-select-option :value="2">银行卡</a-select-option>
        </a-select>
      </a-form-item>
<!--      <a-form-item label="数量限制" name="quantityLimit">-->
<!--        <a-input v-model:value="specialUpload.quantityLimit" disabled></a-input>-->
<!--      </a-form-item>-->
    </a-form>
  </div>

  <!-- 上传组件 -->
  <div v-if="mode_id == 100">
    <a-form
      ref="formRef"
      :model="uploadData"
      v-bind="formItemLayout"
    >
      <a-form-item label="是否限制文件格式" name="isRestrict">
          <a-radio-group v-model:value="uploadData.isRestrict" @change="handelGetRadio">
              <a-radio :value="true">限制</a-radio>
              <a-radio :value="false">不限制</a-radio>
          </a-radio-group>
      </a-form-item>
      <a-form-item label="选择限制类型" name="selectType" v-if="uploadData.isRestrict">
          <a-radio-group v-model:value="uploadData.selectData.selectType" @change="handelReadioGroup">
              <a-radio :value="1">图片类型</a-radio>
              <a-radio :value="2">视频</a-radio>
              <a-radio :value="3">办公软件</a-radio>
              <a-radio :value="4">压缩包</a-radio>
          </a-radio-group>
      </a-form-item>
      <a-form-item label="类型选择" name="selectList" v-if="uploadData.selectData.selectType">
        <a-checkbox-group @change="handlePayTypeChange" v-model:value="uploadData.selectData.selectList" :options="typeList" />
      </a-form-item>
      <a-form-item name="isLargeFilesTwo">
          <template #label>
            <span>文件大小上限</span>
            <span>
                  <a-popover placement="right">
                    <template #content>
                    <p>
                      单位M,默认2M，最大不可超过20M，超过设定的阈值不可上传
                    </p>
                    </template>
                    <template #title>
                    <span>规则说明:</span>
                    </template>
                    <question-circle-outlined />
                  </a-popover>
                  </span>
          </template>
        <a-input-number v-model:value="uploadData.isLargeFilesTwo" :min="1" :max='20' style="width:80%"/>
<!--          <a-radio-group v-model:value="uploadData.isLargeFiles">-->
<!--              <a-radio :value="true">是</a-radio>-->
<!--              <a-radio :value="false">否</a-radio>-->
<!--          </a-radio-group>-->
      </a-form-item>
      <a-form-item name="MaximumRestrict"
          :rules="[{ pattern:/^\+?[1-9]\d*$/, message: '只能输入正整数' ,trigger: 'change'}]"
      >
          <template #label>
            <span>限制最大数量</span>
            <span>
            <a-popover placement="right">
              <template #content>
              <p>
                最大不可超过10个
              </p>
              </template>
              <template #title>
              <span>规则说明:</span>
              </template>
              <question-circle-outlined />
            </a-popover>
            </span>
          </template>
          <a-input-number v-model:value="uploadData.MaximumRestrict" :min="1" :max='10' style="width:80%"/>
      </a-form-item>
    </a-form>
  </div>

</template>

<script>
import { defineComponent, reactive, toRefs, computed, watch } from 'vue'


export default defineComponent({
  props: {
    mode_id:{
      type:String,
      default: () => ''
    }
  },
    setup(props) {
    const formItemLayout = {
      labelCol: {
        span: 6,
      },
      wrapperCol: {
        span: 16,
      },
    }
    const state = reactive({
        mode_id: props.mode_id,
    })
    const uploadState = reactive({
      specialUpload: {
        uploadName: '',
      },
      uploadData:{
        isRestrict: false,
        MaximumRestrict: undefined,
        isLargeFiles: false,
        isLargeFilesTwo:2,
        selectData:{
          selectType: undefined,
          selectName: undefined,
          selectList:[]
        },
      },
      typeList: computed(()=> {
        if(uploadState.uploadData.selectData.selectType === 1){
            return checkBoxs.picType
        }else if(uploadState.uploadData.selectData.selectType === 2){
            return checkBoxs.videoType
        }else if(uploadState.uploadData.selectData.selectType === 3){
            return checkBoxs.fileType
        }else if(uploadState.uploadData.selectData.selectType === 4){
            return checkBoxs.tarType
        }else {
            return []
        }
      })

    })

    const checkBoxs = reactive({
      picType:[ // 图片类型
        {value: '.jpg', label: '.jpg'},
        {value: '.jpeg', label: '.jpeg'},
        {value: '.png', label: '.png'},
        {value: '.svg', label: '.svg'},
        {value: '.gif', label: '.gif'},
      ],
      videoType:[ // 视频类型
        {value: '.mp3', label: '.mp3'},
        {value: '.mp4', label: '.mp4'},
        {value: '.wav', label: '.wav'},
        {value: '.avi', label: '.avi'},
        {value: '.rm', label: '.rm'},
        {value: '.ram', label: '.ram'},
      ],
      fileType:[    // 文件类型
        {value: '.doc', label: '.doc'},
        {value: '.docx', label: '.docx'},
        {value: '.ppt', label: '.ppt'},
        {value: '.wps', label: '.wps'},
        {value: '.pdf', label: '.pdf'},
        {value: '.pptx', label: '.pptx'},
        {value: '.xls', label: '.xls'},
        {value: '.xlsx', label: '.xlsx'},
        {value: '.txt', label: '.txt'},
        {value: '.rtf', label: '.rtf'},
      ],
      tarType:[     // 压缩包类型
        {value: '.zip', label: '.zip'},
        {value: '.rar', label: '.rar'},
        {value: '.7z', label: '.7z'},
      ]
    })
    // 是否选择限制类型
    const handelGetRadio = (e) =>{
      if(!e.target.value){
        uploadState.uploadData.selectData.selectType = undefined
      }
    }
    const handelReadioGroup = (e) =>{
      console.log('进来了', e)
      watch(
        ()=>uploadState.uploadData.selectData.selectType,
        ()=>{
          if(uploadState.uploadData.selectData.selectType === 1){
              uploadState.uploadData.selectData.selectName = '图片类型'
              uploadState.uploadData.selectData.selectList = ['.jpg','.jpeg','.png']
            }else if(uploadState.uploadData.selectData.selectType === 2){
              uploadState.uploadData.selectData.selectName = '视频'
              uploadState.uploadData.selectData.selectList = ['.mp4']
            }else if(uploadState.uploadData.selectData.selectType === 3){
              uploadState.uploadData.selectData.selectName = '办公软件'
              uploadState.uploadData.selectData.selectList = ['.doc','.docx','.ppt','.xls','.xlsx']
            }else if(uploadState.uploadData.selectData.selectType === 4){
              uploadState.uploadData.selectData.selectName = '压缩包'
              uploadState.uploadData.selectData.selectList = ['.zip','.rar']
            }else {
              uploadState.uploadData.selectData.selectName = ''
              uploadState.uploadData.selectData.selectList =  []
            }
        },{immediate:true,deep:true})
    }

    // 类型选择
    const handlePayTypeChange = (value,b,c) =>{
      console.log(value,b,c)
      uploadState.uploadData.selectData.selectList = [...value]
    }

    return {
  ...toRefs(state),
        ...toRefs(uploadState),
        handelReadioGroup,
        // stopWatch,
        handlePayTypeChange,
        checkBoxs,
        handelGetRadio,
        formItemLayout,
    }
    }




})

</script>

<style  scoped>

</style>
