<style scoped></style>

<template>
  <search-form
    :Condition="condition"
    :ColSpan="12"
    @callback="handleSearch"
  ></search-form>
  <div class="btn-bar">
    <a-button type="primary" @click="addElement({}, 'add')">
      <template #icon><FileAddOutlined /></template>新增
    </a-button>
    <a-button danger @click="delSomeElement">
      <template #icon><DeleteOutlined /></template>批量删除
    </a-button>
  </div>
  <a-table
    :columns="columns"
    :data-source="dataSource ? dataSource['result'] : []"
    :row-selection="{
      selectedRowKeys: selectedRowKeys,
      onChange: onSelectChange,
    }"
    :row-key="(record) => record.id"
    :pagination="pagination"
    @change="handleTableChange"
    :loading="loading"
  >
    <template #bodyCell="{ record, column, index }">
      <template v-if="column.key === 'index'">
        {{ (pagination.current - 1) * pagination.pageSize + index + 1 }}
      </template>
      <template v-if="column.key === 'action'">
        <a-space>
          <a-button type="link" @click="addElement(record,'update')">
            <template #icon><FormOutlined /></template>修改
          </a-button>
          <a-button
            type="link"
            @click="delElement(record)"
            danger>
            <template #icon><LinkOutlined /></template>删除
          </a-button>
        </a-space>
      </template>
    </template>
  </a-table>
  <save-product-tempLate ref="proTemplate" @select="handleSearch(lastFilter, pagination.current)"></save-product-tempLate>
</template>

<script>
import {computed, defineComponent, onMounted, reactive, ref, toRefs} from 'vue'
import SearchForm from '../../../components/extended/search_form'
import {getRequestURI, request, requestUsePagination} from '../../../utils/request.js'
import {message, Modal} from 'ant-design-vue'
import {FileAddOutlined,DeleteOutlined, FormOutlined, LinkOutlined} from '@ant-design/icons-vue'
import SaveProductTempLate from './saveProductTempLate'

export default defineComponent({
  components: {
    SaveProductTempLate,
    FileAddOutlined,
    SearchForm,
    DeleteOutlined,
    FormOutlined,
    LinkOutlined
  },
  setup() {
    const userDrawerRef = ref()
    const state = reactive({
      condition: {
        templateName: '模板名称',
        templateCode: '模板编码',
      },
      columns: [
        {
          title: '序号',
          key: 'index',
          width: 30,
          align: 'center',
        },
        {
          title: '模板名称',
          width: 100,
          align: 'center',
          key: 'templateName',
          dataIndex: 'templateName',
        },
        {
          title: '模板编码',
          width: 100,
          key: 'templateCode',
          align: 'center',
          dataIndex: 'templateCode',
        },
        {
          title: '模板描述',
          width: 150,
          align: 'center',
          key: 'templateDescribe',
          dataIndex: 'templateDescribe',
        },
        {
          title: '模板路径',
          width: 100,
          align: 'center',
          key: 'route',
          dataIndex: 'route'
        },
        {
          title: '操作',
          width: 80,
          align: 'center',
          key: 'action',
        },
      ],
      selectedRowKeys: [],
      lastFilter: {},
    })
    //预约上架
    const visible = ref(false)
    const proTemplate = ref()

    const addElement = (data, type) => {
      proTemplate.value.showDrawer(data, type)
    }
    const delSomeElement = function () {
      if (state.selectedRowKeys.length === 0) {
        return message.warning('请选择数据')
      }
      Modal.confirm({
        title: '删除提示',
        content: '是否删除已选择信息？',
        onOk: () => {
          request(getRequestURI('productFlowTemplate_del'), { data: {ids: [...state.selectedRowKeys]} }).then(res => {
            if (res.code === 1) {
              handleSearch({})
              message.success('删除成功')
              state.selectedRowKeys = []
            } else {
              message.warning(res.message)
            }
          })
        },
        onCancel: () => {
          Modal.destroyAll()
        }
      })

    }

    const delElement = function (ele) {
      if (ele.id === undefined) {
        return message.warning('请选择数据')
      }
      Modal.confirm({
        title: '删除提示',
        content: '是否删除已选择信息？',
        onOk: () => {
          request(getRequestURI('productFlowTemplate_del'), { data: {ids: [ele.id]} }).then(res => {
            if (res.code === 1) {
              handleSearch({})
              message.success('删除成功')
              state.selectEId = ''
            } else {
              message.warning(res.message)
            }
          })
        },
        onCancel: () => {
          Modal.destroyAll()
        }
      })

    }
    const formRef = ref()
    const {
      data: dataSource,
      run,
      loading,
      current,
      pageSize,
      total,
    } = requestUsePagination(getRequestURI('productFlowTemplate_selectList'))
    const onSelectChange = (selectedRowKeys) => {
      state.selectedRowKeys = selectedRowKeys
    }
    const pagination = computed(() => ({
      current: current.value,
      pageSize: pageSize.value,
      total: total.value,
    }))
    const handleTableChange = (pag, filters, sorter) => {
      run({
        pagination: {
          current: pag?.current,
          pageSize: pag?.pageSize
        },
        sorter,
        data: state.lastFilter,
      })
    }
    //页面挂载调用数据
    onMounted(() => {
      handleSearch({})
    })
    const handleSearch = function (filters, num) {
      state.lastFilter = filters
      run({
        pagination: {
          current: num ? num : current.value,
          pageSize: pageSize.value
        },
        data: filters,
      })
    }
    return {
      ...toRefs(state),
      handleSearch,
      onSelectChange,
      handleTableChange,
      userDrawerRef,
      pagination,
      loading,
      dataSource,
      visible,
      addElement,
      delSomeElement,
      delElement,
      formRef,
      proTemplate
    }
  },
})
</script>
