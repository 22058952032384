<template>
  <div><strong>当前题目:</strong>{{ titleF }}</div>
  <a-table
    :columns="tabList_title"
    :data-source="tabList"
    :row-key="(record) => record.id"
    :pagination="false"
  >
    <template #bodyCell="{ column, record }">
      <template v-if="column.key === 'configType'">
        <a-space>
          <a-select
            v-model:value="record.configType"
            style="width: 120px"
            :options="options"
            @change="handleChange"
          ></a-select>
        </a-space>
      </template>
      <template v-if="column.key === 'propRange'">
        <a-space>
          <div v-if="record.configType === 'text'||record.configType === 'number'">
            <nobr>
              {{ record.configType === 'text' ? '最小字数' : '最小值' }}
              <a-input-number v-model:value="record.min" style="width: 60px"/>
              {{ record.configType === 'text' ? '最大字数' : '最大值' }}
              <a-input-number v-model:value="record.max" style="width: 60px"/>
            </nobr>
          </div>
        </a-space>
      </template>
      <template v-if="column.key === 'mPropKey'">
        <a-space>
              <a-input v-model:value="record.mPropKey" style="width: 60px"/>
        </a-space>
      </template>
      <template v-if="column.key === 'isMustInput'">
        <a-space>
          <a-checkbox v-model:checked="record.isMustInput"></a-checkbox>
        </a-space>
      </template>
      <template v-if="column.key === 'isOnly'">
        <a-space>
          <a-checkbox v-model:checked="record.isOnly"></a-checkbox>
        </a-space>
      </template>
      <template v-if="column.key === 'keyOnly'">
        <a-space>
          <a-input v-model:value="record.keyOnly"></a-input>
        </a-space>
      </template>
    </template>
  </a-table>
  <div style="text-align: center; margin-top: 20px">
    <a-button type="primary" @click="handleEditOk">保存</a-button>
  </div>
</template>

<script>
import {defineComponent, reactive, toRefs} from 'vue'
import { message } from 'ant-design-vue'

export default defineComponent({
  name: 'InputPropsConfig',
  props: {
    // title: {
    //   type: String,
    //   default: () => ''
    // },
    // strArray: {
    //   type: Array,
    //   default: () => []
    // },
    // inputStrArray: {
    //   type: Array,
    //   default: () => []
    // }
  },
  emits:['setProps'],
  setup(props,context) {
    const state = reactive({
      selectValue: 'text',
      strArray: [],
      inputStrArray: [],
      tabList: [],
      options: [
        {
          value: 'text',
          label: '单行文本'
        },
        {
          value: 'number',
          label: '数字类型'
        },
        {
          value: 'date',
          label: '日期'
        },
        {
          value: 'time',
          label: '时间'
        },
        {
          value: 'dateTime',
          label: '日期 时间'
        },
        {
          value: 'phone',
          label: '手机'
        },
        {
          value: 'tel',
          label: '座机'
        },
        {
          value: 'email',
          label: '邮箱'
        },
        {
          value: 'pId',
          label: '身份证号'
        }
      ],
      titleF: '',
      tabList_title: [
        {
          key: 'indexName',
          dataIndex: 'indexName',
          title: '填空',
          fixed: 'left',
          width: 120,
          align: 'left',
        },
        {
          title: '验证属性',
          width: 180,
          fixed: 'left',
          key: 'configType',
          dataIndex: 'configType',
        },
        {
          title: '范围',
          width: 220,
          fixed: 'left',
          key: 'propRange',
          dataIndex: 'propRange'
        },
        {
          title: '必填',
          width: 100,
          fixed: 'left',
          key: 'isMustInput',
          dataIndex: 'isMustInput',
        },
        {
          title: '设置key',
          width: 100,
          fixed: 'left',
          key: 'mPropKey',
          dataIndex: 'mPropKey',
        }
      ]
    })
    const initPropsData = (t, a, b, updateTabList) => {
      // 当修改是 就不需要初始化了
      if(updateTabList && updateTabList.length > 0) {
        state.tabList = updateTabList
        return
      }
      console.log('执行数据初始化',t, a, b)
      state.titleF = t
      state.strArray = a
      state.inputStrArray = b
      state.tabList = []
      for (let i = 0; i < b.length; i++) {
        state.tabList.push({
          id: i,
          indexName: `填空${i + 1}`,
          configType: 'text',
          propRange: [0, 255],
          isMustInput: true,
          isOnly: false,
          keyOnly: '',
          min: 0,
          max: 255
        })
      }
    }
    const handleChange = (item) => {
      console.log(`item:${JSON.stringify(item)}`)
      console.log(`list:${JSON.stringify(state.tabList)}`)
    }
    const handleEditOk = () => {
      // eslint-disable-next-line no-debugger
      for (let i = 0; i < state.tabList.length; i++) {
        let item = state.tabList[i]
        if (item.mPropKey === '' || item.mPropKey === null || item.mPropKey === undefined){
          message.error(`请检查${item.indexName}的propkey是否设置`)
          return
        }

        if (item.configType === 'text' || item.configType === 'number') {
          if (item.min <= item.max) {
            item.propRange = [item.min,item.max]
          } else {
            message.error(`请检查${item.indexName}的范围值是否输入正确`)
            return
          }
        }
      }
      let obj = {
        title: state.titleF,
        tabList: state.tabList,
        inputStrArray: state.inputStrArray,
        strArray: state.strArray
      }
        context.emit('setProps',obj)
    }
    return {
      // ref
      ...toRefs(state),
      // setState,
      // func
      handleChange,
      initPropsData,
      handleEditOk
    }
  }
})
</script>

<style scoped>

</style>
