<template>
  <a-drawer
    title="告知关联"
    :width="'70%'"
    :visible="visible"
    :body-style="{ paddingBottom: '80px' }"
    :footer-style="{ textAlign: 'right' }"
    @close="onClose"
  >
    <search-form
      :Condition="condition"
      :ColSpan="12"
      @callback="handleSearch"
    ></search-form>
    <div>
      <h3>已经选中的值: </h3>
      <div class="sel_class">
        <a-tag style="margin-bottom: 5px;" v-for="item in selectedRowList" :key="item.id">{{item.moduleName}} <span class="label_class" v-if="item.labelDescription">({{ item.labelDescription }})</span> </a-tag>
      </div>
    </div>
    <a-table
      :columns="tabList_title"
      :data-source="dataSource ? dataSource['result'] : []"
      :pagination="pagination"
      @change="tablePaginationChange"
      :loading="loading"
      :row-key="(record) => record.id"
      :row-selection="{
        selectedRowKeys: selectedRowKeys,
        onChange: onSelectChange,
        onSelect: handelOnSelect,
        onSelectAll: handelOnSelectAll
      }"
    >
      <template #bodyCell="{ column, index }">
        <template v-if="column.key === 'index'">
          {{ (pagination.current - 1) * pagination.pageSize + index + 1 }}
        </template>
      </template>
    </a-table>

    <template #extra>
      <a-space>
        <a-button @click="onClose">
          <template #icon>
            <CloseOutlined/>
          </template>
          关闭
        </a-button>
        <a-button type="primary" @click="onSave">
          <template #icon>
            <SaveOutlined/>
          </template>
          提交
        </a-button>
      </a-space>
    </template>


  </a-drawer>
</template>

<script>
import {computed, defineComponent, reactive, toRefs, defineAsyncComponent, watch, onBeforeMount} from 'vue'
import { getRequestURI, requestUsePagination } from '../../../../utils/request'
export default defineComponent({
  components:{
    SearchForm: defineAsyncComponent(() => import('../../../../components/extended/search_form'))
  },
  setup(props, context){
    const state = reactive({
      list : [],
      selectedRowList: [],
      selectedRowObj: [],
      lastFilter: {},
      visible: false,
      selectedRowKeys: [],
      condition:{
        moduleName: {
          label: '模块名称',
          placeholder: '请输入模块名称关键字',
        },
      },
      tabList_title: [
        {
          key: 'index',
          title: '序号',
          align: 'center',
          width: 60,
        },
        {
          title: '模块名称',
          width: 200,
          align: 'left',
          key: 'moduleName',
          dataIndex: 'moduleName',
        },
        {
          title: '模块状态',
          width: 150,
          align: 'left',
          key: 'statusName',
          dataIndex: 'statusName',
          // customRender: (text)=> getDictionaryValue('module_status', text.value)
        },
        {
          title: '模块归属',
          width: 180,
          align: 'left',
          key: 'moduleTypeName',
          dataIndex: 'moduleTypeName',
        },
        {
          title: '标签',
          width: 140,
          align: 'left',
          key: 'labelDescription',
          dataIndex: 'labelDescription',
        },
        {
          title: '关联所属',
          width: 100,
          align: 'left',
          key: 'associationType',
          dataIndex: 'associationType',
        },
        {
          title: '创建时间',
          width: 200,
          align: 'left',
          key: 'createTime',
          dataIndex: 'createTime',
          customRender: (text) => {
            let time = new Date(+new Date((text.text)) + 8 * 3600 * 1000).toISOString().replace(/T/g, ' ').replace(/\.[\d]{3}Z/, '')
            return time
          }
        },
      ],
    })

    const showDrawer = (data = {}) =>{
      console.log('-------', data)
      state.selectedRowKeys = data.notificationList || []
      state.list = data.notificationList || []
      state.selectedRowObj = data.selectedRowList || []
      state.selectedRowList = data.selectedRowList || []
      state.visible = true
    handleSearch({})

    }

    const {
      data: dataSource,
      run,
      loading,
      current,
      pageSize,
      total,
    } = requestUsePagination(getRequestURI('module_moduleList'))
    const handleSearch = function (filters) {
      filters.moduleType = 'healthNotification'
      state.lastFilter = filters
      run({
        pagination: {
          current: 1,
          pageSize: 10
        },
        data: filters,
      })
    }
    const pagination = computed (() => ({
      current: current.value,
      pageSize: pageSize.value,
      total: total.value,
      pageSizeOptions: [10, 20, 50],
    }))
    const onSelectChange = (selectedRowKeys, obj) => {
      state.selectedRowKeys = selectedRowKeys
      state.selectedRowObj = obj
    }
    // 取消或选中的方法
    const handelOnSelect = (data, type) =>{
      if (!type) {
        handelRowKeys(data.id, data)
      }
    }
    // 全选 / 全不选
    const handelOnSelectAll = (type) => {
      if(!type){
        state.selectedRowKeys = []
        state.selectedRowList = []
        state.list = []
        state.selectedRowObj = []
      }
    }
    const  handelRowKeys = (id) =>{
      state.list.forEach((item,index) =>{
        if(item === id){
          state.list.splice(index, 1)
        }
      })
      state.selectedRowKeys = state.list
      state.selectedRowList.forEach((item, index)=>{
        if(item.id === id){
          state.selectedRowList.splice(index, 1)
        }
      })
      console.log(state.selectedRowList)
    }
    const tablePaginationChange = function (pag, filters, sorter) {
      state.selectedRowKeys = state.list
      run({
        pagination: {
          current: pag?.current,
          pageSize: pag?.pageSize
        },
        sorter,
        data: state.lastFilter,
      })

    }
    watch(()=> [state.selectedRowKeys,state.selectedRowObj], ()=>{
      state.list = state.list.concat(state.selectedRowKeys)
      state.selectedRowList = state.selectedRowList.concat(state.selectedRowObj)
      if(state.selectedRowList && state.selectedRowList.length > 0){
        const map = new Map()
        state.selectedRowList = state.selectedRowList.filter(v => !map.has(v.id) && map.set(v.id, v))
      }
      state.list = [...new Set(state.list)]
    })
  // 提交
  const onSave = () =>{
    let data = {
      selectedRowList: state.selectedRowList,
      notificationList:state.list
    }
    context.emit('handelSaveSelectList',data)
    onClose()
  }

  const onClose = () =>{
    dataSource.value = []
    state.selectedRowList = []
    state.selectedRowObj = []
    state.selectedRowKeys = []
    state.list = []
    state.visible = false
  }
  onBeforeMount(()=>{
  })



    return {
      ...toRefs(state),
      showDrawer,
      handelOnSelect,
      onClose,
      onSave,
      pagination,
      onSelectChange,
      dataSource,
      loading,
      handleSearch,
      tablePaginationChange,
      handelRowKeys,
      handelOnSelectAll
    }
  }


})
  </script>

<style scoped>
    .m_header{
      margin-top: 20px;
      display: flex;
      justify-content: space-between;
      margin: 10px 50px;
    }
    .m_previewPage{
      width: 45%;
    }
    .m_moduleList{
      width: 45%;
    }
    .center_box{
      text-align: center;
    }
    .continer{
     /* min-width: 375px; */
     position: relative;
     height: 517px;
     padding: 17px;
     background: #f2f2f2;
     overflow:hidden;
     overflow-y: auto;
   }
    .del-class{
      float: left;
      width: 35px;
      cursor: pointer;
    }
    .module_detial{
     border: 1px solid #999;
     padding: 10px;
     margin-bottom: 10px;
     white-space: nowrap;
     overflow: hidden;
     text-overflow: ellipsis;
    }
    .module_span{
      color: rgb(189, 188, 189);
      font-size: 13px;
      margin-left: 8px;
    }
    .tree_drag{
      position: fixed;
      border: 1px solid #999;
      min-width: 300px;
      height: 30px;
      text-align: center;
      line-height: 30px;
      z-index: 99999;
    }
    .sel_class{
      min-height: 100px;
      width: 100%;
      padding: 15px;
      border: 1px solid #f2f2f2;
      margin-bottom: 15px;
    }
    .label_class{
      color: #bdbcbd;
    }
  </style>
