<style scoped>
.bg {
  margin-bottom: 20px
}
</style>

<template>
  <!-- :title=panDuan -->
  <a-drawer
    :width="'70%'"
    :visible="visible"
    :body-style="{ paddingBottom: '80px' }"
    :footer-style="{ textAlign: 'right' }"
    @close="onClose"
  >
    <search-form
      ref="searchFormRef"
      :Condition="condition"
      :ColSpan="12"
      @callback="handleSearch"
    ></search-form>

    <div>
      <a-table :columns="columns" :data-source="dataSource ? dataSource['result'] : []"
               :row-selection="{ selectedRowKeys: checkids,
            onChange: onSelectChanges,  type:'radio'
            }"
               :row-key="record => record.id"
               :pagination="pagination" @change="handleTableChange"
               :loading="loading"
               :scroll="{ x: 1700 }"
      >
        <template #bodyCell="{ column, index}">
          <template v-if="column.key === 'index'">
            {{ (pagination.current - 1) * pagination.pageSize + index + 1 }}
          </template>
        </template>
      </a-table>
    </div>
    <template #extra>
      <a-space>
        <a-button @click="onFormClean">
          <template #icon>
            <CloseOutlined/>
          </template>
          关闭
        </a-button>
      </a-space>
    </template>
  </a-drawer>
</template>
<script>
// import {SaveOutlined} from '@ant-design/icons-vue'
import {defineComponent, reactive, toRefs, ref, computed} from 'vue'
// import {message} from 'ant-design-vue'
import {getRequestURI, requestUsePagination} from '../../../utils/request'
import SearchForm from '../../../components/extended/search_form'
import {getDictionaryValue} from '../../../utils/request_extend'

export default defineComponent({
  components: {
    SearchForm,
  },
  props: {
    is_insurance: {
      type: Number,
      default() {
        return 0
      }
    }
  },
  setup(props, {expose, emit}) {
    const userDrawerRef = ref()
    const searchFormRef = ref()
    const formRef = ref()
    const formData = reactive({
      dutyCode: '',//责任编码
      dutyName: '',//责任名称
      coverageWay: '',//保额方式
      dutyType: '',//责任类型
//      isInsurance: props.is_insurance,
      insurePremiumList: [{
        isInsurance: null //财寿险标识
      }]
    })
    const state = reactive({
      status: '',
      data: [],
      checkids: [],
      condition: {
        dutyCode: '责任编码',
        dutyName: '责任名称'
      },
      //表格表头
      columns: [
        {
          title: '序号',
          key: 'index',
          fixed: 'left',
          width: 60,
          align: 'left',
        },
        {
          title: '责任编码',
          width: 100,
          align: 'center',
          key: 'dutyCode',
          dataIndex: 'dutyCode',
        },
        {
          title: '责任名称',
          width: 100,
          align: 'center',
          key: 'dutyName',
          dataIndex: 'dutyName',
        },
        {
          title: '财寿险标识',
          width: 100,
          align: 'center',
          key: 'isInsurance',
          dataIndex: 'isInsurance',
          customRender: (text) => getDictionaryValue('riskType', text.value)
        },
        // {
        //   title: '保额选择方式',
        //   width: 100,
        //   align: 'center',
        //   key: 'coverageWay',
        //   dataIndex: 'coverageWay',
        //   customRender: (text) => getDictionaryValue('amntChoiceWay', text.value)
        // }
      ],

      visible: false,
      isNew: true,
    })
    //搜索
    const {
      data: dataSource,
      run,
      loading,
      current,
      pageSize,
      total,
    } = requestUsePagination(getRequestURI('duty_queryDuty'), {
      // TODO 责任类型 可选全部
      // is_insurance: props.is_insurance
    })
    const pagination = computed(() => ({
      current: current.value,
      pageSize: pageSize.value,
      total: total.value,
    }))
    const handleTableChange = (pag, filters, sorter) => {
      console.log('handleTableChange', pag, filters, sorter)
      run({
        pagination: {
          current: pag?.current,
          pageSize: pag?.pageSize
        },
        sorter,
        data: formData,
      })
    }

    const onSelectChanges = (selectedRowKeys, selectedRows) => {
      console.log('selectedRowKeys changed: ', selectedRowKeys)
      state.checkids = selectedRowKeys
      let obj
      selectedRows.forEach(item => {
        obj = item
      })
      Object.keys(obj).forEach(key => {
        if (obj[key] === undefined || obj[key] === null) {
          obj[key] = ''
        }
      })
      emit('setShow', getNewObj(obj))
      onClose()
      state.checkids = []

      console.log('asd', state.checkids)
    }

    const handleSearch = function (filters) {
      formData.dutyCode = filters.dutyCode
      formData.dutyName = filters.dutyName
      run({
        pagination: {
          current: 1,
          pageSize: 10
        },
        data: formData,
      })
    }
    const activeKey = ref('1')
    // 组件传值
    const showDrawer = (oldData = {}) => {
      console.log('oldData-one', oldData)
//      formData.isInsurance = oldData.isInsurance || 0
      handleSearch({})
      state.visible = true
      state.isNew = !(typeof oldData.id === 'string' && oldData.id.length > 0)
      console.log('editNewUser', state.isNew, oldData)
      Object.keys(formData).forEach((key) => {
        formData[key] = oldData[key] || formData[key]
      })
    }

    const onClose = () => {
      clearSearchDate()
      state.visible = false
      state.dataSource = []
      console.log(state.dataSource, '123')
    }
    //关闭按钮
    const onFormClean = () => {
      state.visible = false
      onClose()
      state.dataSource = []
    }

    const clearSearchDate = () => {
      // sformShow.value = true
      // nextTick(() => {
      //   sformShow.value = false
      // })
      searchFormRef.value.clearSearchInput(['dutyCode','dutyName'])
    }
    const fileList = ref([])
    const getNewObj = (obj) => {
      console.log('-----======-----',JSON.stringify(obj))
      obj.comDutyCode = obj.dutyCode
      obj.dutyCode = ''
      return obj
    }
    expose({
      showDrawer
    })
    return {
      formRef,
      formData,
      loading,
      dataSource,
      ...toRefs(state),
      // activeKey: ref('1'),
      onClose,
      onFormClean,
      fileList,
      headers: {
        authorization: 'authorization-text'
      },
      handleSearch,
      run,
      onSelectChanges,
      pagination,
      handleTableChange,
      activeKey,
      userDrawerRef,
      clearSearchDate,
      searchFormRef
    }
  }
})
</script>
