<template>
  <a-drawer
    title="JSON演示"
    :width="'40%'"
    :visible="visible"
    :body-style="{ paddingBottom: '30px' }"
    :footer-style="{ textAlign: 'right' }"
    @close="onClose"
  >
    <div>
        <JsonEditorVue class="editor" v-model="dropDownConfiguration" />
    </div>

  </a-drawer>
</template>

<script>
import JsonEditorVue from 'json-editor-vue3'
import {defineComponent, reactive, toRefs} from 'vue'
export default defineComponent({
  components:{
    JsonEditorVue
  },
  setup() {
    const state = reactive({
      visible: false,
      dropDownConfiguration: []
    })

    const showDrawer = (oldData) => {
      state.visible = true
      state.dropDownConfiguration = oldData
    }
    const onClose = () => {
      state.visible = false
    }
    return{
      showDrawer,
      onClose,
      ...toRefs(state)
    }
  }
})
  
</script>

<style scoped>
  .editor{
    width: 460px;
    height: 600px;
    margin-left: 30px;
  }
</style>