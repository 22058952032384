<template>
  <h3>前端展示</h3>
  <div style="display: inline-block" v-for="(item , index) in allData" :key="index">
      <!-- <nobr> -->
        <span  v-if="item.type === 'string'">{{item.str}}</span>
        <div v-else >
          <a-input  v-if="item.configType === 'text'" style="width: 100px" v-model:value="item.value" :maxlength="item.max" @change="textChangeListener(item.value,item)"></a-input>
          <a-input-number v-else-if="item.configType === 'number'"  v-model:value="item.value" :min="item.min" :max="item.max"/>
          <a-date-picker v-else-if="item.configType === 'date'"  v-model:value="item.value" />
          <a-time-picker v-else-if="item.configType === 'time'"  v-model:value="item.value" value-format="HH:mm:ss" allowClear/>
          <a-date-picker v-else-if="item.configType === 'dateTime'" show-time placeholder="选择日期时间" v-model:value="item.value" />
          <a-input v-else-if="item.configType === 'pId'" style="width: 100px" v-model:value="item.value" :maxlength="18" @change="textChangeListener(item.value,item)"></a-input>
          <a-input v-else-if="item.configType === 'phone'" style="width: 100px" v-model:value="item.value" :maxlength="11" @change="textChangeListener(item.value,item)"></a-input>
          <a-input v-else-if="item.configType === 'email'" style="width: 100px" v-model:value="item.value"  @change="textChangeListener(item.value,item)"></a-input>
          <a-input v-else style="width: 200px" v-model:value="item.value" ></a-input>
        </div>
      <!-- </nobr> -->
  </div>
  <p ref="tip" style="color: red;font-size: smaller">{{ tips }}</p>
</template>

<script>
import {reactive, toRefs} from 'vue'
import {vTools} from '../../../js/verificationTool'

export default {
  name: 'ShowInFront',
  setup() {
    const state = reactive({
      allData:[],
      tips:''
    })
    const initShowFData = (a, b, c) => {
      console.log('a:',JSON.stringify(a))
      console.log('b',JSON.stringify(b))
      console.log('c',JSON.stringify(c))
      let newArray = []
      if (c) {
        newArray = b.map((v, i) => ({ ...v, ...c[i] }))
      } else {
        let c1 = getDemoData(b)
        newArray = b.map((v, i) => ({ ...v, ...c1[i] }))
      }
      console.log('newArray',JSON.stringify(newArray))
     let tempArray = a.concat(newArray)
      state.allData = tempArray.sort(compare('indexStart'))
      console.log('allData',state.allData)
    }
    //property:根据什么属性排序
    const compare = (property) => {
      return function (a, b) {
        let value1 = a[property]
        let value2 = b[property]
        return value1 - value2//升序排序
      }
    }
    /**
     * 没有设置属性时，默认每一项都为text属性，且赋予其他固定参数
     * @param b
     * @returns {*[]}
     */
    const getDemoData = (b) => {
     let c = []
      for (let i = 0; i < b.length; i++) {
        c.push({
          id: i,
          indexName: `填空${i + 1}`,
          configType: 'text',
          propRange: [0, 255],
          isMustInput: true,
          isOnly: false,
          min: 0,
          max: 255
        })
      }
      return c
    }
    const textChangeListener = (value, item) => {
      console.log('value:', value)
      console.log('item:', JSON.stringify(item))
      let vToolResult
      switch (item.configType) {
        case 'text':
          if (value< item.min) {
            state.tips = '输入字符长度不足'
          } else {
            state.tips = ''
          }
          break
        case 'phone':
          vToolResult = vTools('phone2',value)
          if (vToolResult !== true ) {
            state.tips = vToolResult
          } else {
            state.tips = ''
          }
          break
        case 'email':
          vToolResult = vTools('email',value)
          if (vToolResult !== true ) {
            state.tips = vToolResult
          } else {
            state.tips = ''
          }
          break
        case 'pId':
          value = value.replaceAll('x','X')
          vToolResult = vTools('pId',value)
          if (vToolResult !== true ) {
            state.tips = vToolResult
          } else {
            state.tips = ''
          }
          break
        default:
          state.tips = '一蓑烟雨任平生'
          break
      }
    }
    return {
      ...toRefs(state),
      initShowFData,
      compare,
      getDemoData,
      textChangeListener
    }
  }
}
</script>

<style scoped>

</style>
