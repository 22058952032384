export const jkgz_demo = {
  'element_name': '健康告知', // 元素名称
  'element_id': '',
  'element_state': '', //元素状态：启用，暂存，禁用
  'element_operating_mode': { // 元素展示类型
    'mode_id': 99,
      'mode_config':{
        'multiple': true, // 是否允许多选 ---
        'level': 0,// 是否必须选择到子级
        'dataStyle': 0// 0 - 日期+时间 1 日期 2时间
      },
    'operating_mode_list': [ // 全部类型列表
      {
        'mode_id': 0,
        'mode_name': '输入框'
      },
      {
        'mode_id': 1,
        'mode_name': '下拉框',
      },
      {
        'mode_id': 2,
        'mode_name': '单选按钮'//单选
      },
      {
        'mode_id': 3,
        'mode_name': '多选按钮',
      },
      {
        'mode_id': 4,
        'mode_name': '城市选择器',
      },
      {
        'mode_id': 5,
        'mode_name': '职业选择器',
        'level': 0
      },
      {
        'mode_id': 6,
        'mode_name': '时间选择器',
      },
      {
        'mode_id': 7,
        'mode_name': '证件类型'
      },
      {
        'mode_id': 99,
        'mode_name': '健康告知'
      }
    ]
  },
  'elementCode': 'e01', // 元素编码 不等于 元素id
  'elementMinValuelength': 0,// 0不限制长度
  'elementMaxValuelength': 255,// 0不限制长度
  'titlePos': 0, // 上下0 左右1
  'inputConfig': { // 输入框相关配置
    'isPassword': true,
    'rows': 4, // 最大行数
    'showCount': true,
    'textarea': false, // 是否文本区域，富文本框
    'bordered': false, // 输入框是否有边框
    'disabled': true
  },
  'inputType': '', // 输入框类型：
  'elementTitle': '健康告知', // 元素标题
  'elementHint': '', // 元素提示语
  'elementTips': '', // 校验提示
  'elementValueType': { // 元素值类型列表
    'typeId': 0,
    'elementValueTypeList': [
      {
        'typeId': 0,
        'typeName': '文本'
      },
      {
        'typeId': 1,
        'typeName': '数字'
      },
      {
        'typeId': 2,
        'typeName': '数字范围'
      },
      {
        'typeId': 3,
        'typeName': '时间节点'
      },
      {
        'typeId': 4,
        'typeName': '时间范围'
      },
      {
        'typeId': 5,
        'typeName': '日期节点'
      },
      {
        'typeId': 6,
        'typeName': '日期范围'
      },
      {
        'typeId': 7,
        'typeName': '邮箱'
      },
      {
        'typeId': 8,
        'typeName': '座机'
      },
      {
        'typeId': 9,
        'typeName': '移动电话',
        'isChina': '86'
      },
      {
        'typeId': 10,
        'typeName': '身份证号'
      },
      {
        'typeId': 11,
        'typeName': '年龄'
      },
      {
        'typeId': 12,
        'typeName': '年龄范围'
      },
      {
        'typeId': 13,
        'typeName': '职业类范围'
      },
      {
        'typeId': 99,
        'typeName': '健康告知'
      }
    ]
  },
  'elementVaule': {
    'typeId': 0,
    'unit': '万元',
    'value': '', // 根据typeId输入值，如果为范围的则取rangeList
    'rangeList': [
      {
        'startValue': '',
        'endValue': '',
        'containsFlag': 0//0-(),1-(],2[),3[] 包含关系标
      },
      {
        'startValue': '',
        'endValue': '',
        'containsFlag': 0//0-(),1-(],2[),3[]
      }
    ],
  },
  'isDictionary': true, // 是否关联字典表标识,关联则取dictionary对象，不关联则直接取dictionaryValue
  'dictionary': {
    'isDictionary': true, // 是否关联字典表标识
    'dictionaryName': 'cityList', // 关联的字典表type字段名
    'dictionaryValue': [
      {
        label: '爷爷',
        value: 'yeye',
        organizationType: 1,
        children: [
          {
            label: '爸爸',
            value: 'baba',
            organizationType: 1,
            children: [
              {
                label: '儿子',
                value: 'erzi',
                organizationType: 0,
                children: [
                  {
                    label: '孙子',
                    value: 'sunzi',
                    organizationType: 0,
                  }
                ]
              },
              {
                label: '女儿',
                value: 'nver',
                organizationType: 0,
                children: [
                  {
                    label: '外孙1',
                    value: 'waisun1',
                    organizationType: 0,
                  },
                  {
                    label: '外孙2',
                    value: 'waisun2',
                    organizationType: 0,
                  }
                ]
              }
            ]
          }
        ]
      },
      {
        value: 'zhejiang',
        label: '浙江',
        children: [{
          value: 'hangzhou',
          label: '杭州',
          children: [{
            value: 'xihu',
            label: '西湖',
          }],
        }],
      },
      {
        value: 'jiangsu',
        label: '江苏',
        children: [{
          value: 'nanjing',
          label: '南京',
          children: [{
            value: 'zhonghuamen',
            label: '中华门',
          }],
        }],
      }],
    'checkIndex': 0,
  },
  'dictionaryValue': [
    {'value': '1', 'label': 'xxxx', 'checked': true, 'disabled': true},
    {'value': '2', 'label': 'xxxx', 'checked': false, 'disabled': false}
  ],
  'associated': [ // 关联元素
    {
      'element_id': '1111',
      'way': '0',// 级联的子元素怎么处理没想好 ，交集？并集？
    },
    {
      'element_id': '1111',
      'way': '1',
    }
  ],
  'HealthTold': {
    'title': '是否抽烟:____？年龄:____岁，电话：___',
    'inputConifgs': [ // 填空属性
      {
        'type': 'String',
        'Minlength': 0, // 最小长度
        'maxLength': 255, // 最大长度
      },
      {
        'type': 'number',
        'Minlength': 0, // 最小长度
        'maxLength': 255, // 最大长度
      },
      {
        'type': 'phone',
        'Minlength': 0, // 最小长度
        'maxLength': 255, // 最大长度
      }
    ],
    'isMust': true, //是否必须校验标识
    'modeType': '0',// 0 - 单选 1-多选 2-输入
    'inputConfig': { // 输入框相关配置  不用了
      'isPassword': true,
      'Minlength': 0, // 最小长度
      'maxLength': 255, // 最大长度
      'rows': 4, // 最大行数
      'showCount': true,
      'textarea': false, // 是否文本区域，富文本框
      'bordered': false, // 输入框是否有边框
      'disabled': true
    },
    'multiple_num': 3,// 多选数量限制
    'keyList': [ // 单个健康告知元素匹配词列表
      {
        'rangeFlag': false,
        'keyWord': '是',
        'keyType': 0//类似 elementValueTypeList
      },
      {
        'keyWord': '是',
        'keyType': 2,//类似 elementValueTypeList
        'rangeFlag': true,
        'rangeList': [// 范围区间集合，不排除有多个间断范围
          {
            'startValue': '',
            'endValue': '',
            'containsFlag': 0//0-(),1-(],2[),3[] 包含关系标
          },
          {
            'startValue': '',
            'endValue': '',
            'containsFlag': 0//0-(),1-(],2[),3[]
          }
        ]
      }
    ],
    'answerSortMode': '0',// 0 A-Z,1 1-99999, 2 a-z 答案排序序号方式选择
    'answerList': [
      {
        answerId: 1,
        'answerTitle': 'xxxxxxx',
        'child': [
          {'element_id': '1111'},
          {'element_id': '2222'},
        ]
      },
      {
        'answerTitle': 'xxxxxxx222',
        'child': []// 未关联子项的选项
      },
    ]

  }, // 健康告知类型复杂一点单独拿出来, 如果还有其他复杂的就拿出来一个元素类型对象,
  'other': {},
  'other1': {}
}
export const time_demo = {
  'element_name': '健康告知', // 元素名称
  'element_id': '',
  'element_state': '', //元素状态：启用，暂存，禁用
  'element_operating_mode': { // 元素展示类型
    'mode_id': 6,
    'mode_config':{
      'multiple': true, // 是否允许多选 --- checkBox 专用
      'multiple_num': 3,// 多选数量限制 --- checkBox slect 级联等等 用
      'level': 0,// 是否必须选择到子级 -- 级联
      'time_interval': 0,
      'dataStyle': 0// 0 - 日期+时间 1 日期 2时间
    },
    'operating_mode_list': [ // 全部类型列表
      {
        'mode_id': 0,
        'mode_name': '输入框'
      },
      {
        'mode_id': 1,
        'mode_name': '下拉框',
      },
      {
        'mode_id': 2,
        'mode_name': '单选按钮'//单选
      },
      {
        'mode_id': 3,
        'mode_name': '多选按钮',
      },
      {
        'mode_id': 4,
        'mode_name': '城市选择器',
      },
      {
        'mode_id': 5,
        'mode_name': '职业选择器',
        'level': 0
      },
      {
        'mode_id': 6,
        'mode_name': '时间选择器',
      },
      {
        'mode_id': 7,
        'mode_name': '证件类型'
      },
      {
        'mode_id': 99,
        'mode_name': '健康告知'
      }
    ]
  },
  'elementCode': 'e01', // 元素编码 不等于 元素id
  'elementMinValuelength': 0,// 0不限制长度
  'elementMaxValuelength': 255,// 0不限制长度
  'titlePos': 1, // 上下0 左右1
  'inputConfig': { // 输入框相关配置
    'isPassword': true,
    'rows': 4, // 最大行数
    'showCount': true,
    'textarea': false, // 是否文本区域，富文本框
    'bordered': false, // 输入框是否有边框
    'disabled': true
  },
  'inputType': '', // 输入框类型：
  'elementTitle': '时间选择', // 元素标题
  'elementHint': '', // 元素提示语
  'elementTips': '', // 校验提示
  'elementValueType': { // 元素值类型列表
    'typeId': 6,
    'elementValueTypeList': [
      {
        'typeId': 0,
        'typeName': '文本'
      },
      {
        'typeId': 1,
        'typeName': '数字'
      },
      {
        'typeId': 2,
        'typeName': '数字范围'
      },
      {
        'typeId': 3,
        'typeName': '时间节点'
      },
      {
        'typeId': 4,
        'typeName': '时间范围'
      },
      {
        'typeId': 5,
        'typeName': '日期节点'
      },
      {
        'typeId': 6,
        'typeName': '日期范围'
      },
      {
        'typeId': 7,
        'typeName': '邮箱'
      },
      {
        'typeId': 8,
        'typeName': '座机'
      },
      {
        'typeId': 9,
        'typeName': '移动电话',
        'isChina': '86'
      },
      {
        'typeId': 10,
        'typeName': '身份证号'
      },
      {
        'typeId': 11,
        'typeName': '年龄'
      },
      {
        'typeId': 12,
        'typeName': '年龄范围'
      },
      {
        'typeId': 13,
        'typeName': '职业类范围'
      },
      {
        'typeId': 99,
        'typeName': '健康告知'
      }
    ]
  },
  'elementVaule': {
    'typeId': 0,
    'unit': '万元',
    'value': '', // 根据typeId输入值，如果为范围的则取rangeList
    'rangeList': [
      {
        'startValue': '',
        'endValue': '',
        'containsFlag': 0//0-(),1-(],2[),3[] 包含关系标
      },
      {
        'startValue': '',
        'endValue': '',
        'containsFlag': 0//0-(),1-(],2[),3[]
      }
    ],
  },
  'isDictionary': true, // 是否关联字典表标识,关联则取dictionary对象，不关联则直接取dictionaryValue
  'dictionary': {
    'isDictionary': true, // 是否关联字典表标识
    'dictionaryName': 'cityList', // 关联的字典表type字段名
    'dictionaryValue': [
      {
        label: '爷爷',
        value: 'yeye',
        organizationType: 1,
        children: [
          {
            label: '爸爸',
            value: 'baba',
            organizationType: 1,
            children: [
              {
                label: '儿子',
                value: 'erzi',
                organizationType: 0,
                children: [
                  {
                    label: '孙子',
                    value: 'sunzi',
                    organizationType: 0,
                  }
                ]
              },
              {
                label: '女儿',
                value: 'nver',
                organizationType: 0,
                children: [
                  {
                    label: '外孙1',
                    value: 'waisun1',
                    organizationType: 0,
                  },
                  {
                    label: '外孙2',
                    value: 'waisun2',
                    organizationType: 0,
                  }
                ]
              }
            ]
          }
        ]
      },
      {
        value: 'zhejiang',
        label: '浙江',
        children: [{
          value: 'hangzhou',
          label: '杭州',
          children: [{
            value: 'xihu',
            label: '西湖',
          }],
        }],
      },
      {
        value: 'jiangsu',
        label: '江苏',
        children: [{
          value: 'nanjing',
          label: '南京',
          children: [{
            value: 'zhonghuamen',
            label: '中华门',
          }],
        }],
      }],
    'checkIndex': 0,
  },
  'dictionaryValue': [
    {'value': '1', 'label': 'xxxx', 'checked': true, 'disabled': true},
    {'value': '2', 'label': 'xxxx', 'checked': false, 'disabled': false}
  ],
  'associated': [ // 关联元素
    {
      'element_id': '1111',
      'way': '0',// 级联的子元素怎么处理没想好 ，交集？并集？
    },
    {
      'element_id': '1111',
      'way': '1',
    }
  ],
  'HealthTold': {
    'title': '是否抽烟？',
    'isMust': true, //是否必须校验标识
    'modeType': '0',// 0 - 单选 1-多选 2-输入
    'multiple_num': 3,// 多选数量限制
    'keyList': [ // 单个健康告知元素匹配词列表
      {
        'rangeFlag': false,
        'keyWord': '是',
        'keyType': 0//类似 elementValueTypeList
      },
      {
        'keyWord': '是',
        'keyType': 2,//类似 elementValueTypeList
        'rangeFlag': true,
        'rangeList': [// 范围区间集合，不排除有多个间断范围
          {
            'startValue': '',
            'endValue': '',
            'containsFlag': 0//0-(),1-(],2[),3[] 包含关系标
          },
          {
            'startValue': '',
            'endValue': '',
            'containsFlag': 0//0-(),1-(],2[),3[]
          }
        ]
      }
    ],
    'answerSortMode': '0',// 0 A-Z,1 1-99999, 2 a-z 答案排序序号方式选择
    'answerList': [
      {
        'answerTitle': 'xxxxxxx',
        'child': [
          {'element_id': '1111'},
          {'element_id': '2222'},
        ]
      },
      {
        'answerTitle': 'xxxxxxx222',
        'child': []// 未关联子项的选项
      },
    ]

  }, // 健康告知类型复杂一点单独拿出来, 如果还有其他复杂的就拿出来一个元素类型对象,
  'other': {},
  'other1': {}
}
