<template>
  <!-- 元素添加,编辑 页面-->
  <!--  设置元素标题和编码 -->
  <a-form
    ref="formRef"
    :model="eleData"
    :rules="formRules"
    v-bind="formItemLayout"
    @finishFailed="onFinishFailed"
    @finish="onFinish"
  >
    <a-form-item name="elementName">
      <template #label>
        <span>元素名称</span>
        <span>
          <a-popover placement="right">
            <template #content>
            <p>
              1. 在拖动元素组成模块时，会设置该元素是否必填。若该元素为必填项，但是在使用时未录入值，则会提示“请填写《元素名称》”
              <br>2. 因为要满足条件1，又要为了防止元素名称重复导致无法区分，允许在元素名称结尾使用“[]”做以区分。如投保人姓名[平安保司专用]
            </p>
            </template>
            <template #title>
            <span>规则说明:</span>
            </template>
            <question-circle-outlined />
          </a-popover>
        </span>
      </template>
      <a-input class="inputWidth" placeholder="请输入元素名称" v-model:value="eleData.elementName"/>
    </a-form-item>
    <a-form-item name="elementType" required>
      <template #label>
        <span>元素类型</span>
        <span>
          <a-popover placement="right">
            <template #content>
            <p>
              1. 输入框：文本录入框，提供多种校验规则
              <br>2. 下拉框：会在底部弹出可选择窗口，并动态支持多个下拉字段联动
              <br>3. 单选按钮：会在同一行内展示多个选项，结果仅可选择一个
              <br>4. 多选按钮：会在同一行内展示多个选项，结果仅可选择多个
              <br>5. 城市选择器：可以设置选择范围，并设置选择的层级
              <br>6. 职业选择器：选择职业使用，会传递职业编码
              <br>7. 时间选择器：支持多种时间格式
              <br>8. 证件类型：支持多种证件类型
              <br>9. 健康告知项：定义健康告知问题使用
              <br>10. 开关：滑块，仅是否两个选项
              <br>11. 告知模块：作为健康告知的入口，关联一个或多个健康告知模块
              <br>12. 影像附件：身份证，银行卡上传后的展示页面
              <br>13. 附件：支持设定模板，以及文件的回传
              <br>14. 上传-身份证&银行卡：身份证，银行卡的上传
              <br>15. 上传组件：文件上传的组件
            </p>
            </template>
            <template #title>
            <span>规则说明:</span>
            </template>
            <question-circle-outlined />
          </a-popover>
        </span>
      </template>
      <a-select
        class="inputWidth"
        ref="select"
        v-model:value="eleData.elementType"
        show-search
        placeholder="请选择元素类型"
        :options="options"
        @focus="focus"
        @change="handleChange"/>
    </a-form-item>
    <a-form-item name="eleTitle" required>
      <template #label>
        <span>元素标题</span>
        <span>
          <a-popover placement="right">
            <template #content>
            <p>
              1. 用于在前端展示表单的名称
            </p>
            </template>
            <template #title>
            <span>规则说明:</span>
            </template>
            <question-circle-outlined />
          </a-popover>
        </span>
      </template>
      <a-textarea class="inputWidth" :rows="1" placeholder="单行输入" v-model:value="eleData.eleTitle"></a-textarea>
    </a-form-item>
    <a-form-item name="elementCode" required>
      <template #label>
        <span>元素编码</span>
        <span>
          <a-popover placement="right">
            <template #content>
            <p>
              需要和系统内报文编码一致，报文可以参考https://product.test.hrhbbx.com/product/backend/trade-service/trade/doc.html#/default/%E4%BF%9D%E5%AD%98%E6%8A%95%E4%BF%9D%E6%97%B6%E5%89%8D%E7%AB%AF%E9%A1%B5%E9%9D%A2%E5%BD%95%E5%85%A5%E7%9A%84%E6%95%B0%E6%8D%AE/saveDataUsingPOST
            </p>
            </template>
            <template #title>
            <span>规则说明:</span>
            </template>
            <question-circle-outlined />
          </a-popover>
        </span>
      </template>
      <a-input class="inputWidth" placeholder="请生成元素编码" v-model:value="eleData.elementCode">
      </a-input>
    </a-form-item>
    <a-form-item label="元素状态" name="eletype">
      <a-radio-group v-model:value="eleData.eletype">
        <a-radio :value="1">启用</a-radio>
        <a-radio :value="2">禁用</a-radio>
        <a-radio :value="0">暂存</a-radio>
      </a-radio-group>
    </a-form-item>
    <a-form-item name="defaultRequired">
      <template #label>
        <span>是否设置必填项</span>
        <span>
      <a-popover placement="right">
        <template #content>
        <p>
          选择“是”时，单选和下拉框选项会以第一个元素值显示，选择“否”则不会显示选项，需要手动选择元素值
        </p>
        </template>
        <template #title>
        <span>规则说明:</span>
        </template>
        <question-circle-outlined />
      </a-popover>
      </span>
      </template>
    <a-radio-group v-model:value="eleData.defaultRequired">
      <a-radio value="Y">是</a-radio>
      <a-radio value="N">否</a-radio>
    </a-radio-group>
  </a-form-item>
    <a-form-item label="显示排列方式" name="rangeType">
      <a-radio-group v-model:value="eleData.rangeType">
        <a-radio :value="0">上下</a-radio>
        <a-radio :value="1">左右</a-radio>
      </a-radio-group>
    </a-form-item>
    <a-form-item  v-show="attach"><!---->
      <template #label>
        <span>上传附件</span>
        <span>
        <a-popover placement="right">
          <template #content>
          <p>
            上传附件用于维护在页面中可以下载的模板，如通过excel填写多个被保人信息的场景，在页面上下载excel文件时用的就是此文件
          </p>
          </template>
          <template #title>
          <span>规则说明:</span>
          </template>
          <question-circle-outlined />
        </a-popover>
        </span>
      </template>
      <upload-file filetype="excel"
                   :showImg="showImg" num="1"
                   @fileChange="uploadCosFileChange"></upload-file>
    </a-form-item>
    <a-form-item v-show="attach">
      <template #label>
        <span>是否允许回传</span>
        <span>
        <a-popover placement="right">
          <template #content>
          <p>
            用于控制下载的模板是否允许上传
          </p>
          </template>
          <template #title>
          <span>规则说明:</span>
          </template>
          <question-circle-outlined />
        </a-popover>
        </span>
      </template>
      <a-radio-group v-model:value="elementConfig.isPassBack">
        <a-radio value="Y">是</a-radio>
        <a-radio value="N">否</a-radio>
      </a-radio-group>
    </a-form-item>
    <a-form-item name="isSameSalesRegion" v-show="eleData.elementType === '4'">
      <template #label>
        <span>是否同销售地区</span>
        <span>
        <a-popover placement="right">
          <template #content>
          <p>
            通过该字段限制选择区域的范围
              <br>1. 当选择否时，不限制区域范围，可选全国
              <br>2. 当选择是时，会根据产品所配置的售卖区域进行限制
              <br>3. 同时区域范围还跟经代产品/网销产品有关，网销产品不加限制，但是如果为经代产品，还会根据代理人的可售卖区域进行限制，如代理人售卖区域为北京，则页面可选择的售卖范围仅为北京
          </p>
          </template>
          <template #title>
          <span>规则说明:</span>
          </template>
          <question-circle-outlined />
        </a-popover>
        </span>
      </template>
      <a-radio-group v-model:value="eleData.isSameSalesRegion">
        <a-radio value="Y">是</a-radio>
        <a-radio value="N">否</a-radio>
      </a-radio-group>
    </a-form-item>
    <a-form-item  name="urbanAreaLevel" v-show="eleData.elementType === '4'">
      <template #label>
        <span>城市地区等级</span>
        <span>
        <a-popover placement="right">
          <template #content>
          <p>
            用来限制叶子节点的区域等级
          </p>
          </template>
          <template #title>
          <span>规则说明:</span>
          </template>
          <question-circle-outlined />
        </a-popover>
        </span>
      </template>
      <a-select
        class="inputWidth"
        v-model:value="eleData.urbanAreaLevel"
        placeholder="请选择城市地区等级"
        :options="urbanAreaLevelList"/>
    </a-form-item>
    <a-form-item name="dictionValue" v-show="otherShow">
      <template #label>
        <span>是否关联字典</span>
        <span>
          <a-popover placement="right">
            <template #content>
            <p>
              1. 是否关联字典选择“否”时，我们可以自定义下拉数据，格式可以通过点击【演示写法】来查看，
              <br>2. 大多数时候我们不直接引用字典表，是因为字典表中的数据量要远大于实际的使用量，并且页面展示的中文描述也会与字典有所差别，在这种场景下尽量使用自定义数据
              <br>3. 要注意数据的value值一定要遵循我们字典表的数据
              <br>4. 通过自定义下拉数据还可以实现多个字段的联动，如投保人类型为企业和个人，证件类型为统一社会信用代码和身份证，通过父节点中设置childrenType属性，其下添加元素编码集合，可以在页面上选择完投保人类型后，动态的更改证件类型，数据格式如下:
                [
                  {
                    "children": [],
                    "label": "企业",
                    "childrenType": {
                      "appntIdType": [
                        {
                          "children": [],
                          "label": "统一社会信用代码",
                          "value": "23"
                        }
                      ]
                    },
                    "value": "2"
                  },
                  {
                    "children": [],
                    "label": "个人",
                    "childrenType": {
                      "appntIdType": [
                        {
                          "children": [],
                          "label": "身份证",
                          "value": "01"
                        }
                      ]
                    },
                    "value": "1"
                  }
                ]
              <br>5. 选择“是”时，会联动出“关联的字典”选项；
            </p>
            </template>
            <template #title>
            <span>规则说明:</span>
            </template>
            <question-circle-outlined />
          </a-popover>
          </span>
      </template>
      <a-radio-group v-model:value="eleData.dictionValue" @change="handleDiction">
        <a-radio :value="false" :checked="true">否</a-radio>
        <a-radio :value="true" :checked="false">是</a-radio>
      </a-radio-group>
    </a-form-item>
    <a-form-item name="eleValueType" :required="inputShow ? true : (dictionShow ? false : true)" v-show="inputShow ? true : (dictionShow ? false : true)">
      <template #label>
        <span>元素值校验规则</span>
        <span>
        <a-popover placement="right">
          <template #content>
          <p>
            元素值校验规则：指的是在输入框离焦时会按照以下内置规则进行校验，并在录入框下会显示红色字体提示
              <br>1. 文本：校验是否非空
              <br>2. 数字：校验是否为数字格式
              <br>3. 姓名：校验非空并且不能包含特殊字符
              <br>4. 邮箱：校验邮箱格式
              <br>5. 座机：校验座机号码格式
              <br>6. 移动电话：校验手机号格式
              <br>7. 身份证号：校验身份证号是否合规
              <br>8. 年龄：校验符合0-105岁区间
              <br>9. 时间节点：展示具体的时分
              <br>10. 时间范围：两个时间点组成的时间段，具体到时分秒
              <br>11. 日期节点：展示年月日
              <br>12. 日期节点（可选长期）：可以选择年月日，也可以选择长期字符
              <br>13. 日期范围：两个日期组成的时间段，具体到年月日
          </p>
          </template>
          <template #title>
          <span>规则说明:</span>
          </template>
          <question-circle-outlined />
        </a-popover>
        </span>
      </template>
      <a-select
        class="inputWidth"
        v-model:value="eleData.eleValueType"
        show-search
        placeholder="请选择元素值类型"
        :options="eleData.eleValueTypeList"
        @change="handleeleValueType"/>
    </a-form-item>

    <a-form-item label="元素值最大长度" name="elementMaxValueLength" v-show="inputShow && eleData.elementTypeData !=='时间选择器'">
      <a-input class="inputWidth" v-model:value="eleData.elementMaxValueLength" placeholder="请选择元素最大长度"/>
    </a-form-item>
    <a-form-item label="元素值最小长度" name="elementMinValueLength" v-show="inputShow && eleData.elementTypeData !=='时间选择器'">
      <a-input class="inputWidth" v-model:value="eleData.elementMinValueLength" placeholder="请选择元素最小长度"/>
    </a-form-item>
    <!-- 这是 元素类型选择输入框之后展示 -->
    <a-form :labelCol="{span: 6}" :wrapperCol="{span: 15}" autocomplete="off" v-if="eleData.elementTypeData==='输入框'">
      <a-form-item label="最大行数" name="rows">
        <a-input class="inputWidth"  v-model:value="elementConfig.rows" placeholder="请选择最大行数"></a-input>
      </a-form-item>
      <a-form-item name="promptLanguage">
        <template #label>
          <span>提示语</span>
          <span>
          <a-popover placement="right">
            <template #content>
            <p>
              未录入数据之前，文本框内的提示语
            </p>
            </template>
            <template #title>
            <span>规则说明:</span>
            </template>
            <question-circle-outlined />
          </a-popover>
          </span>
        </template>
        <a-input class="inputWidth"  v-model:value="elementConfig.promptLanguage" placeholder="请输入提示语"></a-input>
      </a-form-item>
      <a-form-item name="specialClues">
        <template #label>
          <span>特殊提示</span>
          <span>
          <a-popover placement="right">
            <template #content>
            <p>
              同提示语不同的是，特殊提示会一直显示在页面上，并且以黄色字体展示在录入框下方
            </p>
            </template>
            <template #title>
            <span>规则说明:</span>
            </template>
            <question-circle-outlined />
          </a-popover>
          </span>
        </template>
        <a-input class="inputWidth"  v-model:value="elementConfig.specialClues" placeholder="请输入特殊提示"></a-input>
      </a-form-item>
      <a-form-item name="calculate">
        <template #label>
          <span>单位</span>
          <span>
          <a-popover placement="right">
            <template #content>
            <p>
              会在标题后展示，如标题为年收入，这里配置万元，展示形式为“年收入（万元）
            </p>
            </template>
            <template #title>
            <span>规则说明:</span>
            </template>
            <question-circle-outlined />
          </a-popover>
          </span>
        </template>
        <a-input class="inputWidth"  v-model:value="elementConfig.calculate" placeholder="请输入单位"></a-input>
      </a-form-item>

      <a-form-item name="isPassword">
        <template #label>
          <span>展示密码</span>
          <span>
          <a-popover placement="right">
            <template #content>
            <p>
              在文本框之后显示查看密码的按钮
            </p>
            </template>
            <template #title>
            <span>规则说明:</span>
            </template>
            <question-circle-outlined />
          </a-popover>
          </span>
        </template>
        <a-radio-group v-model:value="elementConfig.isPassword" name="radioGroup" class="inputWidth">
          <a-radio :value="false">否</a-radio>
          <a-radio :value="true">是</a-radio>
        </a-radio-group>
      </a-form-item>
      <a-form-item name="bordered">
        <template #label>
          <span>边框设置</span>
          <span>
          <a-popover placement="right">
            <template #content>
            <p>
              会凸显边框线条
            </p>
            </template>
            <template #title>
            <span>规则说明:</span>
            </template>
            <question-circle-outlined />
          </a-popover>
          </span>
        </template>
        <a-radio-group v-model:value="elementConfig.bordered"  class="inputWidth">
          <a-radio :value="false">否</a-radio>
          <a-radio :value="true">是</a-radio>
        </a-radio-group>
      </a-form-item>
      <a-form-item name="disabled">
        <template #label>
          <span>是否禁用</span>
          <span>
          <a-popover placement="right">
            <template #content>
            <p>
              不可选，比只读层级更高
            </p>
            </template>
            <template #title>
            <span>规则说明:</span>
            </template>
            <question-circle-outlined />
          </a-popover>
          </span>
        </template>
        <a-radio-group v-model:value="elementConfig.disabled" class="inputWidth">
          <a-radio :value="false">否</a-radio>
          <a-radio :value="true">是</a-radio>
        </a-radio-group>
      </a-form-item>
      <a-form-item name="readonly">
        <template #label>
          <span>是否只读</span>
          <span>
          <a-popover placement="right">
            <template #content>
            <p>
              仅可读，不可变更
            </p>
            </template>
            <template #title>
            <span>规则说明:</span>
            </template>
            <question-circle-outlined />
          </a-popover>
          </span>
        </template>
        <a-radio-group v-model:value="elementConfig.readonly" class="inputWidth">
          <a-radio :value="false">否</a-radio>
          <a-radio :value="true">是</a-radio>
        </a-radio-group>
      </a-form-item>
      <a-form-item name="isCheckPhone">
        <template #label>
          <span>是否校验</span>
          <span>
          <a-popover placement="right">
            <template #content>
            <p>
              进行手机号校验
            </p>
            </template>
            <template #title>
            <span>规则说明:</span>
            </template>
            <question-circle-outlined />
          </a-popover>
          </span>
        </template>
        <a-radio-group v-model:value="elementConfig.isCheckPhone" class="inputWidth">
          <a-radio :value="false">否</a-radio>
          <a-radio :value="true">是</a-radio>
        </a-radio-group>
      </a-form-item>
      <a-form-item name="textarea">
        <template #label>
          <span>设置文本区域</span>
          <span>
          <a-popover placement="right">
            <template #content>
            <p>
              变成文本域，边框可以下拉，可以输入更多的文字
            </p>
            </template>
            <template #title>
            <span>规则说明:</span>
            </template>
            <question-circle-outlined />
          </a-popover>
          </span>
        </template>
        <a-radio-group  v-model:value="elementConfig.textarea" class="inputWidth">
          <a-radio :value="false">否</a-radio>
          <a-radio :value="true">是</a-radio>
        </a-radio-group>
      </a-form-item>
      <a-form-item name="showCount">
        <template #label>
          <span>是否显示输入字数</span>
          <span>
          <a-popover placement="right">
            <template #content>
            <p>
              动态提示已输入的字符数
            </p>
            </template>
            <template #title>
            <span>规则说明:</span>
            </template>
            <question-circle-outlined />
          </a-popover>
          </span>
        </template>
        <a-radio-group  v-model:value="elementConfig.showCount" class="inputWidth">
          <a-radio :value="false">否</a-radio>
          <a-radio :value="true">是</a-radio>
        </a-radio-group>
      </a-form-item>
    </a-form>

    <!-- 元素类型是下拉框 且 关联字典时 展示 -->
    <a-form-item name="childNode" v-if="isDown">
      <template #label>
        <span>是否选择子节点</span>
        <span>
        <a-popover placement="right">
          <template #content>
          <p>
            表示可以选择到子节点，当关联到字典时，要求字典是有children结构的，当未关联到字典时，可以在自定义的数据结构中添加children层级
          </p>
          </template>
          <template #title>
          <span>规则说明:</span>
          </template>
          <question-circle-outlined />
        </a-popover>
        </span>
      </template>
      <a-radio-group v-model:value="eleData.childNode">
        <a-radio :value="true">是</a-radio>
        <a-radio :value="false">否</a-radio>
      </a-radio-group>
    </a-form-item>
    <a-form-item label="关联的字典" name="dictionarie" v-show="isShow">
      <!-- @change="handleChange" -->
      <a-select
        class="inputWidth"
        v-model:value="eleData.dictionarie"
        show-search
        placeholder="请选择相应的字典"
        :options="eleData.dictionarieList"
        />
    </a-form-item>
    <!-- 是否单选  选择为多选的时候展示  -->
    <a-form-item label="多选最小数量" name="minSelection" v-if="eleData.elementTypeData === '多选按钮'"
      :rules="[{pattern: /^(([0-9]|([1-9]\d)|(1\d\d)|(2([0-4]\d|5[0-5]))))$/, message: '范围值为0-255' ,trigger: ['change', 'blur']}]"
    >
      <a-input class="inputWidth" v-model:value="eleData.minSelection" title="0表示不限制长度" placeholder="请选择多选最小数量"></a-input>
    </a-form-item>
    <a-form-item label="多选最大数量" name="maxSelection" v-if="eleData.elementTypeData === '多选按钮'"
      :rules="[{pattern: /^(([0-9]|([1-9]\d)|(1\d\d)|(2([0-4]\d|5[0-5]))))$/, message: '范围值为0-255' ,trigger: ['change', 'blur']}]"
    >
      <a-input class="inputWidth" v-model:value="eleData.maxSelection" title="0表示不限制长度" placeholder="请选择多选最小数量"></a-input>
    </a-form-item>

    <!-- 当元素值是 范围值时展示 -->
    <a-form v-bind="formItemLayout" v-if="isRangeShow || isNodeShow">
      <a-form-item :label="eleData.eleValueData" v-if="isRangeShow">
        <div v-for="(range,index) in eleData.rangeList" :key="index">
          <div v-if="eleData.eleValueData === '数字范围'" style="margin-bottom: 10px">
            起始:
            <a-input style="width:100px" @blur="onBlur" @change="onDateChange(index)" v-model:value="range.startData"/>
            <span style="margin-left: 20px">结束: </span>
            <a-input style="width:100px" @blur="endDataBlur($event,index)" v-model:value="range.endData"/>
          </div>
          <div v-if="eleData.eleValueData === '年龄范围'" style="margin-bottom: 10px">
            起始:
            <a-input style="width:60px"  @blur="onBlur" @change="onDateChange(index)" v-model:value="range.startData"/>
            <a-select style="width:55px;margin-left: 1px;" v-model:value="range.type">
              <a-select-option value="天">天</a-select-option>
              <a-select-option value="周">周</a-select-option>
              <a-select-option value="年">年</a-select-option>
            </a-select>
            <span style="margin-left: 20px">结束: </span>
            <a-input style="width:60px" @blur="endDataBlur($event,index)" v-model:value="range.endData"/>
            <a-select style="width:55px;margin-left: 1px;" v-model:value="range.type">
              <a-select-option value="天">天</a-select-option>
              <a-select-option value="周">周</a-select-option>
              <a-select-option value="年">年</a-select-option>
            </a-select>
          </div>
          <div v-if="eleData.eleValueData === '职业类范围'" style="margin-bottom: 10px">
            起始:
            <a-select style="width:60px" v-model:value="range.startData">
              <a-select-option :value="num" v-for="num in 6" :key="num">{{num}}类</a-select-option>
            </a-select>
            <span style="margin-left: 75px">结束: </span>
            <a-select style="width:60px" v-model:value="range.endData">
              <a-select-option :value="num" v-for="num in 6" :key="num">{{num}}类</a-select-option>
            </a-select>
          </div>
        </div>
      </a-form-item>
      <a-form-item :wrapper-col="{ span: 14, offset: 13 }" v-if="isRangeShow">
        <a-button @click="addRange">+ 增加</a-button>
        <a-button style="margin-left: 10px" @click="delRange">- 删除</a-button>
      </a-form-item>
      <!-- 节点 展示  需要验证 -->
      <!-- <a-form-item :label="eleData.eleValueData" v-if="eleData.eleValueData === '年龄'">
        <div v-if="eleData.eleValueData === '年龄'">
          <a-input style="width:65px" v-model:value="eleData.nodeObj.ageNode"/>
          <a-select style="width:55px;margin-left: 3px;" v-model:value="eleData.nodeObj.ageNodeDate">
            <a-select-option value="天">天</a-select-option>
            <a-select-option value="周">周</a-select-option>
            <a-select-option value="年">年</a-select-option>
          </a-select>
        </div>
      </a-form-item> -->
    </a-form>
    <a-form-item name="buttonOptions" v-if="isButtonShow">
      <template #label>
        <span>按钮配置选项</span>
        <span>
        <a-popover placement="right">
          <template #content>
          <p>
            自定义添加内容，如性别：男和女，此时value值默认从1开始递增
          </p>
          </template>
          <template #title>
          <span>规则说明:</span>
          </template>
          <question-circle-outlined />
        </a-popover>
        </span>
      </template>
      <div v-for="butt in eleData.buttonOptionsList" :key="butt.value" style="margin-bottom: 15px">
        <a-input class="inputWidth" placeholder="请输入配置按钮选项" v-model:value="butt.label"/>
      </div>
      <a-form-item :wrapper-col="{ span: 14, offset: 11 }" style="margin-top: 15px">
        <a-button @click="addButtonOption">+ 增加</a-button>
        <a-button style="margin-left: 10px" @click="delButtonOption">- 删除</a-button>
      </a-form-item>
    </a-form-item>
    <!-- 证件类型中的 配置选项 -->
    <a-form-item label="证件配置选项" v-if="isCertificateShow">
      <a-row :gutter="2">
        <a-col>
          <a-checkbox-group v-model:value="eleData.certificateValue">
            &nbsp;&nbsp;<a-checkbox :value="certificate.value" style="width:150px; padding-left:5px" v-for="certificate in eleData.certificateOptions" :key="certificate.value">{{certificate.label}}</a-checkbox>
          </a-checkbox-group>
        </a-col>
      </a-row>
    </a-form-item>
    <a-form-item label="下拉框多级配置" v-if="isDropDownShow">
      <a-button type="primary">导入</a-button>
      <a-button type="primary" @click="showJson" style="margin-left: 10px;margin-bottom: 10px">演示写法</a-button>
      <div>
        <JsonEditorVue class="editor" v-model="eleData.dropDownConfiguration" @blur="validate" />
      </div>
    </a-form-item>
    <a-form-item name="isNotification" v-if="eleData.elementType == '104'">
      <template #label>
        <span>是否关联健康告知: </span>
        <span>
        <a-popover placement="right">
          <template #content>
          <p>
            1. 告知模块指的是在投保流程录入页面的健康告知入口
            <br>2. 多个健康告知项可以组成一个健康告知页面，一个或多个页面按照顺序组装好，形成告知模块
            <br>3. 是否关联健康告知，就是用于将一个或多个健康告知页面组装成整体的
          </p>
          </template>
          <template #title>
          <span>规则说明:</span>
          </template>
          <question-circle-outlined />
        </a-popover>
        </span>
      </template>
      <a-button type="primary" @click="handelNotification" size="small">告知关联</a-button>
    </a-form-item>
    <a-form-item label="开关左边展示" name="switchLeft" v-if="eleData.elementType == '103'">
      <a-input class="inputWidth"  v-model:value="switchLeft" placeholder="请输入开关左边展示"></a-input>
    </a-form-item>
    <a-form-item label="开关右边展示" name="switchRight" v-if="eleData.elementType == '103'">
      <a-input class="inputWidth"  v-model:value="switchRight" placeholder="请输入开关右边展示"></a-input>
    </a-form-item>
  </a-form>
  <!--  选择操作类型，展示不同配置区域-->
  <div>
    <!--通用部分-->
    <HealthSingleSonfig v-if="healthinfoShow" ref="healthinfoConfig"/>
    <!-- 告知关联 -->
    <associated-notification @handelSaveSelectList="handelSaveSelectList" ref="notificationRef"/>
    <!-- 上传 -->
    <UploadComponent ref="uploadRef" :mode_id="eleData.elementType" :key="time" v-if="uploadShow"/>
  </div>
  <DropDownList ref="dropDown"/>
</template>

<script>
import {reactive, ref, toRefs, defineComponent, onMounted, nextTick} from 'vue'
import {guid} from '../js/snowflake'
import {jl_demo} from './views/cascader_demo'
import {newElementJson} from '../js/emptyElementJson'
import { message } from 'ant-design-vue'
import uploadFile  from '../../../components/extended/uploadFileNew'
// import {vTools} from '../js/verificationTool'
import dayjs from 'dayjs'
import AssociatedNotification from './views/associatedNotification'
import store from '../../../store'
import { getRequestURI, request } from '../../../utils/request'
import {getDictionaryData, getExtendUri} from '../../../utils/request_extend'
import DropDownList from './mod/dropDownList.vue'
import JsonEditorVue from 'json-editor-vue3'
import UploadComponent from './mod/uploadConfig/uploadComponent'
import HealthSingleSonfig from '../healthNotificationConfiguration/healthSingleSonfig'
export default defineComponent({
  emits: ['refreshElementSuccess'],
  props: {
    elementId: {
      type: String,
      default: ()=> ''
    },
    searchfFilters: { type: Object },
    handleSearch:{
      type: Function,
      default: ()=> {}
    },
    editData:{
      type: Object,
      default:() => {}
    },
    elementTypeData: {
      type: Array,
      default: () =>[]
    },
    dictionaryData: {
      type: Array,
      default: () =>[]
    },
    current:{
      type: [String, Number],
      default:() => 1
    }
  },
  components:{
    JsonEditorVue,
    DropDownList,
    HealthSingleSonfig,
    UploadComponent,
    AssociatedNotification,
    uploadFile

  },
  setup(props, content) {
    const state = reactive({
      switchLeft: '',
      switchRight:'',
      notification: {},
      dropDownList: jl_demo.dictionary.dictionaryValue,
      conversion:'',
      type:'date',
      time: undefined,
      elementObj: {},
      options: props.elementTypeData,
      elementId: props.elementId,
      showImg: [],
      eleData: {
        id:null,
        eleTitle:'',
        elementName: '',
        elementCode: '', // 随机编码
        elementType:undefined, // 元素类型
        elementTypeData:'', // 元素类型的值
        eleValueTypeList:[], // 元素值的假数据
        eleValueType:undefined, // 元素值校验规则
        eleValueData: '', // 元素值的数据
        dictionarieList: props.dictionaryData, // 字典的数据
        dictionarie:undefined, // 字典
        dictionValue: undefined, // 是否关联字典
        elementMaxValueLength: undefined, // 元素最大长度
        elementMinValueLength: undefined, // 元素最小长度
        eletype:undefined, // 元素状态
        rangeType:undefined, //显示排列方式
        childNode: false, // 是否选择子节点
        minSelection: '', // 多选最小数量
        maxSelection: '', // 多选最大数量
        buttonOptionsList: [], // 单选和多选的按钮配置选项
        rangeList:[],  // 元素值中存放范围的数组
        certificateValue: [], // 证件类型自选的值
        certificateOptions: [],// 证件类型自选配置
        timeChackValue : undefined,
        nodeObj:{
          typeData: '',
          ageNode: '',
          ageNodeDate: '',
          timeNode: '',
          timeNodeType: '',
          nodeDateType: '',
          nodeDate: '',
        },
        timeMinValue: undefined, // 时间选择器最小范围值
        timeMaxValue: undefined, // 时间选择器最大范围值
        dropDownConfiguration:[],
        isSameSalesRegion: 'Y',
        urbanAreaLevel: '',
      },
      // 元素类型为输入框的一些数据
      elementConfig:{
        specialClues:'',
        calculate:'', //单位
        rows: '', // 最大行数
        promptLanguage: '', //提示语
        isPassword: false, // 是否展示密码
        bordered: false,  // 是否展示边框
        disabled: false, // 是否禁用
        textarea: false, //是否设置文本区域
        showCount: false, // 是否展示输入字数
        readonly: false,
        isCheckPhone: false,
        isPassBack: 'N' // 是否回传
      }
    })
    const showData = reactive({
      // 根据选择不同的属性,展示的多种状态
      inputShow: false,
      healthinfoShow: false,
      otherShow: false,
      dictionShow: true,
      isShow:false,
      isDown: false,
      isRangeShow: false,  // 范围
      isNodeShow: false,  // 节点
      isInputNode: false,
      isButtonShow: false,
      isCertificateShow: false,
      dropDownShow: false,
      uploadShow:false,
      isDropDownShow: false,
     attach: false
    })

    // 校验规则
    const formRules = reactive({
      elementName: [
        {required: true,message: '请输入元素名称',}
      ],
      elementType: [
        {required: true,message: '请选择元素类型'}
      ],
      elementCode: [
        {required: true,message: '请生成元素编码'}
      ],
      eleValueType: [
        {required: false,message: '请生成元素值类型'}
      ],
      eleTitle: [
        {required: true,message: '请输入元素标题'}
      ]
    })
    const notificationRef = ref()
    // 是否关联健康告知
    const handelNotification = () =>{
      notificationRef.value.showDrawer(state.notification)
    }
    // 健康告知关联返回值
    const handelSaveSelectList = (data) =>{
      state.notification = data
    }
    const  urbanAreaLevelList = ref([])
    onMounted(()=>{
      getDictionaryData(urbanAreaLevelList,'areaLevel')
    })


    const formRef = ref()
    // 获取元素操作类型列表
    const getElementOperatingModeList = ()=> {
      // 网络请求 获取元素对象

      // 先用假数据
      // state.options = renderOptions(jl_demo.element_operating_mode.operating_mode_list,'mode_id','mode_name')
      state.eleData.eleValueTypeList = renderOptions02(jl_demo.elementValueType.elementValueTypeList,'typeId','typeName','name','isCheck')
    }
    // 用于将下拉框不符合options的数组修改为key为label和value的数组
    // const renderOptions = (arr , key , title) => arr ?  arr.map( (item)=>{
    //   return {
    //     label:item[title],
    //     value:item[key],
    //   }
    // }) : null
    const renderOptions02 = (arr , key , title, name, isCheck) => arr ?  arr.map( (item)=>{
      return {
        label:item[title],
        value:item[key],
        name:item[name],
        isCheck:item[isCheck],
      }
    }) : null
    // 获取元素
    const getElementObj = ()=> {
      // 网络请求 获取元素对象

      // 先用假数据做一下适配
      state.elementObj = jl_demo
    }
    if (state.elementId) {
      getElementObj()
    } else {
      state.elementObj = newElementJson
    }
    const formItemLayout = {
      labelCol: {
        span: 6,
      },
      wrapperCol: {
        span: 16,
      },
    }
    const onFinish = values => {
      console.debug('Success:', values)
    }

    const onFinishFailed = errorInfo => {
      console.debug('Failed:', errorInfo)
    }
    // 随机编码
    const setRandomId = () => {
      // state.elementObj.elementCode = guid()
      state.eleData.elementCode = guid()
    }
    const focus = () => {
      console.debug('focus')
    }
      // 元素选择时的回调
    const handleChange = (value, data) => {
      state.eleData.elementTypeData = data.label
      state.eleData.eleValueTypeList = []
      state.eleData.buttonOptionsList = []
      state.eleData.dictionarieList= props.dictionaryData
      showData.isDown = false
      showData.isShow = false
      showData.dictionShow = true
      showData.isRangeShow = false
      showData.inputShow = false
      showData.otherShow = false
      showData.healthinfoShow = false
      showData.isButtonShow = false
      showData.isInputNode = false
      showData.uploadShow = false
      showData.isCertificateShow = false
      showData.isDropDownShow = false
      state.eleData.dictionValue = undefined
      state.eleData.eleValueType = ''
      state.eleData.eleValueData = ''
      handeShowEleValue(value)
      if(value === '0'){
        showData.inputShow = true
      } else if (value === '99'){
        showData.healthinfoShow = true
      }else if(value === '104' || value === '105'){
        showData.isShow = false
      }else{
        setOtherElement(data.label,data)
      }
    }
    // 根据元素类型的不同展示不同的元素值类型
    const handeShowEleValue = (id) => {
      switch (id) {
        case '0':
          state.eleData.eleValueTypeList = [
              {
                value: 0,
                label: '文本',
                name: 'text',
                isCheck: true  // 是否 验证
              },
              {
                value: 1,
                label: '数字',
                name: 'number',
                isCheck: true
              },
              {
                value: 2,
                label: '数字范围'
              },
              {
                value: 3,
                label: '姓名',
                name: 'name',
                isCheck: true  // 是否 验证
              },
              {
                value: 7,
                label: '邮箱',
                name: 'email',
                isCheck: true

              },
              {
                value: 8,
                label: '座机',
                name: 'tel',
                isCheck: true
              },
              {
                value: 9,
                label: '移动电话',
                isChina: '86',
                name: 'phone',
                isCheck: true
              },
              {
                value: 10,
                label: '身份证号',
                name: 'pId',
                isCheck: true
              },
              {
                value: 11,
                label: '年龄'
              },
              {
                value: 12,
                label: '年龄范围'
              },
            ]
          break
        case '1':
          showData.inputShow = false
          showData.dictionShow = true
          break
        case '2':
        case '3':
          state.eleData.buttonOptionsList.push({
            value: state.eleData.buttonOptionsList.length > 0 ? state.eleData.buttonOptionsList.length +1 : 1,
            label: ''
          })
          break
        case '6':
          state.eleData.eleValueTypeList = [
            {
              value: 3,
              label: '时间节点'
            },
            {
              value: 4,
              label: '时间范围'
            },
            {
              value: 5,
              label: '日期节点'
            },
            {
              value: 50,
              label: '日期节点(可选长期)'
            },
            {
              value: 6,
              label: '日期范围'
            },
          ]
          break
        case '4' :
        case '5' :
        case '7' :
        case '99':
        case '103':
          getElementOperatingModeList()
          break
        default:
          break
      }
    }
    const selectByFirst = (id) => {
      // 城市
      if(id == 4) {
        request(getRequestURI('queryArea'),
        {
          data: {
            code: '',
            id: 0,
            name: '',
            parentId: 0,
            parentIds: '',
            sort: 0,
            type: 1
          },
          pagination: {
            current: 1,
            pageSize: 999999,
            total: 0
          },
        }).then(res=>{
          if(res.code == 1){
            let arr = []
            res.result.forEach(item =>{
              let node = {
                value: item.id,
                label: item.name
              }
              arr.push(node)
            })
            state.eleData.dictionarieList = arr
          } else {
            state.eleData.dictionarieList = []
          }
        })
      }
      if(id == 5){
        request(getRequestURI('productProfessionCommon_selectByFirst'),{}).then(
        res =>{
          let arr = []
          if(res.code === 1){
            res.result.forEach(item => {
              let node = {
                value : item.id,
                label: item.name
              }
              arr.push(node)
            })
            state.eleData.dictionarieList = arr
          } else {
            state.eleData.dictionarieList = []
          }
        }
      )
      }


    }
    // 选择其他元素类型 的 逻辑
    const setOtherElement = (label,data) => {
      console.debug('选择其他元素类型', label, data)
      showData.attach = false
      if(data.value === '4' || data.value === '5') {
        // message.warning('请选择关联的字典')
        showData.otherShow = false
        // 需调用对应的字典表数据 进行操作
        selectByFirst(data.value)
      }else if(data.value === '6'){
        showData.inputShow = true
        showData.otherShow = false
      } else if (data.value === '100' || data.value === '101' ){
        showData.inputShow = false
        showData.uploadShow = true
        state.time = new Date().getTime()
      }else if(data.value === '103'){
        showData.inputShow = false
        showData.otherShow = false
      }else if(data.value === '104' || data.value === '105'){
        showData.inputShow = false
        showData.isShow = false
      }else if(data.value === '15') {
        console.log('选择是否生效15')
        showData.attach = true
        showData.inputShow = false
        showData.otherShow = false
      }
    else {
        // 接下需要判断是否关联了字典表
        if(data.isDictionary !== ''){
          showData.isShow = false
          showData.otherShow = true
        }else {
          showData.isShow = false
          showData.otherShow = false
        }

      }
    }
    // 元素值选择时的回调
    const handleeleValueType = (value,data) => {
      state.eleData.eleValueData = data.label ? data.label : data.elementVaule.elementTypeValue
      showData.isRangeShow = false
      showData.isNodeShow = false
      showData.isInputNode = false
      if(value == 2 || value == 12 || value == 13){
        state.eleData.nodeObj.typeData = ''
        showData.isRangeShow = true
        state.eleData.rangeList = []
        if(data.elementVaule && data.elementVaule.rangeList && data.elementVaule.rangeList.length > 0) {

          state.eleData.rangeList = [...data.elementVaule.rangeList]
        }else{
          state.eleData.rangeList.push({type: undefined,startData: undefined, endData: undefined})
        }
      }else if(value === 3  || value === 4 || value === 5 || value === 6) {
        showData.isNodeShow = false
      }else{
        showData.isNodeShow = true
        if(value !== 3 && value !== 5 && value !== 11){
          showData.isInputNode = true
        }
      }
    }

    // 是否关联字典表的回调
    const handleDiction = (e) =>{
      console.debug('点击得到的value值', state.eleData.elementType)
      showData.isShow = e.target.value ? true : false
      showData.isRangeShow = false
      showData.isInputNode = false
      showData.isButtonShow = false
      showData.isCertificateShow = false
      showData.isDropDownShow = false
      state.eleData.certificateValue = []
      state.eleData.certificateOptions = []
      state.eleData.dictionarie = undefined
      if(state.eleData.elementType === '1') {
        showData.isDown = true
      } else {
        showData.isDown = false
      }
      if((state.eleData.elementType === '2' || state.eleData.elementType === '3') && !(e.target.value)){
        console.debug(showData.isButtonShow, e.target.value)
        showData.isButtonShow = true
      }
      if(state.eleData.elementType === '7' && !(e.target.value)){
        showData.isCertificateShow = true
        getCertificateData()
      }
      if(state.eleData.elementType === '1' && !(e.target.value)){
        showData.isDropDownShow = true
      }
      if (state.eleData.elementType === '1' ||
          state.eleData.elementType === '2' ||
          state.eleData.elementType === '3' ||
          state.eleData.elementType === '7'
      ) {
        showData.inputShow = false
      } else {
        showData.dictionShow = e.target.value ? true : false
      }
      if(e.target.value) {
        state.eleData.eleValueType = ''
        state.eleData.eleValueData = ''
      }
    }

    // 证件类型 选择不关联字典  查询字典的全部数据
    const getCertificateData = () => {
      try {
        request(getExtendUri('dictionary_query_byType'),{data: {types: ['cbs_idtype']}}).then(
          res => {
          state.eleData.certificateOptions = res.result.cbs_idtype
        })
      } catch (error) {
        message.error(error)
      }
    }

    // 选择多选的时候回调
    const onChange = (e) =>{
      console.debug(e.target.value)
    }

    const healthinfoConfig = ref()
    const uploadRef = ref()
    // 确定
    const handleOk =  async () => {
      console.log(33333333, healthinfoConfig.value)

      let inputObj = {}
      let healthType = ''
      if(healthinfoConfig.value && healthinfoConfig.value.heaData.way === 2){
        healthType='inputType'
        let {tabList} = healthinfoConfig.value.inputTypeData.modelPropData
        for (let i = 0; i < tabList?.length; i++) {
          if(!tabList[i].mPropKey){
            return message.warning('请设置文本属性')
          }
        }
        inputObj = healthinfoConfig.value.inputTypeData
      }else if(healthinfoConfig.value && healthinfoConfig.value.heaData.way === 1){
        healthType = 'radioType'
      } else if(healthinfoConfig.value && healthinfoConfig.value.heaData.way === 3){
        healthType = 'checkType'
      }
      await formRef.value.validateFields()
      let params = {
        id: state.eleData.id,
        elementName: state.eleData.elementName,
        elementState: state.eleData.eletype,
        healthType,
        isSameSalesRegion: state.eleData.isSameSalesRegion,
        urbanAreaLevel: state.eleData.urbanAreaLevel,
        elementTitle:state.eleData.eleTitle, // 元素标题
        expressionValue: ( healthinfoConfig.value && healthinfoConfig.value.heaData.expressionValue) ? healthinfoConfig.value.heaData.expressionValue : '', // 表达式
        elementOperatingMode:{
          mode_id: state.eleData.elementType, // 区别元素类型 的 ID
          elementTypeData: state.eleData.elementTypeData,  // 得到元素类型值
          elementConfig: state.elementConfig, // 输入框配置
          elementTitle:state.eleData.eleTitle, // 元素标题
          mode_config: {  // 选择的配置
            multiple:state.eleData.elementTypeData === '单选按钮' ? true : false,  // 是否单选
            multipleMaxNum:state.eleData.maxSelection, // 多选的最大数量
            multipleMinNum:state.eleData.minSelection, // 多选的最小数量
            elementType:state.eleData.eleValueType, // 元素值校验规则
            isDictionary:state.eleData.dictionValue, // 是否关联字典名
            dictionaryName:state.eleData.dictionarie,  // 关联的字典名
            buttonOptionsList: state.eleData.buttonOptionsList, // 不关联字典  用户自己配置按钮选项
          },
          mode_select:{ // 下拉框配置
            isDictionary:state.eleData.dictionValue,
            elementType:state.eleData.eleValueType,
            checkNode: state.eleData.childNode, // 是否选择到子节点
            dictionaryName:state.eleData.dictionarie,
            // 下拉框选项
            selectList:state.eleData.eleValueTypeList,
            selectNode: state. eleData.eleValueType,
            dropDownConfiguration: state.eleData.dropDownConfiguration, // 用户输入的下拉框多级配置
          },
          mode_city: { // 城市
            dictionaryName:state.eleData.dictionarie,
            isSameSalesRegion: state.eleData.isSameSalesRegion,
            urbanAreaLevel: state.eleData.urbanAreaLevel,
          },
          mode_occupation: { // 职业
            dictionaryName:state.eleData.dictionarie,
          },
          mode_time: {  // 时间
            isDictionary:state.eleData.dictionValue,
            elementType:state.eleData.eleValueType,
            dictionaryName:state.eleData.dictionarie,
            timeMinValue : state.eleData.timeMinValue,
            timeMaxValue : state.eleData.timeMaxValue
          },
          mode_documentType:{ // 证件类型
            isDictionary:state.eleData.dictionValue,
            elementType:state.eleData.eleValueType,
            dictionaryName:state.eleData.dictionarie,
            certificateValue: state.eleData.certificateValue, // 选择证件的内容
            certificateOptions: state.eleData.certificateOptions // 证件可选全部内容
          },
          mode_health: { // 健康告知
            isCheck:  healthinfoConfig.value && healthinfoConfig.value.heaData.isCheck, // 元素标题
            keywordList: healthinfoConfig.value && healthinfoConfig.value.heaData.keywords ? healthinfoConfig.value.heaData.keywords : [], // 匹配词
            way: healthinfoConfig.value && healthinfoConfig.value.heaData.way ? healthinfoConfig.value.heaData.way : '', // 选择方式
            conSpanList: healthinfoConfig.value && healthinfoConfig.value.getHealthOptionList() ?  healthinfoConfig.value.getHealthOptionList() : [], // 配置选项
            // 健康感知的选择方式为  输入时
            healthInputConfig: inputObj ? inputObj : {}
          },
          mode_upload:{ // 上传
            specialUpload: uploadRef.value ? uploadRef.value.specialUpload : {}, // 特殊上传组件
            uploadData:uploadRef.value ? uploadRef.value.uploadData : {}, // 上传组件
          },
          // 告知模块
          mode_notification: {
            notificationList: state.notification && state.notification.notificationList? state.notification.notificationList : [],
            selectedRowList: state.notification &&  state.notification.selectedRowList? state.notification.selectedRowList : [],
          },
          // 开关元素
          mode_switch: {
            switchLeft: state.switchLeft,
            switchRight: state.switchRight
          },
        },
        selectedRowList: state.notification && state.notification.selectedRowList ? state.notification.selectedRowList : [],
        elementCode:state.eleData.elementCode,
        elementMinValueLength:state.eleData.elementMinValueLength,
        elementMaxValueLength:state.eleData.elementMaxValueLength,
        titlePos: state.eleData.rangeType,
        elementConfig: state.elementConfig,
        elementMode:state.eleData.elementType,
        elementHint:'元素提示语',
        elementTips:'校验提示',
        defaultRequired: state.eleData.defaultRequired,
        elementType:state.eleData.elementTypeData,
        elementVaule:{ // 元素值校验规则
          elementTypeValue:state.eleData.eleValueData,
          typeId:state.eleData.eleValueType,
          typeData: state.eleData.nodeObj.typeData,  //文本 或 数字
          nodeObj: state.eleData.nodeObj,
          rangeList: state.eleData.rangeList,
          fileUrl: state.eleData.fileUrl
        },
        isDictionary:state.eleData.dictionValue,
        dictionary: {
          dictionaryName:state.eleData.dictionarie,
          dictionaryValue:[]
        },
        firstValue:null,
        endValue: null,
        createId: store.getters['user/getUserId'],
        dictionaryName:state.eleData.dictionarie
      }
        request(getRequestURI('element_addElement'), params).then(res=>{
            if(res.code === 1) {
              message.success('保存成功')
              props.handleSearch(props.searchfFilters,props.current)
              content.emit('refreshElementSuccess', params)
              onClose()
            }else {
              message.error(res.message)
            }
        })

    }

    // 关闭
    const onClose = () =>{
      state.eleData.elementTypeData = ''
     // formRef.value.resetFields()
    //  formRef.value.clearValidate()
      state.showImg.splice(0)
      state.elementConfig.isCheckPhone = false
      Object.assign(showData,{
        // 根据选择不同的属性,展示的多种状态
        inputShow: false,
        healthinfoShow: false,
        otherShow: false,
        dictionShow: true,
        isShow:false,
        isDown: false,
        isButtonShow: false,
        isRangeShow: false,
        isNodeShow: false,
        isInputNode: false,
        isCertificateShow: false,
        dropDownShow: false,
        isDropDownShow: false,
        uploadShow:false
      })
    }

    // 增加一行的回调
    const addRange = () =>{
      state.eleData.rangeList.push({startData: undefined, endData: undefined})
    }
    // 删除一行的回调
    const delRange = () =>{
      if(state.eleData.rangeList.length <= 1) {
        return message.warning('手下留情, 再点就没啦')
      }
      state.eleData.rangeList.pop()
    }

    // 用于校验元素值类型 的输入框
    // const elementValueVerification = (eleValueType) =>{
    //   // console.debug(state.eleData.eleValueTypeList, eleValueType)
    //   state.eleData.eleValueTypeList.forEach( item => {
    //     if (item.value === eleValueType && item.isCheck) {
    //       let check = vTools(item.name, state.eleData.nodeObj.typeData)
    //       if (check) {
    //         state.eleData.nodeObj.typeData = ''
    //         throw 'err'
    //       }
    //       return true
    //     }
    //   })
    // }
    const getUpdateData = (editData) => {
      // let obj = props.editData
      state.notification = editData.elementOperatingMode.mode_notification
      state.switchLeft = editData.elementOperatingMode.mode_switch?.switchLeft ?? ''
      state.switchRight = editData.elementOperatingMode.mode_switch?.switchRight ?? ''
      state.eleData.defaultRequired =  editData.defaultRequired || 'N'
      state.eleData.fileUrl = editData.elementVaule.fileUrl
      let minTime = ''
      let maxTime = ''
      if(editData.elementVaule.typeId == '6'){
        minTime = dayjs(editData.elementOperatingMode.mode_time.timeMinValue)
        maxTime = dayjs(editData.elementOperatingMode.mode_time.timeMaxValue)
      }if(editData.elementVaule.typeId == '4'){
        minTime = dayjs(editData.elementOperatingMode.mode_time.timeMinValue)
        maxTime = dayjs(editData.elementOperatingMode.mode_time.timeMaxValue)
      }
      // 先把对应的数据都赋值
      Object.assign(state.eleData, {
        id:editData.id,
        eleTitle:editData.elementOperatingMode.elementTitle, // 元素标题
        elementName: editData.elementName,
        elementCode: editData.elementCode, // 随机编码
        elementType: editData.elementOperatingMode.mode_id, // 元素类型
        elementTypeData:editData.elementOperatingMode.elementTypeData, // 元素类型的值
        eleValueType:editData.elementVaule.typeId, // 元素值校验规则
        eleValueData: editData.elementVaule.typeData, // 元素值的数据
        dictionarie:editData.dictionary.dictionaryName, // 字典
        dictionValue: JSON.parse(editData.isDictionary), // 是否关联字典
        elementMaxValueLength: editData.elementMaxValueLength, // 元素最大长度
        elementMinValueLength: editData.elementMinValueLength, // 元素最小长度
        eletype:editData.elementState * 1, // 元素状态
        rangeType:editData.titlePos * 1, //显示排列方式
        childNode: editData.elementOperatingMode.mode_select.checkNode, // 是否选择子节点
        minSelection: editData.elementOperatingMode.mode_config.multipleMinNum, // 多选最小数量
        maxSelection: editData.elementOperatingMode.mode_config.multipleMaxNum, // 多选最大数量
        rangeList:editData.elementVaule.rangeList,  // 元素值中存放范围的数组
        timeChackValue : editData.elementOperatingMode.mode_time.timeChackValue,
        timeMinValue: minTime,
        timeMaxValue: maxTime,
        nodeObj:{...editData.elementVaule.nodeObj, nodeDate: dayjs(editData.elementVaule.nodeObj.nodeDate)},
        certificateValue: editData.elementOperatingMode.mode_documentType.certificateValue, // 证件类型自选的值
        dropDownConfiguration:editData.elementOperatingMode.mode_select.dropDownConfiguration,
        buttonOptionsList: editData.elementOperatingMode.mode_config.buttonOptionsList,
        urbanAreaLevel: editData.urbanAreaLevel,
        isSameSalesRegion: editData.isSameSalesRegion
      })
      Object.assign(state.elementConfig,editData.elementOperatingMode.elementConfig)
      // 去判断 对应的元素类型  那些需要设置为true
      setDiction(editData, editData.isDictionary)
    }
    const setDiction = (editData, isDic) => {
      showData.attach = false
      const {mode_id} = editData.elementOperatingMode
      const {typeId} = editData.elementVaule
      if(mode_id === '1') {
        showData.otherShow = true
        showData.isDown = true
        if(isDic === 'false'){
          showData.isDropDownShow = true
        } else {
          showData.isShow = true
        }

      } else if(mode_id === '0') {
        showData.inputShow = true
        handleeleValueType(typeId,editData)
        handeShowEleValue(editData.elementOperatingMode.mode_id)
      } else if((mode_id ===  '2' || mode_id === '3') && isDic === 'false'){
        showData.otherShow = true
        showData.isButtonShow = true
      } else if(mode_id ===  '4' || mode_id === '5'){
        // showData.isShow = true
        // console.debug(editData.elementOperatingMode)
        selectByFirst(editData.elementOperatingMode.mode_id)
      } else if(mode_id === '6'){
        handeShowEleValue(editData.elementOperatingMode.mode_id)
        handleeleValueType(typeId,editData)
        showData.dictionShow = false
        showData.inputShow = true
        showData.otherShow = false
      } else if(mode_id === '99'){
        showData.healthinfoShow = true
        nextTick(() => {
          let {heaData} = healthinfoConfig.value
          let {mode_health} = editData.elementOperatingMode
          // 判断健康告知的 选择方式(单选 1  输入 2)
          Object.keys(heaData).forEach(key => {
            heaData[key] = mode_health[key]
          })
          heaData['expressionValue'] = editData['expressionValue']
          heaData.keywords = mode_health.keywordList ?? []
          heaData.configureList = []
          console.debug('健康告知',heaData,mode_health)
          if(mode_health.way === 1 || mode_health.way === 3) {
            healthinfoConfig.value.isSpan = true
           // console.debug(healthinfoConfig.value.healthOptionRef)
           let conSpanList = JSON.stringify(mode_health.conSpanList)
           let optionItemList = JSON.parse(conSpanList)
           healthinfoConfig.value.healthOptionRef.optionItemList = optionItemList
          }else if(mode_health.way === 2){
            let {strArrayStr,strArray} = mode_health.healthInputConfig
            let {tabList} =  mode_health.healthInputConfig.modelPropData
            healthinfoConfig.value.isImport = true
            healthinfoConfig.value.inputConfig.titleStr = mode_health.healthInputConfig.titleStr
            healthinfoConfig.value.inputConfig.strArray = strArray
            healthinfoConfig.value.inputConfig.strArrayStr = strArrayStr
            healthinfoConfig.value.inputConfig.isUpdate = true
            healthinfoConfig.value.inputConfig.modelPropData = mode_health.healthInputConfig.modelPropData
            healthinfoConfig.value.inputConfig.updateTabList  = mode_health.healthInputConfig.modelPropData.tabList
            healthinfoConfig.value.inputConfig.showInF.initShowFData(strArrayStr, strArray,tabList)
          }
        })
      } else if(mode_id === '7' && isDic === 'false'){
        showData.isCertificateShow = true
        getCertificateData()
        showData.otherShow = true
      }else if(mode_id === '101' || mode_id === '100'){
        showData.uploadShow = true
        getDetail(editData.id)
      }else if(mode_id == '103'){
        showData.inputShow = false
        showData.otherShow = false
      }else if(mode_id == '104' || mode_id === '105'){
        showData.isShow = false
      } else if(mode_id=== '15') {

        showData.attach = true
        showData.otherShow = false
        let obj = {}
        obj.url = editData.elementVaule.fileUrl
        state.showImg.push(obj)
      }
      else {
        showData.isShow = true
        showData.otherShow = true
      }
    }

    const uploadCosFileChange=(data)=>{
      state.eleData.fileUrl = data.url

    }
    // 调用详情接口
    const getDetail = (id) =>{
      request(getRequestURI('element_getElementById'), {
        id:id
      }).then(result => {
        let {mode_upload} = result.result.elementOperatingMode
        uploadRef.value.uploadData = mode_upload.uploadData
        uploadRef.value.specialUpload = mode_upload.specialUpload
      })
    }

    // 日期范围  起始时间失去焦点
    const onBlur = e => {
      state.conversion = e.target.value
    }
    // 日期 禁用
    const disabledDate = (current) => {
      return current && current < dayjs(state.conversion)
    }
    const onDateChange = (index) => {
      state.eleData.rangeList[index].endData = undefined
    }
    // 数字范围 / 时间范围
    const endDataBlur = (e,index) =>{
      if(e.target.value < state.conversion) {
        onDateChange(index)
        return message.warning('请选择正确的范围')
      }
    }

    // 新增 按钮配置选项
    const addButtonOption = () => {
      state.eleData.buttonOptionsList.push({
        value: state.eleData.buttonOptionsList.length +1,
        label: ''
      })
    }
    // 删除 按钮配置选项
    const delButtonOption = () => {
      if(state.eleData.buttonOptionsList.length <= 1) {
        return message.warning('手下留情, 再点就没啦')
      }
      state.eleData.buttonOptionsList.pop()
    }

    // 下拉框的多级展示
     const validate = async (editor) => {
      const res = await editor.validate()
      // res 是错误列表，如果是空数组，则表示检测没有错误
      console.debug(res)

    }
    const dropDown = ref()
    // 展示Json演示框
    const showJson = () => {
      dropDown.value.showDrawer(state.dropDownList)
    }




    onMounted(()=> {

    })
    return {
      notificationRef,
      handelNotification,
      handelSaveSelectList,
      getCertificateData,
      onBlur,
      dropDown,
      disabledDate,
      endDataBlur,
      addButtonOption,
      delButtonOption,
      validate,
      showJson,
      setDiction,
      getDetail,
      onFinish,
      onFinishFailed,
      getElementObj,
      setRandomId,
      focus,
      handleChange,
      handleeleValueType,
      handleDiction,
      formItemLayout,
      onChange,
      onClose,
      handleOk,
      formRef,
      healthinfoConfig,
      uploadRef,
      getUpdateData,
      addRange,
      delRange,
      formRules,
      selectByFirst,
      ...toRefs(state),
      ...toRefs(showData),
      uploadCosFileChange,
      urbanAreaLevelList
    }
  }
})
</script>

<style scoped>
  .inputWidth{
    width: 315px;
  }
  .editor{
    height: 300px;
    width: 430px;
  }
</style>
