<!--
操作指南
引用组件 =>
           <upload-file-view filetype="img" :showImg="showImg" listType="picture" @fileChange="fileChange"></upload-file-new>
组件参数 =>  filetype:上传的类型 img 、pgf、xls、amr后续可新增
           showImg: 回显的数据 => 数组对象[{url:''}]
           fileChange: 接收上传成功后返回的数据
           listType:展示样式   picture-card 、picture
           num:上传1张，2张.......
-->
<template>
  <div>
    <a-upload
      v-model:file-list="fileList"
      list-type="picture-card"
      :multiple="true"
      :max-count="fileData.num"
      @change="handleChange"
      @preview="handlePreview"
      :customRequest="uploadFile"
      :accept="fileData.filetypeL"
      style="margin-bottom:15px"
      :beforeUpload="beforeUpload"
      :showUploadList="true"
    >
      <div v-if="fileList.length < num">
        <PlusOutlined/>
        <div style="margin-top: 8px">上传</div>
      </div>
      <template #itemRender="{ file,fileList}" v-if="filetype === '视频'">
        <!-- v-if="filetype == '视频'" -->
          <div style="width:100%;height:100%">
            <img style="width:100%;height:100%"
            :src="getImg(file,fileList)"
            alt="正在飞速加载......"
            >
            <!-- <img style="width:100%;height:100%"
            v-if="filetype !== '视频'"
            :src="
                fileData.previewVideoImgUrl === ''
                  ? 'https://uploaduat-10051630.cos.ap-shanghai.myqcloud.com/upload/common/scrm/2021/04/09/dd5daa91_48cc_40f6_9bc3_40321d43eae8%E5%BE%AE%E4%BF%A1%E5%9B%BE%E7%89%87_20201121173557.jpg'
                  : fileData.previewVideoImgUrl
              "
            alt="正在飞速加载......"
            > -->
          </div>
          <p style="text-align:center;">{{ file.name }}</p>
      </template>

    </a-upload>
    <!-- 打开文件夹的弹窗 -->
    <a-modal :visible="previewVisible" :title="previewTitle" :footer="null" @cancel="handleCancel">
      <img alt="example" style="width: 100%" :src="previewImage" />
    </a-modal>
  </div>
</template>

<script>
import {request} from '../../../../../utils/request'
import { defineComponent, ref, onMounted, reactive} from 'vue'
import { PlusOutlined   } from '@ant-design/icons-vue'
import { message } from 'ant-design-vue'
let COS = require('cos-js-sdk-v5')

function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => resolve(reader.result)
    reader.onerror = error => reject(error)
  })
}
export default defineComponent({
  components: {
    PlusOutlined,
    // UploadOutlined
  },
  props:{
    filetype:{
      type:String,
      required:true,
      default(){
        return ''
      }
    },
    showImg:{
      type:Array,
      required:false,
      default(){
        return []
      }
    },
    listType:{
      type:String,
      required:true,
      default(){
        return 'picture-card'
      }
    },
    disabled: {
      type: Boolean,
      required: false,
      default(){
        return false
      }
    },
    num:{
      type: [Number, String],
      required:true,
      default(){
        return 1
      }
    },
    enclosureName:{
      type: String,
      default:() => ''
    },
    selectList:{ // 限制的类型
      type: Array,
      default:() => []
    }
  },
  emits: ['fileChange'],
  setup(props, { emit }) {
    const handleChange = info => {
      console.log('看看这是返回啥',info)
    }
    const previewVisible = ref(false)
    const videoPreviewVisible = ref(false)
    const previewImage = ref('')
    const previewTitle = ref('')

    onMounted(() => {
      //限制的类型进行遍历
      fileData.filetypeL = props.selectList.join()
      console.log('限制的类型', fileData.filetypeL)
    })



    const fileList = ref(props.showImg)
    // 点击文件链接或预览图标时的回调
    const handlePreview = async file => {
      console.log('这个是啥方法', file)
      if (props.filetype == 'pdf') {
        // console.log(file.url)
        window.location = file.url
      }
      if (props.filetype == 'xlsx') {
        window.location = file.url
      }
      if (!file.url && !file.preview) {
        file.preview = await getBase64(file.originFileObj)
      }
      previewImage.value = file.url || file.preview
      if(props.filetype === 'amr'){
        previewVisible.value = false
      }else{
        previewVisible.value = true
      }
      previewTitle.value = file.name || file.url.substring(file.url.lastIndexOf('/') + 1)
    }
    const handleCancel = () => {
      previewVisible.value = false
      previewTitle.value = ''

    }
    //此页面内部使用数据
    const fileData = reactive({
      num: props.num*1,
      previewVideoImgUrl:'',  // 视频, 文档, 压缩包 上传后的img
      coverurl:'',
      filetypeL:'', // 上传时限制的类型
      bucket:'',
      region:'',
      folder:'',
      TmpSecretId:'',
      TmpSecretKey:'',
      XCosSecurityToken:'',
      StartTime:'',
      ExpiredTime:'',
      uploadFileId:'uploadId',
      fileArr:[],
      ScopeLimit:false,
      showProgress:false,
      // listType:props.listType
    })
    const getUUID = () => {
      let s = []
      let hexDigits = '0123456789abcdef'
      for (let i = 0; i < 36; i++) {
        s[i] = hexDigits.substr(Math.floor(Math.random() * 0x10), 1)
      }
      s[14] = '4' // bits 12-15 of the time_hi_and_version field to 0010
      s[19] = hexDigits.substr((s[19] & 0x3) | 0x8, 1) // bits 6-7 of the clock_seq_hi_and_reserved to 01
      s[8] = s[13] = s[18] = s[23] = '_'
      let uuid = s.join('')
      return uuid
    }
  // 上传腾讯云
  const uploadFile = (e) =>{
      let file = e.file
      let filename = e.file.name
    // let fileNameInit = filename.split('.')[1]
    fileData.showProgress = true
    const uuid = getUUID()
    console.log('uuid',uuid)
    // console.info('上传位置:' + bucket)
    let uri = '/product-service/product/cos/getTempToken'
    let param = {
      'data': {
        'type': ''
      },
    }
    if (uri) {
      return request(uri, param).then(result => {
        console.log('请求结果:' + JSON.stringify(result))
        if (result.code === 1) {
          fileData.bucket = result.result.app_cosBucket
          fileData.region = result.result.app_cosRegion
          fileData.folder = result.result.app_cosFolder + getSimpleDate() + '/'
          fileData.TmpSecretId = result.result.credentials.tmpSecretId
          fileData.TmpSecretKey = result.result.credentials.tmpSecretKey
          fileData.XCosSecurityToken = result.result.credentials.sessionToken
          fileData.StartTime = result.result.startTime
          fileData.ExpiredTime = result.result.expiredTime
          fileData.ScopeLimit = true
          uploadCosFile(file, filename, uuid)
        }
      })
    }
  }
  const uploadCosFile = (file, filename, value1) =>{
    const cos = new COS({
      getAuthorization: function(options, abc) {
        abc({
          TmpSecretId: fileData.TmpSecretId,
          TmpSecretKey: fileData.TmpSecretKey,
          XCosSecurityToken: fileData.XCosSecurityToken,
          StartTime: fileData.StartTime, // 单位是秒
          ExpiredTime: fileData.ExpiredTime,
          ScopeLimit: fileData.ScopeLimit // 细粒度控制权限需要设为 true，会限制密钥只在相同请求时重复使用
        })
      }
    })
    cos.uploadFile(
      {
        Bucket: fileData.bucket /* 必须 */,
        Region: fileData.region /* 存储桶所在地域，必须字段 */,
        // Key: folder + uuid + filename, /* 必须 */
        Key: fileData.folder + value1 + '/' + filename/* 必须 */,
        StorageClass: 'STANDARD',
        SliceSize: 1024 * 1024 * 10, /* 触发分块上传的阈值，超过10MB使用分块上传，非必须 */
        Body: file, // 上传文件对象
        onTaskReady: function(taskId) { /* 非必须 */
          console.log(taskId)
        },
        onFileFinish: function (err, data, options) { /* 非必须 */
          console.log(options.Key + '上传' + (err ? '失败' : '完成'))
        },
        onProgress: function(progressData) {
          if (progressData) {
            fileData.percentage = progressData.percent
          }
        }
      },
      function(err, data) {
        fileData.showProgress = false
        console.info(err)
        if (data && data.statusCode === 200) {
          fileData.fileArr = {'enclosureName': filename, 'url': 'https://' + data.Location, 'id': fileData.uploadFileId}
          fileList.value.forEach( (item) =>{
            item.status = 'down'
          })
          getVideoBase64(fileData.fileArr.url)
          emit('fileChange', fileData.fileArr)
        } else {
          console.log('请求失败')
        }
      }
    )
  }

  const getSimpleDate = (_label = '/') => {
    let date = new Date()
    let year = date.getFullYear()
    let month = date.getMonth() + 1
    let strDate = date.getDate()
    if (month >= 1 && month <= 9) {
      month = '0' + month
    }
    if (strDate >= 0 && strDate <= 9) {
      strDate = '0' + strDate
    }
    return year + _label + month + _label + strDate
  }

      // 获取视频第一帧
    const getVideoBase64 = (url) => {
      // return new Promise(function (resolve) {
            let dataURL = ''
            let video = document.createElement('video')
            video.setAttribute('crossOrigin', 'anonymous')//处理跨域
            video.setAttribute('src', url)
            video.setAttribute('width', 400)
            video.setAttribute('height', 240)
            video.setAttribute('preload', 'auto')
            video.currentTime = 1 // 第一帧
            video.addEventListener('loadeddata', () =>{
              let canvas = document.createElement('canvas')
              let width = video.width  //canvas的尺寸和图片一样
              let height = video.height
              canvas.width = width
              canvas.height = height
              canvas.getContext('2d').drawImage(video, 0, 0, width, height) //绘制canvas
              dataURL = canvas.toDataURL('image/jpeg') //转换为base64
              fileData.previewVideoImgUrl = dataURL
              Object.assign(fileList.value[fileList.value.length-1],{img:dataURL})
              // resolve(dataURL)
            })
        // })
    }
    const getImg = (file, list) => {
      let arr = list.filter(item =>{
        return item.uid === file.uid
      })
      return arr[0].img
    }



  // 限制上传类型
  const beforeUpload = file => {
    // console.log(file)
      let fileType = file.name.split('.').pop() // 得到上传的后缀名
      // 强制上传某种类型的文件
      let format = props.selectList.map(item => item.toLocaleUpperCase())
      if(!format.includes('.' + fileType.toLocaleUpperCase())){
        message.warning(`请上传 ${format} 格式的文件`)
        return false
      }else {
        return true
      }
  }
  return {
    getImg,
    getVideoBase64,
    beforeUpload,
    videoPreviewVisible,
    uploadCosFile,
    uploadFile,
    fileData,
    props,
    fileList,
    headers: {
      authorization: 'authorization-text',
    },
    handleChange,
    handlePreview,
    previewVisible,
    previewImage,
    handleCancel,
    previewTitle,
  }
  },
})


</script>

<style scoped>
/* you can make up upload button and sample style by using stylesheets */
.ant-upload-select-picture-card i {
  font-size: 32px;
  color: #999;
}

.ant-upload-select-picture-card .ant-upload-text {
  margin-top: 8px;
  color: #666;
}

:deep(.ant-upload-list) :deep(.ant-upload-list-picture-card-container) {
  margin-bottom:30px !important;
}

</style>
