<template>
  <search-form
    :Condition="condition"
    :ColSpan="12"
    @callback="handleSearch"
  ></search-form>
  <div class="btn-bar">
    <a-button type="primary" @click="addPro">
      <template #icon>
        <FileAddOutlined />
      </template>
      新增流程
    </a-button>
  </div>
  <div>
    <!-- :row-selection="{
        selectedRowKeys: selectedRowKeys,
        onChange: onSelectChange,
      }" -->
    <a-table
      :columns="columns"
      :data-source="dataSource ? dataSource['result'] : []"
      :pagination="pagination"
      style="padding: 0 30px"
      :row-key="(record) => record.id"
      @change="handleTableChange"
      :loading="loading"
    >
      <template #bodyCell="{ column, record, index }">
        <template v-if="column.key === 'index'">
          {{ (pagination.current - 1) * pagination.pageSize + index + 1 }}
        </template>
        <template v-if="column.key === 'operation'">
          <div>
            <a-space>
              <a-button
                type="primary"
                @click="handelEdit(record, 1)"
                size="small"
              >
                <template #icon><FormOutlined /></template>修改
              </a-button>
              <a-button
                type="primary"
                @click="handelPreview(record)"
                size="small"
              >
                <template #icon><EyeOutlined /></template>预览
              </a-button>
              <a-button
                type="primary"
                @click="handelUpdateStatus(record)"
                size="small"
              >
                <template #icon><setting-two-tone /></template>更新状态
              </a-button>
              <a-button
                type="primary"
                @click="handelNodeConfig(record)"
                size="small"
              >
                <template #icon><setting-two-tone /></template>节点配置
              </a-button>
              <a-button
                type="primary"
                @click="handelDelData(record.id)"
                size="small"
                danger
              >
                <template #icon><LinkOutlined /></template>删除
              </a-button>
            </a-space>
          </div>
        </template>
      </template>
    </a-table>
  </div>
  <PreviewShow ref="preview"></PreviewShow>
  <ProConfiguration
    ref="proConfiguration"
    :handleSearch="handleSearch"
    :searchfFilters="searchfFilters"
    :current="pagination.current"
  ></ProConfiguration>
  <a-modal v-model:visible="isUpstatus" title="更新状态" @ok="handleOk">
    <a-select v-model:value="status" style="width: 300px">
      <a-select-option value="0">暂存</a-select-option>
      <a-select-option value="1">启用</a-select-option>
      <a-select-option value="2">禁用</a-select-option>
    </a-select>
  </a-modal>
  <process-node-view ref="processNodeRef" />
</template>

<script>
import SearchForm from '../../../components/extended/search_form'
import ProConfiguration from './proConfiguration.vue'
import ProcessNodeView from './preview/processNodeView'
import { defineComponent, reactive, onMounted, toRefs, computed, defineAsyncComponent, ref, provide } from 'vue'
import { requestUsePagination, getRequestURI, request } from '../../../utils/request.js'
// import {getDictionaryValue} from '../../../utils/request_extend'
import { message, Modal } from 'ant-design-vue'
export default defineComponent({
  components: {
    SearchForm,
    ProcessNodeView,
    ProConfiguration,
    PreviewShow: defineAsyncComponent(() => import('./preview/previewShow.vue')),

  },
  setup () {
    const state = reactive({
      condition: {
        name: {
          label: '流程名称',
          placeholder: '请输入流程名称'
        }
      },
      lastFilter: {},
      columns: [
        {
          title: '序号',
          width: 60,
          align: 'center',
          key: 'index',
        },
        {
          title: '流程名称',
          width: 200,
          align: 'center',
          key: 'name',
          dataIndex: 'name'

        },
        {
          title: '状态',
          width: 200,
          align: 'center',
          key: 'statusName',
          dataIndex: 'statusName',
          // customRender: (text)=> getDictionaryValue('module_status', text.value)
          //   customRender: (text) => {
          //   if (text.text === '0') {
          //     return '暂存'
          //   } else if (text.text === '1') {
          //     return '启用'
          //   } else if (text.text === '2') {
          //     return '禁用'
          //   }
          // }
        },
        {
          title: '创建时间',
          width: 200,
          align: 'left',
          key: 'createDate',
          dataIndex: 'createDate',
          customRender: (text) => {
            var time = new Date(+new Date(text.text) + 8 * 3600 * 1000).toISOString().replace(/T/g, ' ').replace(/\.[\d]{3}Z/, '')
            return time
          }
        },
        {
          title: '操作',
          width: 200,
          align: 'left',
          key: 'operation',
          dataIndex: 'operation'
        },
      ],
      selectedRowKeys: [],
      isUpstatus: false,
      status: undefined,
      statusId: ''
    })
    const preview = ref()
    const {
      data: dataSource,
      run,
      loading,
      current,
      pageSize,
      total,
    } = requestUsePagination(getRequestURI('process_processList'))
    //页面挂载调用数据
    onMounted(() => {
      handleSearch({})
    })
    // 存储搜索组件传过来的数据
    const searchfFilters = ref({})
    // 查询
    const handleSearch = function (filters, num = '') {
      if (filters) {
        searchfFilters.value = filters
      }
      state.lastFilter = filters
      run({
        pagination: {
          current: num ? num : 1,
          pageSize: 10
        },
        data: filters,
      })
    }
    provide('handleSearch', handleSearch)
    // 删除的时候用
    const reloadList = () => {
      run({
        pagination: {
          current: current.value,
          pageSize: pageSize.value
        },
        data: state.lastFilter,
      })
    }
    const pagination = computed(() => ({
      current: current.value,
      pageSize: pageSize.value,
      total: total.value,
    }))
    const handleTableChange = (pag, filters, sorter) => {
      console.debug('handleTableChange', pag, filters, sorter)
      run({
        pagination: {
          current: pag?.current,
          pageSize: pag?.pageSize
        },
        sorter,
        data: state.lastFilter,
      })

    }

    //多选
    const onSelectChange = (selectedRowKeys, selectedRow) => {
      console.debug(selectedRowKeys, selectedRow)
      // state.data.splice(0)
      // console.debug('selectedRowKeys changed: ', selectedRowKeys)
      state.selectedRowKeys = selectedRowKeys
      // state.data = selectedRow
      // state.datas = selectedRow
      // console.debug(state.datas,'789889')
      // console.debug(state.result,999999)
    }
    //删除
    const handelDelData = function (id) {
      console.debug(id)
      Modal.confirm({
        title: '删除流程',
        content: '是否删除已选择流程？',
        onOk: () => {
          request(getRequestURI('process_delete'), { id }).then(res => {
            if (res.code === 1) {
              reloadList()
              message.success('删除成功')
            } else {
              message['error'](res.message)
            }
          })
        },
        onCancel: () => {
          Modal.destroyAll()
        }
      })
    }

    // 预览
    const handelPreview = (record) => {
      preview.value.showDrawer(record)
    }
    const proConfiguration = ref()
    // 新增
    const addPro = () => {
      proConfiguration.value.showDrawer()
    }
    // 更新状态
    const handelUpdateStatus = (record) => {
      console.debug(record)
      state.isUpstatus = true
      state.status = record.status
      state.statusId = record.id

    }
    const handleOk = () => {
      request(getRequestURI('process_updateStatus'), {
        id: state.statusId,
        status: state.status
      }).then(
        res => {
          if (res.code === 1) {
            state.isUpstatus = false
            message.success('更新成功')
            handleSearch(searchfFilters.value)
          } else {
            message.warning(res.message)
          }

        })
    }

    const handelEdit = (oldData, num) => {
      proConfiguration.value.showDrawer(oldData, num)
    }

    const processNodeRef = ref()
    // 节点配置
    const handelNodeConfig = (oldData) => {
      processNodeRef.value.showDrawer(oldData)

    }

    return {
      ...toRefs(state),
      handelEdit,
      handelNodeConfig,
      handleSearch,
      handelDelData,
      handleTableChange,
      onSelectChange,
      handelPreview,
      addPro,
      handelUpdateStatus,
      handleOk,
      processNodeRef,
      proConfiguration,
      pagination,
      loading,
      preview,
      dataSource,
      searchfFilters
    }
  }
})
</script>

<style scoped>
.btn-bar {
  margin-left: 30px;
}
</style>
