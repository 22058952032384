<style scoped>

</style>

<template>
  <a-drawer
    :title="panDuan"
    :width="'40%'"
    :visible="visible"
    :body-style="{ paddingBottom: '80px' }"
    :footer-style="{ textAlign: 'right' }"
    @close="onClose"
  >
    <a-form
      :model="formDataOne"
      layout="vertical"
      ref="formRef2"
      autocomplete="off"
    >
      <a-row :gutter="10">
        <a-col span="24" >
          <a-form-item label="上传文件:"
                       :rules="[{required:true,message:'必填信息',trigger:'change'}]">
            <upload-files @getFileList="getFileList" ref="upFile" @handelDel="handelDel" v-if="!isUpdateUpload"  :disabled="zhuangTai2" :filetype="editFileType" :showImg="showImg" @fileChange="uploadCosFileChange" :key="time" num="1" />
            <div class="updateUpload" v-if="isUpdateUpload">
              <a href="javascript:void(0)" @click="onPreviewFile(formDataOne)">{{formDataOne.uploadFileName}}</a>
              <DeleteOutlined style="margin-left: 10px" @click="handleRemove"/>
            </div>
          </a-form-item>
        </a-col>
        <a-col span="24">
          <a-form-item label="文件显示名称:" name="uploadFileName"
                       :rules="[{required:true,message:'必填信息',trigger:'change'},
          {max: 100, message: '输入长度不能超过100' ,trigger: 'change'}]"
          >
            <a-input v-model:value="formDataOne.uploadFileName" :disabled="zhuangTai2" placeholder="请选择显示名称"/>
          </a-form-item>
        </a-col>
        <a-col span="24">
          <a-form-item label="文件显示位置:" name="mustReadOrder"
                       :rules="[{required:true,message:'必填信息',trigger:'change'}]"
          >
            <a-select v-model:value="formDataOne.mustReadOrder"  placeholder="请选择文件显示位置"
                      :options="mustReadOrderOne" :disabled="zhuangTai2" >
            </a-select>
          </a-form-item>
        </a-col>
        <a-col span="24">
          <a-form-item label="必读文件显示顺序:" name="fileOrder"
                       :rules="[{required:true,message:'必填信息',trigger:'change'},
          {max: 3, message: '输入长度不能超过3' ,trigger: 'change'},
           { pattern:/^\+?[1-9]\d*$/, message: '支持正整数格式' ,trigger: 'change'}]"
          >
            <a-input v-model:value="formDataOne.fileOrder"   :disabled="zhuangTai2"  placeholder="请选择必读文件显示顺序"/>
          </a-form-item>
        </a-col>
      </a-row>

    </a-form>
    <template #extra>
      <a-space>
        <a-button @click="onClose">
          <template #icon>
            <CloseOutlined/>
          </template>
          关闭
        </a-button>
        <a-button html-type="submit" type="primary" @click="onSave" v-if="!zhuangTai2">
          <template #icon>
            <SaveOutlined/>
          </template>
          保存
        </a-button>
      </a-space>
    </template>
    <a-drawer
          placement="bottom"
          :visible="popVisible"
          @close="closePopPage"
          height="100%"
          title="预览文件"
          :style="{zIndex: 9999999}"
        >
          <vue-pdf-embed :source="pdfSource"/>
        </a-drawer>
  </a-drawer>
</template>
<script>
import {SaveOutlined} from '@ant-design/icons-vue'
import {defineComponent, reactive, toRefs, ref, computed, onBeforeMount, watch, onMounted} from 'vue'
import {getRequestURI} from '../../../utils/request'
import {message} from 'ant-design-vue'
import uploadFiles from '../../../components/extended/uploadFileNew'
import { getDictionaryData } from '../../../utils/request_extend'
import VuePdfEmbed from 'vue-pdf-embed'
import {check_option, getCheckOptionLabelByNum, getCheckOptionValueByNum, parseCheck} from '../../../utils/utlis.js'

export default defineComponent({
  components: {
    SaveOutlined,
    uploadFiles,
    VuePdfEmbed
  },
  props: {
    status: {
      type: String,
      required: true,
      default() {
        return '123'
      },
    }
  },
  emits:['type', 'getUploadFilesData', 'getEditUploadFilesData'],
  setup(props, {emit,expose}, context) {
    const formRef = ref()
    const formDataOne = reactive({
      mustReadOrderLabel: '',
      fileType: undefined,//文件资料类型
      uploadFile: '',//上传文件
      fileName: '',//文件显示名称
      riskId: '',//匹配的id
      riskCode:'',//险种编码
      uploadFileName:'',//上传文件名称
      id:'',
      mustReadOrder: undefined,//文件显示位置
      fileOrder:1,//必读文件显示顺序
    })
    const pdfState = reactive({
      popVisible: false,
      pdfSource: null,
      isUpdateUpload: false
    })
    const upFile = ref()
    // 删除
    const handleRemove = () =>{
      pdfState.isUpdateUpload = false
      state.showImg = []
      formDataOne.uploadFile = ''
      formDataOne.fileName = ''
      formDataOne.uploadFileName = ''
    }
    const handelDel = () =>{
      state.showImg = []
      formDataOne.uploadFile = ''
      formDataOne.fileName = ''
      formDataOne.uploadFileName = ''
    }
    // 获取到对应的预览文件
    const onPreviewFile =(file) =>{
      let index = file.uploadFileName.substr(-3)
      if(index === 'pdf'){
        handelPdf(file)
      }else{
        window.location = file.uploadFile
      }
    }
    const handelPdf = (file) =>{
      pdfState.popVisible = true
      pdfState.pdfSource = file.uploadFile

    }

    // 预览文件关闭
    const closePopPage =() =>{
      pdfState.popVisible = false

    }
    const fileTypeOne = ref([])
    onBeforeMount(()=>{
      getDictionaryData(fileTypeOne,'risk_file_type')
    })
    const mustReadOrderOne = ref([])
    onBeforeMount(()=>{
      getDictionaryData(mustReadOrderOne,'mustReadOrderType')
    })

    const state = reactive({
      checkBox: [],
      checkOptions: [],
      zhuangTai2:false,
      showImg:[],
      status:'',
      time:null,
      visible: false,
      isNew: true,
      editFileType: ''
    })
    const handelFileTypeChange =(e) =>{
      Object.assign(formDataOne, {
        uploadFile: '',//上传文件
        fileName: '',//文件显示名称
        uploadFileName:'',//上传文件名称
        mustReadOrder:''
      })
      // 产品介绍 6  只支持上传图片  其他支持图片, PDF
      if(e === '6'){
        state.editFileType = 'img'
      }else{
        state.editFileType = 'productType'

      }
    }
    const changeCheckBox = (a) => {
      // a 是选中的数组
      formDataOne.mustReadOrder = parseCheck(a)
    }

    watch(() => formDataOne.mustReadOrder, (val) => {
      state.checkBox = getCheckOptionValueByNum(val)
    }, {immediate: true})
    const setType = (name) =>{
      //判断是不是图片类型的
      let type = name.substr(-3).toLowerCase()
      let type2 = name.substr(-4).toLowerCase()
      if(type !== 'jpg' && type !== 'png' && type !== 'svg' && type !== 'gif' &&
       type !== 'bmp' && type !== 'psd' && type2!== 'jpeg' && type2!== 'webp'){
        pdfState.isUpdateUpload = true
      }
    }

    const showDrawerOne = (oldData = {},newData = '') => {
      console.log(111111, oldData,formDataOne )
      state.time = new Date().getTime()
      if(newData=='chakan'){
        state.zhuangTai2=true
      }else {
        state.zhuangTai2=false
      }
      state.status=newData
      state.visible = true
      let obj = {}
      obj.url = decodeURIComponent(oldData.uploadFile)
      obj.enclosureName=oldData.uploadFileName
      state.showImg.push(obj)
      if (newData=='bianJi'){
        formDataOne.id=oldData.id
        formDataOne.riskId=oldData.riskId
        setType(oldData.uploadFile)
      }else {
        formDataOne.id=''
        formDataOne.riskId=oldData.id
      }
      state.isNew = !(typeof oldData.id === 'string' && oldData.id.length > 0)
      // console.log('editNewUser', state.isNew, oldData)
      Object.keys(formDataOne).forEach(key => {
        formDataOne[key] = oldData[key] || formDataOne[key]
      })
      if (newData=='newAdds'){
        state.showImg.splice(0)
      }
      if(formDataOne.fileType){
        formDataOne.fileType += ''
      }
      if(formDataOne.mustReadOrder){
        formDataOne.mustReadOrder += ''
      }

    }

    const uploadCosFileChange = (data) => {
      if(!formDataOne.fileType) return
      formDataOne.uploadFile = data.url
      formDataOne.uploadFileName=data.enclosureName
      formDataOne.fileName = data.enclosureName
    }

    const onFinish = (values) => {
      // console.log('search-form-state: ', formState)
      context.emit('callback', values)
    }
    //title判断状态
    const panDuan = computed(() => {
      if (state.status === 'bianJi') {
        return '资料上传信息编辑'
      } else {
        return '资料上传信息新增'
      }
    })

    //关闭
    const onClose = () => {
      state.visible = false
      state.showImg.splice(0)
      state.time = new Date().getTime()
      formDataOne.fileType=  undefined//文件资料类型
      formDataOne.displayOrder= '' //显示顺序
      formDataOne.uploadFile= ''  //上传文件
      formDataOne.fileName='' //文件显示名称
      formDataOne.riskId= '' //匹配的id
      formDataOne.riskCode='' //险种编码
      formDataOne.uploadFileName='' //上传文件名称
      formDataOne.mustReadOrder= undefined//文件显示位置
      formDataOne.fileOrder=1//必读文件显示顺序
      formDataOne.id=''
      state.status=''
      formDataOne.mustReadOrderLabel = ''
      pdfState.isUpdateUpload = false
      emit('type', true)
    }
    //重置
    const onFormClean = () => {
      // formRef.value.resetFields()

    }
    //保存
    const onSave = () => {

      if(!formDataOne.uploadFileName){
        message['warning']('请输入文件显示名称')
        return
      }
      if(!formDataOne.uploadFile){
        message['warning']('请选择上传文件')
        return
      }else if(!formDataOne.mustReadOrder){
        message['warning']('请选择文件显示位置')
        return
      }else if(!formDataOne.fileOrder){
        message['warning']('请输入必读文件显示顺序')
        return
      }

      //文件显示名称校验
      let regbArray = formDataOne.uploadFileName.split('')
      if(regbArray.length > 100){
        message['warning']('显示顺序输入长度不能超过100')
        return
      }
      let data = {}
      if(state.status === 'newAdds') formDataOne.id = formDataOne.uploadFile
      // formDataOne.mustReadOrderLabel = mustReadOrderOne.value.filter(item => item.value === formDataOne.mustReadOrder)[0].label
      formDataOne.mustReadOrderLabel = getCheckOptionLabelByNum(mustReadOrderOne.value)
      data = formDataOne
      if(state.status === 'newAdds'){
        emit('getUploadFilesData', JSON.parse(JSON.stringify(data)))
      }else if(state.status === 'bianJi'){
        emit('getEditUploadFilesData', JSON.parse(JSON.stringify(data)))
      }
      onClose()
    }
    // 判断上传文件是否含有空格
    const getFileList = (info) =>{
      console.log(11111111, info.file.status)
      if (/\s/g.test(info.file.name) && (info.file.status === 'done' || info.file.status === 'uploading')) {
        handelDel()
        message.warning('上传的文件名不能包含空格')
      }
    }

    onMounted(() => {
      state.checkOptions = check_option
    })

    expose({
      showDrawerOne
    })
    return {
      getFileList,
      changeCheckBox,
      check_option,
      upFile,
      handelFileTypeChange,
      fileTypeOne,
      mustReadOrderOne,
      formRef,
      formDataOne,
      ...toRefs(state),
      ...toRefs(pdfState),
      onClose,
      onSave,
      onFormClean,
      getRequestURI,
      onFinish,
      showDrawerOne,
      uploadCosFileChange,
      panDuan,
      closePopPage,
      onPreviewFile,
      handleRemove,
      setType,
      handelDel
    }
  },

})
</script>
