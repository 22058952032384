<template>
  <a-drawer
    title="配置列表"
    :width="'55%'"
    :visible="visible"
    :body-style="{ paddingBottom: '80px' }"
    :footer-style="{ textAlign: 'right' }"
    @close="onClose"
  >
  <search-form
    :Condition="condition"
    :ColSpan="12"
    @callback="handleSearch"
  ></search-form>
  <!-- 表格数据 -->
  <a-table
    :columns="columns"
    :data-source="dataSource ? dataSource['result'] : []"
    :pagination="pagination"
    @change="handleTableChange"
    :row-key="(record) => record.id"
    :row-selection="{
      selectedRowKeys: selectedRowKeys,
      onChange: onSelectChange,
    }"
    :loading="loading"
    style="margin-top: 10px"
  >
    <a-spin :delay="200"/>
    <!-- record -->
    <template #bodyCell="{ column, index, }">
      <template v-if="column.key === 'index'">
        {{ (pagination.current - 1) * pagination.pageSize + index + 1 }}
      </template>
    </template>
  </a-table>
  <template #extra>
      <a-space>
        <a-button @click="onClose">
          <template #icon>
            <CloseOutlined/>
          </template>
          关闭
        </a-button>
        <a-button type="primary" @click="addToConfigure">
          <template #icon>
            <SaveOutlined/>
          </template>
          提交
        </a-button>
      </a-space>
    </template>
  </a-drawer>
</template>

<script>
import { defineComponent, reactive, toRefs,computed, onMounted } from 'vue'
import {requestUsePagination, getRequestURI} from '../../../utils/request.js'
import SearchForm from '../../../components/extended/search_form.vue'
export default defineComponent({
  components:{
    SearchForm
  },
  emits:['getElementList'],
  setup(props,context){
    const state = reactive({
      lastFilter:{},
      condition:{
        elementName: {
          label: '元素名称',
          placeholder: '请输入元素名称关键字',
        },
      },
      selectedRowKeys:[],
      selectedRowValues:[], // 选中的元素列表
      columns: [
        {
          key: 'index',
          title: '序号',
          width: 60,
          align: 'left',
        },
        {
          title: '元素名称',
          width: 150,
          key: 'elementName',
          dataIndex: 'elementName',
        },
        {
          title: '元素类型',
          width: 150,
          key: 'elementType',
          dataIndex: 'elementType',
        },
        {
          title: '元素状态',
          width: 150,
          key: 'elementState',
          dataIndex: 'elementState',
          customRender: (text) => {
            if(text.value === '0') {
              return '暂存'
            }else if(text.value === '1'){
              return '启用'
            }else if(text.value === '2'){
              return '禁用'
            }
          }
        },
        {
          title: '创建时间',
          width: 180,
          key: 'createTime',
          dataIndex: 'createTime',
          customRender:(text) =>{
            let time = new Date(+new Date((text.text)) + 8 * 3600 * 1000).toISOString().replace(/T/g, ' ').replace(/\.[\d]{3}Z/, '')
            return time
          }
        },
      ]
    })

    const showData = reactive({
      visible : false
    })


    const showDrawer = () => {
      showData.visible = true
      state.selectedRowKeys = []
      state.selectedRowValues = []
      handleSearch({})
    }
    const onClose = () =>{
      showData.visible = false

    }
    const pagination = computed(() => ({
      current: current.value,
      pageSize: pageSize.value,
      total: total.value,
    }))
    const {
      data: dataSource,
      run,
      loading,
      current,
      pageSize,
      total,
    } = requestUsePagination(getRequestURI('element_healthToldList'))
    const handleSearch = function (filters) {
      state.lastFilter = filters
      run({
        pagination: {
          current: current.value,
          pageSize: pageSize.value
        },
        data: filters,
      })
    }
    // 页面发生改变回调
    const handleTableChange = (pag, filters, sorter) => {
      run({
        pagination: {
          current: pag?.current,
          pageSize: pag?.pageSize
        },
        data: state.lastFilter,
        sorter,
      })
    }
    const onSelectChange = (selectedRowKeys, selectedRowValues) => {
      console.log('selectedRowKeys changed: ', selectedRowValues)
      state.selectedRowKeys = selectedRowKeys
      state.selectedRowValues = selectedRowValues
    }
    // 添加值已关联  提交
    const addToConfigure = () => {
      console.log(state.selectedRowValues)
      context.emit('getElementList',state.selectedRowValues)
      onClose()
    }




    onMounted(() =>{

    })


    return {
      ...toRefs(state),
      ...toRefs(showData),
      onClose,
      showDrawer,
      onSelectChange,
      addToConfigure,
      pagination,
      dataSource,
      loading,
      handleSearch,
      handleTableChange
    }
  }
})


</script>

<style  scoped>

</style>
