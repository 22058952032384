<template>
  <div>
    <search-form
      :Condition="condition"
      :ColSpan="8"
      @callback="handleSearch"
    ></search-form>
    <div class="btn-bar">
      <a-button type="primary">
        <template #icon><FileAddOutlined/></template>新增
      </a-button>
    </div>
    <!-- :row-selection="{
			selectedRowKeys: selectedRowKeys,
			onChange: onSelectChange,
      :data-source="dataSource ? dataSource['result'] : []"
		}" -->
    <a-table
      :columns="columns"
      :data-source="list"
      :row-key="(record) => record.id"
      :pagination="pagination"
      @change="handleTableChange"
      :loading="loading"
    >
      <template #bodyCell="{ column, index, record }">
        <template v-if="column.key === 'index'">
          {{ (pagination.current - 1) * pagination.pageSize + index + 1 }}
        </template>
        <template v-if="column.key === 'matchList'">
          <a-tag v-for="(match,num) in record.matchList" :key="num">{{match}}</a-tag>
        </template>
        <template v-if="column.key === 'operation'">
          <div>
            <a style="color: orange" @click="checkProduct(record,1)">查看</a>&nbsp;&nbsp;
            <a style="color: orange" @click="modifyProduct(record,2)">编辑</a>&nbsp;&nbsp;
            <a style="color: orange" @click="copyInformation(record)">删除</a>&nbsp;&nbsp;
          </div>
        </template>
      </template>
    </a-table>
  </div>
</template>

<script>
import { defineComponent, reactive, toRefs, computed } from 'vue'
import SearchForm from '../../../components/extended/search_form.vue'
import {requestUsePagination, getRequestURI} from '../../../utils/request.js'


export default defineComponent({
  components: {
    SearchForm
  },
  setup(){
    const state = reactive({
      condition:{
        informTitle:{
          label:'健康告知标题',
          type:'input',
          default: '',
        },
        displayForm:{
          label:'展示形式',
          type:'select',
          default: '',
          data:[
            {value: 1, label: '弹窗'},
            {value: 2, label: '长页面'},
            {value: 3, label: '单页面'},
          ]
        },
        supplierId: {
          label: '供应商名称',
          type: 'tree-select',
          urlKey:'querySupplier',
          value:'id',
          default: '',
          data: []
        },
        productClassify: {
          label: '产品分类',
          type: 'select',
          default: '',
          data: [
            {value: 0, label: '意外险'},
            {value: 1, label: '失业险'},
            {value: 2, label: '重疾险'},
          ]
        },
        productId: {
          label: '产品名称',
          type: 'select',
          default: '',
          data: [
            {value: 0, label: '人保'},
            {value: 1, label: '社保'},
            {value: 2, label: '没得保'},
          ]
        }
      },
      columns: [
        {
          title: '序号',
          width: 80,
          fixed: 'left',
          align: 'center',
          key: 'index',
          dataIndex: 'index'
        },
        {
          title: '标题',
          width: 140,
          align: 'center',
          key: 'title',
          dataIndex: 'title'
        },
        {
          title: '形式',
          width: 80,
          align: 'center',
          key: 'modality',
          dataIndex: 'modality'
        },
        {
          title: '是否校验',
          width: 80,
          align: 'center',
          key: 'isCheck',
          dataIndex: 'isCheck',
          customRender: (text) => {
            if (text.text === true) {
              return '是'
            } else {
              return '否'
            }
          }
        },
        {
          title: '匹配词列表',
          width: 140,
          align: 'center',
          key: 'matchList',
          dataIndex: 'matchList',
        },
        {
          title: '操作',
          key: 'operation',
          align: 'center',
          width: 140
        }
      ],
      list:[
        { id: '1',
          title: '是否抽烟',
          modality: '单选',
          isCheck: true,
          matchList: ['是']
        },
        { id: '2',
          title: '是否有遗传病史',
          modality: '多选',
          isCheck: true,
          matchList: [1,2,3]
        },
        { id: '3',
          title: '是否感染',
          modality: '父子级',
          isCheck: true,
          matchList: ['感冒', '发烧', '虚弱']
        },
      ]
    })




    const pagination = computed(() => ({
      current: current.value,
      pageSize: pageSize.value,
      total: total.value,
    }))
    const {
      data: dataSource,
      run,
      loading,
      current,
      pageSize,
      total,
    } = requestUsePagination(getRequestURI('ppqa_selectInfo'))
    const handleSearch = function (filters) {
      console.log('搜索参数==', filters)
      run({
        pagination: {
          current: current.value,
          pageSize: pageSize.value
        },
        data: filters,
      })
    }
    // 页面发生改变回调
    const handleTableChange = (pag, filters, sorter) => {
      console.log('===', filters)
      run({
        pagination: {
          current: pag?.current,
          pageSize: pag?.pageSize
        },
        data: filters,
        sorter,
      })
    }

    return {
      ...toRefs(state),
      dataSource,
      pagination,
      loading,
      handleSearch,
      handleTableChange,
    }
  }
})

  
</script>

<style  scoped>
  
</style>