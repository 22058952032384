<template>
  <div class="switch_class">
    <span class="text_class" v-if="switchLeft" :class="{active: !checked}">{{switchLeft}}</span>
    <a-switch  style="width: 48px;margin: 0 10px" v-model:checked="checked" :disabled="disabledType || isDisabled"/>
    <span class="text_class" v-if="switchRight" :class="{active: checked}">{{switchRight}}</span>
  </div>
</template>

<script>
import { defineComponent, onMounted, reactive, toRefs, watch } from 'vue'

export default defineComponent({
  props: {
    moduleIndex:{
      type: [Number,String],
      default:()=> '0'
    },
    moduleId:{ // 模块ID
      type: [Number,String],
      default:()=> ''
    },
    elementId:{ // 元素ID
      type: [Number,String],
      default:()=> ''
    },
    dictionary: {
      type: String,
      default: () => ''
    },
    title: {
      type:String,
      default: ()=> ''
    },
    switchData: {
      type: Object,
      default: () => {}
    },
    elementCode: {
      type: [Number,String],
      default: () => ''
    },
    isRequired:{
      type: [Number,String],
      default:()=> '0'
    },
    firstValue:{
      type: [Number,String, Boolean],
      default:()=> ''
    },
    disabledType: {
      type: Boolean,
      default:()=> false
    },
    elementName : {
      type: String,
      default: () => ''
    },
    isDisabled:{
      type: Boolean,
      default:()=> false
    }
  },
  emits:['callback','update:firstValue'],
  setup(props, context) {
    const state = reactive({
      checked: '',
      elementCode: props.elementCode,
      switchLeft: props.switchData?.switchLeft?? '',
      switchRight:props.switchData?.switchRight?? '',
    })

    watch(props,()=>{
      if(props.firstValue === '0') state.checked = false
      let type = ''
      type = props.elementCode === 'relationToAppnt' ? (state.checked ? '01' : '99') : (state.checked ? '1' : '0')
      let node = {}
        node[state.elementCode] = {
          [state.elementCode]: type,
          elementId: props.elementId,
          moduleType: props.moduleId,
          index:props.moduleIndex,
          required: props.isRequired,
          elementName: props.elementName,
        }
        context.emit('callback', node)
    }, {deep: true})
    watch(()=> state.checked,(newValue) =>{
      let type = props.elementCode === 'relationToAppnt' ? (newValue ? '01' : '99') : (newValue ? '1' : '0')
      let node = {}
        node[state.elementCode] = {
          [state.elementCode]: type,
          elementId: props.elementId,
          moduleType: props.moduleId,
          index:props.moduleIndex,
          required: props.isRequired,
          elementName: props.elementName,
        }
        context.emit('callback', node)
        context.emit('update:firstValue', type)
    })
    onMounted(()=>{
      state.checked = props.firstValue === '1' || props.firstValue === '01'
    })

    return{
      ...toRefs(state),

    }
  }
})
</script>

<style scoped>
.switch_class{
  text-align: right;
}
.text_class{
  display: inline-block;
  height: 100%;
  padding-top: 5px;
}
.active{
  color: #222;
}
</style>
