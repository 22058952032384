<style scoped>
</style>

<template>
  <a-drawer
    :title="panDuan"
    :width="'70%'"
    :visible="visible"
    :body-style="{ paddingBottom: '80px' }"
    :footer-style="{ textAlign: 'right' }"
    @close="onClose"
  >
    <a-form
      :model="formData"
      layout="vertical"
      ref="formRef2"
      autocomplete="off"
    >
      <div class="btn-bar">
        <a-button danger @click="search" v-if="!zhuangTai2">
          <template #icon>
            <SearchOutlined/>
          </template>
          查询责任库
        </a-button>
      </div>

      <a-row :gutter="10">
        <a-col span="12">
          <a-form-item label="责任编码:" name="dutyCode"
                       :rules="[{required:true,message:'必填信息',trigger:'change'},
          {max: 20, message: '输入长度不能超过20' ,trigger: 'change'},
          { pattern:/^[0-9a-zA-Z]+$/, message: '支持数字和字母格式' ,trigger: 'change'}
          ]"
          >
            <a-input v-model:value="formData.dutyCode" :disabled="zhuangTai2" placeholder="请输入责任编码"/>
          </a-form-item>
        </a-col>
        <a-col span="12">
          <a-form-item name="comDutyCode"
          >
            <template #label>
              <span>供应商责任编码: </span>&nbsp;
              <span>
                <a-popover placement="right">
                  <template #content>
                  <p>1.	此录入项允许为空
                    <br>2.	当此编码为空时，在执行接口平台映射时，会被过滤掉，只保留编码不为空的责任和保司进行交互</p>
                  </template>
                  <template #title>
                  <span>规则说明:</span>
                  </template>
                  <question-circle-outlined />
                </a-popover>
                </span>
            </template>
            <a-input v-model:value="formData.comDutyCode" :disabled="zhuangTai2" placeholder="请输入供应商责任编码"/>
          </a-form-item>
        </a-col>
        <a-col span="12">
          <a-form-item label="责任名称:" name="dutyName"
                       :rules="[{required:true,message:'必填信息',trigger:'change'},
          {max: 128, message: '输入长度不能超过128' ,trigger: 'change'}]"
          >
            <a-input v-model:value="formData.dutyName" :disabled="zhuangTai2" placeholder="请输入责任名称"/>
          </a-form-item>
        </a-col>
        <a-col span="12">
          <a-form-item label="责任类型:" name="dutyType"
                       :rules="[{required:true,message:'必填信息',trigger:'change'}]"
          >
            <a-select :options="dutyTypeOne" v-model:value="formData.dutyType" :disabled="zhuangTai2" placeholder="请选择责任类型" show-search :filter-option="filterOption">
            </a-select>
          </a-form-item>
        </a-col>
        <a-col span="12">
          <a-form-item label="界面是否显示:" name="isShowStatus"
                       :rules="[{required:true,message:'必填信息',trigger:'change'}]"
          >
            <a-select :options="isShowStatusList" v-model:value="formData.isShowStatus" :disabled="zhuangTai2" placeholder="请选择界面是否显示">
            </a-select>
          </a-form-item>
        </a-col>
        <a-col span="12">
          <a-form-item label="显示顺序:" name="sort"
                       :rules="[{required:true,message:'必填信息',trigger:'change'},
                       {max: 3, message: '输入长度不能超过3' ,trigger: 'change'},
          { pattern:/^\+?[1-9]\d*$/, message: '支持正整数格式' ,trigger: 'change'}
          ]"
          >
            <a-input v-model:value="formData.sort" :disabled="zhuangTai2" placeholder="请输入选择顺序"/>
          </a-form-item>
        </a-col>

        <a-col span="12">
          <a-form-item name="deductibleExcess"
                       :rules="[
          {max: 100, message: '输入长度不能超过100' ,trigger: 'change'}
          ]"
          >
            <template #label>
              <span>保额/免赔额（责任简述）:</span>&nbsp;
              <span>
                <a-popover placement="right">
                  <template #content>
                  <p>用于显示责任的保额/免赔额。当未设置投保保额列表时，产品详情页责任的保额会显示该值</p>
                  </template>
                  <template #title>
                  <span>规则说明:</span>
                  </template>
                  <question-circle-outlined />
                </a-popover>
                </span>
            </template>
            <a-input v-model:value="formData.deductibleExcess" :disabled="zhuangTai2" placeholder="请输入保额/免赔额"/>
          </a-form-item>
        </a-col>
        <a-col span="12">
          <a-form-item name="isRisk"
                       :rules="[{required:true,message:'必填信息',trigger:'change'}]"
          >
            <template #label>
              <span>是否可选责任:</span>&nbsp;
              <span>
                <a-popover placement="right">
                  <template #content>
                  <p>1.	当选择“否”时，在产品详情页显示必选责任，即险种附带责任；
                      <br>2. 当选择“是”时，在页面上会变为可供选择的责任
                        <br>&emsp;&emsp;a). 可以在{险种投保要素管理}模块中被投保要素进行关联，未关联险种投保要素时，该责任会成为公共可选责任；
                        <br>&emsp;&emsp;b). 此处的可选责任比{险种投保要素管理}模块中的可选责任优先级低，即若此处的配置与{险种投保要素管理}模块中的配置选项不同时，以{险种投保要素管理}模块中的配置项为前端展示形式；
                    </p>
                  </template>
                  <template #title>
                  <span>规则说明:</span>
                  </template>
                  <question-circle-outlined />
                </a-popover>
                </span>
            </template>
            <a-select :options="isRiskList" v-model:value="formData.isRisk" :disabled="zhuangTai2">
            </a-select>
          </a-form-item>
        </a-col>
        <a-col span="12">
          <a-form-item label="是否参与保费计算:" name="isRelatedPremiumCalculation"
                       :rules="[{required:true,message:'必填信息',trigger:'change'}]"
          >
            <a-select :options="isRiskList"
                      @change="handleRelatedPremiumCalculationChange"
                      v-model:value="formData.isRelatedPremiumCalculation"
                      :disabled="zhuangTai2">
            </a-select>
          </a-form-item>
        </a-col>
        <a-col span="12">
          <a-form-item name="countMode"
                       :rules="[{required:false,message:'必填信息',trigger:'change'}]"
          >
            <template #label>
              <span>互斥关键字:</span>&nbsp;
              <span>
                <a-popover placement="right">
                  <template #content>
                  <p>当该险种下多个责任填写了相同的的关键字，则这几个责任互斥，在页面不能同时选择，仅可选中唯一一个
                  </p>
                  </template>
                  <template #title>
                  <span>规则说明:</span>
                  </template>
                  <question-circle-outlined />
                </a-popover>
                </span>
            </template>
            <a-input v-model:value="formData.repulsionKey" :disabled="zhuangTai2" placeholder="请输入互斥关键字"/>
          </a-form-item>
        </a-col>
        <a-col span="12">
          <a-form-item :label="formData.isRelatedPremiumCalculation==='N'?'保额展示方式:':'保额/保费计算方式:'" name="countMode">
            <a-select
                :options="formData.isRelatedPremiumCalculation==='N'?[{value:null, label:'无'},{value:'2', label:'列表展示'}]:countModeList"
                v-model:value="formData.countMode"
                @change="handleCountModeChange"
                :placeholder="formData.isRelatedPremiumCalculation==='N'?'请选择保额展示方式':'请选择保额/保费计算方式'"
                :disabled="zhuangTai2" allow-clear>
            </a-select>
          </a-form-item>
        </a-col>
        <a-col span="12" v-if="formData.countMode === '0'">
          <!-- {max: 3, message: '输入长度不能超过3' ,trigger: 'change'}, -->
          <a-form-item label="请输入保费金额:" name="premiumAmount"
                       :rules="[{ pattern:/^\+?[1-9]\d*$/, message: '支持正整数格式' ,trigger: 'change'}]"
          >
            <a-input v-model:value="formData.premiumAmount" :disabled="zhuangTai2" placeholder="请输入保费金额"/>
          </a-form-item>
        </a-col>
        <a-col span="12" v-if="formData.countMode === '1'">
          <!-- {max: 3, message: '输入长度不能超过3' ,trigger: 'change'}, -->
          <a-form-item label="请输入上浮系数（%）:" name="floatCoefficient"
                       :rules="[{ pattern:/^([1-9]+[\d]*(.[0-9]{1,2})?)$/, message: '支持大于0的整数及整数后最长两位小数' ,trigger: 'change'}]"
          >
            <a-input v-model:value="formData.floatCoefficient" :disabled="zhuangTai2" placeholder="请输入上浮系数"/>
          </a-form-item>
        </a-col>

        <a-col span="24">
          <a-form-item label="责任详情描述" name="dutyIntroduce">
            <a-textarea v-model:value="formData.dutyIntroduce" :disabled="zhuangTai2" :rows="4" placeholder="请输入责任描述"/>
          </a-form-item>
        </a-col>
      </a-row>
      <div style="position: relative;" v-if="formData.countMode == '2'">
        <div>投保保额列表:</div>
        <a-table
          :columns="columnsss"
          :data-source="formData.riskDutyPremiumList"
          :scroll="{ x: 300 }"
          :disabled="zhuangTai2"
          :pagination="false"
          style="width: 450px;margin-bottom: 15px"
          :row-key="(record) => record.id"
          :row-selection="{
			selectedRowKeys: selectedRowKeysOne,
			onChange: onSelectChangess
		}">
          <template #bodyCell="{ column, index, record}">
            <template v-if="column.key === 'index'">
              {{ index + 1 }}
            </template>
            <template v-if="column.key === 'coverage'">
              <div>
                <a-input v-model:value="record.coverage" :disabled="zhuangTai2"/>
              </div>
            </template>
            <template v-if="column.key === 'coverageValue'">
              <div>
                <a-input v-model:value="record.coverageValue" :disabled="zhuangTai2"/>
              </div>
            </template>
          </template>
        </a-table>
        <a-button @click="add" v-if="!zhuangTai2">增加一行</a-button>&nbsp;
        <a-button @click="del1" v-if="!zhuangTai2">删除</a-button>
      </div>
      <div style="position: relative;" v-if="formData.countMode == '3'">
        <div>投保保额范围列表:</div>
        <a-table
          v-if="formData.countMode == '3'"
          :columns="columnss"
          :data-source="formData.riskDutyPremiumScopeList"
          :scroll="{ x: 300 }"
          :pagination="false"
          :disabled="zhuangTai2"
          :row-key="(record) => record.id"
          style="width: 700px"
          :row-selection="{
			selectedRowKeys: selectedRowKeysOne,
			onChange: onSelectChangess
		}">
          <template #bodyCell="{ column, index, record}">
            <template v-if="column.key === 'index'">
              {{ index + 1 }}
            </template>
            <template v-if="column.key === 'coverage'">
              <div>
                <a-input v-model:value="record.coverage" :disabled="zhuangTai2"/>
              </div>
            </template>
            <template v-if="column.key === 'coverageValue'">
              <div>
                <a-input v-model:value="record.coverageValue" :disabled="zhuangTai2"/>
              </div>
            </template>
            <template v-if="column.key === 'endCoverage'">
              <div>
                <a-input v-model:value="record.endCoverage" :disabled="zhuangTai2"/>
              </div>
            </template>
            <template v-if="column.key === 'endCoverageValue'">
              <div>
                <a-input v-model:value="record.endCoverageValue" :disabled="zhuangTai2"/>
              </div>
            </template>
          </template>
        </a-table>
        <a-button @click="add1" v-if="!zhuangTai2">增加一行</a-button>&nbsp;
        <a-button @click="del12" v-if="!zhuangTai2">删除</a-button>
      </div>
    </a-form>

    <template #extra>
      <a-space>
        <a-button @click="onClose">
          <template #icon>
            <CloseOutlined/>
          </template>
          关闭
        </a-button>

        <a-button type="primary" @click="onSaveOne" v-if="!zhuangTai2">
          <template #icon>
            <SaveOutlined/>
          </template>
          提交
        </a-button>
      </a-space>
    </template>
  </a-drawer>
  <shoulderAddOne ref="userDrawerRefOne" @setShow="setShow" :status="status" :is_insurance="isInsurance"></shoulderAddOne>
</template>
<script>
import {SaveOutlined} from '@ant-design/icons-vue'
import {computed, defineComponent, reactive, ref, toRefs} from 'vue'
import {getRequestURI, request} from '../../../utils/request'
import {getDictionaryData} from '../../../utils/request_extend'
import shoulderAddOne from './riskDutyAddOne.vue'
import {message} from 'ant-design-vue'

export default defineComponent({
  components: {
    shoulderAddOne,
    SaveOutlined
  },
  props: {
    status: {
      type: String,
      required: true,
      default() {
        return '123'
      }
    },
    is_insurance: {
      type: Number,
      default() {
        return 0
      }
    },
    handleSearch: {type: Function},
    searchData:{
      type: Object,
      default: ()=>{}
    },
    current:{
      type: Number,
      default: ()=> 1
    }
  },
  emits: ['select'],
  setup(props, {expose,emit}, context) {
    const formRef = ref()
    const formData = reactive({
      isRelatedPremiumCalculation: 'N', // 关联保额
      countMode: null, // 计算方式
      // countMode: undefined, // 计算方式
      premiumAmount: '', // 保费金额
      floatCoefficient: undefined, // 上浮系数
      dutyCode: '',//责任编码
      comDutyCode: '',//供应商责任编码
      dutyName: '',//责任名称
      repulsionKey: '', // 互斥关键字，逗号分割多组
      dutyType: undefined,//责任类型
      dutyTypeName: '',//责任类型名称
      isShowStatus: 'Y',//页面是否显示
      coverageWay: '1',//保额选择方式
      sort: '',//显示顺序
      isRisk: 'N',//是否可选责任
      mainFlag: '1',//主附险标志
      deductibleExcess: '',//免赔额
      dutyIntroduce: '',//责任描述
      id: '0',
      riskId: '',//险种id
      riskDutyPremiumList: [
        {
          coverage: 0,//	保额/起止保额
          coverageValue:'',//保额/起止保额显示值
          id:'',
          riskDutyId:''//险种责任id
        }
      ],
      riskDutyPremiumScopeList: [
        {
          endCoverage:0,//终止保额
          endCoverageValue:'',//终止保额显示值
          id:'',
          coverage:0,//保额/起止保额
          coverageValue:'',//保额/起止保额显示值
          riskDutyId:'',//险种责任id

        }
      ]
    })


    const dutyTypeOne = ref([])  //责任类型
    const isShowStatusList = ref([])
    const coverageWayList = ref([])
    const isRiskList = ref([])
    const mainFlagList = ref([])
    const countModeList   = ref([])
    const state = reactive({
      isInsurance: props.is_insurance,
      status: '',
      visible: false,
      isNew: true,
      selectedRowKeysOne: [],
      zhuangTai2: false,
      columnsss: [
        {
          title: '序号',
          key: 'index',
          fixed: 'left',
          width: 60,
          align: 'left'
        },
        {
          title: '保额',
          key: 'coverage',
          fixed: 'right',
          width: 100
        },
        {
          title: '保额显示值',
          key: 'coverageValue',
          fixed: 'right',
          width: 100
        }
      ],
      columnss: [
        {
          title: '序号',
          key: 'index',
          fixed: 'left',
          width: 60,
          align: 'left'
        },
        {
          title: '起始保额',
          key: 'coverage',
          fixed: 'right',
          width: 100
        },
        {
          title: '保额显示值',
          key: 'coverageValue',
          fixed: 'right',
          width: 100
        },
        {
          title: '终止保额',
          key: 'endCoverage',
          fixed: 'right',
          width: 100
        },
        {
          title: '终止保额显示值',
          key: 'endCoverageValue',
          fixed: 'right',
          width: 100
        }
      ]
    })
    //title判断状态
    // eslint-disable-next-line vue/return-in-computed-property
    const panDuan = computed(() => {
      if (state.status == 'newAdds') {
        return '险种责任信息新增'
      } else if (state.status == 'bianJi') {
        return '险种责任信息编辑'
      }
    })
    const filterOption = (input, option) => {
      return option.label.indexOf(input) > -1
    }

    //获取责任类型
    const shuJuType = () => {
      getDictionaryData(dutyTypeOne, 'duty_type')
      getDictionaryData(isShowStatusList, 'yes_no')
      getDictionaryData(coverageWayList, 'amntChoiceWay')
      getDictionaryData(isRiskList, 'yes_no')
      getDictionaryData(mainFlagList, 'riskMAType')
      getDictionaryData(countModeList, 'count_mode')
      // let obj = {
      //   types: ['count_mode'],
      //   ids: []
      // }
      // let uri = getExtendUri('dictionary_query_byType')
      // request(uri, {data: obj}).then(res => {
      //   // countModeList.value = res.result['count_mode']
      // })

    }
    const add1 = function () {
      let data = new Date()
      let id = data.getTime()
      formData.riskDutyPremiumScopeList.push({
        endCoverage: 0,//终止保额
        endCoverageValue: '',//终止保额显示值
        id: id,
        coverage: 0,//保额/起止保额
        coverageValue: '',//保额/起止保额显示值
        riskDutyId: '',//险种责任id
        riskId: '',//险种id
        sort: ''//顺序
      })
    }
    const add = function () {
      let data = new Date()
      let id = data.getTime()
      formData.riskDutyPremiumList.push({
        coverage: 0,//	保额/起止保额
        coverageValue: '',//保额/起止保额显示值
        id: id,
        riskDutyId: ''//险种责任id
      })
      console.log('第几次', id)
    }
    const onSelectChangess = function (selectedRowKeysOne) {
      state.selectedRowKeysOne = selectedRowKeysOne
    }
    const del12 = function () {
      if(state.selectedRowKeysOne.length === 0) {
        message.warning('请选择数据')
        return
      }
      state.selectedRowKeysOne.forEach((item) => {
        formData.riskDutyPremiumScopeList.forEach((item1, index) => {
          if (item === item1.id) {
            formData.riskDutyPremiumScopeList.splice(index, 1)
          }
        })
      })
    }
    const del1 = function () {
      if(state.selectedRowKeysOne.length === 0) {
        message.warning('请选择数据')
        return
      }
      console.log(formData.riskDutyPremiumList)
      console.log(state.selectedRowKeysOne)
      state.selectedRowKeysOne.forEach((item) => {
        formData.riskDutyPremiumList.forEach((item1, index) => {
          if (item1.id == item) {
            formData.riskDutyPremiumList.splice(index, 1)
          }
        })
      })
    }

    const showDrawerTwo = (oldData, newData = {}) => {
      if (newData == 'chakan') {
        state.zhuangTai2 = true
      } else {
        state.zhuangTai2 = false
      }
      if (newData == 'bianJi') {
        formData.riskId = oldData.riskId
      } else if (newData == 'newAdds') {
        formData.riskId = oldData.id
      }
      state.isInsurance = oldData.riskCsSign
      state.status = newData
      state.visible = true
      state.isNew = !(typeof oldData.id === 'string' && oldData.id.length > 0)
      Object.keys(formData).forEach(key => {
        formData[key] = oldData[key] || formData[key]
      })
      formData.mainFlag += ''
      formData.coverageWay += ''
      // console.log('要海洋', formData)
      shuJuType(formData)
    }
    const setShow = function (aa) {
      console.log('aa',aa)
      state.status = 'newAdds'
      formData.dutyName = aa.dutyName + ''
      formData.repulsionKey = aa.repulsionKey? aa.repulsionKey + '' : ''
      formData.comDutyCode = aa.comDutyCode + ''
      formData.dutyCode = aa.dutyCode + ''
      formData.dutyType = aa.dutyType + ''
      formData.isShowStatus = aa.isShowStatus + ''
      formData.countMode = aa.countMode || ''
      formData.sort = aa.sort
      formData.isRisk = aa.isRisk? aa.isRisk: 'N'
      formData.mainFlag = aa.mainFlag
      formData.deductibleExcess = aa.deductibleExcess
      formData.dutyIntroduce = aa.dutyIntroduce + ''
      if (formData.countMode == '2') {
        formData.riskDutyPremiumList = aa.insurePremiumList
      }
      if (formData.countMode == '3') { // customInsurePremiumList
        formData.riskDutyPremiumScopeList = aa.customInsurePremiumList
      }
    }

    const onFinish = (values) => {
      console.log('ssss', values)
      // console.log('search-form-state: ', formState)
      context.emit('callback', values)
    }
    const formRef2 = ref()

    //关闭
    const onClose = () => {
      emit('resetValue')
      state.visible = false
      formRef2.value.resetFields()
      formRef2.value.clearValidate()
      state.selectedRowKeysOne = []
      Object.assign(formData, {
        isRelatedPremiumCalculation: 'N', // 关联保额
        countMode: undefined, // 计算方式
        premiumAmount: '',
        floatCoefficient: undefined,
        dutyCode: '',//责任编码
        comDutyCode: '',//供应商责任编码
        dutyName: '',//责任名称
        repulsionKey: '',
        dutyType: '',//责任类型
        dutyTypeName: '',//责任类型名称
        isShowStatus: 'Y',//页面是否显示
        coverageWay: '1',//保额选择方式
        sort: '',//显示顺序
        isRisk: 'N',//是否可选责任
        mainFlag: '1',//主附险标志
        deductibleExcess: '',//免赔额
        dutyIntroduce: '',//责任描述
        id: '0',
        riskId: '',//险种id
        riskDutyPremiumList: [
          {
            coverage:'0',//	保额/起止保额
            coverageValue:'',//保额/起止保额显示值
            id:'',
            riskDutyId:''//险种责任id
          }
        ],
        riskDutyPremiumScopeList: [
          {
            endCoverage:'0',//终止保额
            endCoverageValue:'',//终止保额显示值
            id:'',
            coverage:'0',//保额/起止保额
            coverageValue:'',//保额/起止保额显示值
            riskDutyId:'',//险种责任id

          }
        ]
      })

    }
    const userDrawerRefOne = ref()
    const search = function () {
      console.log('123', state)
      userDrawerRefOne.value.showDrawer(state)
    }
    //重置
    const onFormClean = () => {

    }
    //提交按钮
    const onSaveOne = () => {
      //责任编码校验
      let reg1 = /^[0-9a-zA-Z]+$/
      // let reg1Array =formData.dutyCode.split('')
      let vive1 = reg1.test(formData.dutyCode)
      if (formData.dutyCode === '') {
        message['warning']('责任编码不能为空')
        return
      }

      if (!vive1) {
        message['warning']('责任编码支持数字和字母格式')
        return
      }
      if (formData.dutyCode.length > 20) {
        message['warning']('责任编码输入长度不能超过20')
        return
      }
      if ( formData.dutyType == '') {
        message.warning('责任类型不能为空')
        return
      }
      //供应商责任编码校验
      // let reg2Array =formData.comDutyCode.split('')
      // if (formData.comDutyCode.length > 20) {
      //   message['warning']('供应商责任编码输入长度不能超过20')
      //   return
      // }

      //责任名称校验
      if (formData.dutyName === '') {
        message['warning']('责任名称不能为空')
        return
      }
      if (formData.dutyName.length > 128) {
        formData.dutyName = ''
        message['warning']('责任名称输入长度不能超过128')
        return
      }
      // 互斥关键字
      if (formData.repulsionKey.length > 128) {
        message['warning']('互斥关键字输入长度不能超过128')
        return
      }
      //责任类型校验
      if (!formData.dutyType) {
        message['warning']('责任类型不能为空')
        return
      }

      //界面是否显示校验
      if (!formData.isShowStatus) {
        message['warning']('界面是否显示不能为空')
        return
      }
      //保额选择方式校验
      if (formData.coverageWay == '') {
        message['warning']('保额选择方式不能为空')
        return
      }

      if (formData.countMode == '2') {
        //起止保额校验
        formData.riskDutyPremiumList.forEach(item => {
          let reg6 = /^([0-9]+[\d]*(.[0-9]{1,2})?)$/
          let vive6 = reg6.test(item.coverage)
          if (item.coverage === '') {
            item.coverage = 0
            message['warning']('保额不能为空')
            throw 'error'
          }
          if (!vive6) {
            message['warning']('保额支持整数或小数格式，最长两位小数')
            item.coverage = ''
            throw 'error'
          }
          if (item.coverage.length > 10) {
            item.coverage = ''
            message['warning']('保额输入长度不能超过10')
            throw 'error'
          }
          //投保保额列表处的保额显示值校验
          if (item.coverageValue === '') {
            item.coverageValue = ''
            message['warning']('保额显示值不能为空')
            throw 'error'
          }
          if (item.coverageValue.length > 20) {
            item.coverageValue = ''
            message['warning']('保额显示值输入长度不能超过20')
            throw 'error'
          }
        })

      }

      if (formData.count_mode == '3') {
        //起止保额校验
          for (let item of formData.riskDutyPremiumScopeList) {
          let reg6 = /^([1-9]+[\d]*(.[0-9]{1,2})?)$/
          let vive6 = reg6.test(item.coverage)
          if (item.coverage === '') {
            item.coverage = ''
            message['warning']('起始保额不能为空')
            return
          }
          if (!vive6) {
            message['warning']('起始保额支持整数或小数格式，最长两位小数')
            // item.premium = ''
            return
          }
          if (item.coverage.length > 10) {
            item.coverage = ''
            message['warning']('起始保额输入长度不能超过10')
            return
          }
          //起始保额显示值校验
          if (item.coverageValue === '') {
            item.coverageValue = 0
            message['warning']('起始保额显示值不能为空')
            return
          }
          if (item.coverageValue.length > 20) {
            item.coverageValue = 0
            message['warning']('起始保额显示值输入长度不能超过20')
            return
          }

          //终止保额校验
          let reg8 = /^([1-9]+[\d]*(.[0-9]{1,2})?)$/
          let vive8 = reg8.test(item.endCoverage)
          if (item.endCoverage === '') {
            item.endCoverage = ''
            message['warning']('终止保额不能为空')
            return
          }
          if (!vive8) {
            message['warning']('终止保额支持整数或小数格式，最长两位小数')
            item.endCoverage = ''
            return
          }
          if (item.endCoverage.length > 10) {
            item.endCoverage = ''
            message['warning']('终止保额输入长度不能超过10')
            return
          }
          //终止保额显示值校验
          if (item.endCoverageValue === '') {
            item.endCoverageValue = ''
            message['warning']('终止保额显示值不能为空')
            return
          }
          if (item.endCoverageValue.length > 20) {
            item.endCoverageValue = ''
            message['warning']('终止保额显示值输入长度不能超过20')
            return
          }
        }
      }

      //显示顺序校验
      let reg4 = /^\+?[1-9]\d*$/
      let vive4 = reg4.test(formData.sort)
      if (formData.sort === '') {
        formData.sort = ''
        message['warning']('显示顺序不能为空')
        return
      }
      if (!vive4) {
        message['warning']('显示顺序支持正整数字格式并小于三位')
        formData.sort = ''
        return
      }
      if (formData.sort.length > 3) {
        formData.sort = ''
        message['warning']('显示顺序输入长度不能超过3')
        return
      }

      //是否可选责任校验
      if (formData.isRisk === '') {
        message['warning']('是否可选责任不能为空')
        return
      }
      let obj = {
        data: formData
      }
      if (state.status == 'newAdds') {
        const uri = getRequestURI('4')
        request(uri, obj).then(res => {
          if (res.code === 1) {
            props.handleSearch({})
            onClose()
            message['success'](res.message)
          } else {
            message['warning'](res.message)
          }

        })
      } else if (state.status == 'bianJi') {
        const uri = getRequestURI('riskDuty_updateRiskDuty')
        request(uri, obj).then(res => {
          if (res.code === 1) {
            props.handleSearch(props.searchData, props.current)
            formData.id = ''
            state.status = 'newAdds'
            onClose()
            message['success'](res.message)
          } else {
            message['warning'](res.message)
          }

        })
      }

    }
    const handleRelatedPremiumCalculationChange = (value) => {
      console.debug('handleRelatedPremiumCalculationChange', value)
      formData.countMode = undefined
      if (value === 'N') {
        formData.countMode = null
      }
    }
    const handleCountModeChange = (value) => {
      console.debug('handleCountModeChange', value)
    }
    expose({
      showDrawerTwo
    })

    return {
      mainFlagList,
      coverageWayList,
      isShowStatusList,
      countModeList,
      dutyTypeOne,
      formRef,
      formData,
      formRef2,
      ...toRefs(state),
      onClose,
      onSaveOne,
      onFormClean,
      getRequestURI,
      onFinish,
      showDrawerTwo,
      shuJuType,
      add,
      del1,
      setShow,
      search,
      isRiskList,
      userDrawerRefOne,
      panDuan,
      onSelectChangess,
      add1,
      del12,
      filterOption,
      handleRelatedPremiumCalculationChange,
      handleCountModeChange
    }
  }

})
</script>
