<template>
<div>
<!--健康告知元素-->
  <div style="text-align: right;margin-top: 0.3rem;" v-if="model === 1">
<!-- 这个是单选模式-->
  <!--<div style="color: rgb(184, 180, 180);margin: 5px 0" v-if="!conSpanList.length">2222暂无数据</div>-->
    <!-- @change="handelRadioGroup" -->
    <a-radio-group v-model:value="checkedValue" button-style="solid" @change="handelRadioGroup" :disabled="disabledType">
      <div v-for="(config,index) in conSpanList" :key="index" v-bind="$attrs" style="display: inline">
        <a-radio-button :value="config.value" >{{config.label}}</a-radio-button>
      </div>
    </a-radio-group>
  </div>
  <div v-if="childState.isChild">
      <!-- {{ checkedValue }} --------checkedValue -->
      <PreviewPop :isModel="false" :required="isRequired"  :previewData="childState" @PreviewPopData ="PreviewPopData"></PreviewPop>
  </div>
  <!-- 这个是输入模式的 -->
  <div :class="(elementTitle.includes('身高(cm)') || elementTitle.includes('体重(kg)')) ? 'input_class1': 'input_class'" v-if="model === 2">
    <span v-if="(elementTitle.includes('身高(cm)') || elementTitle.includes('体重(kg)'))" class="title-lable"><span class="required-lable">*</span>{{ elementTitle }}</span>
    <div
      style="display: inline-block;"
      :class="(elementTitle.includes('身高(cm)') || elementTitle.includes('体重(kg)')) ? 'box' : {'width100': !((healthList.length && healthList[0]['isHasStr']) || healthList.length && healthList[0]['str'])}"
      v-for="(item , index) in healthList"  v-bind="$attrs"  :key="index">
      <!-- {{ firstValue }} ----firstValue
      {{ item }} ---------item -->
      <!-- {{ inputStrList }} -------state.inputStrList -->
      <!-- <nobr> -->

        <span  v-if="item.type === 'string'">{{item.str}}</span>
        <div v-else :class="(elementTitle.includes('身高(cm)') || elementTitle.includes('体重(kg)')) ? 'nav-title1': 'nav-title'">
          <div  v-if="item.configType === 'text'" id="contenteditableId " placeholder="请填写" class="div-input text-config"  tabindex="0" ref="inputMsg"  @input="inputDiv($event, item,item.value)" :class="item['isHasStr'] ? 'borderB' : ''" :contenteditable="!disabledType" v-html="item.value"></div>
<!--          <a-input  v-if="item.configType === 'text'" class="borderClass" v-model:value="item.value" :maxlength="item.max" @change="textChangeListener(item,item.value)"></a-input>-->
          <a-input-number :readonly="disabledType" style="width: 100%;" :maxLength="(elementTitle.includes('身高(cm)') || elementTitle.includes('体重(kg)'))? 3 : 9999" :placeholder="`请填写${elementTitle}`" v-else-if="item.configType === 'number'" v-model:value="item.value" :min="item.min"  @change="textChangeListener(item,item.value)"/>
          <a-date-picker :readonly="disabledType"  style="width: 100%;"  v-else-if="item.configType === 'date'"  v-model:value="item.value" @change="textChangeListener(item,item.value)"/>
          <a-time-picker :readonly="disabledType"  style="width: 100%;"  v-else-if="item.configType === 'time'"  v-model:value="item.value" value-format="HH:mm:ss" allowClear @change="textChangeListener(item,item.value)"/>
          <a-date-picker :readonly="disabledType"  style="width: 100%;"  v-else-if="item.configType === 'dateTime'" show-time placeholder="选择日期时间" v-model:value="item.value" @change="textChangeListener(item,item.value)"/>
          <a-input :readonly="disabledType"  style="width: 100%;"  v-else-if="item.configType === 'pId'"  v-model:value="item.value" :maxlength="18" @change="textChangeListener(item,item.value)"></a-input>
          <a-input :readonly="disabledType"   style="width: 100%;" v-else-if="item.configType === 'phone'" v-model:value="item.value" :maxlength="11" @change="textChangeListener(item,item.value)"></a-input>
          <a-input :readonly="disabledType"  style="width: 100%;" v-else-if="item.configType === 'email'" v-model:value="item.value"  @change="textChangeListener(item,item.value)"></a-input>
<!--          <a-input  v-else class="borderClass" v-model:value="item.value" @change="textChangeListener(item,item.value)"></a-input>-->
          <div  v-else id="contenteditableId" tabindex="0"  @input.self="inputDiv2 ($event, item,item.value)" placeholder="请填写" class="div-input" :contenteditable="!disabledType" v-html="item.value"></div>
          <!-- <a-input v-else v-model:value="item.value" :disabled="disabledType" :placeholder="`请填写${(elementTitle.includes('身高(cm)') || elementTitle.includes('体重(kg)'))? elementTitle : '' }`" @change="textChangeListener(item,item.value)"></a-input> -->
         <!-- <div  v-else  contenteditable="true"  class="div-input" @change="textChangeListener(item,item.value)">{{item.value}}</div> -->
        </div>
      <!-- </nobr> -->
    </div>
  </div>

  <!-- 这个是输入模式的 -->
  <div v-if="elementTitle.includes('体重(kg)')" :class="(elementTitle.includes('身高(cm)') || elementTitle.includes('体重(kg)')) ? 'input_class1': 'input_class'" style="margin-top: 25px;">
    <span v-if="(elementTitle.includes('身高(cm)') || elementTitle.includes('体重(kg)'))" class="title-lable"><span class="required-lable"></span>BMI<van-icon @click="showTips(true)" name="question-o" style="margin-left: 5px;" /></span>
    <div id="contenteditableId " placeholder="请填写" class="div-input text-config" style="margin-right: 20px;"  tabindex="0" ref="inputMsg"  v-html="BIM"></div>
  </div>


  <!-- 这个是多选模式的 -->
  <div v-if="model === 3">
<!--    <div style="color: rgb(184, 180, 180);margin-top: 10px 0;" v-if="!conSpanList.length">暂无数据</div>-->
    <!--<div v-if="!conSpanList.length">暂无数据</div>-->
    <div v-for="(item,index) in conSpanList"
         :key="index"
         style="margin-bottom:5px; margin-top: 5px">
      <a-checkbox>{{getSerialNumber(index)}}. &nbsp;{{(item.isOther ? item.otherText : item.label)}}</a-checkbox>
      <div v-if="item.otherOptionState === 2" style="margin-left:20px; margin-top: 5px">
        <div style="color: rgb(184, 180, 180);margin: 5px 0 10px 0"
             v-if="!item.otherOptionList.length">暂无数据</div>
        <a-radio-group v-model:value="item.checkedValue" button-style="solid">
          <div v-for="(config,index) in item.otherOptionList" :key="index" v-bind="$attrs" style="display: inline">
<!--            <a-radio :value="config.value" @change="handelRadioGroup2($event, config)">-->
<!--              <span v-if="item.otherOptionList.length">{{String.fromCharCode(65 + index)}}. &nbsp;</span>-->
<!--              <span>{{config.label}}</span>-->
<!--            </a-radio>-->
            <a-radio-button :value="config.value" @change="handelRadioGroup2($event, config)">{{config.label}}</a-radio-button>
          </div>
        </a-radio-group>
      </div>
      <div v-if="item.otherOptionState === 3" style="margin-left:20px; margin-top: 5px">
        <div v-for="(config,index) in item.otherOptionList"
             :key="index"
             style="margin-bottom:5px; margin-top: 5px">
          <a-checkbox>{{getSerialNumber(index)}}. &nbsp;{{config.label}}</a-checkbox>
        </div>
      </div>
    </div>
    </div>
  </div>
</template>

<script>
import {
  onMounted,
  reactive,
  ref,
  toRefs,
  onBeforeUnmount,
  defineComponent,
  nextTick,
  watch,
  onBeforeUpdate,
  onBeforeMount
} from 'vue'
import {Icon} from 'vant'
// import PreviewPop from '../../mod/PreviewPop'
export default defineComponent({
  components:{
    [Icon.name]: Icon
    // PreviewPop
  },
  props: {
    moduleIndex:{
      type: [Number,String],
      default:()=> '0'
    },
    healthObj: {
      type: Object,
      default:()=> {}
    },
    elementTitle: {
      type: String,
      default: () => ''
    },
    model:{
      type: Number,
      default: () => 1
    },
     elementCode: {
      type: [Number,String],
      default: () => ''
    },
    required:{
      type: [Number,String],
      default:()=> '0'
    },
    moduleId:{ // 模块ID
      type: [Number,String],
      default:()=> ''
    },
    elementId:{ // 元素ID
      type: [Number,String],
      default:()=> ''
    },
    childId:{ // 当前id
      type: [Number,String],
      default:()=> ''
    },
    healthType:{
      type: String,
      default:()=> ''
    },
    firstValue:{
      type: [String,Number,Array],
      default:()=> ''
    },
    superiorId: {
      type: String,
      default:()=> ''
    },
    disabledType: {
      type: [Boolean, String],
      default: () => {
        return false
      }
    },
    BIM: {
      type: [String, Number],
      default: () => {
        return '-'
      }
    }
  },
  emits:['callback','update:firstValue','showTips'],
  setup(props,context){
    const state = reactive({
      model: props.model,
      conSpanList:props.healthObj.conSpanList,
      healthList: props.firstValue,
      checkedValue:(props.firstValue || props.firstValue === 0) ? props.firstValue*1: null, // 单选
      elementCode:props.elementCode,
      childId: props.childId,
      moduleId: JSON.parse(JSON.stringify(props.moduleId)),
      isRequired: JSON.parse(JSON.stringify(props.required)),
      inputStrList:[]
    })
    const inputMsg = ref(null)
    const titleUpdate = ref('')
    const formatter = (value)  => {
      // 限制长度为3
      const strValue = String(value)
      return strValue.length > 3 ? strValue.substring(0, 3) : strValue
    }

    const  inputDiv = (e, data) =>{
      console.log(e, data, state.inputStrList, 'ffffffff111')
      state.inputStrList.forEach((item,index) =>{
        if(item.key == data.mPropKey || item.key == data.key){
          state.inputStrList[index].value = e.target.innerText
        }
      })
      inputMsg.value = e.target
      e.target.addEventListener('blur', onBlurHandler)
    }
    const  inputDiv2 = (e, data) =>{
      console.log(e, data, 'ffffffff')
      state.inputStrList.forEach((item,index) =>{
        if(item.key == data.key){
          state.inputStrList[index].value = e.target.innerText
        }

      })
      inputMsg.value = e.target
      e.target.addEventListener('blur', onBlurHandler)
    }
    const  onBlurHandler = () =>{
      console.log('-----6666666-----', state.inputStrList, inputMsg.value)
        // context.emit('update:firstValue', state.healthList)
        getData(state.inputStrList, inputMsg.value)
    }
    const  getData = (data,target) =>{
      console.log(data, 'data111')
      let node = {}
      node[state.elementCode] = {
        [state.elementCode] : data,
        healthType:props.healthType,
        moduleId: state.moduleId,
        elementId: props.elementId,
        index:props.moduleIndex,
        required : props.required,
        superiorId: props.superiorId,
        elementCode: state.elementCode,
        titleStr: props.healthObj.healthInputConfig.titleStr
      }
      console.log(node, 'node122222')
      context.emit('callback',node)
      if(target){
        target.removeEventListener('blur', onBlurHandler)
      }
    }

    onBeforeUpdate(()=>{
      // state.checkedValue = props.firstValue
      // if(state.model === 1 && state.checkedValue === 2){
      //   childState.isChild = false
      // }
    })
    const childState = reactive({
      childModel :undefined,
      conSpanList: [],
      healthList:[],
      childCheckValue: undefined,
      isChild: false,
      productElementList:[],
      superiorId: ''
    })

    // let {inputStrArray,strArray,tabList} =  props.healthObj.healthInputConfig.modelPropData
    const initShowFData = (a, b, c) => {
      console.log(a,b,c,props.elementCode, props.firstValue, 'ttttttttyyyyyyy')
      if(!c){
        return
      }
      c.forEach(item => {
        console.log(props.firstValue, c, 'props.firstValue')
        if(props.firstValue && props.firstValue.length){
          const {value} = props.firstValue.filter(fir => fir.mPropKey === item.mPropKey ||fir.key === item.mPropKey)[0]
          state.inputStrList.push({key:item.mPropKey,value, configType: item.configType})
        }else{
          state.inputStrList.push({key:item.mPropKey,value: '', configType: item.configType})
        }
      })
      let newArray = []
      if (c) {
        newArray = b.map((v, i) => ({ ...v, ...c[i] }))
        console.log(newArray, 'newArray')
      } else {
        let c1 = getDemoData(b,a)
        newArray = b.map((v, i) => ({ ...v, ...c1[i] }))
      }
      console.log(props.firstValue, 'props.firstValue5')
      // if(props.firstValue && props.firstValue.length){
      //   state.healthList = props.firstValue
      // }else{
        let tempArray = a.concat(newArray)
        state.healthList = tempArray.sort(compare('indexStart'))
        let list = state.healthList.filter(item => item.type === 'string' && item.str !== '')
        if (list && list.length > 0) {
        console.log(props.firstValue, '111112222222')

          console.log(state.healthList, 'state.healthList1')
          state.healthList.forEach(item =>{
            if (props?.firstValue?.length) {
              props?.firstValue?.forEach(i => {
                if (item.mPropKey === i.key) {
                  item.value = i.value
                }
              })
            }
            item.isHasStr = true
          })
        }else{
          console.log('sssssssssss')
          state.healthList.forEach(item =>{
            if (props.firstValue && props.firstValue?.length) {
              props?.firstValue?.forEach(i => {
                if (item.mPropKey === i.key) {
                  item.value = i.value
                }
              })
            }
            item.isHasStr = false
          })
        }
      // }
    }
    //property:根据什么属性排序
    const compare = (property) => {
      return function (a, b) {
        let value1 = a[property]
        let value2 = b[property]
        return value1 - value2//升序排序
      }
    }
    const getDemoData = (b,a=[]) => {
      if(!b.length){
        return
      }
     let c = []

      for (let i = 0; i < b.length; i++) {
        c.push({
          id: i,
          indexName: `填空${i + 1}`,
          configType: 'text',
          propRange: [0, 255],
          isMustInput: true,
          isOnly: false,
          min: 0,
          max: 255,
          key:a[i].str
        })
      }
      return c
    }
    const handelRadioGroup2 = () => {

      // let arr = config.otherOptionList.filter(item => {
      //   return e.target.value == item.optionIndex
      // })
      // state.checkedValue = e.target.value
      // if(state.checkedValue == 2){
      //   childState.isChild = false
      // }
      // let node = {}
      // node[state.elementCode] = {
      //   [state.elementCode] : arr[0].value,
      //   healthType:props.healthType,
      //   required : props.required,
      //   childId: props.childId,
      //   moduleId: state.moduleId,
      //   index:props.moduleIndex,
      //   elementId: props.elementId,
      // }
      // context.emit('callback',node)
      // watch(()=>state.checkedValue,()=>{
      //   context.emit('update:firstValue', state.checkedValue)
      // },{immediate: true,deep: true})
      // childState.productElementList = []
      // if(!arr[0].elementList.length){
      //   return
      // }
      // childState.isChild = true
      // nextTick(() =>{
      //   arr[0].elementList.required = state.isRequired
      //   arr[0].elementList.moduleId = state.moduleId
      //   Object.assign(childState,{productElementList:[arr[0].elementList]})
      // })
    }
    watch(()=>state.checkedValue,()=>{
      console.log(state.checkedValue, state.model, '8888888888')
      if(state.model !== 1){
        return
      }
      let node = {}
      node[state.elementCode] = {
        [state.elementCode] : state.checkedValue,
        healthType:props.healthType,
        required : props.required,
        childId: props.childId,
        moduleId: state.moduleId,
        index:props.moduleIndex,
        elementCode: state.elementCode,
        elementId: props.elementId,
      }
      console.log(node, 'node2')
      context.emit('update:firstValue', state.checkedValue)
      context.emit('callback',node)
    },{immediate: true,deep: true})

    // watch(()=> state.healthList,()=>{
    //   context.emit('update:firstValue', state.healthList)
    // },{immediate: true,deep: true})

    // 第一次选中时的回调
    const handelRadioGroup = (e) =>{
      let arr = state.conSpanList.filter(item => {
        return e.target.value == item.value
      })
      state.checkedValue = e.target.value
      console.log(state.checkedValue, 'state.checkedValuestate.checkedValue')
      if(state.checkedValue !== 1 && state.checkedValue !== 0 ){
        childState.isChild = false
      }
      console.log('子级的问题', state.conSpanList, childState, props, arr)
      childState.productElementList = []
      if(!arr.length || !arr[0].elementList.length){
        return
      }
      console.log('909090')
      if(state.checkedValue !== '' && state.checkedValue !== undefined && state.checkedValue !== null && !childState.isChild) {
        childState.isChild = true
      }
      nextTick(() =>{
        // arr[0].elementList.required = state.isRequired
        arr[0].elementList.moduleId = state.moduleId
        Object.assign(childState,{productElementList:[arr[0].elementList]})
        childState.superiorId = props.childId
        // childState.isChild = true
      })

    }

    const handelCheckBox = (list) =>{
      let node = {}
      node[state.elementCode] = {
        [state.elementCode] : list,
        healthType:props.healthType,
        moduleId: state.moduleId,
        elementId: props.elementId,
        index:props.moduleIndex,
        required : props.required
      }
      context.emit('callback',node)

    }

    const textChangeListener = (data,value) =>{
      let {mPropKey, key} = data
      state.inputStrList.forEach((item,index) =>{
        if(item.key == mPropKey || item.key == key){
          state.inputStrList[index].value = value
        }
      })
      getData(state.inputStrList, inputMsg.value)
      // let node = {}
      // node[state.elementCode] = {
      //   [state.elementCode] : state.inputStrList,
      //   healthType:props.healthType,
      //   moduleId: state.moduleId,
      //   elementId: props.elementId,
      //   index:props.moduleIndex,
      //   superiorId: props.superiorId,
      //   required : props.required
      // }
      // context.emit('callback',node)
    }
    const PreviewPopData = function(data){
      console.log(data, 'datadata')
      context.emit('callback',data)
    }

    // 序号  A  B  C   D  ....
    const getSerialNumber = (index) => {
      return String.fromCharCode(65 + index)
    }
    const showP = (item) => {
      if (item.otherOptionState !== 1 && item.otherOptionList) {
        return false
      }
      return true
    }
    onBeforeMount(()=>{})
    onMounted(() => {
      if(state.model === 2 && !childState.isChild){ // 输入
        nextTick(()=>{
          let {strArray,strArrayStr} = props.healthObj.healthInputConfig
          let {tabList} =  props.healthObj.healthInputConfig.modelPropData
          initShowFData(strArrayStr, strArray,tabList)
        })
      }
    })
    onBeforeUnmount(() =>{
      // childState.isChild = false
    })

    watch(props, ()=>{
      const list = props.healthObj?.conSpanList
      if (list && list.length > 0) {
        state.conSpanList = list
      }
      console.log(props.model, 'props.modelprops.model')
      if(props.model === 1 || props.model === 2){
        state.checkedValue = props.firstValue
        if (state.checkedValue === null || state.checkedValue === '' || state.checkedValue === undefined){
          childState.isChild = false
        }else{
          handelRadioGroup({target:{value: state.checkedValue}})
        }
      }
    },{immediate: true, deep: true})
    const showTips = (val) => {
      context.emit('changeShowBMI', val)
    }
      return {
        inputDiv,
        inputDiv2,
        showTips,
        ...toRefs(state),
        childState,
        textChangeListener,
        inputMsg,
        handelCheckBox,
        initShowFData,
        compare,
        getDemoData,
        handelRadioGroup,
        PreviewPopData,
        getSerialNumber,
        showP,
        handelRadioGroup2,
        titleUpdate,
        formatter
    }
  }
})
</script>

<style scoped>
.div-input:empty::before{
            color:#d9d9d9;
            font-size: 14px;
            content:attr(placeholder);
        }
.div-input {
  min-width: 65px;
  padding-left: 10px;
  padding-right: 10px;
}
.borderB {
  border-bottom: 1px solid #999;
}
  .ant-checkbox-group{
    height: 100%;
    width: 100%;
    line-height: 30px;
  }
  .borderClass{
    /*max-width: 100px;*/
    min-width: 50px;
    border: none;
    border-radius:0;
    /* border-bottom: 1px solid #666; */
    padding-left: .5rem;
  }
  /*以下是单选*/
  .ant-radio-button-wrapper{
    margin:0 3px;
    height: 28px;
    line-height: 27px;
    min-width: 55px;
    text-align: center;
  }
  :deep(.ant-radio-button-wrapper:first-child:last-child){
    border-radius: 20px;
  }
/* 以下是输入模式 */
  .input_class1{
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    border-radius: .5rem;
    margin-top: 0.8rem;
    min-height: 3rem;
    background: #f4f5f7;
  }
  .input_class{
    width: 100%;
    /* border: 1px solid #999; */
    border-radius: .5rem;
    margin-top: 0.8rem;
    padding: 0.7rem 1rem 0.6rem 1rem;
    /* background: #f4f5f7; */
    line-height: 1.5rem;
    border: 1px solid #1890ff;
  }
  .title-lable {
    display: inline-block;
    padding: 0 15px;
    min-width: 100px;
  }
  .nav-title1 {
    width: 100%;
    margin-right: 20px;
  }
  .required-lable {
    color: #e21818;
    margin-right: 2px;
  }
  .box {
    box-sizing: content-box !important;
  }
  .ant-input-number {
    text-align: right;
    border: none !important;
    background: #f4f5f7;
  }
  :deep(.ant-input-number-input,.ant-input) {
    text-align: right !important;
  }
  .ant-input-number-focused {
    box-shadow: none;
  }
  .ant-input:focus {
    box-shadow: none;
  }
  .ant-input{
    background: #f4f5f7;
    border: none;
    text-align: right !important;
  }
  .width100{
    width: 100% !important;
  }
  [contenteditable]:focus {
    outline: 0px solid transparent;
  }
  .textClass{
    box-sizing: border-box;
    resize: none;
    outline: none;
    border: none;
    border-bottom: 1px solid #666;
    padding-left: .5rem;
  }
  .ant-radio-button-wrapper-disabled.ant-radio-button-wrapper-checked {
    background: #1890ff !important;
    color: #fff !important;
  }
  :deep(.ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):hover){
  color: #fff;
  background: #1890ff;
  border-color: #1890ff;
}
  .text-config{
    user-select: text;
  }

</style>
