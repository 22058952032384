<template>
  <a-drawer
    :title="title"
    width="70%"
    :visible="visible"
    :body-style="{ padding: '0' }"
    :footer-style="{ textAlign: 'top' }"
    @close="onClose"
  >
    <div class="drag_header" ref="dragLayoutRef">

      <div style="margin-bottom: 20px; font-size:16px">
        <a-form>
          <a-form-item label="流程名称: " required>
            <a-input v-model:value="proName" style="width:300px;display: inline-block; " aria-placeholder="请输入流程名称"></a-input>
          </a-form-item>
        </a-form>
      </div>
      <div class="currentPro">
        <div class="currentPro_left">
        </div>
        <div class="currentPro_right">
          <div class="selectedPro">
            <h3 class="center_box">已选节点</h3>
            <div id="select_proBox" class="selectBox continer" @dragover="dragover($event)">
              <!--
                draggable: 设置当前模块是否可以移动
                @dragstart="dragstart(item)"  开始拖动
                @dragenter="dragenter(item, $event)"  当被鼠标拖动的对象进入其容器范围内时
                @dragend="dragend(item, $event)"  用户完成元素拖动后触发
                @dragover="dragover($event)"  当某被拖动的对象在另一对象容器范围内拖动时触发此事件
              -->
              <div
                class="content"
                v-for="list in positionList"
                :key="list.id"
                draggable="true"
                @dragstart="dragstart($event,list)"
                @dragenter="dragenter($event,list)"
                @dragend="dragend"
              >
                <div style="width:100%;padding: 5px 20px;">
                  <div class="header">
                    <div class="content_left">
                      <div class="content_label" style="font-size:16px;font-weight:700">
                        {{list.label ? list.label : list.name}}
                      </div>
                      <p style="color: #999">id:{{list.value ? list.value : list.code}}</p>
                    </div>
                    <!-- class="content_icon" -->
                    <div class="content_right" @click="setNodeClick(list)">
                      <unordered-list-outlined style="fontSize: 28px;color:rgb(102, 99, 251);"/>
                    </div>
                  </div>
                  <div>
                    <p class="bottom_left">界面样式: {{list.pageStyleName?list.pageStyleName:'页面'}}</p>
                    <p class="bottom_right">关联模板: {{list.associatedTemplateName ? list.associatedTemplateName : '未关联'}}</p>
                  </div>
                </div>

              </div>
            </div>
          </div>
          <div class="optionalPro">
            <div class="drag_content" v-show="isDrag" ref="dragElRef">
                <div class="content_icon">
                  <unordered-list-outlined style="fontSize: 28px"/>
                </div>
                <div class="content_detial"></div>
            </div>
            <h3 class="center_box">可选节点</h3>
            <div class="optionBox continer">
              <div class="content"  @mousedown="(event)=>startDrag(event, pro)" v-for="pro in processData" :key="pro.value">
                <div class="content_icon">
                  <unordered-list-outlined style="fontSize: 28px"/>
                </div>
                <div class="content_detial">
                  {{pro.label}}
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
    </div>
    <template #extra>
      <a-space>
        <a-button @click="onClose">
          <template #icon>
            <CloseOutlined/>
          </template>取消
        </a-button>
        <a-button @click="onReact">
          <template #icon>
            <ReloadOutlined />
          </template>重置
        </a-button>
        <a-button type="primary" @click="addPro">
          <template #icon>
            <SaveOutlined/>
          </template>保存
        </a-button>
      </a-space>
    </template>
  </a-drawer>
  <!-- 节点配置 -->
  <a-modal v-model:visible="nodeShow" :maskClosable="false" :footer="null" :closable="false" destroyOnClose title="节点设置">
    <node-setting :nodeData="nodeData" :proName="proName" :closeModal="closeModal" @nodeDetailData="getNodeDetail"/>
  </a-modal>
</template>

<script>
import {defineComponent, reactive, toRefs, ref, onMounted, onBeforeUnmount, nextTick} from 'vue'
import {UnorderedListOutlined } from '@ant-design/icons-vue'
import { message, Modal } from 'ant-design-vue'
import {getRequestURI, request} from '../../../utils/request.js'
import {getExtendUri} from '../../../utils/request_extend'
import NodeSetting from './preview/nodeSetting.vue'
  export default defineComponent({
  components: {
    NodeSetting,
    UnorderedListOutlined
  },
  props:{
    searchfFilters: { type: Object },
    handleSearch:{
      type: Function
    },
    current:{
      type: Number
    }
  },

  setup(props){
    const dragState = reactive({
      isDrag: false,
      dragTarget: null, // 父节点的事件
      // 鼠标点击获取的值
      dragText:'',
      dragX: 0,  // 鼠标开始位置
      dragY: 0,  // 鼠标结束位置
    })
    const boxState = reactive({
      boxLeft: 0,
      boxRight: 0,
      boxTop: 0,
      boxBottom: 0
    })
    const dragElRef = ref()
    const dragLayoutRef = ref()
    const state = reactive({
      isUpdate:false,
      title: '新增流程配置',
      proName:'',  // 流程名称
      protype:1,  // 流程状态 默认启用
      positionList: [],
      oldData:{}, // 拖拽已选的旧数据
      newData: {}, // 拖拽过程的数据
      visible: false,
      processData:[],
      proObj: {},
      id:'',
      status:undefined,
      nodeShow: false,

    })
    const dragDel = reactive({
      isDel: false,
      dragTarget: null,
      // 鼠标点击获取的值
      delText:'',
      delX: 0,  // 鼠标开始位置
      delY: 0,  // 鼠标结束位置
    })
    const startDrag = (event,pro) =>{
      if(event.button !== 0)  return
      if(event.button === 0){
        state.proObj = pro
        dragState.dragTarget = event.currentTarget
        dragState.dragText = event.target.innerText ? event.target.innerText : getText(event.path)
        dragState.dragX = event.clientX
        dragState.dragY = event.clientY
        dragElRef.value.innerHTML = event.currentTarget.outerHTML
        dragElRef.value.style.left = event.clientX + 'px'
        dragElRef.value.style.top = event.clientY + 'px'
        dragState.dragTarget.classList.add('drag')
        dragState.isDrag = true
      }
      nextTick(()=>{
        dragLayoutRef.value.addEventListener('mouseup', stopDrag)
        dragLayoutRef.value.addEventListener('mousemove', drag)
        getSelectedBox()
      })
    }
    const getText = (text) =>{
      for (let val of text) {
        if(val.className === 'content_detial' || val.className === 'content'){
          return val.innerText
        }
      }
    }
    // 鼠标拖拽时 的坐标
    const drag = (event) => {
      if (event.button === 0 && dragState.isDrag === true) {
        event.preventDefault() // 阻止默认行为
        dragElRef.value.style.left = event.clientX + 'px'
        dragElRef.value.style.top = event.clientY + 'px'
      }
    }

    // 拖拽结束时 的回调
    const stopDrag = (event) => {
      console.debug(event)
      getBox()
    }
    const getBox = () => {
      state.proObj.identification = algorithm()
      state.positionList.push(JSON.parse(JSON.stringify(state.proObj)))
      dragState.dragTarget.classList.remove('drag')
      dragLayoutRef.value.classList.remove('drag')
      Object.assign(dragState, {
        isDrag: false,
        dragTarget: null,
        dragText:'',
        dragX: 0,
        dragY: 0,
      })
      dragLayoutRef.value.removeEventListener('mouseup', stopDrag)
      dragLayoutRef.value.removeEventListener('mousemove', drag)
    }
    const algorithm =  function(){
        let abc=['a','b','c','d','e','f','g','h','i','g','k','l','m','n','o','p','q','r','s','t','u','v','w','x','y','z']
        let [max,min]=[Math.floor(Math.random()*(10-7+1)+1),Math.floor(Math.random()*(17-10+1)+17)]
        let newAbc=abc.sort(()=>0.4-Math.random()).slice(max,min).slice(0,8).join('')
        return new Date().getTime()+ newAbc
      }

    // 获取已选流程元素位置
    const getSelectedBox = () =>{
      let selectBox = document.getElementById('select_proBox')
      let position =  selectBox.getBoundingClientRect()
      boxState.boxLeft = position.left
      boxState.boxRight = position.right
      boxState.boxTop = position.top
      boxState.boxBottom = position.bottom
    }
    onMounted(() => {

    })
    onBeforeUnmount(() =>{
      dragLayoutRef.value.classList.remove('drag')
      dragLayoutRef.value.classList.remove('stopDrag')
    })

    const onReact = () =>{
      state.positionList = []
    }
    // 1. 已选区域的位置拖拽  赋值
    const dragstart = (event, item) =>{
      console.debug(event)
      state.oldData = item
    }
    // 2. 当被鼠标拖动的对象进入其容器范围内时，将元素赋值给 newData。
    const dragenter = (event, value) =>{
      state.newData = value
      event.preventDefault()
    }
    // 3, 用户完成元素拖动后触发，比较新值和旧值是否相同，如果不同，则从数组中获取对应的 index，删除老的节点，在数组中，将新值添加进入
    const dragend = (event) =>{
      getSelectedBox()
      if(event.clientX < boxState.boxLeft || event.clientX > boxState.boxRight ||
         event.clientY < boxState.boxTop  || event.clientY > boxState.boxBottom
      ){
        Modal.confirm({
          title: '删除提示',
          content: '确定删除此数据吗?',
          onOk: () => {
            message.success('删除成功')
            state.positionList.forEach((item,index) =>{
              let itemId = item.id ? item.id :item.identification
              if(itemId == state.newData.identification || itemId === state.newData.id){
                state.positionList[index].dragModuleList = []
                state.positionList.splice(index,1)
              }
            })
          },
          onCancel: () => {
            Modal.destroyAll()   // 清除弹出框
          }
        })
      } else {
          let oldIndex = ''
          let newIndex = ''
          state.positionList.forEach((item,index)=>{
            //
            if((item.identification && item.identification === state.oldData.identification) ||
               (item.id && item.id === state.oldData.id)){
              oldIndex = index
            }
            // item.identification === state.newData.identification ||
            if((item.identification && item.identification === state.newData.identification) ||
            (item.id && item.id === state.newData.id)){
              newIndex = index
            }
          })
          let newItems = [...state.positionList]
          // 删除老的节点
          newItems.splice(oldIndex, 1)
          // 在列表中目标位置增加新的节点
          newItems.splice(newIndex, 0, state.oldData)
          state.positionList = [...newItems]
        // }
      }

    }
    // 4.触发 drop事件，需要阻止 dragover事件的默认行为。
    const dragover = (e) =>{
      e.preventDefault()
    }
    const showDrawer = (oldData, num) =>{
      if(num){
        state.title = '修改流程配置'
        state.isUpdate = true
        state.proName = oldData.name
        state.id = oldData.id
        state.status = oldData.status
        getDetails(oldData.id)
      } else {
        state.title = '新增流程配置'
      }
      state.visible = true
      getProcess()
    }

    // 获取可选流程
    const getProcess = () => {
      request(getExtendUri('dictionary_query_byType'), {data: {types:['process']}}).then(
        res => {
          res.result.process.forEach(item =>{
            Object.assign(item, {
              value: item.value,
              label: item.label,
              relationProcessId: item.value,
              pageStyleId: '2',
              associatedTemplateId: '',
              pageJson: ''
            })
          })
          state.processData = res.result.process
        })
    }
    // 详情
    const getDetails = (id) =>{
        request(getRequestURI('process_processDetails'),{id}).then(
          res =>{
            if(res.code === 1 && res.result.processDictList.length > 0){
              state.positionList = res.result.processDictList
            }else {
              message.warning('暂无可选流程')
            }
          }
        )
      }
    // 新增 / 修改
    const addPro = () =>{
      if (!state.proName) return message.warning('请输入流程名称')
      if (!state.positionList.length) return message.warning('请选择节点')
        state.positionList.forEach(item =>{
          if(!item.relationProcessId){
           item.relationProcessId =  item.code ?  item.code : item.value
          }
          delete item.id
        })
        request(getRequestURI('process_addProcess'), {
          id: state.isUpdate ? state.id : null,
          name:state.proName,
          nodeList:state.positionList
        }).then(
          res => {
            if(res.code === 1){
              message.success('保存成功')
              props.handleSearch(props.searchfFilters, props.current)
              onClose()
            }else{
              message.warning(res.message)
            }

        })

    }
    // const nodeData = reactive({})
    const nodeData = ref({})
    // 点击已选节点的图标 触发
    const setNodeClick = (node) =>{
      // Object.assign(nodeData, {})
      nodeData.value={}
      if(!state.proName){
        return message.warning('请输入流程名称')
      }
      nodeData.value={...node}
        let processDataList=state.processData.filter(item=>item.value===node.code)
      if(!nodeData.value.type){

        nodeData.value.type=processDataList[0].type
      }
      if(!nodeData.value.value){

        nodeData.value.value=processDataList[0].value
      }

      state.nodeShow = true
    }
    // 获取节点配置的数据
    const getNodeDetail = (value) =>{
      console.debug('节点配置的数据', value,state.positionList)
      let dragModule = ''
      let node = {}
      if(value.dragModuleList && value.dragModuleList.length){
        dragModule = JSON.stringify(value.dragModuleList)
      }
      node = {
        relationProcessId: value.nodeId, // 流程id（字典）
        pageStyleId: value.pageStyleId, // 界面样式id
        associatedTemplateId: value.associatedTemplateId, //关联模板Id
        pageJson: dragModule, //页面json
      }
      state.positionList.forEach((item,index)=>{
        if((item.identification && item.identification === value.identification) || (item.id && item.id === value.id)){
          Object.assign(state.positionList[index],{
            name:value.nodeName,
            code:value.nodeId,
            dataList: value.clauseTypeList,
            ...value,
            ...node,
          })
        }
      })
      //  value.clauseTypeList = []
    }


    const onClose = () =>{
      state.visible = false
      state.proName = ''
      state.protype = 1
      state.isUpdate= false
      state.nodeShow = false
      // Object.assign(nodeData, {})
      nodeData.value={}
      console.log('onclose111111111',nodeData.value)
      state.positionList = []
    }
    const closeModal = (value) =>{
      state.nodeShow = value
    }




    return{
      ...toRefs(dragState),
      ...toRefs(state),
      ...toRefs(dragDel),
      getNodeDetail,
      closeModal,
      nodeData,
      setNodeClick,
      getDetails,
      addPro,
      startDrag,
      getSelectedBox,
      getProcess,
      drag,
      stopDrag,
      onReact,
      dragstart,
      dragenter,
      dragend,
      dragover,
      showDrawer,
      onClose,
      dragLayoutRef,
      dragElRef,
      boxState,

    }
  }
})
</script>

<style  scoped>
 .drag_header{
   padding: 30px;
 }
 .currentPro{
   display: flex;
   justify-content: flex-start;
 }
 .currentPro_left{
   width: 30%;
 }
 .steps{
   width: 250px;
   height: 517px;
   overflow: auto;
 }
 .currentPro_right{
   display: flex;
   justify-content: flex-start;
 }
 .selectedPro{
   width: 50%;
 }
 .optionalPro{
   flex: 1;
   margin-left: 96px;
 }
 .drag_content{
   position: fixed;
   width: 339px;
   height: 88px;
   background: rgb(255, 255, 255);
   border-radius: 10px;
   border: 1px solid #bbbbbb;
   display: flex;
   justify-content: flex-start;
   overflow: hidden;
   opacity: 0.8;
   z-index: 99999;
 }
 .center_box{
   text-align: center;
 }
 .continer{
   min-width: 375px;
   height: 517px;
   padding: 17px;
   background: #f2f2f2;
   overflow:hidden;
   overflow-y: auto;
 }
 .content{
   transition: transform 0.3s;
   position: relative;
   width: 340px;
   height: 88px;
   margin-bottom: 10px;
   background: rgb(255, 255, 255);
   border-radius: 10px;
   border: 1px solid #bbbbbb;
   display: flex;
   justify-content: flex-start;
 }
 .content_icon{
   padding-left: 20px;
   position: absolute;
   top: 0;
   left: 0;
   line-height: 100px;
 }
 .content_detial{
    width: 100%;
    text-align: center;
    line-height: 85px;
  }
  .content_left{
    float: left;

  }
  .header::after{
    content: '';
    display: block;
    clear: both;
  }
  .content_right{
    float: right;
    margin-right: 20px;
    margin-top: 5px;
    cursor: pointer;
  }
  .bottom_left{
    float: left;
    width: 100px;
    border-right: 1px solid rgb(0, 0, 0);
    font-size:12px;
    font-weight: 700;
  }
  .bottom_right{
    float: left;
    font-size:12px;
    font-weight: 700;
    margin-left: 20px;
  }
</style>
